import React, { useEffect, useState } from "react";
import { Box, MenuItem, Radio, Select, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
  Button,
} from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import toast from "react-hot-toast";
import { updateUserAccount } from "../../../store/user/my-account";
import useStyles from "./style";
import DateRange from "../../../components/date-range/date-range";
import { getGeneralReports } from "../../../store/crm/reports";
import moment from "moment";
import momentTz from 'moment-timezone';
import { useDispatch, useSelector } from "react-redux";
import Waveloading from "../../loading/Waveloading";
import { updateCrmCalenderFilter } from "../../../store/crm/calender";
import { getProviderStaff } from "../../../store/crm/staff";


export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  // marginRight: "5px",
  // marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },

};

export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginRight: "5px",
  marginLeft: "5px",
  height: '44px',
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",
    
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const GeneralReport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [age, setAge] = React.useState('');
  const [staff, setStaff] = React.useState('');
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Booking");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
 

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const reportsStore = useSelector(state => state?.reportsStore)
  const { generalReports = [], } = reportsStore
  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const {crmStaffs = []} = crmStaffStore
  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
  });

console.log("staff data",crmStaffs)
  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };
  let timeZone = localStorage.getItem("timeZone");
  const today = momentTz.tz(timeZone).toDate();
  const formik = useFormik({
    initialValues: {
      start_date: today,
      end_date: today,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
        end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
        status: selectedStatus,
        booking_type: selectedBookingType,
        date_type: selectedRadio,
        staff_id :staff,
      };

      try {
        setLoading(true);
        await dispatch(getGeneralReports(payload));
        setLoading(false);
        setDataFetched(true);
      } catch (error) {
        console.error(error);
      }
    },
  });
  // const handleChange = (event) => {
  //   const newValue = event.target.value;
  //   dispatch(getProviderStaff());
  //   handleStaffChange(event, newValue);
  // };
  useEffect(() => {
    dispatch(getProviderStaff());
  }, []);
    
  const handleChanges = (event) => {
    setAge(event.target.value); 
  };
  const handleChange = (event) => {
    setStaff(event.target.value); 
  };
  return (
    <>
    <Typography variant="h5" color="initial">
    <DescriptionIcon sx={{color:'#09ABF1',marginTop:'-3px'}}/>  General Reports
    </Typography>
      <Grid container sx={{ alignItems: { md: 'start', xs: 'center' }, justifyContent: "space-between", mt: {sm:3, xs:0} }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid  
            item
            lg={12}
            xs={12}
            sx={{ display: "flex", flexWrap: 'wrap' }}
          >
            <FormControl sx={{ width: { xs: '100%', sm: '220px' },  mt: { xs: 2, sm: 1 }, }}>
              <FormLabel>Search</FormLabel>
              <TextField
                variant="outlined"
                name={"email"}
                sx={inputStyle}
                placeholder={"Service Name"} 
                value={age}
                onChange={handleChanges}  
              />
            </FormControl>        

        <FormControl
              sx={{   
                minWidth: { xs: '100%', sm: '220px' },
                mt: { xs: 2, sm: 1 },    
              }}    
            >  
              <FormLabel>Select Date</FormLabel>    
              <DateRange 
              maxHeight={"44px"}   
        setStartDate={(date) => formik.setFieldValue('start_date', date)}  
        startDate={formik.values.start_date}   
        setEndDate={(date) => formik.setFieldValue('end_date', date)}
        endDate={formik.values.end_date}        
        onBlur={formik.handleBlur}                                                                                                                  
      />      
                {(formik.touched.start_date || formik.touched.end_date) && (!formik.values.start_date || !formik.values.end_date) && (
    <div style={{ color: "red" }}>Date field is required</div>  
  )}   
            </FormControl>

            <FormControl  sx={{    
                  minWidth: { xs: "100%", sm: "220px" },  
                  maxWidth: { xs: "100%", sm: "220px" },   
                  mt: { xs: 2, sm: 1 },  }}>
              <FormLabel>Status</FormLabel>
              <Select
                value={selectedStatus}
                onChange={handleChangeStatus}
                displayEmpty
                sx={selectStyle}
              >
                <MenuItem value="All">Status</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="UnPaid">UnPaid</MenuItem>
                <MenuItem value="CheckOut">Completed</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="NoShow">No show</MenuItem>
                <MenuItem value="Cancel">Cancel</MenuItem>
                <MenuItem value="Void">Void</MenuItem>
                <MenuItem value="Confirmed">Confirmed</MenuItem>
              </Select>
            </FormControl>      
            <FormControl    
                sx={{    
                  minWidth: { xs: "100%", sm: "220px" },  
                  maxWidth: { xs: "100%", sm: "220px" },   
                  mt: { xs: 2, sm: 1 },  
                }}
              >     
                <FormLabel>Staff</FormLabel>  
                <Select
                  value={staff}             
                  onChange={handleChange}
                  displayEmpty
                  sx={selectStyle}
                >
                   {crmStaffs?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>     
                  ))}   
                </Select>
              </FormControl>      
    
          </Grid>
          <Grid item lg={12} xs={12} sx={{ display: 'flex', flexWrap: 'wrap', mt: 3 }}>
            <RadioGroup     
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"        
              value={selectedRadio}    
              onChange={handleRadioChange}
              defaultValue="top"     
              sx={{ mt: 2 }}  
            >     
              <FormControlLabel   
                value="Created"
                control={<Radio />}
                sx={{ mr: { xs: 1, md: 5 } }}
                label="By Created Date"
              />  
              <FormControlLabel   
                value="Booking"     
                control={<Radio />}    
                label="By Booking  Date"
              />   
            </RadioGroup>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, ml: 3, justifyContent:{sm:'unset', xs:'end',},'@media (max-width: 800px) ': {width:'100%'}, }}>
              <Button variant="contained" color="primary" type="submit">
                Fetch Report
              </Button>
            </Box>
          </Grid>
        </form>

      </Grid>

      {loading ? ( 
        <Waveloading />
      ) : dataFetched ? (
        <>

          <Grid  
            container
            sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}
          >


            <Grid item lg={5.8} md={5.9} xs={12}>
              <Box>
                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box 
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",

                      }}
                      >
                2      Net Sales
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      


                      }}
                    > 
                      ${generalReports?.data?.net_sales}
                    </Typography>
                  </Box>
                </Grid>
 
              </Box>  
              <Box> 
                <Grid
                  item
                  xs={12}

                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",

                      }}
                    >
                      Discounts

                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >   
                      ${generalReports?.data?.total_discount}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
              <Box>

                <Grid

                  item
                  xs={12}
                  className={classes.odd}    
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box  
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      NET SALE AFTER DISCOUNT
                    </Typography> 
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.net_sale_after_discount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>
                <Grid
                  item
                  xs={12}

                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      TAX
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_tax_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      GROSS SALES
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.gross_sales}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                <Grid

                   item
                  xs={12}

                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                     sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      TIPS
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_tips}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      WALLET USED
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${(generalReports?.data?.total_wallet_used).toFixed(2)}

                    </Typography>
                  </Box>
                </Grid>

              </Box>
            </Grid>
            <Grid item lg={5.8} md={6} xs={12} sx={{ mt: { md: 0, xs: 3 } }}>
              <Box>
                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      CASH
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_cash_received}
                    </Typography>
                  </Box>
                </Grid>

              </Box>

              <Box>

                <Grid

                  item
                  xs={12}

                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      EXTERNAL DEVICE/E-TRANSFER
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_external_device_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                {/* <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      BRAINTREE
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_braintree_amount}
                    </Typography>
                  </Box>
                </Grid> */}

              </Box>
              <Box>
                <Grid
                  item
                  xs={12}

                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      SQUARE ONLINE
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_square_online_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              {/* <Box>

                <Grid
                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",

                      }}
                    >
                      E-TRANSFER
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_e_transfer_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box> */}
              <Box>

                <Grid

                  item
                  xs={12}

                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      GIFT CARD
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >

                      ${generalReports?.data?.total_gift_card_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      TOTAL COLLECTED
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >

                      ${generalReports?.data?.total_collected_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
            </Grid>

          </Grid>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}
          >
            <Grid item lg={5.8} md={5.9} xs={12}>
              <Box>


                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      MEMBER RENEWAL AMOUNT
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.member_renewal_amount}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                <Grid

                  item
                  xs={12}

                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",

                      }}
                    >
                      NEW MEMBERS
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.new_member_amount}
                    </Typography>
                  </Box>
                </Grid>


              </Box>
              <Box>


                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      TOTAL MEMBERSHIP AMOUNT
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex", 
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${(generalReports?.data?.member_renewal_amount + generalReports?.data?.new_member_amount || 0).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>

              </Box>

            </Grid>
            <Grid item lg={5.8} md={5.9} xs={12} sx={{ mt: { md: 0, xs: 3 } }}>
              <Box>

                <Grid

                  item
                  xs={12}
                  className={classes.odd}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                   TOTAL COLLECTED AMOUNT (TAX)
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${((generalReports?.data?.member_renewal_amount) + (generalReports?.data?.new_member_amount) + (generalReports?.data?.gross_sales  || 0) +(generalReports?.data?.total_tips  || 0)).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
              <Box>

                <Grid

                  item
                  xs={12}
                  sx={{
                    fontWeight: "bold",
                    background: "#fff",
                    borderRadius: "8.4769px",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: { md: '30px', xs: '20px' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      TOTAL (WITHOUT TAX)
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#000000",
                        fontSize:'20px',
                        fontWeight: 'bold'
                      }}
                    >
                      ${generalReports?.data?.total_bookings_without_tax_count}
                    </Typography>
                  </Box>
                </Grid>

              </Box>
            </Grid>
          </Grid>

        </>
      ) : ""}
    </>

  );
};
export default GeneralReport;

