import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getUserChartFormSlice, storeChartFormData, updateChartFormData} from "../../../store/user/chart-form";
import {Avatar, Box, Button, FormControl, FormLabel, TextField, Typography, Grid} from "@mui/material";
import logo from '../../../assets/logo/3d-white-logo.jpg'
import CustomModal from "../../../components/modal/customModal";
import UserSignature from "./UserSignature";

import * as yup from "yup";
import * as Yup from "yup";
import {useFormik} from "formik";
import Slide from "react-reveal/Slide";

import {useLocation} from "react-router-dom";
import PopupLogin from "../../login/popupLogin";
import {successConfirmation} from "../../../services/utills";
import printer from "../../../assets/images/printer.png"
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
  
const ChartForm = () => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(null)
    const [isSignature, setIsSignature] = useState("")
    const [loginOpen, setOpenLogin] = useState(false)
    const IsSubmited =  useRef(false);

    const user = useSelector((state) => state?.authStore?.user);
    const dispatch = useDispatch()
    const {slug, provider_id,customer_id} = useParams()
    const {pathname} = useLocation()
    const tableRef = useRef(null);

    const isEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    console.log("User"+ user)
    // activeClinics
// const user = userChartFormStore
const userChartFormStore = useSelector((state) => state?.userChartFormStore)
const {activeClinics = {}} = userChartFormStore
    const validationSchema = yup.object({
        name: Yup.string().required('Required'),
        date: Yup.string().required('Required'),
        signature: Yup.string().required('Required'),
     /*   staff_name: Yup.string().required('Required'),
        staff_date: Yup.string().required('Required'), 
        staff_signature: Yup.string().required('Required'),*/
    });


    const handleUpdateClick = async () => {
        let finalData = {...values};
        finalData['customer_id'] = customer_id || user?.id;
        finalData['treatment_form_id'] = formData?.id;
        finalData['provider_id'] = provider_id; 
    
        let fieldsData = {};
        const targetDiv = document.getElementsByTagName('form')[0];
        if (targetDiv) {
            const formElements = targetDiv.querySelectorAll('input, textarea, select');
            formElements.forEach((element) => {
                const { value, name, checked, type } = element;
                if ((type === 'checkbox' || type === 'radio') && checked) {
                    fieldsData[name] = checked;
                } else {
                    fieldsData[name] = value;
                }
            });
        }
        if (Object.keys(fieldsData)?.length > 0) {
            finalData['fields_data'] = fieldsData;
        }
    
        const API_ENDPOINT = 'https://api.3dlifestyle.ca/api/update-treatment-form-data';

        try {
            const response = await fetch(API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(finalData),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            if (response.ok) {
                // Show the success confirmation modal
                await successConfirmation('Form Updated Successfully', 'This Tab is going to be closed.');
    
                // Once the user acknowledges the success, close the parent modal
                window.parent.postMessage({ type: 'closeModal' }, '*');
            }
    
            // Handle success (e.g., show confirmation message)
        } catch (error) {  

            // Handle error (e.g., show error message)
        }
    };

    const {values, handleSubmit, setFieldValue, handleChange, resetForm, touched, errors} = useFormik({
        initialValues: {
            name: '',
            date: '',
            signature: '',  
            staff_name: '',
            staff_date: "",
            staff_signature: "",  
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
                setLoading(true)
                let finalData = {...data}
                finalData['customer_id'] = customer_id || user?.id
                finalData['treatment_form_id'] = formData?.id
                finalData['provider_id'] = provider_id


                let fieldsData = {}
            const targetDiv = document.getElementsByTagName('form')[0]; // Accessing the first form element

            if (targetDiv) {
                const formElements = targetDiv.querySelectorAll('input, textarea, select');
                formElements.forEach((element) => {
                    const { value, name, checked, type } = element;
                    if (type === 'checkbox' && checked) {
                        fieldsData[name] = checked;
                    } else {
                        fieldsData[name] = value;
                    }
                });
            }



            if (Object.keys(fieldsData)?.length > 0) {
                    finalData['fields_data'] = fieldsData  
                }

                let {payload} = await dispatch(storeChartFormData(finalData))
                if (payload?.success === 'true'){
                    await successConfirmation('Form Submitted Successfully','This Tab is going to be  close.')
                    CloseWindow()
                }

                setLoading(false)
        }
    })


    useEffect(() => {
        (async () => {
            if (slug && provider_id) {
                   fetchUserChartData()
            }
        })()

    }, [])

    const CloseWindow = () => {
        if(user?.id) {
            window.parent.postMessage({ type: 'closeModal' }, '*');
        }else{
            window.location.href = 'https://app.3dlifestyle.ca'; // Navigating to a blank page
        }

    }
    const fetchUserChartData = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        let submit = 0
        if (queryParams.has("submit")) {
            // Get the value of the 'service_slug' parameter
            submit = queryParams.get("submit");
        }
        setLoading(true)
        let payloadData = {
            slug: slug,
            provider: provider_id,
            submit: submit,
            user: customer_id || user?.id
        }  

        let {payload} = await dispatch(getUserChartFormSlice(payloadData))
        setFormData(payload)
        if (payload?.is_submitted) {
            IsSubmited.current = true; // Mark as run
  
            const foam_submitted_data = payload?.foam_submitted_data
           setFieldValue("name",foam_submitted_data?.name)
           setFieldValue("date",foam_submitted_data?.date)
           setFieldValue("signature",foam_submitted_data?.signature)
           setFieldValue("staff_name",foam_submitted_data?.staff_name)
           setFieldValue("staff_date",foam_submitted_data?.staff_date)
           setFieldValue("staff_signature",foam_submitted_data?.staff_signature)  

            if (foam_submitted_data?.fields_data !== null){
                Object.keys(foam_submitted_data?.fields_data).forEach( (key, index) =>{
                    try {
                        let element = document.getElementsByName(key)
                        if (element[0]?.type === 'checkbox'){
                            element[0].checked = foam_submitted_data?.fields_data[key]
                        }else {
                            element[0].value = foam_submitted_data?.fields_data[key]
                        }
                    }catch (e) {
                    }
                })
            }

        }
        setLoading(false)
       
    }
    const handleReset = () => {
        const form = document.getElementById('chart-form');
        if (form) {
            form.reset();
        }
        resetForm()
    }

    const saveSignature = (signature) => {
        if (isSignature === 'staff') {
            setFieldValue('staff_signature', signature)  
        } else {
            setFieldValue('signature', signature)
        }
    }


    const handleSuccessLogin = () => {
        if (user?.role_id !==3){
            setOpenLogin(false)
            fetchUserChartData()
        }
    }
    const handleDownload = () => {
        const table = tableRef.current;

        if (!table) {
            console.error('Table not found.');
            return;
        }

        const pdf = new jsPDF('p', 'pt', 'a4');

        // Define the PDF dimensions
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Use html2canvas to render the table to a canvas
        html2canvas(table, {
            scale: 2, // Higher scale for better quality
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');

            // Get the canvas dimensions
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // Calculate scaling to fit within the PDF dimensions
            const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);

            // Calculate new dimensions for the image
            const imgWidth = canvasWidth * ratio;
            const imgHeight = canvasHeight * ratio;

            // Center the image in the PDF
            const xOffset = (pdfWidth - imgWidth) / 2;
            const yOffset = (pdfHeight - imgHeight) / 2;

            // Add the image to the PDF
            pdf.addImage(imgData, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);

            // Save the PDF
            pdf.save('form.pdf');
        }).catch((error) => {
            console.error('Error generating PDF:', error);
        });
    };



    return (
        <Box
            sx={{
                background: '#D5DBDB',

            }}
        >
            {loginOpen ?
                <Slide top>
                    <CustomModal
                        open={loginOpen}
                        isCloseHide
                        customWidth={'700px'}
                        setOpen={()=>{
                            if(user?.id &&  user?.role_id ===3) {
                                setOpenLogin(false)
                            }
                        }}
                    >
                        <PopupLogin loginOpen={loginOpen} setOpenLogin={()=>handleSuccessLogin()}/>
                    </CustomModal>  
                </Slide>   
                : ""
            }
            <div ref={tableRef}>
            {formData?.name && formData?.description && <Box
                sx={{
                    background: '#fff',
                    p: '40px 20px',
                    mx: 'auto',
                    maxWidth: {xs: '90%', sm: 'sm', md: "md", lg: 'lg', xl: '1300px'}
                }}
            >
                <Box >
                    <Box style={{display:"flex",justifyContent:"flex-end",marginBottom:"2px"}}>
                    <Button
                variant="varient"
                color="white"
                sx={{color:"white",backgroundColor:"green", '&:hover': {
                    backgroundColor: "white", 
                    color: "green",           
                    border: "1px solid green" 
                } }}
                
                
                onClick={handleDownload}
              >
               Download
              </Button>
                    </Box>
           <Box  style={{display:"flex",justifyContent:"space-between"}}>
  <img
    // src={formData.treatment_form_logo}
    src={logo}
    alt="Treatment Form Logo"
    style={{
        width: '261px',
        height: '90px'
    }}
  />

{
  formData?.treatment_form_logo &&
  <img
    src={formData.treatment_form_logo}

    alt="Treatment Form Logo"
    style={{
        width: '261px',
        height: '90px'
    }}
  />
} 

</Box> 

                    <Box
                        sx={{
                            background: '#00AEEF',
                            py: '22px',
                            mb: '16px',
                            mt: '6px',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center', 
                                color: '#fff',
                                fontSize: "26px",
                            }}
                        >
                            {formData?.name}  
                        </Typography>
                    </Box>
                </Box>
                <form id="chart-form" onSubmit={handleSubmit}>  
                    <Box id={'htmlData'} >
                        {formData?.description && <div dangerouslySetInnerHTML={{__html: formData?.description}}/>}
                    </Box>
                    <Box
                        sx={{  
                            background: '#F6F6F6',
                            width: '100%',
                            p: '20px',
                            mt: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: '20px',
                                alignItems: 'center',
                               
                            }}
                        >
                            <Grid container spacing={3} sx={{ display:"flex",justifyContent:"space-between",alignItems:"center" }}>
                               <Grid item xs={12} md={6} lg={4}>
                               <FormControl
                                sx={{ width:"100%" }}
                            >
                                <FormLabel style={{color: '#000'}}>
                                    Name
                                </FormLabel>
                                <TextField
                                    value={values?.name}
                                    onChange={handleChange}
                                    variant="outlined"
                                    sx={{
                                        width: '100%',
                                        border: 'none !important',
                                        borderBottom: '2px solid black !important',
                                        borderRadius: '0px !important',
                                        '& .MuiOutlinedInput-notchedOutline': { // targeting the fieldset here
                                            border: 'none',
                                        }
                                    }}
                                    name={'name'}
                                    error={errors?.name && touched?.name}
                                />
                            </FormControl>
                               </Grid>
                               <Grid item xs={12} md={6} lg={4}>
                               <FormControl
                                sx={{ width:"100%" }}
                            >
                                <FormLabel style={{color: '#000'}}>
                                    Signature
                                </FormLabel>
                                <Box
                                    sx={{
                                        width: '100%',
                                        // borderRadius: '5px',
                                        border: `1px solid ${errors?.signature && touched?.signature ? '#D32F2F' : "transpatent"}`,
                                        borderBottom:'2px solid black',
                                        height: '56px',
                                        cursor: 'pointer',
                                        p: '5px 10px',
                                        background: "transpatent",
                                    }}
                                    onClick={() => setIsSignature('user')}

                                >
                                    {values?.signature && <img
                                        style={{height: '100%'}}
                                        alt={'signature'}
                                        src={values?.signature}
                                    />}
                                </Box>
                            </FormControl>
                               </Grid>
                               <Grid item xs={12} md={12} lg={4}>
                               <FormControl   
                                sx={{ width:"100%" }}
                            >
                                <FormLabel style={{color: '#000'}}>
                                    Date
                                </FormLabel>   
                                <TextField
                                    value={values?.date}
                                    onChange={handleChange}
                                    variant="outlined"
                                    type={'date'}
                                    sx={{
                                        background: "transpatent",
                                        borderBottom:'2px solid black',
                                        '& .MuiOutlinedInput-notchedOutline': { // targeting the fieldset here
                                            border: 'none',
                                        }
                                    }}
                                    name={'date'}
                                    error={errors?.date && touched?.date}
                                />
                            </FormControl>
                               </Grid>
                            </Grid>
                            
                            
                            
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {!isEmpty(user) && user?.role_id !== 3 && (
                                <>

                                <Grid container spacing={3} sx={{ display:"flex",justifyContent:"space-between",alignItems:"center" }}>
                                   <Grid item xs={12} md={6} lg={4}>
                                   <FormControl sx={{ width: '100%' }}>
                                        <FormLabel style={{ color: '#000' }}>Staff Name</FormLabel>
                                        <TextField
                                            value={values?.staff_name}
                                            onChange={handleChange}
                                            sx={{
                                                background: 'transparent',
                                                borderBottom:'2px solid #000',
                                                '& .MuiOutlinedInput-notchedOutline': { // targeting the fieldset here
                                                    border: 'none',
                                                }
                                            }}
                                            variant="outlined"
                                            name={'staff_name'}
                                            error={errors?.staff_name && touched?.staff_name}
                                        />
                                    </FormControl>
                                   </Grid>
                                   <Grid item xs={12} md={6} lg={4}>
                                   <FormControl sx={{ width: '100%' }}>
                                        <FormLabel style={{ color: '#000' }}>Signature</FormLabel>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                border: `1px solid ${
                                                    errors?.staff_signature && touched?.staff_signature
                                                        ? '#D32F2F'
                                                        : 'transparent'
                                                }`,
                                                borderBottom:'2px solid black',
                                                height: '56px',
                                                cursor: 'pointer',
                                                p: '5px 10px',
                                                background: 'transpatent',
                                                '& .MuiOutlinedInput-notchedOutline': { // targeting the fieldset here
                                                    border: 'none',
                                                }
                                                
                                            }}
                                            onClick={() => setIsSignature('staff')}
                                        >
                                            {values?.staff_signature && (
                                                <img
                                                    style={{ height: '100%' }}
                                                    src={values?.staff_signature}
                                                    alt={'signature'}
                                                />
                                            )}
                                        </Box>
                                    </FormControl>
                                   </Grid>
                                   <Grid item xs={12} md={12} lg={4}>
                                   <FormControl sx={{ width: '100%' }}>
                                        <FormLabel style={{ color: '#000' }}>Date</FormLabel>
                                        <TextField
                                            type={'date'}
                                            value={values?.staff_date}
                                            onChange={handleChange}
                                            variant="outlined"
                                            sx={{
                                                background: '#transpatent',
                                                borderBottom:'2px solid #000',
                                                '& .MuiOutlinedInput-notchedOutline': { // targeting the fieldset here
                                                    border: 'none',
                                                }
                                            }}
                                            name={'staff_date'}
                                            error={errors?.staff_date && touched?.staff_date}
                                        />
                                    </FormControl>
                                   </Grid>
                                     
                                  
                                </Grid>
                                    
                                    
                                    
                                </>
                            )}
                        </Box>
                    </Box>
                        <Box
                            sx={{
                                mt: '32px',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '20px',
                            }}
                        >
                            <Button
                                variant={'outlined'}
                                color={'error'}
                                type={'button'}
                                sx={{
                                    p: '5px 40px',
                                }}
                                onClick={CloseWindow}
                            >
                                Close
                            </Button>
                            <Button
                               onClick={handleUpdateClick}
                                    sx={{
                                        p: '5px 40px',
                                        display: IsSubmited.current ? 'block' : 'none',
                                    }}
                                    variant={'outlined'}
                                >
                                    Update
                                </Button>

                            {
                                !IsSubmited.current &&
                                <Button
                                    type={'button'}
                                    color={'secondary'}
                                    sx={{ p: '5px 40px' }}
                                    variant={'outlined'}
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            }
                            {
                                !IsSubmited.current &&
                                <Button
                                    type={'submit'}
                                    sx={{
                                        p: '5px 40px',
                                    }}  
                                    variant={'outlined'}
                                >
                                    Save
                                </Button>
                            }

                        </Box>
                </form>
            </Box>
            }
            </div>
            <CustomModal
                customWidth={"800px"}
                open={isSignature !== ''}
                setOpen={() => setIsSignature('')}
            >
                <UserSignature
                    setOpen={() => setIsSignature('')}
                    signature={isSignature === 'staff' ? values?.staff_signature : values?.signature}
                    setSignatuer={saveSignature}
                />
                {/* chaart form changes */}
            </CustomModal>
        </Box>
    );
};

export default ChartForm;