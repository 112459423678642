import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";


export const getAllClinicsBusinessHours = createAsyncThunk('getAllClinicsBusinessHours', async (provider_id,{dispatch}) => {
    try {
        const response = await axios.get(apis.get_clinic_business_hours+`${provider_id}?page=1&limit=100`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})


export const storeClinicBusinessHours = createAsyncThunk('storeClinicBusinessHours', async (data,{dispatch}) => {
    try {

        const response = await axios.post(apis.store_clinics_business_hours, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.errors) {
            console.log( response?.data?.errors)

        } else if (response?.data?.success === "true") {
            await dispatch(getAllClinicsBusinessHours(data?.provider_id))
            toast.success(` Business Hours Updated Successfully`, {duration: 3000})
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})



export const storeClinicHolidays = createAsyncThunk('storeClinicHolidays', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinic_holiday, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {

            return response?.data
        } else if (response?.data?.success === "true") {
            await dispatch(getAllClinicsHolidays(data?.provider_id))
            toast.success(` Clinic Holiday Added Successfully`, {duration: 3000})
            return response?.data
        }

    }catch (err){

        if (err?.response?.data?.errors){
            return  err?.response?.data
        }
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})

export const getAllClinicsHolidays = createAsyncThunk('getAllClinicsHolidays', async (provider_id,{dispatch}) => {
    try {
        const response = await axios.get(apis.get_clinic_holiday+`${provider_id}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const deleteClinicsHoliday = createAsyncThunk('deleteClinicsHoliday', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.delete_clinic_holiday,{...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsHolidays(data?.provider_id))
            toast.success("Holiday removed successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})



/********************************* Staff Schedule / hours ***************************************/

export const getStaffSchedule = createAsyncThunk('getStaffSchedule', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.get_staff_schedule+`${data?.staff_id}&provider_id=${data?.provider_id}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {

            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const storeStaffSchedule = createAsyncThunk('storeStaffSchedule', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_staff_schedule, data,{
            headers: apiHeaders()
        })
        if (response?.data?.success ==='true'){
            await  dispatch(getStaffSchedule({provider_id:data?.provider_id,staff_id:data?.staff_id}))
            toast.success("Staff Schedule Added Successfully",{duration:3000})
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

/********************************* Staff holidays ***************************************/


export const getAllStaffHolidays = createAsyncThunk('getAllStaffHolidays', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.get_staff_holiday+`${data?.provider_id}&staff_id=${data?.staff_id}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const storeStaffHolidays = createAsyncThunk('storeStaffHolidays', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_staff_holiday, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {
            return response?.data
        } else if (response?.data?.success === "true") {
            await dispatch(getAllStaffHolidays({
                provider_id:data?.provider_id,staff_id:data?.staff_id
        }))
            toast.success(` Staff Holiday Added Successfully`, {duration: 3000})
            return response?.data
        }

    }catch (err){

        if (err?.response?.data?.errors){
            return  err?.response?.data
        }
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})

export const deleteStaffHoliday = createAsyncThunk('deleteStaffHoliday', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.delete_staff_holiday,{...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllStaffHolidays({provider_id:data?.provider_id,staff_id:data?.staff_id}))
            toast.success("Staff holiday removed successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})
/********************************* Staff break times ***************************************/


export const getAllStaffBreakTime = createAsyncThunk('getAllStaffBreakTime', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.get_staff_break,{...data}, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const storeStaffBreakTime = createAsyncThunk('storeStaffBreakTime', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_staff_break, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {

            return response?.data
        } else if (response?.data?.success === "true") {
            await dispatch(getAllStaffBreakTime({
                provider_id:data?.provider_id,staff_id:data?.staff_id
        }))
            toast.success(` Staff break time Added Successfully`, {duration: 3000})
            return response?.data
        }

    }catch (err){

        if (err?.response?.data?.errors){
            return  err?.response?.data
        }
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})


export const storeBlockHour = createAsyncThunk('storeBlockHour', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.Block_Hours, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {

            return response?.data
        } else if (response?.data?.success === "true") {
        //     await dispatch(getAllStaffBreakTime({
        //         provider_id:data?.provider_id,staff_id:data?.staff_id
        // }))
            toast.success(` Block Hour store Successfully`, {duration: 3000})
            return response?.data
        }

    }catch (err){

        if (err?.response?.data?.errors){
            return  err?.response?.data
        }
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})

// update
export const UpdateBlockHour = createAsyncThunk('UpdateBlockHour', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_block_hour, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {

            return response?.data
        } else if (response?.data?.success === "true") {
        //     await dispatch(getAllStaffBreakTime({
        //         provider_id:data?.provider_id,staff_id:data?.staff_id
        // }))
            toast.success(response?.data?.message, {duration: 3000})
            return response?.data
        }

    }catch (err){

        if (err?.response?.data?.errors){
            return  err?.response?.data
        }
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})

export const deleteStaffBreakTime = createAsyncThunk('deleteStaffBreakTime', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.delete_staff_break,{...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllStaffBreakTime({provider_id:data?.provider_id,staff_id:data?.staff_id}))
            toast.success("Staff break time removed successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})


export const clinicBusinessHoursSlice = createSlice({
    name: "clinicBusinessHoursSlice",
    initialState: {
        businessHours: [],
        clinicHolidays:[],
        staffHours:[],
        staffHolidays:[],
        staffBreakTimes:[],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllClinicsBusinessHours.fulfilled, (state, action) => {
            state.businessHours = action.payload
        })
        builder.addCase(getAllClinicsHolidays.fulfilled, (state, action) => {
            state.clinicHolidays = action.payload
        })
        builder.addCase(getStaffSchedule.fulfilled, (state, action) => {
            state.staffHours = action.payload
        })
        builder.addCase(getAllStaffHolidays.fulfilled, (state, action) => {
            state.staffHolidays = action.payload
        })
        builder.addCase(getAllStaffBreakTime.fulfilled, (state, action) => {
            state.staffBreakTimes = action.payload
        })
    }
});

export default clinicBusinessHoursSlice.reducer;