import React from 'react';
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  ListSubheader,
  Box,
  Popper
} from "@mui/material";




const FormAutoParentChild = ({
  options,
  mb,
  values,
  multiple,
  name,
  errors,
  touched,
  setFieldValue,
  label
}) => {




  const isOptionSelected = (option) => {
    if (option.child && option.child.length > 0) {
      return values[name].includes(option) || option.child.every(child => values[name].includes(child));
    }
    return values[name].includes(option);
  };
  const isAllSelected = () => {
    return options.every(option =>
      values[name].includes(option) ||
      (option.child && option.child.every(child => values[name].includes(child)))
    );
  };

  const handleSelect = (option, isParent = false) => {
    const isSelected = values[name].includes(option);
    let newValues;

    if (isParent) {
      if (isSelected) {
        newValues = values[name].filter(item => item.id !== option.id && !option.child.some(child => child.id === item.id));
      } else {
        newValues = [...values[name], option, ...option.child.filter(child => !values[name].includes(child))];
      }
    } else {
      if (isSelected) {
        newValues = values[name].filter(item => item.id !== option.id);
        const parentOption = options.find(o => o.child && o.child.some(child => child.id === option.id));
        if (parentOption && newValues.includes(parentOption)) {
          newValues = newValues.filter(item => item.id !== parentOption.id);
        }
      } else {
        newValues = [...values[name], option];
        const parentOption = options.find(o => o.child && o.child.some(child => child.id === option.id));
        if (parentOption && parentOption.child.every(child => newValues.includes(child) || child.id === option.id)) {
          newValues.push(parentOption);
        }
      }
    }

    setFieldValue(name, newValues);
  };
  const handleSelectAllToggle = () => {
    if (isAllSelected()) {
      setFieldValue(name, []); // Deselect all
    } else {
      // Select all options and their children
      const allOptionsAndChildren = options.reduce((acc, option) => [
        ...acc, option, ...(option.child ? option.child : [])
      ], []);
      setFieldValue(name, allOptionsAndChildren);
    }
  };
  

  // Custom tag rendering
  const renderTags = (value, getTagProps) => {
    // Render up to two chips, and then "...", if more options are selected
    return value.slice(0, 2).map((option, index) => (
      <Chip
        label={option.title}
        {...getTagProps({ index })}
        onDelete={
          option.child && option.child.length > 0
            ? () => handleSelect(option, true)
            : () => handleSelect(option)
        }
      />
    )).concat(
      value.length > 2 ? [<Chip label="..." key="more" />] : []
    );
  };





  const renderOption = (props, option) => {
    // Handle the "Select All" option
    if (option.isSelectAll) {
      return (
        <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={isAllSelected()}
            onClick={(event) => {
              event.stopPropagation(); // Prevent the dropdown from closing
            }}
            onChange={(event) => {
              handleSelectAllToggle();
            }}
          />
          Select All
        </Box>
      );
    }
  
    // Correctly return regular options
    return (
      <Box component="li" {...props} key={option.id}>
        {option.child && option.child.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: "column" }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={isOptionSelected(option)}
                onChange={(event) => {
                  event.stopPropagation(); // Prevent the dropdown from closing
                  handleSelect(option, true);
                }}
              />
              <ListSubheader>{option.title}</ListSubheader>
            </Box>
            {option.child.map((child) => (
              <Box key={child.id} sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={values[name].includes(child)}
                  onChange={(event) => {
                    event.stopPropagation(); // Prevent the dropdown from closing
                    handleSelect(child);
                  }}
                />
                {child.title}
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isOptionSelected(option)}
              onChange={(event) => {
                event.stopPropagation(); // Prevent the dropdown from closing
                handleSelect(option);
              }}
            />
            {option.title}
          </Box>
        )}
      </Box>
    );
  };
  

  const enhancedOptions = [{ title: "Select All", isSelectAll: true }, ...options];

  
  return (
    <FormControl fullWidth sx={{ mb: mb || "16px" }}>

      <FormLabel sx={{ fontSize: "16px", color: '#27272e', letterSpacing: '.3px' }}>{label}</FormLabel>
     
      <Autocomplete
        multiple={multiple}
        clearIcon={null} 
        options={enhancedOptions}
        value={values[name]}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => <TextField {...params} placeholder="Select" />}
        onChange={(event, newValue, reason) => {
          if (reason === 'selectOption' && newValue.some(option => option.isSelectAll)) {
            // Perform "Select All" logic without directly setting it as a value
            handleSelectAllToggle();
          } else {
            setFieldValue(name, newValue);
          }
        }}
        renderTags={renderTags}
        renderOption={renderOption}
        
      />
      {errors[name] && touched[name] && (
        <FormHelperText error>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormAutoParentChild;
