import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControl, Grid, TextField, TextareaAutosize, Typography } from '@mui/material';
import { useFormik } from 'formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomButtoncolor from '../../components/custom-button/customColorButton';
import { RemoveAccount } from '../../store/crm/crm';
import { toast } from 'react-hot-toast';
import Waveloading from '../loading/Waveloading';

const AccountPage = () => {
    const [loading, setLoading] = useState(null);
    const validate = (values) => {
      const errors = {};
      if (!values.subject) {
        errors.subject = "Subject is required";
      }
      if (!values.email) {
        errors.email = "Email is required";
      }
      return errors;
    };
  
    const formik = useFormik({
      initialValues: {
        email: "", // Assuming you need to capture the email
        subject: "",
        message: "",
      },
      validate,
      onSubmit: (values) => {
        setLoading(true);
      setTimeout(() => {
        toast.success("Account removal request submitted successfully", { duration: 3000 });
        handleSentemail(values.message, values.subject);
        setLoading(false);
        formik.resetForm();
        // You can handle further logic here if needed, but no API call or dispatch is needed
      }, 2000); // Wait for 5 seconds before showing the toast
    },
    });
    const handleSentemail = async (message, subject) => {
        try {
            const payload = {
                message: message,
                subject: subject, 
            };
            dispatch(RemoveAccount(payload));
            toast.success('Set email successfully'); // Display success toast
        } catch (error) {
            console.log('Error sending email', error);
        }
    };
  
    return (
      <Box sx={{
        p: '27px 12px',
        borderRadius: '6px',
        gap: '5px',
        maxWidth: '600px',
        margin: 'auto',
        marginTop: '50px'
      }}>
{loading? <Waveloading fullSize/> :""}
        <form onSubmit={formik.handleSubmit}>

          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item lg={12} style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <Typography sx={{ fontSize: "18px", fontFamily: 'helvetica-lt-std-roman', fontWeight: '400', textAlign: "center", width: '100%' }}>
                Request for Account Removal
              </Typography>
            </Grid>
            <Box style={{ marginTop: '26px', width: "100%", padding: "0px 3px 0px 10px" }}>
              <Grid item lg={12} md={12} sm={12} style={{ marginBottom: "10px" }}>
                <FormControl fullWidth>
                  <Typography>Email :</Typography>
                  <TextField
                    fullWidth
                    name="email"
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: '4px',
                      ".MuiOutlinedInput-notchedOutline": { borderWidth: "0!important" },
                    }}
                    placeholder=""
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography color="error">{formik.errors.email}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} style={{ marginBottom: "10px" }}>
                <FormControl fullWidth>
                  <Typography>Subject :</Typography>
                  <TextField
                    fullWidth
                    name="subject"
                    sx={{
                      background: "#EDF2F7",
                      borderRadius: '4px',
                      ".MuiOutlinedInput-notchedOutline": { borderWidth: "0!important" },
                    }}
                    placeholder=""
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.subject && formik.errors.subject && (
                    <Typography color="error">{formik.errors.subject}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} style={{ marginTop: "10px", marginBottom: "15px" }}>
                <Typography> Reason : </Typography>
                <FormControl fullWidth>
                  <TextareaAutosize
                    style={{
                      height: '250px',
                      border: "none",
                      background: "#EDF2F7",
                      borderRadius: '4px',
                      outline: "none",
                      "&:focus": { borderColor: "#EDF2F7", outline: "none" },
                      "&:hover": { borderColor: "#EDF2F7", outline: "none" },
                    }}
                    rows={6}
                    name='message'
                    placeholder=""
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <Typography color="error">{formik.errors.message}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px"
              }}>
                <Button variant="contained" size="large"
                  style={{
                    height: "50px",
                    width: "92px",
                    backgroundColor: '#72D5FF',
                    borderRadius: '8px'
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Box>
          </Grid>
        </form>
      </Box>
    );
  };
  

export default AccountPage;
