import { Avatar, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, Modal, Radio, Tab, Tabs, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Label, Margin } from '@mui/icons-material';
import { setEmail, setSms } from '../../store/crm/crm';
import { useFormik } from 'formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { getAppointmentNotes } from '../../store/crm/appointment/notes';
import { useParams } from 'react-router';
import {scrollCSS} from "../../App";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddNotesModal from './AddNotesModal';
import { getCrmNotes } from '../../store/crm/crm/crm-details';
import { getAppointmentDetails } from '../../store/crm/appointment';
import AppointmentDetailsmodal from './AppointmentDetailsmodal';
import Waveloading from '../../pages/loading/Waveloading';
import AddAttachment from '../../pages/crm/appointment/appointment-details/appointment-attachment/addAttachment';
import AddAttachmentModal from './AddAttachmentModal';
import { addAppointmentMileStone, deleteAppointmentAttachment, deleteAppointmentGallery, deleteAppointmentGalleryFile, getAppointmentAttachments, getAppointmentGallery } from '../../store/crm/appointment/attachment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {MySwal, successConfirmation} from "../../services/utills";

import ViewStreamIcon from '@mui/icons-material/ViewStream';
import CSV from '../../assets/images/csv.png'
import PDF from '../../assets/images/pdf.png'
import word from '../../assets/images/word.png'
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from 'moment/moment';
import CustomModal from './customModal';
const style = { 
    position: 'absolute',
    top: '60%',  
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {   
        xs: '90%',   // Extra small devices
        sm: '90%',   // Small devices
        md: '80%',   // Medium devices
        lg: '70%',   // Large devices
        xl: '70%',   // Extra large devices
    },
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius: '6px',
    gap: "5px",
    boxShadow: (theme) => theme.shadows[5], // Optional: add shadow for better visibility
};

  const wrapper={
    alignItems:"center", 
  }
  const heading={
    fontSize:"24px", 
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'500',
    textAlign:"center",
    width:'100%'

  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}
 export const textareaStyle = {

  height: '220px',
  border:"none",
    background: "#EDF2F7",
    borderRadius: '4px',
    outline:"none",
      "&:focus": {
        borderColor: "#EDF2F7",
        outline: "none",
      },
      "&:hover": {
        borderColor: "#EDF2F7",
        outline: "none",
    }
    
};


const CalenderNoteModal= ({customer_id,appointment_id}) => {
      const dispatch = useDispatch()
      const [open, setOpen] = useState(false)   
      const [loading , setLoading]=useState(false)
      const handleClose = () => setOpen(false)
      const [message, setMessage] = useState("")
      const [subject, setSubject] = useState("")
      const [active, setActive] = useState(0) 
      const crmDetailsStore = useSelector(state => state?.crmDetailsStore)
      const {notes=[]} = crmDetailsStore 
      const [attachmentDetails, setAttachmentDetails] = useState(null)
      const [onHover, setOnHover] = useState(false)
      // const [loading, setLoading] = useState(false)
      const [activeView, setActiveView] = useState( "card")
      const [checkedAttachments, setCheckedAttachments] = useState([]);
  const [modalImage,setModalImage]=useState(null)
      const crmAppointmentAttachmentStore = useSelector(state => state?.crmAppointmentAttachmentStore)
      const {galleryList = []} = crmAppointmentAttachmentStore 
      const [appointmentDetails, setAppointmentDetail] = useState(null)
    // const {appointment_id} = useParams()
    const crmAppointmentStore = useSelector( 
        (state) => state?.crmAppointmentStore
      ); 
      const { appointmentDetail = [] } = crmAppointmentStore; 
      useEffect(()=>{
          (async ()=>{
              await dispatch(getAppointmentAttachments(appointment_id))
          })()
      },[]) 
      const handleOpen = () => {
        setOpen(true); 
        if (!open) { 
            setLoading(true);
            dispatch(getCrmNotes(customer_id))
                .finally(() => setLoading(false));
        } 
    };


      useEffect(() => {
        // Ensuring the modal is open before making the API call
        if (open) {
            setLoading(true);
         dispatch(getCrmNotes(customer_id))
        .finally(() => setLoading(false));
        }
    }, [open, dispatch]);
   

    const handleChange = (event, newValue) => {
        setActive(newValue)  
    }  
     console.log("notes",notes,) 
     const handleAppointmentDetail = async (id) => {

        let { payload } = await dispatch(getAppointmentDetails(id))

        if (payload?.id) {
            setAppointmentDetail(payload)
        } else {
            setAppointmentDetail(null)
        }
    }
    useEffect(() => {
        (async () => {
            await dispatch(getAppointmentGallery(appointment_id)) 
        })()
    }, [])


    const handleDelete = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to remove this gallery.
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",  
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",  
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box> 
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(deleteAppointmentGallery({id: id, booking_id: appointment_id}))
            if (res?.payload?.success === "true") {
                await successConfirmation('Gallery Deleted Successfully')
            }
            setLoading(false)
        }
    }
    const handleDeleteFile = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>  
                    Are you sure? you want to remove this gallery file.    
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',  
                            mx: '10px',  
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",  
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',  
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>  
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(deleteAppointmentGalleryFile({id: id, booking_id: appointment_id}))
            if (res?.payload?.success === "true") {
                await successConfirmation('Gallery File Deleted Successfully')
            }
            setLoading(false)
        }
    }
    const handleMoveMilestone = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to add  this gallery to milestone?
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Add It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(addAppointmentMileStone({
                id: id,
                booking_id: appointment_id
            }))
            if (res?.payload?.success === "true") {
                await successConfirmation('Image Added Into Milestone Successfully')
            }
            setLoading(false)
        }
    }
    const handleCheckboxToggle = async (id) => {
        if (checkedAttachments.includes(id)) {
            setCheckedAttachments(checkedAttachments.filter(id => id !== id));
        } else {
            setCheckedAttachments([...checkedAttachments, id]);
        }

        try {
            let confirm = await MySwal.fire({
                icon: 'question', 
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{mb: 4}}>
                    <Typography sx={{}}>
                        Are you sure? you want to add  this gallery to milestone?
                    </Typography>
                    <Box sx={{mt: 3,}}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes,Add It.
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                setLoading(true)
                let res = await dispatch(addAppointmentMileStone({
                    id: id,
                    booking_id: appointment_id  
                }))
                if (res?.payload?.success === "true") {
                    await successConfirmation('Image Added Into Milestone Successfully')
                }
                setLoading(false)
            }
        } catch (error) { 
        }
    };
    const handleSelectedGallery = (attachment) => {
 
        if (attachmentDetails?.id){
            setAttachmentDetails(attachmentDetails?.id === attachment?.id  ? null : attachment)
            // setActiveView("card")
        }else {
            setAttachmentDetails(attachment)
            setActiveView("list")
        }
    } 
      
    return (
      <>
        <CustomButtoncolor
        handleClick={handleOpen}
         mainColor={"#FDB0C0"}
         size="md"                    
         val='Notes/Photos'
         style={{ 
          padding:'10px 15px',
          fontSize: "14px",
          minWidth:'max-content',
          borderRadius: "6px!important", 
          width: "100%",
          "@media (minWidth: 420px)": {
              width: 'auto'
          },
      }}                                  
      />
   <Modal
    style={{}}
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <Container maxWidth="xl">
        {loading ? <Waveloading fullSize /> : ''}
        <Box sx={style}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <IconButton
                    size="small"
                    onClick={() => setOpen(false)}
                    style={{ color: "red" ,marginBottom:"10px"}}
                >
                    <CloseRoundedIcon style={{ fontSize: '2rem' }} />
                </IconButton>
            </Box>
            <Box sx={{ background: "#fff", minHeight: '100px', borderRadius: "8px", p: { sm: '24px', xs: '8px' } }}>
                <Grid container spacing={2}>
                    <Grid item lg={6} xs={12}>
                        <Box sx={{ 
                            borderBottom: 1,
                            borderColor: 'divider',
                            width: '100%',
                        }}>
                            <Tabs
                                value={active}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    ".MuiTabs-indicator": {
                                        background: '#000000'
                                    },
                                    ".Mui-selected": {
                                        fontWeight: '600',
                                        color: '#000!important',
                                    },
                                    '@media (max-width: 600px)': {
                                        width: 'auto',
                                        display: 'flex',
                                    },
                                    '& .MuiTabs-scroller': {
                                        display: 'flex',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        fontFamily: active === 0 ? "helvetica-lt-std-bold" : "helvetica-lt-std-roman",
                                        fontSize: '18px',
                                        color: '#000',
                                        textTransform: "capitalize",
                                    }}
                                    label={"Medical/Staff Notes "}
                                    key={0}
                                />
                                <Tab
                                    sx={{
                                        fontFamily: active === 1 ? "helvetica-lt-std-bold" : "helvetica-lt-std-roman",
                                        fontSize: '18px',
                                        color: '#000',
                                        textTransform: "capitalize",
                                    }}
                                    label={"Customer Notes"}
                                    key={1} />
                                <Tab
                                    sx={{
                                        fontFamily: active === 2 ? "helvetica-lt-std-bold" : "helvetica-lt-std-roman",
                                        fontSize: '18px',
                                        color: '#000',
                                        textTransform: "capitalize",
                                    }}
                                    label={"Photos"}
                                    key={2} />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item lg={6} xs={12} sx={{ display: 'flex', flexWrap:'wrap', justifyContent: { lg: 'flex-end', xs: 'start', }, pr: { lg: 3, xs: 0 } }}>
                        <AddNotesModal appointmentDetail={appointmentDetail} wid={"100%"}/>
                        <Box sx={{ marginLeft: {sm:'10px', xs:0}, mt:{sm:0, xs:2},width:{sm:'auto', xs:'100%'} }}>
                            <AddAttachmentModal appointmentDetails={appointmentDetail} sx={{ p: '0 !important' }} wid={"100%"}/>
                        </Box>
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        py: '24px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                    }}
                >
                    {active === 0 && <Box>
                        {notes?.data?.Medical?.length > 0 ?   
                            notes?.data.Medical.map((note, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        py: '24px',
                                        px: '16px',
                                        borderTop: index > 0 ? '1px solid #9D9D9D' : 'unset',
                                    }}>
                                    <Typography sx={{ fontSize: '16px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <Typography
                                            as={'span'}
                                        sx={{
                                                fontSize: '16px', fontFamily: "helvetica-world-bold",
                                                mr: '3px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            [ {note?.date} ]  {" "}
                                            {note?.staff?.name}:
                                        </Typography>
                                        {note?.description.split(' ').map((word, idx) => {
    const isImageFile = /^[0-9].*\.(jpeg|jpg|gif|png|jfif)$/.test(word); 

    return (
        <span  
            key={idx} 
            style={{
                cursor: isImageFile ? 'pointer' : 'default',
                color: isImageFile ? 'blue' : 'inherit', 
                textDecoration: isImageFile ? 'underline' : 'none',
            }} 
            onClick={() => {
                if (isImageFile) {
                    const imageUrl = note?.attachments?.file; 
                    console.log("Image URL:", imageUrl); 
                    setModalImage(imageUrl); 
                }
            }} 
        >
            {word}&nbsp; {/* Add a space here */}
        </span>
    );
})}

                                        {note?.booking_id && (
                                            <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                                Booking #
                                                <span style={{ marginLeft: '8px' }}>
                                                    <AppointmentDetailsmodal booking_id={note?.booking_id} />
                                                </span>
                                            </span>
                                        )}
                                    </Typography>
                                </Box>
                            ))
                            : "none"
                        }
                    </Box>
                    }

                    {active === 1 && <Box>
                        {notes?.customer_appointments_notes?.Customer?.length > 0 ?
                            notes?.customer_appointments_notes?.Customer.map((note, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        py: '24px',
                                        px: '16px',
                                        borderTop: index > 0 ? '1px solid #9D9D9D' : 'unset',
                                    }}>
                                    <Typography sx={{ fontSize: '16px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <span
                                            sx={{
                                                fontSize: '16px', fontFamily: "helvetica-world-bold",
                                                fontWeight: 600, marginRight: '8px'
                                            }}
                                        >
                                            [{note?.date}] {note?.staff?.name}  
                                        </span>     
                                        {note?.description.split(' ').map((word, idx) => {
    const isImageFile = /^[0-9].*\.(jpeg|jpg|gif|png|jfif)$/.test(word); 

    return (
        <span  
            key={idx} 
            style={{
                cursor: isImageFile ? 'pointer' : 'default',
                color: isImageFile ? 'blue' : 'inherit', 
                textDecoration: isImageFile ? 'underline' : 'none',
            }} 
            onClick={() => {
                if (isImageFile) {
                    const imageUrl = note?.attachments?.file; 
                    console.log("Image URL:", imageUrl); 
                    setModalImage(imageUrl); 
                }
            }} 
        >
            {word}&nbsp; {/* Add a space here */}
        </span>
    );
})}
                                    </Typography>     
                                </Box>   
                            ))
                            : "none"
                        }
                    </Box>}
                    {active ===2 && <Box>
                     {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Appointment</Typography> */}
                     <Box
            sx={{
                background: "#fff",
                minHeight: '100px',
                borderRadius: "8px",      
                p: '24px 24px 50px 24px',                
            }}
        >                   
            <Box
                sx={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    mb: "32px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                    }}
                >
Photos
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: '2px',
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={() => setActiveView("card")}
                    >
                        <DashboardIcon
                            sx={{color: activeView === 'card' ? '#09ABF1' : '#000'}}
                        />
                    </IconButton>
                    <IconButton
                        onClick={() => setActiveView("list")}
                    >
                        <ViewStreamIcon
                            sx={{color: activeView === 'list' ? '#09ABF1' : '#000'}}
                        />
                    </IconButton>
                </Box>
            </Box>

            {/* {loading ? <Waveloading fullSize/> : ""} */}

            {activeView === 'list' &&
                <Grid
                    container
                    sx={{
                        gap: '20px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                        ...scrollCSS,
                    }}
                >
                    {
                        galleryList?.length > 0 ?
                            galleryList.map((attachment) =>  (
                                <Grid
                                    item
                                    xs={12}
                                    key={attachment?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor:'pointer',
                                        }}
                                        onClick={() => handleSelectedGallery(attachment)}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {attachment?.tag?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(attachment)}
                                        >
                                            {attachmentDetails?.id === attachment?.id ?  <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}

                                        </IconButton>
                                    </Box>
                                    {attachmentDetails?.id === attachment?.id && <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap:'wrap',
                                            }}
                                        >
                                            {attachment?.files?.length > 0 ?
                                                    attachment?.files.map((file, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{ position: 'relative'}}
                                                        >
                                             <Checkbox
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '0px',
                                                        right: '0px',
                                                        zIndex: '2',
                                                        pointer: "cursor",
                                                    }}
                                                    checked={file?.is_milestone === 1 || checkedAttachments.includes(file?.id)}
                                                    onChange={() => handleCheckboxToggle(file?.id)}
                                                />
                                                            <Avatar
                                                                src={file?.file}
                                                                onClick={()=>setModalImage(file?.file)}
                                                                sx={{
                                                                    cursor:'pointer',
                                                                    height: '180px',
                                                                    width: '180px',
                                                                }}
                                                                variant="rounded"
                                                            />
                                                            <Box sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent:'space-between',
                                                                mt: "8px"
                                                            }}>
                                                                <Typography sx={{ mr:'10px' }}>
                                                                    {moment(file?.created_at).format("dddd MMMM D, YYYY")}
                                                                </Typography>
                                                                <IconButton
                                                                       sx={{
                                                                        fontSize: "15px",
                        
                                                                        p: "5px",
                                                                       
                                                                        background: "#EB5757",
                                                                        border: '1px solid #EB5757',
                                                                     
                                                                        
                                                                        "&:hover": {
                                                                            border: '1px solid #EB5757',
                                                                            background: '#fff',
                                                                            "svg": {
                                                                                color: '#EB5757'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        setOnHover(true)
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setOnHover(false)
                                                                    }}
                                                                    onClick={()=>handleDeleteFile(file?.id)}
                                                                >
                                                                    <DeleteOutlineIcon sx={{color: '#fff', fontSize: '20px'}} />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                    :
                                                    <Avatar
                                                        sx={{
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                        variant="rounded"
                                                    />
                                            }
                                        </Box>

                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "8px"
                                        }}>
                                            {moment(attachment?.created_at).format("dddd MMMM D, YYYY")}
                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "16px"
                                        }}>

                                            <Typography
                                                sx={{
                                                    fontFamily: 'helvetica-lt-std-bold',
                                                    background: '#F2F2F2',
                                                    mr: '10px',
                                                    pl: '5px',
                                                    pr: '3px',
                                                }}
                                            >
                                                Comments
                                            </Typography>
                                           <Box>
                                               <Typography
                                                   sx={{
                                                       fontSize: '14px',
                                                   }}
                                               >
                                                   {attachment?.comment}
                                               </Typography>

                                               <Typography
                                                   sx={{
                                                    ml:"50px",
                                                       mt:"10px",
                                                       width:'max-content',
                                                       cursor:'pointer',
                                                       fontSize: '14px',
                                                       background:'#CB3838',
                                                       color:'#fff',
                                                       p:'5px 10px',
                                                       borderRadius:'4px',
                                                   }}
                                                   onClick={()=>handleDelete(attachment?.id)}
                                               >
                                                   Delete
                                               </Typography> 
                                           </Box>
                                        </Box>

                                    </Box>
                                    }
                                </Grid>
                            ))
                            :
                            <Grid xs={12} item>
                                <Typography sx={{textAlign: 'center'}}>None</Typography>
                            </Grid>
                    }
                </Grid>
            }
            {activeView === 'card' &&
                <Grid
                    container
                    sx={{
                        gap: '30px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                        ...scrollCSS,
                    }}
                >
                    {
                        galleryList?.length > 0 ?
                            galleryList.map((attachment) =>(
                                <Grid
                                    key={attachment?.id}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => !onHover && handleSelectedGallery(attachment)}
                                >
                                    <IconButton
                                        sx={{
                                            fontSize: "15px",
                                            position: "relative",
                                            p: "5px",
                                            zIndex: '1',
                                            background: "#EB5757",
                                            border: '1px solid #EB5757',
                                            m: '5px',
                                            left: "9rem",
                                            top: "0px",
                                            "&:hover": {
                                                border: '1px solid #EB5757',
                                                background: '#fff',
                                                "svg": {
                                                    color: '#EB5757'
                                                }
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            setOnHover(true)
                                        }}
                                        onMouseLeave={() => {
                                            setOnHover(false)
                                        }}
                                        onClick={() => onHover && handleDelete(attachment?.id)}
                                    >
                                        <DeleteOutlineIcon sx={{color: '#fff', fontSize: '20px'}}/>
                                    </IconButton>
                                    <Box
                                        sx={{
                                            height: '185px',
                                            mt: "-41px",
                                            width: '185px',
                                            p: '10px',
                                            border: '1px solid #BDBDBD',
                                            borderRadius: '4px',
                                            // position: 'relative'
                                        }}
                                    >
                                        {
                                            attachment?.files?.length > 0 ?
                                                attachment?.files.map((file, index) => index < 2 && (
                                                    <>
                                                     
                                                    <Avatar
                                                        key={index}
                                                        src={file?.file}
                                                        sx={{
                                                            height: attachment?.files?.length > 1 ? '145px' : '100%',
                                                            width: attachment?.files?.length > 1 ? '145px' : '100%',
                                                            position: "relative",
                                                            top: index === 1 ? "-128px" : '',
                                                            left: index === 1 ? "17px" : '',
                                                        }}
                                                        variant="rounded"
                                                    />
                                                    </>
                                                 
                                                ))
                                                :
                                                <Avatar
                                                    sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    variant="rounded"
                                                />
                                        }
                                    </Box>
                                    <Box
                                        sx={{
                                            display:'flex',
                                            justifyContent:'space-between',
                                            alignItems:'center',
                                            mt: '10px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                maxWidth: '185px',
                                                textTransform:'capitalize',
                                                fontFamily: 'helvetica-lt-std-bold',
                                            }}
                                        >
                                            {attachment?.tag?.tag_name}
                                        </Typography>

                                        {/*<Typography>*/}
                                        {/*    {moment(attachment?.created_at).format("YYYY-MM-DD")}*/}
                                        {/*</Typography>*/}
                                    </Box>
                                </Grid>
                            ))
                            :
                            <Grid xs={12} item>
                                <Typography sx={{textAlign: 'center'}}>None</Typography>
                            </Grid>
                    }
                </Grid>
            }
              </Box>
              </Box>}

                    {/* Image Modal */}
                    <CustomModal
                        customWidth={"1000px"}
                        open={modalImage !== null}
                        setOpen={() => setModalImage(null)}
                    >
                        <Box>
                            <Avatar  
                                src={modalImage}
                                sx={{
                                    cursor: 'pointer',
                                    height: '100%',
                                    width: '100%',
                                }}
                                variant="rounded"
                            />
                        </Box>
                    </CustomModal>
                
          
            </Box>
        </Box>
        </Box>
    </Container>
</Modal>

     </>
    )
  }
export default CalenderNoteModal