import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import Loader from "../../../../components/loader/loader";
import {FormControl, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Paper,} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import CustomTable from "../../../../components/table";
import {getAllClinicsRooms, getRooms, updateroom} from "../../../../store/clinics/clinicSettings/clinic-rooms";
import {useParams} from "react-router-dom";
import BackendPagination from '../../../../components/pagination/backendPagination';
import Waveloading from '../../../loading/Waveloading';
import { getAllManual, updateusermanual } from '../../../../store/clinics/clinicSettings/usermanual';
import useStyles from '../../../../pages/crm/report/style';
import TableData from '../../../../components/table/TableData';
import GhlData from '../../../../components/table/GhlTabel';

 const UserManual= () => {
  const [loading, setLoading] = useState(false);
  const [search,setSearch] = useState('')
  const classes = useStyles();
  const params =useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const usermanualStore = useSelector(state => state?.usermanualStore)
  const user = useSelector(state => state?.authStore?.user)
  const {currentPage} = usermanualStore

console.log("data",usermanualStore?.manual)
  useEffect(()=>{
      (async ()=>{
        //   if (!clinicRoomsStore?.rooms?.length){
              setLoading(true)
        //   }
          await dispatch(getAllManual(params?.clinic_id))
          setLoading(false)
      })()
  },[dispatch])
  const refreshData = async () => {
      setLoading(true);
      try {
          await dispatch(getAllManual (params?.clinic_id))
         
      } catch (error) {
          console.error('Error fetching data:', error);
         
      }
      setLoading(false); 
  };
  

 
  const roleHead= [
      {value:"Title",key:'title'},  
      {value:"Video Url",key:'video'},
      {value:"order",key:'order'},
      {value:"Action",key:'action'}  
  ]  

  const handleEdit = (row, isEdit) => { 
      if (isEdit) {  
          navigate(`/admin/configuration/edit-manual/${row?.id}`)
      }
  }  

  const handleClick = () => {
    navigate('/admin/configuration/add-manual') 
}
  const fetchPageData = async(page) => {    
      await dispatch(updateusermanual({currentPage:page}))
      await refreshData()
  }
  return (
    <Grid container p={'20px'} >
        <Grid item xs={12} sx={{mb:'16px',display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%',  '@media (max-width: 450px)': {display:"block"},}}>
            <FormControl sx={{mr: "10px"}}>
                <SimpleInput  
                    search={search}
                    setSearch={setSearch}
                />
            </FormControl>
            <CustomButton val={'Add Manual'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
        </Grid>     
        <Grid item xs={12} >
            {loading ? <Waveloading />: 
          
                          <GhlData           
                            headRow={roleHead}        
                            isStatus={true}         
                            // tableData={data}                                            
                            type={"manual"}               
                            tableData={usermanualStore?.manual?.data}     
                            search={search}    
                            handleEdit={handleEdit}   
                        />    
            }
             {/* <TableContainer 
                  component={Paper}
                  sx={{ mt: 2, boxShadow: "none", maxHeight: "650px" }}
                >
                 
                  <Table
                      aria-label="simple table"
                      id="saleReportPrint"
                    //   style={{ display: 'none' }}
                  > 
                    <TableHead className={classes.tableHead}>
                    <TableRow sx={{}}>


<TableCell
                            align="left"
                            sx={{ fontWeight: "700", borderBottom: 0 }}
                        >

                         Title
                        </TableCell>

<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
Order
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  video link
</TableCell>

</TableRow>
                    </TableHead>

                    <TableBody sx={{
                      mt: 5,
                      boxShadow: "none",
                      maxHeight: 'calc(100vh - 250px)', // Adjust the height as needed
                      overflowY: 'auto', // Add this line to make the content scrollable

                    }}>
                      
                      {usermanualStore?.manual?.length ? (
                        usermanualStore?.manual?.map((item, index)  => (
                              <TableRow
                                  key={index}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  className={classes.odd}
                              >
                                <TableCell align="left">
                                  {item?.title}
                                </TableCell>
                                <TableCell align="left">

                                {item?.order}
                                </TableCell>
                                <TableCell align="left">

                                {item?.video}
                                </TableCell>
                               
                              </TableRow>))
                      ) : (
                          <TableRow>
                            <TableCell
                                sx={{
                                  height: '300px'
                                }}
                                colSpan={9}
                                align="center"
                            >
                              no data
                            </TableCell>
                          </TableRow>
                      )} 
                    </TableBody>


                  </Table>
                </TableContainer> */}

        </Grid>
        <Grid item xs={12} style={{marginTop:"10px"}} >
    {usermanualStore?.manual?.meta?.last_page > 1 &&
        <BackendPagination
            count={usermanualStore?.manual?.meta?.last_page}
            currentPage={currentPage} 
            handleOnChange={fetchPageData}
        />
    }


</Grid>

    </Grid>
);
} 
export default UserManual;
