import React, {useEffect, useState} from 'react';
import {Box, FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllLanguages} from "../../../store/languages";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";

const Languages = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const languageStore = useSelector(state => state?.languageStore)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if (!languageStore?.languages?.length){
                setLoading(true)
            }
            await dispatch(getAllLanguages())
            setLoading(false)
        })()
    }, [dispatch,languageStore?.languages?.length])


    const handleClick = () => {
        navigate('/admin/configuration/add-language')
    }

    const roleHead = [
        {value: "Name", key: 'name'},
        {value: "Status", key: 'status'},
        {value: "Action", key: 'action'}
    ]

    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/admin/configuration/edit-language/${row?.id}`)
        }
    }

    return (
        <ConfigurationLayout>
            <Grid container p={'20px'}>
                <Grid item xs={12} sx={{
                    mb: '16px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    flexWrap:'wrap',
                    '@media (max-width: 440px)' :{
                     display:'flex',
                     justifyContent:'flex-start'
                    },
                }}>
                    <FormControl sx={{mr: "10px"}}>
                        <SimpleInput
                            search={search}
                            setSearch={setSearch}
                        />
                    </FormControl>
                    <CustomButton val={'Add Language'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                    {loading ? (
                        <Box
                        sx={{ 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            height: "60vh" 
                        }}
                    >
                        <Loader />
                    </Box>
                    ) :
                        <CustomTable
                            headRow={roleHead}
                            tableData={languageStore?.languages}
                            search={search}
                            isStatus={true}
                            type={"language"}
                            handleEdit={handleEdit}
                        />}
                </Grid>
            </Grid>
        </ConfigurationLayout>
    );
};

export default Languages;