import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiHeaders } from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import toast from "react-hot-toast";

export const getAllClinics = createAsyncThunk("getAllClinics", async (data, { dispatch ,getState}) => {
  const { search, pageLimit, currentPage } = getState().clinicStore; 
    try {

      let baseUrl = apis.get_all_clinics ;
      let queryString = `?page=${currentPage}&limit=${pageLimit}`; 
      let url = baseUrl + queryString;  
     
      const response = await axios.get(url, { 
        headers: apiHeaders()
      });
    

      if (response?.data?.success === "true") {
        return response?.data;
      }
      if (response?.data?.success === false) {

        return {};
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors,
        };
      }
    }
  }
);
export const storeClinic = createAsyncThunk('storeClinic', async (data) => {
  try {
    const response = await axios.post(apis.store_clinics, {...data},{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
    if (err?.response?.status!==200){

      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})

export const updateClinic = createAsyncThunk('updateClinic', async (data,{dispatch}) => {
  try {
    const response = await axios.post(apis.update_clinics, {...data},{
      headers: apiHeaders()
    })

    if (response?.data?.success==='true'){
      await dispatch(getAllClinics())
      return  response.data
    }else {
      return response?.data
    }
  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      return {
        data :err?.response,
        errors:true
      }
    }
  }
})


export const deleteClinic = createAsyncThunk('deleteClinic', async (id,{dispatch}) => {
  try {
    const response = await axios.get(apis.delete_clinics+id,{
      headers: apiHeaders()
    })

    if (response?.data?.success ==='true'){
      await dispatch(getAllClinics())
      toast.success("Clinic Deleted Successfully",{duration:3000})

    }else {
      console.log(response?.data)
    }

  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
  }
})

export const getClinicByID = createAsyncThunk('getClinicByID', async (id) => {
  try {
    const response = await axios.get(apis.get_clinic_by_id+id,{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
   return err?.response
  }
})
export const updateclinic = createAsyncThunk('updateclinic', async (data) => {
  return data
})
export const clinicSlice = createSlice({
  name: "clinicSlice",
  initialState: {
    clinics: [],
    meta: {},
    links: {},
    clinicByID:[],
    pageLimit:10,
    currentPage:1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllClinics.fulfilled, (state, action) => {
      state.clinics = action.payload ;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
    });
    builder.addCase(getClinicByID.fulfilled, (state, action) => {
      state.clinicByID = action?.payload?.data || [] ;
    });
    builder.addCase(updateclinic.fulfilled, (state, action) => {
      const { search, pageLimit, currentPage } = action.payload; 
      if (pageLimit){  
          state.pageLimit = pageLimit ;
      } 
      if (search){
          state.search = search === 'reset' ? '' : search ;
      }
      if (currentPage){
          state.currentPage = currentPage ;
      }
     
  });
  },
});

export default clinicSlice.reducer;
