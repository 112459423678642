import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'
import Drawer from '@mui/material/Drawer'
import MuiAvatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import CloseIcon from '@mui/icons-material/Close';
import {Autocomplete, Avatar, Button, MenuItem, Modal, Select, TextareaAutosize} from "@mui/material";
import { getAllCRMCustomer } from '../../store/crm/crm';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { selectChat } from '../../store/crm/chat';
import Pusher from "pusher-js";
import {getAllUserNotification} from "../../store/user/notification";
import { getChatlist, getContactlist, GetMessages, getUserDetails, ReadMessages, SendMsg } from '../../store/crm/chat-module';
import Waveloading from '../../pages/loading/Waveloading';
import ribbon from "../../assets/images/ribbon.svg";
import redRibbon from "../../assets/images/red-badg.svg";
import yellowRibbon from "../../assets/images/yellow-badg.svg";
import toast from 'react-hot-toast';
import ClearIcon from '@mui/icons-material/Clear';


const ScrollWrapper = ({ children, hidden }) => {
    if (hidden) {
        return <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>
    } else {
        return <PerfectScrollbar options={{ wheelPropagation: false }}>{children}</PerfectScrollbar>
    }
}

const SidebarLeft = (props) => {
    const {
        store,
        hidden,
        mdAbove,
        dispatch,
        statusObj,
        userStatus,
        selectChat,
        getInitials,
        sidebarWidth,
        setUserStatus,
        leftSidebarOpen,
        removeSelectedChat,
        userProfileLeftOpen,
        formatDateToMonthShort,
        handleLeftSidebarToggle,
        handleUserProfileLeftSidebarToggle
    } = props
    const authStore = useSelector(state => state?.authStore)
    const {user} = authStore
    const [query, setQuery] = useState('')
    const [filteredChat, setFilteredChat] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const [active, setActive] = useState(null)
    const [visibleChats, setVisibleChats] = useState(4); // Initial number of chats to show
const [loading, setLoading] = useState(false); // Track loading state


const [modalOpen, setModalOpen] = useState(false);
const [selectedUser, setSelectedUser] = useState('');
const [message, setMessage] = useState('');

const handleOpenModal = () => setModalOpen(true);
const handleCloseModal = () => setModalOpen(false);

const handleSendMessage = async () => {
    setLoading(true);
console.log("data coming",selectedCustomer)
    const newMessage = {
        user_id: selectedCustomer?.user_id
        ,
        name: store.contacts.find(contact => contact.id === selectedUser)?.name || 'Unknown User',
        lastMessage: {
            message: message,
            time: new Date().toISOString()  
        },
        unread_count: 0  
    };

    setFilteredChat(prevChats => [newMessage, ...prevChats]);

 const payload= await dispatch(SendMsg({ user_id: selectedCustomer?.user_id, message }));
console.log("data",payload)
    await dispatch(getChatlist());
    await dispatch(getUserDetails());

 
    setMessage('');
    setSelectedUser('');
    setLoading(false);
    handleCloseModal(); 
};

    const handleChatClick = (type, id) => {
        console.log("here is id",id, type)
        dispatch(selectChat(id))
     dispatch(GetMessages(id));
     dispatch(ReadMessages(id))
     dispatch(getChatlist())
 
        setActive({ type, id })
       
            const chatContainer = document.getElementById('chatArea'); // Ensure you have an ID or ref for the chat area
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight; // Scroll to bottom
            }
        
        if (!mdAbove) {  
            handleLeftSidebarToggle()
        }
        // Store id in localStorage with expiration (5 minutes)
        const expirationTime = new Date().getTime() + 5 * 60 * 1000; // Current time + 5 minutes in milliseconds
        localStorage.setItem('chatId', JSON.stringify({ id, expiration: expirationTime }));
    }
    // Example function to check if the stored id is still valid
    const getStoredChatId = () => {
        const storedData = localStorage.getItem('chatId');
        if (storedData) {
            const { id, expiration } = JSON.parse(storedData);
            const currentTime = new Date().getTime();
            if (currentTime < expiration) {
                return id; // The id is still valid
            } else {
                localStorage.removeItem('chatId'); // Expired, remove from local storage
                return null;
            }
        }
        return null;
    };
    useEffect(() => {
        if (store && store.Chatlist && store.Chatlist) {
            if (active !== null) {
                if (active.type === 'contact' && store.Chatlist[0] && active.id === store.Chatlist[0].user_id) {
                    setActive({ type: 'chat', id: active.id });
                }
            }
        }
    }, [store, active]);
    
    const hasActiveId = id => {
        if (store && store.Chatlist && store.Chatlist) {
            const arr = store.Chatlist.filter(i => i.user_id === id);
            return !!arr.length;
        }
        return false;
    };
    const crmStore = useSelector((state) => state?.crmStore);
    const { 
      allCrmCustomer = {},
     
    } = crmStore;
    useEffect(() => { 
        (async () => {
        await dispatch(getContactlist());
        })();
      }, []);   
    useEffect(() => {
        const pusher = new Pusher('5e9dcc6e9cd79dc83685', {
            cluster: 'mt1'
        });


        const channel = pusher.subscribe(`chat.unread.provider.${user?.id}`);
        channel.bind('pusher:subscription_succeeded', function () {

        });
        channel.bind("App\\Events\\MessageUnreadCountEvent", function (data) {
            renderChats()
            dispatch(getChatlist())
            // Example usage:
            const storedChatId = getStoredChatId();
            if (storedChatId) {
                dispatch(GetMessages(storedChatId));
                dispatch(ReadMessages(storedChatId))

            } else {
                console.log("Stored chat id is expired or not found.");
            }
        });

    }, [])

    

   
    console.log("data",store?.contacts)

    const renderChats = () => {
        console.log("data coming",store.Chatlist)
        if (store && store.Chatlist) {
            const arrToMap = query.length > 0 ? filteredChat : store.Chatlist;
    
            if (arrToMap.length === 0) {
                // Display message if no chats are found
                return (
                    <ListItem>
                        <Typography sx={{ color: 'text.secondary' }}>No Chats Found</Typography>
                    </ListItem>
                );
            }
    
            return arrToMap.map((chat, index) => {
                if (!chat || !chat.user_id) return null;
    
                const activeCondition = active !== null && active.id === chat.user_id && active.type === 'chat';
    
                return (
                    <ListItem key={index} disablePadding sx={{ '&:not(:last-child)': { mb: 1.5 } }}>
                        <ListItemButton
                            disableRipple
                            onClick={() => handleChatClick('chat', chat.user_id)}
                            sx={{
                                px: 2.5,
                                py: 1,
                                width: '100%',
                                borderRadius: 1,
                                alignItems: 'flex-start',
                                backgroundColor: (theme) =>
                                    activeCondition ? `${theme.palette.primary.main} !important` : '',
                            }}
                        >
                            <ListItemAvatar sx={{ m: 0 }}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={<></>}
                                >
                                    {chat.profile_image ? (
                                        <MuiAvatar
                                            src={chat.profile_image}
                                            alt={chat.name}
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                border: (theme) =>
                                                    activeCondition ? `2px solid ${theme.palette.common.white}` : '',
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            color={chat.avatarColor}
                                            skin={activeCondition ? 'light-static' : 'light'}
                                            sx={{ 
                                                width: 40,
                                                height: 40,
                                                fontSize: '1rem',
                                                border: (theme) =>
                                                    activeCondition ? `2px solid ${theme.palette.common.white}` : '',
                                            }}
                                        >
                                            {getInitials(chat.name)}
                                        </Avatar>
                                    )}
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    my: 0,
                                    ml: 1,
                                    mr: 1.5,
                                    '& .MuiTypography-root': activeCondition ? { color: 'common.white' } : {},
                                }}
                                primary={
                                    <Typography noWrap sx={{ color: !activeCondition ? 'text.secondary' : 'common.white' }}>
                                        {chat.name}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{ color: !activeCondition ? (theme) => theme.palette.text.disabled : {} }}
                                    >
                                        {chat.lastMessage ? chat.lastMessage.message : 'No messages yet'}
                                    </Typography>
                                }   
                            /> 
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        color: activeCondition ? 'common.white' : 'text.disabled',
                                    }}
                                >
                                    {chat.lastMessage
                                        ? formatDateToMonthShort(chat.lastMessage.time, true)
                                        : new Date().toLocaleDateString()}
                                </Typography>
                                {chat.unread_count && chat.unread_count > 0 ? (
                                    <Chip
                                        color="error"
                                        label={chat.unread_count}
                                        sx={{
                                            mt: 0.5,
                                            height: 18,
                                            fontWeight: 600,
                                            fontSize: '0.75rem',
                                            '& .MuiChip-label': { pt: 0.25, px: 1.655 },
                                        }}
                                    />
                                ) : null}
                            </Box>
                        </ListItemButton>
                    </ListItem>
                );
            });
        } else {
            return (
                <ListItem>
                    <Typography sx={{ color: 'text.secondary' }}>No Chats Found</Typography>
                </ListItem>
            );
        }
    };
    
    
 

    const handleFilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setQuery(searchValue);
    
        if (store.Chatlist !== null) {
            if (searchValue.trim() === '') {
                // If search input is empty, reset to the original chat list
                setFilteredChat(store.Chatlist);
            } else {
                // Filter the chat list by name
                const filteredChatsArr = store.Chatlist.filter(chat =>
                    chat.name && chat.name.toLowerCase().includes(searchValue)
                );
                // Update the filtered chat state
                setFilteredChat(filteredChatsArr);
            }
        }
    };
    
    
    // useEffect(() => {
    //     if (query.trim() === '') {
    //         setFilteredChat(store.Chatlist); // Reset on clear
    //     }
    // }, [store.Chatlist, query]);
    const [errors, setErrors] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const handleCustomerChange = (event, newValue) => {
        setSelectedCustomer(newValue);     
      };
      const validateFields = () => {
        const newErrors = {};
        if (!selectedCustomer) {
          newErrors.selectedCustomer = 'Customer is required';
        }
        // if (!message) {
        //     newErrors.selectedCustomer = 'messsage is required';
        //   }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
  
    console.log("userprofile",store.userProfile )
    
    const handleSaveClick = () => {
        if (validateFields()) {
            handleSendMessage()
        } else {
          toast.error("Please fill all required fields.");
        }
      }
    return (
        <Box>
                
            <Drawer
                open={leftSidebarOpen}
                onClose={handleLeftSidebarToggle}
                variant={mdAbove ? 'permanent' : 'temporary'}
                ModalProps={{
                    disablePortal: true,
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    zIndex: 7,
                    height: '100%',
                    display: 'block',
                    position: mdAbove ? 'static' : 'absolute',
                    '& .MuiDrawer-paper': {
                        boxShadow: 'none',
                        overflow: 'hidden',
                        width: sidebarWidth,
                        position: mdAbove ? 'static' : 'absolute',
                        borderTopLeftRadius: theme => theme.shape.borderRadius, 
                        borderBottomLeftRadius: theme => theme.shape.borderRadius
                    },
                    '& > .MuiBackdrop-root': {
                        borderRadius: 1,
                        position: 'absolute',
                        zIndex: theme => theme.zIndex.drawer - 1
                    }
                }}
            >
                <Box  sx={{display:{xs:"none",md:"none",lg:"block",xl:"block"}}} >

                
                  <Box
            sx={{
                px: {sm:"44px", xs:'20px'},
                py: "10px",
                display: 'flex',
            // flexDirection:{lg:"row",md:'column',xs:'column'},
                alignItems: 'center',
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
                justifyContent: 'space-between' // Equal space distribution
            }}
        >
            {store && store.userProfile ? (
                <Badge
                    overlap='circular'  
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}   
                    sx={{ mr: 2 }}
                    onClick={handleUserProfileLeftSidebarToggle}
                    badgeContent={
                        <Box
                            component='span' 
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                color: `${statusObj[userStatus]}.main`,
                                backgroundColor: `${statusObj[userStatus]}.main`,
                                boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`
                            }}
                        />
                    }
                >
                    <MuiAvatar
                        src={store.userProfile.profile_image}
                        alt={store.userProfile.name}
                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                    />
                </Badge>
            ) : null}
          <TextField
    fullWidth
    size="small"
    value={query}
    onChange={handleFilter}
    placeholder="Search for contact..."
    sx={{
        '& .MuiInputBase-root': { borderRadius: 5 },
        width: '70%',
        mx: 2,
    }}
    InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon fontSize="small" />
            </InputAdornment>
        ),
    }}
/>




            <IconButton 
                sx={{ marginLeft: '10px' }}
                onClick={handleOpenModal}
            >
                <PersonAddIcon fontSize="large"  style={{color:"#19A2FF"}} />
            </IconButton>

            {!mdAbove ? (
                <IconButton sx={{ p: 1, ml: 1 }} onClick={handleLeftSidebarToggle}>
                    <CloseIcon sx={{ fontSize: '1.375rem', color:'#D22B2B' }} />
                </IconButton>  
            ) : null}
            </Box>
            </Box>


            <Box
            sx={{
                px: "10px",
                py: "10px",
                display:{xs:'block' ,lg:'none'},
                // display: 'flex',
            // flexDirection:{lg:"row",md:'column',xs:'column'},
                alignItems: 'center',
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
                // justifyContent: 'space-between' // Equal space distribution
            }}
        >
            <Box sx={{   display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>

           
            {store && store.userProfile ? (
                <Badge
                    overlap='circular'  
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}   
                    sx={{ mr: 2 }}
                    onClick={handleUserProfileLeftSidebarToggle}
                    badgeContent={
                        <Box
                            component='span' 
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                color: `${statusObj[userStatus]}.main`,
                                backgroundColor: `${statusObj[userStatus]}.main`,
                                boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`
                            }}
                        />
                    }
                >
                    <MuiAvatar
                        src={store.userProfile.profile_image}
                        alt={store.userProfile.name}
                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                    />
                </Badge>
            ) : null}
            <Box>

            
               <IconButton 
                // sx={{ marginLeft: '10px' }}
                onClick={handleOpenModal}
            >
                <PersonAddIcon fontSize="large"  style={{color:"#19A2FF"}} />
            </IconButton>

            {!mdAbove ? (
                <IconButton sx={{ p: 1, ml: 1 }} onClick={handleLeftSidebarToggle}>
                    <CloseIcon sx={{ fontSize: '1.375rem' }} />
                </IconButton>  
            ) : null}
            </Box>
         
</Box>


<Box sx={{  display: 'flex', justifyContent: 'space-between',}}>
<TextField
    fullWidth
    size="small"
    value={query}
    onChange={handleFilter}
    placeholder="Search for contact..."
    sx={{
        '& .MuiInputBase-root': { borderRadius: 5 },
        width: '100%',
        mx: 2,
    }}
    InputProps={{
        endAdornment: (
            <InputAdornment position="start">
                <SearchIcon fontSize="small" />
            </InputAdornment>
        ),
    }}
/>

         
            </Box>
            </Box>
         

                <Box sx={{ height: `calc(100% - 4.0625rem)` }}>
                    <ScrollWrapper hidden={hidden}> 
                        <Box sx={{ p:{sm:3, xs:1}}}>
                            <Typography variant='h6' sx={{ ml: 2, mb: 3, color: 'primary.main' }}>
                                Contacts
                            </Typography>
                            <List sx={{ mb: 3, p: 0 }} id="chatListContainer">{renderChats()}</List>
                           
                        </Box>
                    </ScrollWrapper>    
                   
                </Box>
            </Drawer>
               {/* Modal */}
               {loading ? <Waveloading fullSize /> : ""}
               <Modal
                open={modalOpen}
                onClose={handleCloseModal} 
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ 
                    position: 'absolute', top: '50%', left: '50%', 
                    transform: 'translate(-50%, -50%)', width: 400, 
                    bgcolor: 'background.paper', p: 4, boxShadow: 24, 
                    borderRadius: 2 
                }}>
                   <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-between'}}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Send Message        
                    </Typography>  
                    
            <ClearIcon sx={{cursor:"pointer"}} onClick={handleCloseModal} />
                    </Box>           
                   
  <Autocomplete  
                      fullWidth
                      options={store?.contacts || []}
                      getOptionLabel={(user) => user.name}
                      onChange={handleCustomerChange}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Customer"
                              margin="normal"
                              variant="outlined"
                              error={!!errors.selectedCustomer}
                              helperText={errors.selectedCustomer}
                          />   
                      )}
                      renderOption={(props, user) => (
                          <li {...props} key={user.id}>
                            {user.name}
                          </li>
                      )}
                  />       

                    <TextareaAutosize  
                        minRows={4}
                        placeholder="Type your message here..."
                        style={{ width: '100%', padding: 10, borderRadius: 5, borderColor: '#ccc' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                            //  error={!!errors.message}
                            //   helperText={errors.message} 
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={handleSaveClick}
                    >
                        Send Message
                    </Button>
                </Box>
            </Modal>

          
        </Box>
    )   
};

export default SidebarLeft;