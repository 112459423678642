import React, { useState } from "react";
import ComingSoon from "../../../components/coming-soon";
import UserLayout from "../../user";
import archive from "../../../assets/images/archive-outline.png";
import archiveBlue from "../../../assets/images/archive-blue.png";
import batch from "../../../assets/icon/batch.png";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import DescriptionIcon from '@mui/icons-material/Description';
import {
  FormControl,
  FormLabel,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material";
import SubmitButton from "../../../components/custom-button/submitButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateUserAccount } from "../../../store/user/my-account";
import useStyles from "./style";
import FormDateInput from "../../../components/form/FormDateInput";
import folder from "../../../assets/images/folder.png";
import DateRange from "../../../components/date-range/date-range";
import { getStaff, getStaffSaleReports } from "../../../store/crm/reports";
import moment from "moment";
import { getProviderStaff } from "../../../store/crm/staff";
import { getAppointmentDetails } from "../../../store/crm/appointment";
import CustomModal from "../../../components/modal/customModal";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import { useNavigate } from "react-router";
import Waveloading from "../../loading/Waveloading";
import ribbonorange from "../../../assets/images/ribbon-orange.svg";
export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const StaffReport = () => {
  const [loadingSecondTable ,setloadingSecondTable ] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const [selectedBookingType, setSelectedBookingType] = useState();
  const [selectedRadio, setSelectedRadio] = useState("Booking");
  const [appointmentDetails, setAppointmentDetail] = useState(null)
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
  });

  const reportsStore = useSelector(state => state?.reportsStore)
  const {
    staffSaleReports = [], Staff = [], } = reportsStore

  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const { crmStaffs = [], } = crmStaffStore
  const navigate = useNavigate()



  const handleStaffChange = (event) => {
    setSelectedStaff(event.target.value);

  };

  const handleBookingTypeChange = (event) => {
    setSelectedBookingType(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };
  useEffect(() => {
    dispatch(getProviderStaff());
  }, []);
  useEffect(() => {
    formik.setFieldValue('staff_id', selectedStaff);   
  }, [selectedStaff]);
  const today = new Date();
  const formik = useFormik({
    initialValues: {
      start_date:today,
      end_date: today,
      booking_type: selectedBookingType,
      date_type: selectedRadio,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
        end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
        booking_type: values.booking_type,
        date_type: values.date_type,
      };


      if (values.staff_id) {
        payload.staff_id = values.staff_id;
      }

      try {
        setSelectedRow(null);
        setLoading(true);
        await dispatch(getStaff(payload));  
        setLoading(false);
        setDataFetched(true);
      } catch (error) {
        console.error(error);
      }
    },
  });
  const handleRowClick = async (index, id, startDate, endDate) => {
    setSelectedRow(index);
    const payload = {
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      booking_type: selectedBookingType,
      date_type: selectedRadio,
      staff_id: id,
    };
   
    try {
      setloadingSecondTable(true);
    await  dispatch(getStaffSaleReports(payload));
    setloadingSecondTable(false)
      
    } catch (error) {
      console.error(error);
      
    }
    
  };

  const handleAppointmentDetail = async (id) => {

    let { payload } = await dispatch(getAppointmentDetails(id))

    if (payload?.id) {
      setAppointmentDetail(payload)  
    } else {
      setAppointmentDetail(null)
    }
  }

  const handleAppointmentClick = (url) => {
    navigate(url)
  }


  return (
    <>
        <Typography variant="h5" color="initial">
        <DescriptionIcon sx={{color:'#09ABF1',marginTop:'-3px'}}/>  Staff Sale Report
        </Typography>
      <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important" }}>

        <Grid
          container   
          sx={{
            alignItems: "center",  
            justifyContent: "space-between",   
            mt: 3,
            flexWrap: "wrap",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              item
              lg={12}
              xs={12}
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}                            
            >

              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1, lg: 0 },
                }}
              >
                <FormLabel>Select Date</FormLabel>
                <DateRange
        setStartDate={(date) => formik.setFieldValue('start_date', date)}
        startDate={formik.values.start_date}
        setEndDate={(date) => formik.setFieldValue('end_date', date)}
        endDate={formik.values.end_date}
        onBlur={formik.handleBlur}
      />
                {(formik.touched.start_date || formik.touched.end_date) && (!formik.values.start_date || !formik.values.end_date) && (
                  <div style={{ color: "red" }}>Date field is required</div>
                )}
              </FormControl>

              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1, md: 0 },
                  ml: { xs: 0, sm: 0,lg: 1 },
                }}
              >
                <FormLabel>Staff Name</FormLabel>
                <Select
                  value={selectedStaff}
                  onChange={handleStaffChange}
                  displayEmpty
                  sx={selectStyle}
                >
                  {crmStaffs.map((staff) => (
                    <MenuItem key={staff.id} value={staff.id}>
                      {staff.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1, md: 0 },
                }}
              >
                <FormLabel>Booking Type</FormLabel>
                <Select
                  value={selectedBookingType}
                  onChange={handleBookingTypeChange}
                  displayEmpty
                  sx={selectStyle}
                >
                  <MenuItem value="Self">Self</MenuItem>
                  <MenuItem value='Crm'>CRM</MenuItem>
                  <MenuItem value='Calendly'>Square</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} sx={{ display: 'flex', flexWrap:{sm:'nowrap', xs:'wrap'}  }}>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={selectedRadio}
                onChange={handleRadioChange}
                defaultValue="top"
                sx={{ mt: 2 }}
              >
                <FormControlLabel
                  value="Created"
                  control={<Radio />}
                  sx={{ mr: { xs: 1, md: 5 } }}
                  label="By Created Date"
                />
                <FormControlLabel
                  value="Booking"
                  control={<Radio />}
                  label="By Booking  Date"
                />
              </RadioGroup>

              <Box sx={{ mt: 2, display:"flex",justifyContent:{xs:"flex-end"} ,
                             width: {      
                            xs: "100%", // Set width to 100% for sm breakpoint
                            sm: "auto", // Set width to auto for other breakpoints
                            md: "auto",
                            lg: "auto"
                          }}} >
                <Button variant="contained" color="primary" type="submit">
                  Fetch Report
                </Button>
              </Box>
            </Grid>
          </form>
        </Grid>


        {/* Buttons */}
      </Container>

      <Box sx={{ mt: 4 }}>
        <UserLayout>
          {loading ? (
            <Waveloading />
          ) : dataFetched ? (

            <>
              {/* Table */}

              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  maxHeight: 'calc(100vh - 250px)',  
                  overflowY: 'auto',
                 
                  // "&::-webkit-scrollbar": {
                  //   width: '6px', 
                  // },
                  // "&::-webkit-scrollbar-thumb": {
                  //   background: '#888', 
                  //   borderRadius: '3px', 
                  // },
                  // "&::-webkit-scrollbar-track": {
                  //   background: '#f1f1f1', 
                  //   borderRadius: '3px', 
                  // },

                }}
              >
                <Table aria-label="simple table">
                  <TableHead className={classes.tableHead}>
                    <TableRow sx={{}}>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: "700",  
                          borderBottom: 0,  
                        }}
                      >
                        Staff Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Total Booking
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Gross Amount <span style={{ fontSize: '12px' }}>(exc tax)</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Time <span style={{ fontSize: '12px' }}>(Service Working Hour)</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Time <span style={{ fontSize: '12px' }}>(Total Hour At Clinic)</span>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        SPRT
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {Staff?.data?.length ? (
                      Staff?.data.map((item, index) => (

                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          className={classes.odd}
                          onClick={() => handleRowClick(index, item?.id, formik.values.start_date, formik.values.end_date, selectedBookingType, selectedRadio)}
                        >
                          <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                            {item?.name}
                          </TableCell>
                          <TableCell align="left" sx={{ fontSize:'18px' }}>

                          {item?.booking_count}
 
                          </TableCell>
                          <TableCell align="left" sx={{ fontSize:'18px' }}>
                            {/* <Typography variant="body1" ></Typography> */}
                            ${item?.total_amount % 1 === 0 
      ? item?.total_amount.toFixed(0)   
      : item?.total_amount.toFixed(2)} 
                            {/* <Typography variant="body1" ></Typography> */}

                          </TableCell>
                          <TableCell align="left" sx={{ fontSize:'18px' }}>{item?.total_time} </TableCell>
                          <TableCell align="left" sx={{ fontSize:'18px' }}> {item?.total_working_hours}</TableCell>
                          <TableCell sx={{ fontSize:'18px' }}>
                            {item?.sprt}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : ( 
                      <TableRow> 
                        <TableCell
                          sx={{
                            height: '300px'
                            
                          }}
                          colSpan={9}
                          align="center"
                        >
                          Staff Sales data not found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>


                </Table>
              </TableContainer>

            </>) : ""}

          {/* Table */}


          {selectedRow !== null && (
  <>
    {loadingSecondTable ? ( 
              <Waveloading />
            ) : (
      <TableContainer
      component={Paper}
    
      sx={{
        marginTop: { lg: '100px', md: '40px', xs: '30px' } ,
        boxShadow: "none",
        maxHeight: 'calc(100vh - 250px)', // Adjust the height as needed
        overflowY: 'auto', 
        // Add this line to make the content scrollable  
        "&::-webkit-scrollbar": {
          width: "10px",
          height: "10px"
        },
        "&::-webkit-scrollbar-track": {
          background: "#fff"
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          background: "rgba(0, 0, 0, 0.2)",
          boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
        }
    
      }}
    >
      <Table aria-label="simple table">
        <TableHead className={classes.tableHead}  style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <TableRow sx={{}}>
            <TableCell
              align="left"
              sx={{
                fontWeight: "700",
                borderBottom: 0,
              }}
            >
              Booking Ref
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Customer
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Customer Contact Info
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Price (exc tax )
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Type
            </TableCell>
            <TableCell
              align="left"  
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Date
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Status
            </TableCell>
            <TableCell
              align="left"
              sx={{ fontWeight: "700", borderBottom: 0 }}
            >
              Payment
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {staffSaleReports?.data?.data?.length ? (
            staffSaleReports?.data?.data.map((item, index) => (
              <TableRow
                key={index}
                sx={{

                  cursor: item?.id ? 'pointer' : 'default',
                }}
                className={classes.odd}
              >
                <TableCell onClick={() => item?.id && handleAppointmentDetail(item?.id)} component="th" scope="row" style={{ color: '#2F80ED' }} >
                  {item?.id}
                </TableCell>
                <TableCell align="left">
                  {item?.customer?.customer_batch && (
                    item?.customer?.customer_batch !== "New User" ? (
                    <>
                      {item?.customer?.customer_batch.includes("Expire/Cancel") ? (
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                          <Typography style={{ color: "#ff0000" }}>
                            {item?.customer?.customer_batch}
                          </Typography>
                        </div>
                      ) : (
                        <>
                          {item?.customer?.customer_batch.includes("Declined") ? (
                            <div style={{ display: "flex", alignItems: "start" }}>
                              <img style={{ marginTop: "4px",width:"20px" }} src={ribbonorange} alt={'member'} />
                              <Typography style={{  color: "#f55d0b" }}>
                                {item?.customer?.customer_batch}
                              </Typography>
                            </div>
                          ) : (
                            <div style={{ display: "flex", alignItems: "start" }}>
                              <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                              <Typography style={{ color: "#219653" }}>
                                {item?.customer?.customer_batch}
                              </Typography>
                            </div>
                          )}
                        </>

                      )
                      }
                    </>
                  ) : (
                    <>
                      <Typography style={{ color: "#09ABF1" }}>
                        {item?.customer?.customer_batch}
                      </Typography>
                    </>
                  ))}
                  <Typography className={classes.tableLink} sx={{ cursor: 'pointer' }} 
                  onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${item?.customer?.id}`)}>{item?.customer?.name}</Typography>

                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1"></Typography>    
                  {item?.customer?.email}
                  <Typography variant="body1" ></Typography>
                  {item?.customer?.phone}   
                </TableCell>
                <TableCell align="left">{item.grand_total}</TableCell>
                <TableCell align="left">{item?.booking_type}</TableCell>
                <TableCell align="left">{item.date}</TableCell>
                <TableCell align="left">
                  <div style={{
                    background: item?.status_color, borderRadius: '13px',
                    color: '#fff', padding: "2px 12px", height: "fit-content"
                    ,display:"flex",justifyContent:"center"
                  }}>
                    {item?.status}
                  </div></TableCell>
                <TableCell align="left">
                  <div style={{
                    background: item?.payment_status === 'Paid' || item?.payment_status === 'Partial Paid' || item?.payment_status === 'Pre Paid' ? '#219653' : 'red',
                    borderRadius: '13px',
                    color: '#fff',
                    padding: '2px 12px',
                    textAlign: "center",
                    height: item?.payment_status === 'Partial Paid' ? "41px" : "fit-content"
                  }}>
                    {item?.payment_status}
                  </div>
                </TableCell>
              </TableRow>))
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  height: '300px'
                }}
                colSpan={9}
                align="center"
              >
                Appointment data not found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    )}
    </> )}
             
           

        </UserLayout>

      </Box>
      <CustomModal
        customWidth={"1000px"}
        open={appointmentDetails !== null}
        setOpen={() => setAppointmentDetail(null)}
      >
        <Box>
          <AppointmentDetails appointmentDetails={appointmentDetails} />
        </Box>
      </CustomModal>
    </>

  );
};

export default StaffReport;


