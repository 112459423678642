import { Autocomplete, Box, Button, CardMedia, Container, Grid, TextField, Typography, InputAdornment, Tab, Tabs, Slide, } from '@mui/material';
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { ServicesPlan, getAllClinic, getsubscriptionPlan, SubCategory, AllServices } from '../../store/home';
import useStyles from './style';
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import threeDlogo from "../../assets/logo/3dlogo-transparant.webp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import fb from "../../assets/icon/fb-tranparant.png";
import insta from "../../assets/icon/instagram-transparant.webp";
import tiktok from "../../assets/icon/png-transparent-tiktok-logo.png";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import homeoutline from '../../assets/images/homeoutline.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import booking from '../../assets/images/Vector (Stroke).png'
import menu from '../../assets/images/homeIcon.png'
import plan from '../../assets/images/wallet2.png'
import profile from '../../assets/images/user.png'
import { TabContext, TabPanel } from '@mui/lab';
import Login from '../login';
import Waveloading from '../loading/Waveloading';
import defaultservice from "../../assets/images/defaultservice.jpg"
import Footer from './Footer';
import CustomModal from '../../components/modal/customModal';
import toast from 'react-hot-toast';

const SubCategories = () => {
    const classes = useStyles();
    const { Slug } = useParams();
    const navigate = useNavigate();


    const location = useLocation();
     const [isOpen, setIsOpen] = useState(false);
    const selectedLocation = location?.state?.selectedClinic;
    const selectedlocation = location?.state?.selectedlocation;
    const selectedwidget= location?.state?.selectedLocation
    const Clinicname = selectedLocation?.name
    const Clinicid = selectedLocation?.id
    const widgetid =selectedwidget?.id
    const [loginOpen, setOpenLogin] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClinic, setSelectedClinic] = useState(selectedLocation || selectedlocation || selectedwidget|| null);
    const dispatch = useDispatch();
    const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || 'home');
    const id = localStorage.getItem('selectedLocationId')
    const backId = localStorage.getItem('selectedLocationId')
    const localStorageKey = 'selectedLocation';
    const homeStore = useSelector(state => state?.homeStore)
    const { clinic, Services, SubscriptionPlan, subcategory, ServiceData } = homeStore
    const [value, setValue] = useState(0);

    const handletabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = (event) => {

        setSearchTerm(event.target.value.toLowerCase());
    };
    // all clinic api=
    useEffect(() => {
        (async () => {
            await dispatch(getAllClinic())
        })()

    }, [])
    const subcategorySlug = subcategory?.payload?.slug;
    // const parentSlugs = subcategory?.payload?.parent?.slug;
    // const childSlugs = subcategory?.payload?.child?.map((child) => child.slug);
    // const selectedChildSlug = childSlugs?.[value];
    // level 3 api
    console.log("ids comes in different page",Clinicid, id, widgetid)
    
    useEffect(() => {
        const fetchData = async () => {  
            try {
                let payload = {

                    provider_id: Clinicid || widgetid || id ,

                    Slug: Slug,
                };
    
                setLoading(true);
                const subCategoryResponse = await dispatch(SubCategory(payload)); // Renamed response to subCategoryResponse
    
                console.log("SubCategory response data", subCategoryResponse);
    
                if (subCategoryResponse?.payload) {
                    const subcategorySlug = subCategoryResponse?.payload?.slug;
                    const parentSlugs = subCategoryResponse?.payload?.parent?.slug;
                    const childSlugs = subCategoryResponse?.payload?.child?.map((child) => child.slug);
                    const selectedChildSlug = childSlugs?.[value];
                    
                    let servicePayload = {

                        provider_id: Clinicid || widgetid|| id,

                    };
    
                    if (selectedChildSlug && selectedChildSlug.length > 0) {
                        servicePayload.treatment = selectedChildSlug;
                        servicePayload.group = subCategoryResponse?.payload?.slug;
                    } else {
                        servicePayload.treatment = subCategoryResponse?.payload?.slug;
    
                        if (subCategoryResponse?.payload?.parent) {
                            servicePayload.group = subCategoryResponse?.payload?.parent?.slug;
                        } else {
                            servicePayload.group = subCategoryResponse?.payload?.slug;
                        }
                    }
    
                    const servicesResponse = await dispatch(AllServices(servicePayload)); // Renamed response to servicesResponse
                    console.log("AllServices response data", servicesResponse);
                } else {
                    toast.error("Error in SubCategory response");
                }
    
                setLoading(false);
                setDataFetched(true);
    
            } catch (error) {
                console.error("Error occurred:", error);  
                setLoading(false);
                toast.error("An error occurred while fetching data");
            }
        };
    
        fetchData();
    }, [Slug, Clinicid, id, widgetid, value]); 
    


    //  options
    const clinicOptions = clinic.data && Array.isArray(clinic.data)
        ? clinic.data.map((clinic) => ({ 
            name: clinic?.name,
            id: clinic?.id,
        }))
        : [];
    useEffect(() => {
        const savedLocation = localStorage.getItem(localStorageKey);
        if (savedLocation) {
            setSelectedClinic(JSON.parse(savedLocation));
        }
    }, []);
    // clinic handel
    const handleClinicSelect = (event, newValue) => {
        localStorage.setItem('selectedLocationId', newValue?.id);
        setSelectedClinic(newValue);
        backhandler(newValue)
        localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    };
    const handleHome = () => {
        navigate('/home');
    };
    const backhandler = (selectedClinic) => {
        navigate(`/home`, { state: { selectedLocation: selectedClinic } });
    };
    const backarrow = () => {
        navigate(`/home`, { state: { selectedLocation: selectedLocation } });
    };
    const authStore = useSelector((state) => state?.authStore);

    const { user } = authStore;
    console.log("data comes", subcategory)
   

    // const fetchData = async () => {
    //     setLoading(true);

    //     let payload = {
    //         provider_id: Clinicid || id,
    //     };

    //     if (selectedChildSlug && selectedChildSlug.length > 0) {
    //         payload.treatment = selectedChildSlug;
    //         payload.group = subcategory?.slug;
    //     } else {
    //         payload.treatment = subcategory?.slug;


    //         if (subcategory?.parent) {
    //             payload.group = subcategory?.parent?.slug;
    //         } else {
    //             payload.group = subcategory?.slug;
    //         }
    //     }

    //     const response = await dispatch(AllServices(payload));

      

    //     setLoading(false);
    //     setDataFetched(true);
    // }

    // useEffect(() => {
    //     fetchData();
    // }, [subcategory, value]);


    const handleCategoryClick = (url) => {
        // Navigate to the URL
        navigate(url, { state: { selectedLocation: selectedClinic, subcategorySlug, scrollToDiv: 'targetDivId' } },);
    };
    const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
    const handleBoxClick = () => {
        setActiveButton('booking');
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {

            navigate('/user/bookings');
        }
        setActiveButton('booking');
        localStorage.setItem('activeButton', 'booking');
    };
    const handleplan = () => {
        setActiveButton('member');
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {

            navigate('/user/membership');
        }
        setActiveButton('member');
        localStorage.setItem('activeButton', 'member');
    };
    const handleprofile = () => {

        if (shouldRedirectToLogin) {
            setOpenLogin(true)

        } else {
            // Navigate to the booking page
            navigate('/user/user-account');

        }
        setActiveButton('profile');
        localStorage.setItem('activeButton', 'profile');
    };

    let capitalizedUserName = "";
    if (user?.name) {
        // Ensure user.name is not null or undefined
        capitalizedUserName = user.name.charAt(0).toUpperCase() + user.name.slice(1);
    }
    const handleClick = (link) => {
        setLoading(true);

        window.location.href = link
    };

    return (
        <>
            {loginOpen ? (
                <Box
                    sx={{
                        position: "fixed",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        top: 0,
                        zIndex: "1111111111111!important",
                    }}
                >
                    <Slide top>
                        <Login loginOpen={loginOpen} setOpenLogin={setOpenLogin}  showFooter={true} />
                    </Slide>
                </Box>
            ) : (
                ''
            )}
            <Box sx={{ overflow: 'hidden', position: 'relative', display: { sm: "block", md: "none" } }}>
            <CustomModal open={isOpen} setOpen={setIsOpen} background={"#f4ede3"}>
  <Autocomplete
    className={classes.selectParent}
    clearIcon={null}
    disablePortal
    id="combo-box-demo"
    options={clinicOptions}
    value={selectedClinic}
    onChange={(event, newValue) => {
      handleClinicSelect(event, newValue);
      if (newValue) {
        setSelectedClinic(newValue); // Update the selected clinic state
        setIsOpen(false); // Close the modal
      }
    }}
    sx={{ mt: 4 }}
    renderInput={(params) => (
      <TextField
        {...params}
        sx={{ label: { mt: "-10px", zIndex: 0 } }}
        label="Select Your Default Clinic"
      />
    )}
    getOptionLabel={(option) => option.name}
    getOptionSelected={(option, value) =>
      option.id === (value.id || id)
    }
  />
</CustomModal>



<Box sx={{ backgroundColor: "#f4ede3" }}>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #AA2167",
                pb: 1,
              }}
            >
              <Box
                component="img"
                src={threeDlogo}
                alt="3D Logo"
                sx={{ width: "120px" }}
              />

              <Box>
                  {selectedClinic?.facebook && (
                      <a
                          href={selectedClinic?.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: '40px',
                              marginLeft: '10px',
                              position: 'relative',
                              top: '-2px',
                          }}
                      >
                          <img
                              src={fb}
                              width="100%"
                              style={{ borderRadius: '8px' }}
                              alt="Facebook"
                          />
                      </a>
                  )}

                  {selectedClinic?.instagram && (
                      <a
                          href={selectedClinic?.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: '40px',
                              marginLeft: '10px',
                              position: 'relative',
                              top: '-1px',
                          }}
                      >
                          <img
                              src={insta}
                              width="100%"
                              style={{ borderRadius: '8px' }}
                              alt="Instagram"
                          />
                      </a>
                  )}

                  {selectedClinic?.tiktok && (
                      <a
                          href={selectedClinic?.tiktok}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: '65px',
                          }}
                      >
                          <img
                              src={tiktok}
                              width="100%"
                              style={{ borderRadius: '8px' }}
                              alt="TikTok"
                          />
                      </a>
                  )}
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1, flexWrap:'wrap',
                flexDirection:'column' }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <PhoneIcon sx={{ fontSize: "20px" }} />
                    <Typography variant="subtitle1" color="initial">
                        <a
                            href={`tel:${selectedClinic?.phone || '+416 850 0231'}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            {selectedClinic?.phone || '+416 850 0231'}
                        </a>
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <EmailIcon sx={{ fontSize: "20px" }} />
                    <Typography variant="subtitle1" color="initial">
                        <a
                            href={`mailto:${selectedClinic?.email || 'care@3dlifestyle.ca'}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            {selectedClinic?.email || 'care@3dlifestyle.ca'}
                        </a>
                    </Typography>
                </Box>
            </Box>
          </Box>
        </Box>

                <Container maxWidth="lg" sx={{ overflow: 'hidden', position: 'relative' }}>
                    <Box sx={{ display: { sm: "block", md: "none" }, mt: 2 }}>
                        {/* <Autocomplete
                         clearIcon={null} 
                            className={classes.selectParent}
                            disablePortal
                            id="combo-box-demo"
                            options={clinicOptions}
                            value={selectedClinic}
                            onChange={handleClinicSelect}
                            renderInput={(params) => (
                                <TextField {...params} sx={{ label: { mt: '-10px',zIndex:0 } }} label="Select store" />
                            )}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === (value.id || id)}
                        /> */}
                        {loading ? <Waveloading fullSize /> : ''}
                        <>
                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                    <Box >
                                        <KeyboardBackspaceIcon onClick={backarrow} />
                                    </Box>
                                    {/*<PersonIcon onClick={() => setOpenLogin(true)} />*/}

                                </Box>


                            {user?.id && user?.role_id === 3 ? (

                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, mb:4 }}>
                                    {/* <Box onClick={backarrow} >
                                        <ArrowBackIcon />
                                    </Box> */}
                      <Typography
                      variant="subtitle1"
                      color="#0274be"
                      sx={{ textDecoration:'underline' }}
                      onClick={() => setIsOpen(true)}
                    >
                      <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />  
                      {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
                    </Typography>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                                        <Box sx={{ display: "flex", justifyContent: "end", }}>
                                            <AccountBalanceWalletIcon />
                                            <sup style={{ fontWeight: '600', left: '-19px', top: '-8px' }}>{user?.wallet_point || 0}</sup>
                                        </Box>
                                        <Box onClick={handleprofile} sx={{ backgroundColor: 'rgba(235, 213, 194, 1)', width: '30px', height: '30px', borderRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography sx={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{capitalizedUserName.charAt(0)}</Typography>
                                        </Box>


                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, }}>
                                    {/* <Box onClick={backarrow} >
                                        <ArrowBackIcon />
                                    </Box> */}
                                    <Typography
                                        variant="subtitle1"
                                        color="#0274be"
                                        sx={{ textDecoration:'underline' }}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <LocationOnIcon sx={{ fontSize: '20px', marginTop: '-5px' }} />
                                        {selectedClinic ? selectedClinic.name : "Select Your Default Clinic"}
                                    </Typography>
                                    <PersonIcon onClick={() => setOpenLogin(true)} />
                                </Box>
                            )}


                            <TextField
                                id="search"
                                type="search"
                                label="Search"
                                value={searchTerm}
                                onChange={handleChange}
                                className={classes.search}
                                sx={{ width: '100%', mt: 3, borderRadius: '20px', display: 'flex', alignItems: 'cernter', label: { mt: '-10px' }, zIndex:0 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Grid container spacing={2} sx={{ mt: 2 }}>

                                <Grid item xs={12} sm={6} key={plan.id}>
                                    <Box sx={{ position: 'relative' }}>
                                        <CardMedia
                                            sx={{ borderRadius: '6px' }}
                                            component="img"
                                            alt="Descriptive alt text"
                                            height="110"
                                            image={subcategory?.file_path || defaultservice} // Use planImage from the plan object
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{ position: 'absolute', bottom: '15px', color: '#fff', left: '10px' }}
                                        >
                                            {subcategory?.title}
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>
{/*                             
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h6">Select Services</Typography>


                            </Box> */}
                            {subcategory?.child && subcategory.child.length === 0 ? (
                                <>



                                    {ServiceData?.promotion_services && ServiceData?.promotion_services.length > 0 ? (
                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                            <Typography variant="h6" style={{
                                                marginLeft: "18px",
                                                fontSize: '21px',
                                                fontWeight: '700',
                                                width: "100%",
                                                borderRadius: "8px",
                                                color: "#fff",
                                                padding: "5px 15px",
                                                backgroundColor: "#000"
                                            }}>Promotion</Typography>
                                            {ServiceData?.promotion_services
                                                .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                .map((service) => (
                                                    <>

                                                        <Grid item xs={12} sm={6} key={service.id}>


                                                            <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                {
                                                                    service?.is_price_show != 0 ? (
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                            {
                                                                                service?.is_upfront_enable === 0 ? (
                                                                                    <>
                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                            <Box>
                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, textDecoration: 'line-through', textDecorationColor: 'black' }}>
                                                                                                    ${service?.regular_price}
                                                                                                </Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                    Regular Price
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                    ${service?.member_price}
                                                                                                </Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                    Promo Price
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </>
                                                                                ) : (
                                                                                    <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 }}>
                                                                                        $ {service?.upfront_amount} deposit required
                                                                                    </Typography>
                                                                                )
                                                                            }
                                                                            <Box>
                                                                                <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    ):null
                                                                }
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                    {service?.is_price_show != 0 ? (
                                                                        <Box>
                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                    onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                Book Now <TrendingFlatIcon />
                                                                            </Button>
                                                                        </Box>
                                                                    ) : (
                                                                        <>
                                                                            <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                        onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                    Book Consultation <TrendingFlatIcon />
                                                                                </Button>
                                                                                <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                        onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                    Book Virtual Consultation <TrendingFlatIcon />
                                                                                </Button>
                                                                            </Box>
                                                                        </>
                                                                    )}

                                                                    {loading ? <Waveloading fullSize /> : ''}
                                                                    <Box>
                                                                        <Typography sx={{
                                                                            fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                            textDecoration: 'underline', cursor: "pointer"
                                                                        }}
                                                                            //  onClick={() => window.location.href = service?.link}
                                                                                    onClick={() => handleClick(service?.link)} >More Info</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>


                                                        </Grid>
                                                    </>
                                                ))}
                                        </Grid>
                                    ) : null}


                                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mb: 20, mt: 3 }}>

                                        <Typography variant="h6"
                                                    style={{
                                                        marginLeft: "18px",
                                                        fontSize: '21px',
                                                        fontWeight: '700',
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        color: "#fff",
                                                        padding: "5px 15px",
                                                        backgroundColor: "#000"
                                                    }}>List Of Services</Typography>

                                        {ServiceData?.data && ServiceData?.data.length > 0 ? (
                                            ServiceData?.data
                                                .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                .map((service) => (
                                                    service?.is_promo === 0 && service?.is_consultation_category === 0 && service?.is_show_website === 1 && service?.is_extra_service === 0 && service?.is_free === 0 ? (
                                                        <>
                                                            <Grid item xs={12} sm={6} key={service.id}>

                                                                <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                    {
                                                                        service?.is_price_show != 0 ? (
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                    {
                                                                                        service?.is_upfront_enable === 0 ? (
                                                                                            <>
                                                                                                <Box sx={{ display: 'flex' }}>
                                                                                                    <Box>
                                                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 , textDecoration: 'line-through', textDecorationColor: 'black' }}>
                                                                                                            ${service?.regular_price}
                                                                                                        </Typography>
                                                                                                        <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                            Regular Price
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box>
                                                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                            ${service?.member_price}
                                                                                                        </Typography>
                                                                                                        <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                            Member Price
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                $ {service?.upfront_amount}  deposit required
                                                                                            </Typography>
                                                                                        )
                                                                                    }



                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        ):null
                                                                    }
                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                        {service?.is_price_show != 0 ? (
                                                                            <Box>
                                                                                <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                        onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                    Book Now <TrendingFlatIcon />
                                                                                </Button>
                                                                            </Box>
                                                                        ) : (
                                                                            <>
                                                                                <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                        Book Consultation <TrendingFlatIcon />
                                                                                    </Button>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                        Book Virtual Consultation <TrendingFlatIcon />
                                                                                    </Button>
                                                                                </Box>
                                                                            </>
                                                                        )}


                                                                        {loading ? <Waveloading fullSize /> : ''}
                                                                        <Box>
                                                                            <Typography sx={{
                                                                                fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                textDecoration: 'underline', cursor: "pointer",
                                                                            }}
                                                                                // onClick={() => window.location.href = service?.link}
                                                                                        onClick={() => handleClick(service?.link)}
                                                                            >More Info</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>


                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>)


                                                ))) : null}
                                    </Grid>
                                </>
                            ) : (
                                <Box sx={{ marginTop: "20px", width: '100%', bgcolor: 'background.paper' }}>
                                    {subcategory?.child && subcategory?.child.length > 0 ? (

                                        <div style={{ overflowX: "auto", width: "100%" }}>


                                            <Tabs
                                                value={value}
                                                onChange={handletabs}
                                                orientation="horizontal"
                                                variant="scrollable"
                                                centered
                                                sx={{
                                                    '& .MuiTabs-scroller': {
                                                        overflowX: 'auto',
                                                    },
                                                }}
                                                TabIndicatorProps={{ style: { display: 'none' } }} >
                                                {subcategory?.child.map((service, index) => (
                                                    <Tab
                                                        key={index}
                                                        label={service?.title}
                                                        sx={{
                                                            marginRight: "20px",
                                                            // marginLeft:'20px',
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                            border: '1px solid black',
                                                            borderRadius: '20px',
                                                            color: "black",
                                                            minHeight: '35px',
                                                            padding: '10px 16px',
                                                            '&.Mui-selected': {
                                                                backgroundColor: 'black',
                                                                color: 'white',
                                                                border: '1px solid black',

                                                            },
                                                        }} />


                                                ))} </Tabs> </div>) : ''}

                                    {subcategory?.child && subcategory.child.length > 0 && (
                                    <>
                                            {ServiceData?.promotion_services && ServiceData?.promotion_services.length > 0 ? (
                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                                    <Typography variant="h6" style={{
                                                        marginLeft: "18px",
                                                        fontSize: '21px',
                                                        fontWeight: '700',
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        color: "#fff",
                                                        padding: "5px 15px",
                                                        backgroundColor: "#000"
                                                    }}>Promotion</Typography>
                                                    {ServiceData?.promotion_services
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            <>

                                                                <Grid item xs={12} sm={6} key={service.id}>


                                                                    <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                        {
                                                                            service?.is_price_show != 0 ? (
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        {
                                                                                            service?.is_upfront_enable === 0 ? (
                                                                                                <>
                                                                                                    <Box sx={{ display: 'flex' }}>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 }}>
                                                                                                                ${service?.regular_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Regular Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                ${service?.member_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Promo Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                    $ {service?.upfront_amount}  deposit required
                                                                                                </Typography>
                                                                                            )
                                                                                        }


                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            ):null
                                                                        }
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                            {service?.is_price_show != 0 ? (
                                                                                <Box>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                        Book Now <TrendingFlatIcon />
                                                                                    </Button>
                                                                                </Box>
                                                                            ) : (
                                                                                <>
                                                                                    <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                            Book Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                            Book Virtual Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                </>
                                                                            )}

                                                                            {loading ? <Waveloading fullSize /> : ''}
                                                                            <Box>
                                                                                <Typography sx={{
                                                                                    fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                    textDecoration: 'underline', cursor: "pointer"
                                                                                }}
                                                                                    // onClick={() => window.location.href = service?.link}
                                                                                            onClick={() => handleClick(service?.link)} >More Info</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>


                                                                </Grid>
                                                            </>
                                                        ))}
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 20 }}>
                                                <Typography variant="h6"
                                                            style={{
                                                                marginLeft: "18px",
                                                                fontSize: '21px',
                                                                fontWeight: '700',
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                color: "#fff",
                                                                padding: "5px 15px",
                                                                backgroundColor: "#000"
                                                            }}>List Of Services</Typography>
                                                {ServiceData?.data && ServiceData?.data.length > 0 ? (
                                                    ServiceData?.data
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            service?.is_promo === 0 && service?.is_consultation_category === 0 && service?.is_show_website === 1 && service?.is_extra_service === 0 && service?.is_free === 0 ? (
                                                                <>
                                                                    <Grid item xs={12} sm={6} key={service.id}>

                                                                        <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                            {
                                                                                service?.is_price_show != 0 ? (
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                            {
                                                                                                service?.is_upfront_enable === 0 ? (
                                                                                                    <>
                                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, textDecoration: 'line-through', textDecorationColor: 'black'  }}>
                                                                                                                    ${service?.regular_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Regular Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                    ${service?.member_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Member Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                        $ {service?.upfront_amount} deposit required
                                                                                                    </Typography>
                                                                                                )
                                                                                            }



                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                ):null
                                                                            }
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                                {service?.is_price_show != 0 ? (
                                                                                    <Box>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                            Book Now <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                ) : (
                                                                                    <>
                                                                                        <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                                Book Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                                Book Virtual Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </>
                                                                                )}

                                                                                {loading ? <Waveloading fullSize /> : ''}
                                                                                <Box>
                                                                                    <Typography sx={{
                                                                                        fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                        textDecoration: 'underline', cursor: "pointer"
                                                                                    }}
                                                                                        // onClick={() => window.location.href = service?.link}
                                                                                                onClick={() => handleClick(service?.link)}
                                                                                    >More Info</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <>

                                                                </>)
                                                        ))) : ''}
                                            </Grid>
                                        </>
                                    )}

                                </Box>


                            )}


                        </>

                    </Box>
                </Container>
                <Footer  />
            </Box>
        </>






    );
};

export default SubCategories;