 import React, {useState} from "react";
import {Box, Collapse, Drawer, ListItemIcon, Typography, useMediaQuery, useTheme} from "@mui/material";
import logo from "../../assets/images/logo.jpg";
import { Link } from "react-router-dom";
import {ReactComponent as Analytics} from "../../assets/icon/analytics.svg";
import {ReactComponent as  Calendar} from "../../assets/icon/calendar-outline.svg";
import {ReactComponent as CalendarNumber} from "../../assets/icon/calendar-number-outline.svg";
import {ReactComponent as Mail} from "../../assets/icon/mail-outline.svg";
import {ReactComponent as PersonAdd} from "../../assets/icon/person-add-outline.svg";
import {ReactComponent as Call} from "../../assets/icon/call-outline.svg";
import {ReactComponent as HalfStar} from "../../assets/icon/star-half.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import InventoryIcon from '@mui/icons-material/Inventory';
import { removeUser } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";
import {MdExpandLess, MdExpandMore, MdPowerSettingsNew} from "react-icons/md";
import { confirmation } from "../../services/utills";
import {scrollCSS} from "../../App";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {closedMixin, openedMixin} from "./adminSidebar";
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CircleIcon from '@mui/icons-material/Circle';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonIcon from '@mui/icons-material/Person';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CategoryIcon from '@mui/icons-material/Category';


const listItemHoverStyle = {
    borderRadius: "7px",
    mb: "7px",
    "&:hover": {
      backgroundColor: "#C9EDFC",
    
      width: '100%', 
     
     
    },
  
    width: '100%',
    justifyContent: 'flex-start', 
  };
const providerId = 2;
const getSideData = (user) => {
    console.log("SideBar User:"+user)

    // Define all menu items with a condition for the "STAFF SCHEDULE" item
    const allItems = [
        {
            name: "ZOEY AI",
            key: "Zoey-Ai",
            logo: <AdsClickIcon style={{color: 'black', fontSize: "28px"}} />,
            url: `/crm/Zoey-Ai`,
            active: ["Zoey-Ai"] 
        },
        {
            name: "DASHBOARD",
            key: "dashboard",
            logo: <Analytics />,
            url: "/crm/dashboard",
            active: ['dashboard'],
        },
        {
            name: "CALENDAR",
            key: "calendar",
            logo: <Calendar />,
            url: "/crm/calender",
            active: ['calender'] 
        },
        {
            name: "APPOINTMENT",
            key: "appointment",
            logo: <CalendarNumber />,
            url: "/crm/appointments",
            active: ['appointments', 'add-appointment']
        },
        // Conditionally include "INBOX"
        ...(user?.is_inbox_enable ? [{
            name: "INBOX",
            key: "Chat",
            logo: <ForwardToInboxIcon style={{ color: 'black', fontSize: "28px" }} />,
            url: "/crm/chat",
            active: ['Chat']
        }] : []),
        {   
            name: "POS",
            key: "pos",                
            logo: <PostAddIcon style={{color: 'black', fontSize: "28px"}} />,
            url: "/crm/point-of-sale/new-invoice",
            active: ['new-invoice', 'point-of-sale']    
        }, 
        { 
            name: "CRM",
            key: "crm",  
            logo: <PersonAdd />,  
            url: "/crm/customer-relationship-management",
            active: ['customer-relationship-management',]
        },
        // Include the "MEMBERSHIP" conditionally
        ...(user?.is_new === 1 ? [{
            name: "MEMBERSHIP",
            key: "membership",
            logo: <PersonIcon style={{color: 'black', fontSize: "28px"}} />,
            url: `/crm/membership`,
            active: ["membership"]
        }] : []),
        {
            name: "TIME TRACKER",
            key: "time-tracker",
            logo: <AssignmentOutlinedIcon style={{color: 'black', fontSize: "28px"}} />,
            url: "/crm/Staff-attendance",
            active: ['staff-attendance',]
        },
        // Include the "STAFF SCHEDULE" conditionally 
        {
            name: "STAFF SCHEDULE",
            key: "staff-schedule",
            logo: <HalfStar style={{color: 'black', fontSize: "28px"}} />,
            url: user?.role_id === 4 ? `/crm/configuration/clinic/${user?.provider_id}/staff` : `/crm/configuration/clinic/${user?.id}/scheduler`,
            active: ["scheduler"]
        },

       
       
        {
            name: "END OF DAY",
            key: "end-of-day",
            logo: <SummarizeIcon style={{color: 'black', fontSize: "28px"}} />,
            url: `/crm/eod-report`,
            active: ["eod-report"] 
        },
        // 
        {
            name: "ACTIVITY LOGS",
            key: "activity-log",
            logo: <NaturePeopleIcon style={{color: 'black', fontSize: "28px"}} />,
            url: `/crm/activity-log`,
            active: ["activity-log"]
        },
        {
            name: "REPORTS",
            key: "reports",
            logo: <SummarizeOutlinedIcon style={{color: 'black', fontSize: "28px"}} />,
            url: `/crm/reports`,
            active: ["reports"]
        },
        {
            name: "INVENTORY",
            logo: <CategoryIcon sx={{fontSize:"28px", color:'#000'}}/>,
            url: "/crm/inventory",
            active:['inventory',]
        },


        //



    ];
    if (user?.role_id === 4) {
        const menuKeys = user.menus.map(menu => menu.key); // Extract menu keys from user.menus
        return allItems.filter(item => menuKeys.includes(item.key)); // Filter items based on presence of key in menuKeys
    } else {

        return allItems; 
    }   
    // Filter out any null values resulting from the conditional inclusion
}
const callLogsRoutes = [
    {   
        name: "APPOINTMENT",
        logo: <CircleIcon sx={{fontSize:"14px"}}/>,
        url: "/crm/appointment-call-logs",
        active:['appointment-call-logs',]
    },
    {
        name: "TREATMENT",
        logo: <CircleIcon sx={{fontSize:"14px"}}/>,
        url: "/crm/treatment-call-logs",
        active:['treatment-call-logs',]   
    },
]
// const inventoryRoutes = [
//     {
//         name: "PRODUCT",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/inventory/product",
//         active:['product',]
//     },
//     {
//         name: "ADD PRODUCT",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,  
//         url: "/crm/inventory/product/basic-info",
//         active:['basic-info','pricing','inventory','brand','category',"vendor","product-image"]
//     },
//     {  
//         name: "PURCHASED",   
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/inventory/purchase",
//         active:['purchase',]
//     },
//     {
//         name: "INV CONSUME",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/inventory/inv-consume",
//         active:['inv-consume',]
//     },
// ]
// const reportsRoutes = [
//     {
//         name: "GENERAL REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/general-report",
//         active:['general-report',]
//     },
//     {
//         name: "SALE REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/sale-report" ,
//         active:['sale-report',]
//     },
//     {
//         name: "GIFT CARD REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/gift-card-report" ,
//         active:['gift-card-report',]
//     },
//     {
//         name: "MEMBER REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/member-report",
//         active:['member-report',]
//     },
//     // {
//     //     name: "EOD REPORTS",
//     //     logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//     //     url: "/crm/eod-report",
//     //     active:['eod-report',]
//     // },
//     {
//         name: "Staff REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/staff-sale-report" ,
//         active:['staff-sale-report',]
//     },
//     {
//         name: "CATEGORY REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/category-sale-report",
//         active:['category-sale-report',]
//     },
//    {
//         name: "WALLET REPORTS",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,
//         url: "/crm/wallet-report",
//         active:['wallet-report',]
//     },    
//     {     
//         name: "ATTENDENCE",
//         logo: <CircleIcon sx={{fontSize:"14px"}}/>,   
//         url: "/crm/attendance-report",
//         active:['attendance',]
//     },  

// ]
 
export const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: 250,
        flexShrink: 0,
        zIndex:'11',
        whiteSpace: 'nowrap',   
        boxSizing: 'border-box',
        ".css-12i7wg6-MuiPaper-root-MuiDrawer-paper":{
            zIndex:'11',
        },
        ...(open && {
            ...openedMixin(theme,250),
            '& .MuiDrawer-paper': openedMixin(theme,250),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const CRMSidebar = ({ sidebarOpen,mobileOpen,setMobileOpen }) => {
    const [open, setOpen] = useState(0);    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();   
    const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const {pathname} = location;
    const authStore = useSelector((state) => state?.authStore);
    const { user } = authStore;
    const params = useParams()
    const sideData = getSideData(user);
   
    const handleNavigate = (url) => {
        setMobileOpen(false);
        navigate(url);
      
    };
    const handleClick = (val) => {
        setOpen(val === open ? 0 : val);
    }; 
    const handleLogout = async () => {
       
            // Navigate to login page
            navigate("/crm/user-manual");
    
            // Optionally, force a page reload
          
    };
    const shouldDisplayMenu = (menuKey) => {
        // Check if user role is 4 and then check if the menuKey exists in user.menus
        return user?.role_id === 4 ? user.menus.some(menu => menu.key === menuKey) : true;
    };
    const checkActive = (activeArray) => {

        let res = pathname.split('/')
        let lastValue
        if (pathname.includes('edit-')) {
            lastValue = res[res?.length - 2];

        } else {
            lastValue = res[res?.length - 1];
        }
        return !!activeArray.includes(lastValue)
        // return pathname.includes(activeArray)
    }
     
    const drawer = () => (
        <Box
            sx={{
                borderRight: " 1px solid #dee2e6",
                height: "100vh",
                padding: sidebarOpen || isMdScreen ? "16px 5px 16px 16px" : "6px",
                '@media (max-width:350px)':{ padding:"16px 16px" }, 
                '@media (max-width:319px)':{ padding:"6px 15px" },
                overflowY: "auto",
                ...scrollCSS, 
            }}
        >
            <Box
                sx={{
                    mt:"3px",
                    mb: "3px",    
                }}
            >    
                {sidebarOpen || isMdScreen ? (
                    <Link to={"/"}>
                        <img src={logo} alt={"logo"} height={"fit-content"} width={"100%"}/>
                    </Link>
                ) : <></>}
            </Box>
            <Box>
                <List
                    sx={{  
                        width: "100%",  
                        ".MuiListItemIcon-root ": {
                            minWidth: "37px",
                        },
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                   {sideData?.map((item, index) => (
  <ListItemButton
    key={index}
    onClick={() => handleNavigate(item?.url)}
    sx={{
      ...listItemHoverStyle,
      backgroundColor: checkActive(item?.url) ? "#C9EDFC" : "#fff",
      border:item.key === "Zoey-Ai" ? '1px solid #e0494973' :"",
      position: "relative", // Ensure absolute positioning works inside
    }}
  >
    {/* Add the "New" tag conditionally */} 
    {item.key === "Zoey-Ai" && (
      <Typography
        variant="body2"
        sx={{
          position: "absolute",
          top: "5px",
          right: "10px",
          background: "#ff1361",
          backgroundImage:
            "linear-gradient(-225deg, #ff1361 0%, #44107a 29%, #ff1361 67%, #fff800 100%)",
          backgroundSize: "200% auto",
          color: "transparent",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",  
          animation: "textclip 2s linear infinite",  
          fontSize: "14px",
          fontWeight: "bold",
          "@keyframes textclip": {
            to: { backgroundPosition: "200% center" },
          },
        }}
      >
        New
      </Typography>
    )} 
    
    <ListItemIcon>{item?.logo}</ListItemIcon> 
    {(sidebarOpen || isMdScreen) && <ListItemText primary={item?.name} />} 
  </ListItemButton>
))}

                    {shouldDisplayMenu('call-logs') && (
                        <>
                        <ListItemButton
                            onClick={() => handleClick(2)}
                        >
                            <ListItemIcon> <Call/> </ListItemIcon>
                            <ListItemText primary="CALL LOGS"/>
                            {open === 2 ? <MdExpandLess fontSize={"24px"}/> : <MdExpandMore fontSize={"24px"}/>}
                        </ListItemButton>
                        <Collapse in={open === 2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {callLogsRoutes.map((item, index) => (
                                    <ListItemButton
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(item?.url)
                                        }
                                        sx={{
                                            borderRadius: "7px",
                                            mb: "7px",
                                            backgroundColor: checkActive(item?.active) ? "#C9EDFC" : "#fff",
                                            "&:hover": {
                                                backgroundColor: "#C9EDFC",
                                            },
                                        }}
                                    >
                                        <ListItemIcon sx={{minWidth:"28px!important"}}>{item?.logo}</ListItemIcon>
                                        <ListItemText primary={item?.name}/>
                                    </ListItemButton>
                                ))}  
                            </List>
                        </Collapse>
                        </>    
                    )}  
                    {/* {shouldDisplayMenu('inventory') && (
                        <>
                        <ListItemButton
                            onClick={() => handleClick(3)}
                        > 
                            <ListItemIcon>
                                <InventoryIcon style={{color:'black'}}/>
                            </ListItemIcon>
                            <ListItemText primary="INVENTORY"/>
                            {open === 3 ? <MdExpandLess fontSize={"24px"}/> : <MdExpandMore fontSize={"24px"}/>}
                        </ListItemButton>
                        <Collapse in={open === 3} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {inventoryRoutes.map((item, index) => (
                                    <ListItemButton
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(item?.url)
                                        }
                                        sx={{
                                            borderRadius: "7px",
                                            mb: "7px",
                                            backgroundColor: checkActive(item?.active) ? "#C9EDFC" : "#fff",
                                            "&:hover": {
                                                backgroundColor: "#C9EDFC",
                                            },
                                        }}
                                    >
                                        <ListItemIcon sx={ {minWidth:"28px!important"}}>{item?.logo}</ListItemIcon>
                                        <ListItemText primary={item?.name}/>
                                    </ListItemButton>
                                ))}                 
                            </List>
                        </Collapse>
                        </>
                    )} */}
                    {/* <ListItemButton
                        onClick={() => handleLogout()}
                        sx={{
                            borderRadius: "7px",
                            "&:hover": {
                                backgroundColor: '#fff',
                                color: "#2F80ED",
                            },
                        }}
                    >
                        <ListItemIcon>
                        <OndemandVideoIcon style={{ color:'black',fontSize: "1.5rem"}}/>
                        </ListItemIcon>
                        {(sidebarOpen || isMdScreen) && <ListItemText primary="User Manual"/>}
                    </ListItemButton> */}
                </List>
            </Box>
        </Box>
    )
    return (
        <Box> 
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(!mobileOpen)}
                sx={{
                    display: {xs: 'block', lg: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: {xs: '70%', sm: 250}},
                }}
            >  
                <Box>
                    {drawer()}
                </Box>
            </Drawer>
            <CustomDrawer  
                variant="permanent"
                open={sidebarOpen}
                sx={{
                    display: {xs: 'none', lg: 'block'},
                }}
            >
                {drawer()}
            </CustomDrawer>  
        </Box>
    );  
};

export default CRMSidebar;
