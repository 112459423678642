import { Box, Grid, Typography } from '@mui/material'
// import React from 'react'
import ThreeDLogo from "../../assets/logo/logo-3d-lifestyleR-White-01.png";
import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Map from "../../assets/images/treatment.webp";
import { Autocomplete, Avatar,  Button, ButtonGroup, Checkbox, MenuItem, Select, TextField } from "@mui/material";
// import { useLocation } from 'react-router-dom'; // Example import

// Import bowser for browser detection
// import bowser from 'bowser';
import { useLocation } from 'react-router';
import Bowser from 'bowser'; 
// import { useLocation } from 'react-router';
const SucessCard = ({data}) => {

    const location = useLocation();
    const selectedLocation = location?.state?.selectedLocation;
    console.log("data coming", selectedLocation);
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isFirefoxOrSafari = browser?.parsedResult?.browser?.name === "Firefox" ||
    browser?.parsedResult?.browser?.name === "Safari";
  // Example usage of browser detection
  console.log("Is Safari:", browser?.parsedResult?.browser?.name);
  console.log("Is Chrome:", browser?.parsedResult?.browser);
  console.log("Is Firefox:", browser.firefox);
    const containerStyles = {
      width: "100%",
      display: "flex", 
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: browser?.parsedResult?.browser?.name === "Chrome" ? "0px" : "350px",
    }; 

    let formattedCode = '';
    const giftCardCode = selectedLocation?.code;

    console.log("Gift code coming", giftCardCode)
 
    if (giftCardCode) {
        // Ensure giftCardCode is a string
        const codeString = giftCardCode.toString();

        for (let i = 0; i < codeString.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedCode += '\u00A0\u00A0'; // Add non-breaking spaces between groups
            }
            formattedCode += codeString[i];
        }
    } else {
        console.log("No gift card code found.");
    }

    console.log("Formatted gift card code:", formattedCode);
  
  return (
 <Box 
    sx={containerStyles} > 
     
    
    
   
    <Box id="gift-card" className="card">
            <div className="card-inner">
              <div className="front" style={{ position:'unset !important' }}>
                <img
                  src={Map}
                  className="map-img"
                  alt=""
                  style={{ borderRadius:'15px' }}
                  crossorigin="anonymous"
                />
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item={5.9}>
                    <img
                      src={ThreeDLogo}
                      alt=""
                      className="logo-3d"
                      crossorigin="anonymous"
                    />
                  </Grid>
                  <Grid item={5.9}>
                    <Typography
                      variant="h5"
                      className="color-white"
                      style={{
                        color: "#fff",
                        "@media print": { color: "#fff !important" },
                      }}
                    >
                      Gift Card
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="card-no">

                  <Grid item lg={12} id="gift-code">
                  <p
                    className="color-white"
                    style={{
                      color: "#fff",
                      fontFamily: "helvetica-lt-std-bold",
                      fontSize: "18px",
                     display:'block'
                    }}
                  >
                 {selectedLocation?.gift_card?.title}
                  </p>
                    <p
                      className="color-white"
                      style={{
                        color: "#fff",
                        "@media print": { color: "#fff !important" },
                      }}
                    >
                    {formattedCode}
                    </p>
                  </Grid>
                </Grid>
                <Grid container className="name">
                  <Grid lg item={12}>
                    <p
                      className="color-white"
                      style={{
                        color: "#fff",
                        "@media print": { color: "#fff !important" },
                      }}
                    >
                       {selectedLocation?.user?.name}
                    </p>
                  </Grid>
                </Grid>
                <Grid container sx={{ width:'100%', display:'flex', justifyContent:'space-between' }}>
                    <Grid item lg={3.9}>
                      <a style={{ textDecoration:'none', color:'#fff', fontSize:'13px' }} href="https://3dlifestyle.ca">3dlifestyle.ca</a>
                    </Grid>
                    <Grid item lg={3.9}>
                    <a style={{ textDecoration:'none', color:'#fff', fontSize:'13px' }} href="tel:4168500231">416-850-0231</a>
                    </Grid>
                    <Grid item lg={4}>
                      
                    </Grid>
                </Grid>
              </div>
            </div>
          </Box> 
      
          <Box sx={{marginTop:"20px",marginLeft:"30px"}}>
          <Typography variant="h6" color="initial" sx={{ mt: "20px", pt: 3 }}>
          <span style={{ fontWeight: 'bold', fontSize: '25px' }}>   Thank You for Your Purchase! Your gift card is ready. 🎉 </span>
      <br /><br />
      • <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Amount:</span>{selectedLocation?.amount}
      <br />
      • <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Gift Card Code:</span> {selectedLocation?.code}
      <br />
      • <span style={{ fontWeight: 'bold',fontSize: '20px' }}>Recipient:</span> {selectedLocation?.user?.name}
      <br /><br />
      <span style={{ fontWeight: 'bold', fontSize: '20px' }}>  We’ve emailed the details to {selectedLocation?.user?.email}. If you have any questions, feel free to contact us.</span>
      <br /><br />
      <span style={{ fontWeight: 'bold', fontSize: '20px' }}>   Enjoy gifting!</span>
    </Typography>
          </Box> 
          
         
         
        </Box>   
  )
}

export default SucessCard