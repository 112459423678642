import React, {useEffect, useState} from 'react';
import {Box, FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllClinics, getClinicByID, updateclinic} from "../../../store/clinics";
import ConfigurationLayout from "../configurationLayout";
import {useLocation, useParams} from "react-router-dom";
import ClinicSittings from "../clinic-settings";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";
import CustomBreadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import { updateroom } from '../../../store/clinics/clinicSettings/clinic-rooms';
import BackendPagination from '../../../components/pagination/backendPagination';

const Clinics = () => {
    const [loading, setLoading] = useState(false)
    const [clinicLoading, setClinicLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [lastItem, setLastItem] = useState('')

    const clinicStore = useSelector(state => state.clinicStore)
    const user = useSelector((state) => state?.authStore?.user);
console.log("clinics data",clinicStore)

    const {pathname} = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const handleClick = () => {
        navigate('/admin/configuration/add-clinic')
    }

    const {currentPage} = clinicStore
    useEffect(() => {
        let lastValue = pathname.split('/');
    
        let valueToSet = '';
    
        if (pathname.includes('edit-room')) {
            valueToSet = 'Edit-Room';
        } 
        if (pathname.includes('edit-promo')) {
            valueToSet = 'Edit-promo';
        } 
        if (pathname.includes('add-gift')) {
            valueToSet = 'Add-Gift';
        } 
        if (pathname.includes('edit-gift')) {
            valueToSet = 'Edit-Gift';
        } 
        
        // 
       else if (pathname.includes('edit-treatment-group')) {
            valueToSet = 'Edit-TreatmentGroup ';
        }
        else if (pathname.includes('edit-treatment-form')) {
            valueToSet = 'Edit-TreatmentForm';
        }
        else if (pathname.includes('edit-staff')) {
            valueToSet = 'Edit-Staff';
        } 
        else if (pathname.includes('edit-service')) {
            valueToSet = 'Edit-Service'
        }  
        else if (pathname.includes('edit-events')) {
            valueToSet = 'Edit-Events'
        }  
        else if (pathname.includes('edit-plan')) {
            valueToSet = 'Edit-Plan';    
        }  
        else if (pathname.includes('add-room')) { 
            valueToSet = 'Add-Room';    
        }   
        else if (pathname.includes('add-treatment-form')) {
            valueToSet = 'Add-Treatment-Form';    
        }   
        else if (pathname.includes('add-treatment-group')) {
            valueToSet = 'Add-Treatment-Group';    
        } 
        else if (pathname.includes('add-service')) {
            valueToSet = 'Add-Service';    
        }  
        else if (pathname.includes('add-events')) {
            valueToSet = 'Add-Events';    
        } 
        else if (pathname.includes('add-staff')) {
            valueToSet = 'Add-Staff';    
        } 
        else if (pathname.includes('add-plan')) {
            valueToSet = 'Add-Bundle';    
        } 
      
        else if (pathname.includes('add-promo')) {
            valueToSet = 'Add-Promo';    
        } 
        else{
            // valueToSet =replace('-', ' ');
        } 
        setLastItem(valueToSet);
    }, [pathname]);
        
    // useEffect(()=>{
    //     let lastValue = pathname.split('/')
    //     lastValue=lastValue[pathname.includes('edit-room')?lastValue?.length-2:lastValue.length-1]
    //     setLastItem(lastValue.replace('-',' '))
        
    // },[pathname]) 
     
    useEffect(() => {
        (async () => {  
            if (user?.role_id === 1) {  
                if (!clinicStore?.clinics?.length) {
                    setLoading(true)  
                }    
                await dispatch(getAllClinics())
                setLoading(false)
            }
        })()
    }, [dispatch,clinicStore?.clinics?.length])
    const refreshData = async () => {
        setLoading(true);
        try {
            await dispatch(getAllClinics())
           
        } catch (error) {
            console.error('Error fetching data:', error);
           
        }
        setLoading(false); 
    };
 
    const roleHead = [  
        {value: "Name", key: 'name'},
        {value: "Email", key: 'email'},
        {value: "Created at ", key: 'created_at'},
        {value: "Action", key: 'action'}
    ]

    const handleEdit = async (row, isEdit) => {
        if (isEdit) {
           navigate(`/admin/configuration/edit-clinic/${row?.id}`)
        } else {
            setClinicLoading(true)
            await dispatch(getClinicByID(row?.id))
            setClinicLoading(false)
            navigate(`/admin/configuration/clinic/${row?.id}/business-details`)
        }
    }
    let routeName;

if (location.pathname.includes('room')) {
    routeName = 'Rooms';
} else if (location.pathname.includes('treatment-form')) {
    routeName = 'Treatment Form';
} 
else if (location.pathname.includes('treatment-form')) {
    routeName = 'Treatment Form';
}
//  else if (location.pathname.includes('hours')) {
//     routeName = 'staff schedule'; 
// } 

else if (location.pathname.includes('service-type')) {
    routeName = 'Service Type';  
}
else if (location.pathname.includes('gift')) {
    routeName = 'Gift-Card'; 
}

else if (location.pathname.includes('extraservices')) {
    routeName = ' Extra Services'; 
} 
else if (location.pathname.includes('service')) {
    routeName = 'Services'; 
} 

else if (location.pathname.includes('events')) {
    routeName = 'Events'; 
} 
else if (location.pathname.includes('staff')) {
    routeName = 'Staff'; 
} 
else if (location.pathname.includes('plan')) {
    routeName = 'Bundle'; 
}  
else if (location.pathname.includes('promo')) {
    routeName = 'promo'; 
}  
else if (location.pathname.includes('business-details')) {
    routeName = 'Business Details'; 
}

else if (location.pathname.includes('business-hours')) {
    routeName = 'Business Hours'; 
}
else if (location.pathname.includes('contact-details')) {
    routeName = 'Contact Details'; 
}
else if (location.pathname.includes('location')) {
    routeName = 'Location'; 
}



else if (location.pathname.includes('social-info')) {
    routeName = 'Social Info'; 
}
else if (location.pathname.includes('business-radius')) {
    routeName = 'Business Radius'; 
}
else if (location.pathname.includes('update-password')) {
    routeName = 'Update Password'; 
}
else if (location.pathname.includes('twilio-config')) {
    routeName = 'Goto Config'; 
}
else if (location.pathname.includes('booking-settings')) {
    routeName = 'Booking Settings'; 
}
else if (location.pathname.includes('calender-settings')) {
    routeName = 'Calender Settings'; 
}
else if (location.pathname.includes('/scheduler')) {
    routeName = 'Settings'; 
}
else if (location.pathname.includes('/hour')) {
    routeName = 'staff schedule'; 
}
// 

else {
    routeName = clinicStore?.clinicByID?.name || 'Clinic';
}
const fetchPageData = async(page) => {    
    await dispatch(updateclinic({currentPage:page}))
    await refreshData()
}


    return (
        <ConfigurationLayout clinic={clinicStore?.clinicByID}>
            {params?.clinic_id &&
                <Box
                    sx={{
                        pb:"16px",
                        background:'#EDF2F7',
                    }}
                >
                    <CustomBreadcrumbs
                       back={
                        params?.clinic_id 
                        ? `/crm/configuration/clinic/${params.clinic_id}/` + 
                            (routeName === 'Treatment Form' ? 'treatment-form' :
                                routeName === 'Settings' ? 'rooms' :

                             routeName === 'Rooms' ? 'rooms' :
                             routeName === 'Treatment Group' ? 'treatment-group' :
                             routeName === 'Service Type' ? 'service-type' :
                             routeName === ' Extra Services' ? 'extraservices' :
                             routeName === 'Events' ? 'events' :
                             routeName === 'Staff' ? 'staff' :
                             routeName === 'Services' ? 'services' :
                             routeName === 'Bundle' ? 'plan' :
                             routeName === 'Business Details' ? 'business-details' :
                             routeName === 'Business Hours' ? 'business-hours' :
                             routeName === 'Contact Details' ? 'contact-details' :
                             routeName === 'Location' ? 'location' :
                             routeName === 'Gift-Card' ? 'gift' :
                            
                             routeName === 'staff schedule' ? 'scheduler' :

                             routeName === 'Social Info' ? 'social-info' :
                             routeName === 'Business Radius' ? 'business-radius' :
                             routeName === 'Update Password' ? 'update-password' :
                             routeName === 'Goto Config' ? 'twilio-config' :
                             routeName === 'Booking Settings' ? 'booking-settings' :
                             routeName === 'Calender Settings' ? 'calender-settings' :
                             routeName === 'promo' ? 'promo' :
                             '')  
                        : '/crm/dashboard'
                    }
                        backLabel={routeName}
                        name={lastItem || null }
                    />
                </Box>
            } 
            {clinicLoading ? <Loader fullSize/> : ""}
            {!params?.clinic_id && (
                <Grid container p={'20px'}>
                    <Grid item xs={12} sx={{
                        mb: '16px',                 
                          display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        flexWrap:'wrap',
                        '@media (max-width: 410px)' :{
                         display:'flex',
                         justifyContent:'flex-start'
                        },
                    }}>
                        <FormControl sx={{mr: "10px"}}>
                            <SimpleInput
                                search={search}
                                setSearch={setSearch}
                            />
                        </FormControl>
                        <CustomButton val={'Add Clinic'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
                    </Grid>
                    <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                        {loading ? (
                            <Box
                            sx={{ 
                                display: "flex", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                height: "60vh" 
                            }}
                        >
                            <Loader />
                        </Box>
                        ) :<CustomTable
                            headRow={roleHead}
                            tableData={clinicStore?.clinics?.data}
                            search={search}
                            isClinic={true}
                            type={"clinic"}
                            handleEdit={handleEdit}
                        />}
                    </Grid>
                    <Grid item xs={12}  >
    {clinicStore?.clinics?.data?.meta?.last_page > 1 &&
        <BackendPagination
            count={clinicStore?.clinics?.data?.meta?.last_page}
            currentPage={currentPage} 
            handleOnChange={fetchPageData}
        />
    }


</Grid>

                </Grid>
                
            )}
           
            {params?.clinic_id && user?.id && (<ClinicSittings clinicId={params?.clinic_id}/>)}
            
           
        </ConfigurationLayout>
    );
};

export default Clinics;