import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Appointment from "./appointment";
import Invoices from "./invoices";
import Treatment from "./treatment";
import ActivityLogs from "./activityLogs";
import WalletHistory from "./walletHistory";
import {useDispatch, useSelector} from "react-redux";
import {getAppointmentActivity, getAppointmentHistory} from "../../../../../store/crm/appointment";
import {useParams} from "react-router-dom";
import Loader from "../../../../../components/loader/loader";
import {scrollCSS} from "../../../../../App";
import Waveloading from '../../../../loading/Waveloading';

export const tabHistory =[
    {id:0,title:'Appointment'},
    {id:1,title:'Invoices'},
    {id:2,title:'Treatment'},
    {id:3,title:'Activity log'}, 
    {id:4,title:'Wallet History'},   
    
] 
const AppointmentHistory = ({appointmentDetails,onShowParent}) => {
    const [active, setActive] = useState(0)
    const [invoiceLoading, setInvoiceLoading] = useState(false)
    const [appointmentLoading, setAppointmentLoading] = useState(false)
    const [treatmentLoading, setTreatmentLoading] = useState(false)    
    const [activityLoading, setActivityLoading] = useState(false)
    const [walletLoading, setWalletLoading] = useState(false)
    // const [appointmentHistory, setAppointmentHistory] = useState(fal);
 
    const crmAppointmentStore = useSelector((state)=>state?.crmAppointmentStore)
    const {invoices=[],treatments=[],walletHistory= {} ,Activity=[]} = crmAppointmentStore
    const appointmentsHistory = useSelector(state => state.crmAppointmentStore.appointmentsHistory);

    const dispatch = useDispatch()
    let id=appointmentDetails?.customer?.id
console.log("coming load",Activity?.data)
    useEffect(()=>{
        (async ()=>{

            if (appointmentDetails?.customer?.id){
                setInvoiceLoading(true)
                await dispatch(
                    getAppointmentHistory({
                        user_id:appointmentDetails?.customer?.id,type:'Invoices'
                    })
                )
                setInvoiceLoading(false)

            }
        })()
    },[appointmentDetails?.customer?.id])

    useEffect(()=>{
        (async ()=>{

            if (appointmentDetails?.customer?.id){
                setAppointmentLoading(true)
                await dispatch(
                    getAppointmentHistory({
                        user_id:appointmentDetails?.customer?.id,type:'Appointments'
                    })
                )
                setAppointmentLoading(false)
                console.log("api call 1 ")

            }
        })()
    },[appointmentDetails?.customer?.id])
    useEffect(()=>{
      (async ()=>{

          if (appointmentDetails?.customer?.id){
            setActivityLoading(true)
              await dispatch(getAppointmentActivity(appointmentDetails?.customer?.id))
                setActivityLoading(false)
              console.log("api call 1 ")
     
          }
      })()
  },[appointmentDetails?.customer?.id])

    useEffect(()=>{
        (async ()=>{

            if (appointmentDetails?.customer?.id){
                setTreatmentLoading(true)
                await dispatch(
                    getAppointmentHistory({
                        user_id:appointmentDetails?.customer?.id,type:'Treatments'
                    })
                )
                setTreatmentLoading(false)

            }
        })()
    },[appointmentDetails?.customer?.id])

    useEffect(()=>{
        (async ()=>{

            if (appointmentDetails?.customer?.id){
                setWalletLoading(true)
                await dispatch(
                    getAppointmentHistory({
                        user_id:appointmentDetails?.customer?.id,type:'WalletHistory'
                    })
                )
                setWalletLoading(false)
            }
        })()
    },[appointmentDetails?.customer?.id])

    const handleChange = (event, newValue) => {
        setActive(newValue)
    }
    return (
        <Box sx={{ background: "#fff", minHeight: '100px' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'max-content',
            position: 'sticky', 
            top: 0, 
            zIndex: 1, 
            background: '#fff',
            overflowX: 'auto', 
            maxWidth: '100%', 
          }}
        >
          <Tabs 
            value={active}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              ".MuiTabs-indicator": {
                background: '#000000',
              },
              ".Mui-selected": {
                color: '#000!important',
                fontWeight: '600',
              },
            }}
          >
            {tabHistory?.length > 0 &&
              tabHistory.map((item, index) => (
                <Tab
                  sx={{
                    fontSize: '20px',
                    color: '#000',
                    textTransform: 'capitalize',
                    fontFamily:
                      active === item?.id
                        ? 'helvetica-lt-std-bold'
                        : 'helvetica-lt-std-roman',
                  }} 
                  label={item?.title}
                  key={item?.id}
                />
              ))}
          </Tabs>
        </Box>
        <Box
          sx={{
            background: "#F1F5F9",
            borderRadius: '8px',
            px: {xs:'8px',sm:'32px' },
            pt: {xs:'8px',sm:'32px' },
            mt: '24px',
            minHeight: '300px',
            pb: active === 3 ? '32px' : '',
            
          }}
        >
          {active === 0 && appointmentLoading ? <Waveloading /> : ''}
          {active === 1 && invoiceLoading ? <Waveloading/> : ''}
          {active === 2 && treatmentLoading ? <Waveloading /> : ''}
          {active === 3 && activityLoading ? <Waveloading /> : ''}
          {active === 4 && walletLoading ? <Waveloading /> : ''}
          
          <Box
            sx={{   
              maxHeight: 'calc(100vh - 250px)', 
              overflowY: 'auto', 
              "&::-webkit-scrollbar": {
                width: "10px",   
                height: "10px"
              },
              "&::-webkit-scrollbar-track": {  
                background: "#fff"
              },           
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                background: "rgba(0, 0, 0, 0.2)",
                boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
              }
            }}
          >
{active === 0 && !appointmentLoading && (
  <Appointment appointmentHistory={appointmentsHistory} onShowParent={onShowParent} />  
)}
{active === 1 && !invoiceLoading && (
  <Invoices appointmentInvoices={invoices} />    
)}
{active === 2 && !treatmentLoading && ( 
  <Treatment treatments={treatments} />        
)}
{active === 3 && !activityLoading  && (
  <ActivityLogs activity={Activity?.data} />
)}
{active === 4 && !walletLoading && (
  <WalletHistory walletHistory={walletHistory}/>
)}

          </Box>   
        </Box>
      </Box>
      
          
      
    );
};

export default AppointmentHistory;