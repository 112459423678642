import { Box, Typography, Grid, TableCell, TablePagination, TableRow, TableBody, TableHead, Table, TableContainer, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import useStyles from "./style";
import { scrollCSS } from "../../App";
import DateRangePicker from '../date-range/DatePicker';
import moment from 'moment';



const Consultationweekly = (props) => {

  
  const {
    consultationTitle,
    consultationStats
   } = props;
   const startOfWeek = moment().startOf("isoWeek").toDate();
   const endOfWeek = moment().endOf("isoWeek").toDate();
   const [localStartDate, setLocalStartDate] = useState(startOfWeek);
   const [localEndDate, setLocalEndDate] = useState(endOfWeek);
 
     
       const handleDateChange = (start, end) => {
        
         setLocalStartDate(start);
         setLocalEndDate(end);
         props.fetchData(start, end);
       
       };
       // Fetch data function
 
   const fetchDatafun = async () => {
     const response = await props.fetchData(localStartDate, localEndDate);
     // Process response or perform further actions
   };
 
   // Call fetchData on component mount
  //  useEffect(() => {
  //    fetchDatafun();
  //  }, []); // Empty dependency array to run only on mount
 
   const handleButtonClick = async () => {
     await fetchDatafun();
   };
 
   
    const color =  
    consultationTitle === 'Weekly Consultation Stats'
     ? 'rgb(46, 125, 50)' // Green
     : '#000'; // Default color


     const background =
     consultationTitle === 'Weekly Consultation Stats'
       ? '#E8F5E9'  
       : '#FFF7F8'; // Default color
  

       const classes = useStyles(props);
  
       function createData(newIntroduction, total, completed) {    
         return { newIntroduction, total, completed };  
       }   

       function createData2(exIntroduction, total, completed) {   
        return { exIntroduction, total, completed };
      }

       const rows = [
         createData("Virtual", consultationStats?.new_introduction_virtual_total, consultationStats?.new_introduction_virtual_completed, ),
         createData(    
           "In Person",
           consultationStats?.new_introduction_in_person_total,              
           consultationStats?.new_introduction_in_person_completed,      
         ),        
        
       ];
       const exeRows = [
        createData2("Virtual", consultationStats?.existing_introduction_virtual_total, consultationStats?.existing_introduction_virtual_completed, ),
        createData2(    
          "In Person",       
          consultationStats?.existing_introduction_in_person_total,
          consultationStats?.existing_introduction_in_person_completed,   
        ),
       
      ];

  return (
    <Box sx={{ padding: "40px 10px" }}>

        
    <Box sx={{ position:'relative', padding:'20px 0',  borderRadius: "8px", overflow:'hidden', background: background ,   height:'auto',
      '@media (min-width:1600px)': {padding:'20px 10px', height:'530px'} }}>  
      {/* Table */}
      <Typography variant="h5" sx={{ fontFamily:'helvetica-lt-std-bold', color:color, padding:'20px 20px' }}>
           {consultationTitle}       
        </Typography>   
        <DateRangePicker
  widthMember="100%"
  smWidth="100%"
        setStartDate={setLocalStartDate}
        setEndDate={setLocalEndDate}    
        startDate={localStartDate}
        endDate={localEndDate}
        onChange={handleDateChange} // Add this prop to your DateRange component if it supports it
      />     
      <TableContainer   
        component={Paper}
        sx={{
          mt: {sm:5, xs:2}, 
          boxShadow: "none",
          maxHeight: "650px",
          overflowY: "auto",      
          ...scrollCSS,
        }}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>  
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:'52%',
                }}   
              >
                New Introduction
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Total
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Completed
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.newIntroduction}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.newIntroduction}  
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>  
                  {row.total}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>      
                  {row.completed}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>


        <Table sx={{}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                }}
              >
                Existing Introduction
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Total
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Completed
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {exeRows.map((row) => (
              <TableRow  
                key={row.exIntroduction}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"  
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.exIntroduction}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.total}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.completed}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

          
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""
        rowsPerPage=""
        page=""
        onPageChange=""
        onRowsPerPageChange=""
      /> */}


    </Box>
    <Box/>
</Box>
  )
}

export default Consultationweekly