import React, { useCallback, useState } from "react";
import ComingSoon from "../../../components/coming-soon";
import UserLayout from "../../user";
import archive from "../../../assets/images/archive-outline.png";
import archiveBlue from "../../../assets/images/archive-blue.png";
import batch from "../../../assets/icon/batch.png";
import { Divider, Link as MuiLink, TableFooter} from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Box,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import {
  FormControl,
  FormLabel,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material";
import SubmitButton from "../../../components/custom-button/submitButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateUserAccount } from "../../../store/user/my-account";
import useStyles from "./style";
import FormDateInput from "../../../components/form/FormDateInput";
import folder from "../../../assets/images/folder.png";
import DateRange from "../../../components/date-range/date-range";
import { getCategoryReports, getCategorySaleReports, getCategoryService } from "../../../store/crm/reports";
import moment from "moment";
import CustomModal from "../../../components/modal/customModal";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import { getAppointmentDetails } from "../../../store/crm/appointment";
import { useLocation, useNavigate } from "react-router";
import Waveloading from "../../loading/Waveloading";
import CalenderAppointmentDetail from "../calender/calenderAppointmentDetail";
import { getAllCalenderBookings } from "../../../store/crm/calender";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import ribbonorange from "../../../assets/images/ribbon-orange.svg";
import {
  content,
  handleEditReschedule,
  heading,
  ribbonwrapper,
  textul, wallet
} from "../../../components/card-appointment/CardAppointment";
import { getProviderStaff } from "../../../store/crm/staff";

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

export const boxwrapper = {
  border: "1px solid #000",
  padding: "10px",
  height: "13vh",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "8px",
};

export const box = {
  display: "block",
  margin: "15px auto",
};
const statusColorMapping = {

  Pending :'#56CCF2',
  Confirmed :'#323681',
  UnConfirmedPending :'#56CCF2',
  ConfirmedPending : '#323681',
  CheckIn:'#F2C94C',
  Completed: '#219653',
  CheckOut : '#219653',
  Canceled : '#EB5757',
  Refund: '#EB5757',
  NoShow :'#9A9797',
  PendingConfirmation: '#56CCF2',

};  

const Categorysale = () => {
  const [loadingSecondTable ,setloadingSecondTable ] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [age, setAge] = React.useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Booking");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [appointmentDetail, setAppointmentDetail] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true);
  // categorySaleReports

  const reportsStore = useSelector(state => state?.reportsStore)
  const { categorySaleReports = [], CategoryReport = [], CategoryService = [], } = reportsStore
  const [staff, setStaff] = React.useState('');
  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const {crmStaffs = []} = crmStaffStore
 
  useEffect(() => {
    dispatch(getProviderStaff());
  }, []);
  const handleChange = (event) => {
    setStaff(event.target.value); 
  };
  const handleChanges = (event) => {
    setAge(event.target.value); 
  };
  const crmAppointmentStore = useSelector((state) => state?.crmAppointmentStore)
    const {appointmentDetails = []} = crmAppointmentStore

    const { state } = useLocation()

  const classes = useStyles();   
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
  });
  const navigate = useNavigate()

  const handleStaffChange = (event) => {
    setSelectedStaff(event.target.value); 
  };

  const handleBookingTypeChange = (event) => {   
    setSelectedBookingType(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleRadioChange = (event) => {   
    setSelectedRadio(event.target.value);
  };
 
  console.log("data", CategoryReport)
  useEffect(() => {
    dispatch(getCategoryService());
  }, []);

  useEffect(() => {
    formik.setFieldValue('staff_id', age); 
  }, [age]);
  useEffect(() => {
    formik.setFieldValue('staff_id', selectedBookingType);
  }, [selectedBookingType]); 



  const today = new Date();
  const formik = useFormik({   
    initialValues: {
      start_date: today,  
      end_date: today,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
        end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
        date_type: selectedRadio,
        staff_id :staff,

      };    
      if (selectedBookingType) {
        payload.booking_type = selectedBookingType;
      }  
    
      if (selectedStatus) {   
        payload.status = selectedStatus;
         }
   
         console.log("values",values)
         if (age) { 
          payload.service_category_id = age;
      }
      
      try {   
        setSelectedRow(null);
        setLoading(true);
        await dispatch(getCategoryReports(payload)) 
        setLoading(false);  
        setDataFetched(true);
      } catch (error) {  
        console.error(error);
      }
    },
  });
  
  const handleAppointmentClick = (url) => {
    navigate(url)
}


//   const fetchCalenderBookings = async (date)=>{
//     setLoading(true)
//     await dispatch(getAllCalenderBookings({date:date || startDate}))
//     setLoading(false)
// }


  const handleRowClick = async(index, id, startDate, endDate) => {
    setSelectedRow(index);
    const payload = {
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      date_type: selectedRadio,
      service_category_id: id ,
      staff_id :staff,
    };
    if (selectedBookingType) {
      payload.booking_type = selectedBookingType;
    }

    
    if (selectedStatus) {
      payload.status = selectedStatus
    }

    try { 
      setloadingSecondTable(true)
    await  dispatch(getCategorySaleReports(payload));
    setloadingSecondTable(false);


    } catch (error) {
      console.error(error);
    }
  };  
  
  const handleAppointmentDetail = async (id) => {
    let {payload} = await dispatch(getAppointmentDetails(id))

    if (payload?.id){
      setAppointmentDetail(payload)
    }else {
      setAppointmentDetail(null)
    }    
  }  

  

  useEffect(() => {
    const fetchData = async () => {
      if (state && shouldFetch) {
        let startDateValue = state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : null;
        let endDateValue = state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : null;
  
        formik.setFieldValue('start_date', startDateValue);
        formik.setFieldValue('end_date', endDateValue);
  

        let payload = {
          start_date: startDateValue,
          end_date: endDateValue,
          date_type: selectedRadio,
          staff_id :staff,
        };
  

        if (state?.value) { 
          payload.service_category_id = state?.value;
          setAge(state?.value)

      }
      // valuestatus
      if (state?.valuestatus) { 
        payload.selectedStatu = state?.valuestatus;
        setSelectedStatus(state?.valuestatus)

    }
      
  
        try {
          setLoading(true);
          const isSubmitSuccessful=   await dispatch(getCategoryReports(payload)) 

          setLoading(false);
       
          setDataFetched(true);
  
          // Set the selectedStatus based on state.value
          setShouldFetch(false);
        } catch (error) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  }, [shouldFetch, state, selectedRadio, selectedBookingType,]);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (state && shouldFetch) {
  //       let startDateValue = state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : null;
  //       let endDateValue = state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : null;

  //       // Update startDate and endDate state for DateRange filter
  //       formik.setFieldValue('start_date', startDateValue);
  //       formik.setFieldValue('end_date', endDateValue);

  //       let payload = {
  //         start_date: startDateValue,
  //         end_date: endDateValue,
  //         date_type: selectedRadio,
  //         service_category_id: state.id,
  //       };

  //       if (state.id !== age) {
  //         setAge(state.id);
  //       }

  //       const isSubmitSuccessful = await handleSubmit(payload);
  //       if (isSubmitSuccessful) {
  //         await handleRowClick(payload.start_date, payload.end_date, payload.service_category_id); // Ensure correct arguments
  //       }
  //       setShouldFetch(false);  
  //     }
  //   };   

  //   fetchData();
  // }, [state, selectedRadio, handleSubmit, handleRowClick, setStartDate, setEndDate]);
  const status = appointmentDetails?.status;
  const background = statusColorMapping[status] || 'gray';
  const color = statusColorMapping[status] || 'gray';
  const borderColor= statusColorMapping[status] || 'gray';


  return (
    <>
            <Typography variant="h5" color="initial">
            <DescriptionIcon sx={{color:'#09ABF1',marginTop:'-3px'}}/>  Category Sale Report
            </Typography>
      <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            sx={{
              alignItems: { md: "start", xs: "center" },
              justifyContent: "space-between",
              mt:{sm:3,xs:0},
              flexWrap: "wrap",   
            }}
          >
            <Grid item lg={12} xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
 
  <FormControl   
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },     
                  mt: { xs: 2, sm: 1, lg: 2 },   
                }}  
              >      
      <FormLabel>Select Date</FormLabel> 
        <DateRange
        setStartDate={(date) => formik.setFieldValue('start_date', date)}
        startDate={formik.values.start_date}
        setEndDate={(date) => formik.setFieldValue('end_date', date)}
        endDate={formik.values.end_date}  
        onBlur={formik.handleBlur}
      />

 {(formik.touched.start_date || formik.touched.end_date) && (!formik.values.start_date || !formik.values.end_date) && (
   <div style={{ color: "red" }}>Date field is required</div>   
  )}   
              </FormControl>
              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  maxWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1 },
                }}
              >  
                <FormLabel>Service Category</FormLabel>  
                <Select
                  value={age}  
                  onChange={handleChanges}
                  displayEmpty
                  sx={selectStyle}
                >
                  {CategoryService.map((service) => (
                    <MenuItem key={service.category_id} value={service.category_id}>
                      {service.category_name}
                    </MenuItem>     
                  ))}   
                </Select>
              </FormControl>    

              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1 },
                }}
              >
                <FormLabel>Booking Type</FormLabel>
                <Select
                  value={selectedBookingType}
                  onChange={handleBookingTypeChange}
                  displayEmpty
                  sx={selectStyle}   
                >
                  <MenuItem value="Self">Self</MenuItem>  
                  <MenuItem value='Crm'>CRM</MenuItem>
                  <MenuItem value='Calendly'>Square</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1 },
                }}
              >
                <FormLabel>Status</FormLabel>
                <Select
                  value={selectedStatus}
                  onChange={handleChangeStatus}   
                  displayEmpty
                  sx={selectStyle}  
                >
                  <MenuItem value="">Status</MenuItem>
                  {/*<MenuItem value="Paid">Paid</MenuItem>*/}
                  {/*<MenuItem value="UnPaid">UnPaid</MenuItem>*/}
                  <MenuItem value="CheckOut">Completed</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  {/*<MenuItem value="No Show">No show</MenuItem>*/}
                  {/*<MenuItem value="Cancel">Cancel</MenuItem>*/}
                  {/*<MenuItem value="Void">Void</MenuItem>*/}
                  {/*<MenuItem value="Confirmed">Confirmed</MenuItem>*/}
                </Select>
              </FormControl>
              <FormControl    
                sx={{    
                  minWidth: { xs: "100%", sm: "220px" },  
                  maxWidth: { xs: "100%", sm: "220px" },   
                  mt: { xs: 2, sm: 1 },  
                }}
              >     
                <FormLabel>Staff</FormLabel>  
                <Select
                  value={staff}             
                  onChange={handleChange}
                  displayEmpty
                  sx={selectStyle}
                >
                   {crmStaffs?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>     
                  ))}   
                </Select>
              </FormControl> 
            </Grid>
            <Grid item lg={12} sx={{ display: 'flex', flexWrap:{sm:'nowrap', xs:'wrap'} }}>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={selectedRadio}
                onChange={handleRadioChange}
                defaultValue="top"
                sx={{ mt: 2 }}
              >
                <FormControlLabel
                  value="Created"
                  control={<Radio />}
                  sx={{ mr: { xs: 1, md: 5 } }}
                  label="By Created Date"
                />
                <FormControlLabel
                  value="Booking"
                  control={<Radio />}
                  label="By Booking  Date"
                />
              </RadioGroup>

              <Box sx={{ mt: 2,
    display:"flex",
    justifyContent:{xs:"flex-end",lg:"flex-start",md:"flex-start",sm:"flex-start"} ,
     width: {
    xs: "100%", // Set width to 100% for sm breakpoint
    sm: "auto", // Set width to auto for other breakpoints
    md: "auto",
    lg: "auto"
  }}}>
                <Button variant="contained" color="primary" type="submit">
                  Fetch Report
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>

      <Box sx={{mt: 4 }}>
        <UserLayout>
          {loading ? (
            <Waveloading />  
          ) :   

            dataFetched ? (
              <>
                <TableContainer
                  component={Paper}
                  sx={{ mt: 5, maxHeight: "650px" }}
                >
                  <Table sx={{}} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: "700",
                            borderBottom: 0,
                          }}
                        >
                          Category Name
                        </TableCell>
                        <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                Revenue Earned
                </TableCell>
             
                <TableCell
                  align="left"   
                  sx={{       
                    fontWeight: "700",
                    borderBottom: 0,
                  }}
                >
        Revenue Earned Count
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Forecast
                </TableCell>   
                <TableCell
                  align="left"
                  sx={{       
                    fontWeight: "700",
                    borderBottom: 0,
                  }}
                >
           Forecasted Count
                </TableCell>

                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                Percentage
                </TableCell>
               
                      </TableRow>   
                    </TableHead>

                    <TableBody> 
                      {CategoryReport?.data?.categories   
                        ?.length ? (
                        CategoryReport?.data?.categories
                          .map((item, index) => (     
                            <TableRow
                              key={index}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              className={classes.odd}
                              onClick={() => handleRowClick(index, item?.id, formik.values.start_date, formik.values.end_date,)}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableLink}
                                sx={{ cursor: 'pointer' }}
                              >
                                {item.title}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  fontSize={"18px"}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"18px"
                                  }}
                                >
                                      $  {item.total_revenue_earned}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  fontSize={"18px"}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"18px"
                                  }}
                                >
                                  {item.revenue_earned_count}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  fontSize={"18px"}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"18px" 
                                  }}
                                >
                                       $  {item.forecast}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  fontSize={"18px"}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"18px"
                                  }}
                                >
                                  {item.forecast_count}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="body1" color="initial"
                                fontSize={"18px"}>
                                  {item.percentage_revenue_earned ??"N/A"}%
                                </Typography>
                              </TableCell>


                            </TableRow>
                          ))                  
                      ) : (
                        <TableRow>
                          <TableCell
                            sx={{
                              height: '300px'
                            }}
                            colSpan={9}
                            align="center"     
                          >
                            Category data not found
                          </TableCell>
                        </TableRow>
                      ) }

                    </TableBody>
                    <TableFooter className={classes.tableHead} sx={{
      position: "sticky",
      bottom: 0,
    
    }}>
      <TableRow sx={{}}>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 , fontSize:"18px" }}>
          Total
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 , fontSize:"18px" }}>
     
    {CategoryReport?.data?.totals?.total_revenue_earned_sum ? `$ ${CategoryReport.data.totals.total_revenue_earned_sum}` : `$0`}


        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 , fontSize:"18px" }}>    
        {CategoryReport?.data?.totals?.total_revenue_earned_count}
      
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 , fontSize:"18px" }}> 
    
       {CategoryReport?.data?.totals?.total_forecast_sum ? `$ ${CategoryReport?.data?.totals?.total_forecast_sum}` : `$0`}

        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 , fontSize:"18px" }}>
         
          {CategoryReport?.data?.totals?.total_forecast_count} 
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 , fontSize:"18px" }}>
          100%
        </TableCell>
       
      </TableRow>
    </TableFooter>
                  </Table>
                </TableContainer>
              </>

            ) : ""}


          {/* Table */}
          <div style={{marginTop:"40px"}}>

          {selectedRow !== null && (
            <>
            {loadingSecondTable ? ( 
              <Waveloading />  
            ) : (
              <TableContainer
                component={Paper}    
                sx={{
                  boxShadow: "none",
                  maxHeight: 'calc(100vh - 250px)', // Adjust the height as needed
                  overflowY: 'auto', 
                  "&::-webkit-scrollbar": {
                    width: "10px",
                    height: "10px"
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#fff"
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    background: "rgba(0, 0, 0, 0.2)",
                    boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                  }
              
                }}
              >

                <Table
                  aria-label="simple table" 
                >
                  <TableHead className={classes.tableHead} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: "700",
                          borderBottom: 0,
                        }}
                      >
                        Booking Ref
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Customer
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Customer Contact Info
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Service Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Price (exc tax )
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "700", borderBottom: 0 }}
                      >
                        Payment
                      </TableCell> 
                    </TableRow>
                  </TableHead> 


                  <TableBody>
                    {categorySaleReports?.data?.bookings?.data?.length ? (
                      categorySaleReports?.data?.bookings?.data.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{      
                            cursor: item?.id ? 'pointer' : 'default',
                          }} 
                          className={classes.odd}
                        >
                          <TableCell onClick={() => item?.id && handleAppointmentDetail(item?.id)} component="th" scope="row" style={{ color: '#2F80ED' }} >
                            {item?.id}
                          </TableCell>
                          <TableCell align="left">
                            {/* <Typography
                              variant="body1"

                              sx={{ 
                                display: "flex",
                                alignItems: "center",
                                color: "#00BD06",
                                fontSize:"18px"
                              }}
                            >
                              <img src={batch} alt=""  width="25px" style={{ marginRight: '5px' }}  />{" "}
                              {item?.customer?.customer_batch}
                              {/* Active
                               */}
                            {/* </Typography> */}
                              {item?.customer?.customer_batch && (
                               item?.customer?.customer_batch!== "New User" ? (
                                                    <>
                                                        {item?.customer?.customer_batch.includes("Expire/Cancel") ? (
                                                            <div style={{display:"flex",alignItems:"start"}}>
                                                                <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                                                <Typography style={{color: "#ff0000" }}>
                                                                    {item?.customer?.customer_batch}
                                                                </Typography>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {item?.customer?.customer_batch.includes("Declined") ? (
                                                                   <div style={{display:"flex",alignItems:"start"}}>
                                                                        <img style={{ marginTop: "4px",width:"20px" }} src={ribbonorange} alt={'member'} />
                                                                        <Typography style={{  color: "#f55d0b" }}>
                                                                            {item?.customer?.customer_batch}
                                                                        </Typography>
                                                                    </div>
                                                                ) : (
                                                                  <div style={{display:"flex",alignItems:"start"}}>
                                                                        <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                                                        <Typography style={{ color: "#219653" }}>
                                                                            {item?.customer?.customer_batch}
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography style={{ color: "#09ABF1" }}>
                                                            {item?.customer?.customer_batch}
                                                        </Typography>
                                                    </>
                                                ))}
                            <Typography className={classes.tableLink} sx={{ cursor: 'pointer' }}   onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${item?.customer?.id}`)}>{item?.customer?.name}</Typography>

                          </TableCell>
                          <TableCell align="left">   
                            <Typography variant="body1"></Typography>
                            {item?.customer?.email}
                            <Typography variant="body1" ></Typography>
                            {item?.customer?.phone}
                          </TableCell>
                          <TableCell align="left">{item.service_name}</TableCell>
                          <TableCell align="left">{item.services_total_cost}</TableCell>
                          <TableCell align="left">CRM</TableCell>
                          <TableCell align="left">{item?.date}</TableCell>
                          <TableCell align="left">
                            <div 
                            style={{
                              background: item?.status_color, 
                              borderRadius: '13px',
                              color: '#fff', 
                              padding: "2px 12px", 
                              textAlign:"center",
                              height:item?.status === "Pending Confirmation" ? "43px":"fit-content",
                            }}>
                             
                              {item?.status}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div style={{ 
                              background:item?.payment_status === 'Paid' || item?.payment_status === 'Partial Paid' || item?.payment_status === 'Pre Paid' ? '#219653' : 'red',
                              borderRadius: '13px',
                              color: '#fff',
                              textAlign:"center",
                              padding: '2px 12px',
                              height: item?.payment_status ==='Partial Paid' ?"41px":"fit-content"
                            }}>
                              {item?.payment_status}
                            </div>
                          </TableCell>
                        </TableRow>))
                    ) : (   
                      <TableRow>
                        <TableCell
                          sx={{
                            height: '300px'
                          }}
                          colSpan={9}
                          align="center"
                        >
                          Appointment data not found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            </>
          )}
          </div>



        </UserLayout>
      </Box>
      <CustomModal
              customWidth={"1000px"}
              open={appointmentDetail !== null}
              setOpen={() => setAppointmentDetail(null)}
          >
           <Box sx={{background: '#F1F5F9'}}>

            <Box
                         sx={{
                            background: '#F1F5F9',
                            px: {lg:'35px',sm:'20px', xs:'15px'},
                            mt: appointmentDetails?.status === 'Completed' ? '35px' : "20px"
                        }}>
                            <Grid container sx={{background: '#fff', p: '35px', borderRadius: '7px', mt:{sm:0, xs:4}}}>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Box>
                                        <Typography>Customer Name:</Typography>
                                        <Box>         
                                            <div>
                                                <Typography sx={{  
                                                    ...textul,
                                                    textTransform: 'capitalize',
                                                    cursor:"pointer"
                                                }} onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${appointmentDetails?.customer?.id}`)}>{appointmentDetails?.customer?.name}</Typography>
                                            </div>
                                            <Box sx={ribbonwrapper}>
                                                {appointmentDetails?.customer?.customer_batch && (
                                                    appointmentDetails?.customer?.customer_batch !== "New User" ? (
                                                    <>
                                                        {appointmentDetails?.customer?.customer_batch.includes("Expire/Cancel") ? (
                                                            <>
                                                                <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                                                <Typography style={{ color: "#FF0000" }}>
                                                                    {appointmentDetails?.customer?.customer_batch}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {appointmentDetails?.customer?.customer_batch.includes("Declined") ? (
                                                                    <>
                                                                        <img style={{ marginTop: "4px",width:"20px" }} src={ribbonorange} alt={'member'} />
                                                                        <Typography style={{  color: "#f55d0b" }}>
                                                                            {appointmentDetails?.customer?.customer_batch}
                                                                        </Typography>
                                                                    </>
                                                                ) : (    
                                                                    <>
                                                                        <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                                                        <Typography style={{ color: "#219653" }}>
                                                                            {appointmentDetails?.customer?.customer_batch}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography style={{ color: "#09ABF1" }}>
                                                            {appointmentDetails?.customer?.customer_batch}
                                                        </Typography>
                                                    </>
                                                ))}
                                            </Box>
                                            <div>
                                                <MuiLink href="#" color="inherit">
                                                    Member ID {appointmentDetails?.customer?.id}
                                                </MuiLink>
                                            </div>  
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sx={{px: '24px'}}>
                                    <Divider
                                        orientation="vertical"
                                        style={{borderColor: "#9A9797", height: "100%", width: "2px"}}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box sx={{display: "flex"}}>
                                        <Typography sx={{minWidth: '100px'}}>Wallet:</Typography>
                                        <Typography sx={content}>
                                            ${appointmentDetails?.customer?.wallet_point}
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: "flex"}}>
                                        <Typography sx={{minWidth: '100px'}}>Phone:</Typography>
                                        <Typography sx={content}>{appointmentDetails?.customer?.phone}</Typography>
                                    </Box>
                                    <Box sx={{display: "flex"}}>   
                                        <Typography sx={{...heading, minWidth: '100px'}}>Email:</Typography>
                                        <Typography sx={content}>{appointmentDetails?.customer?.email}</Typography>
                                    </Box>
                                </Grid>
       <Grid item sx={{display:"flex", marginLeft:{sm:'auto', xs:'unset'}, mt:{lg:0, md:1,sm:1, xs:1}}} lg={2} md={2} xs={6}>
                 <Box
                        sx={{
                            ...boxwrapper,
                            height: 'max-content',  
                            borderColor,
                            cursor: 'pointer',
                        }}
                    >
                        <Box
                            sx={{
                                ...box,
                                height: 'max-content',  
                                // background: appointmentDetails?.status === 'Confirmed' ? 'green' : 'red',
                                // my: appointment?.plan?.id ? '5px' : '',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentDetails?.id}`)}
                        >
                             
                            <Typography sx={{...content, color:"#fff", background ,borderRadius:"22px",padding:'2px 6px'}}>
                                Booking No
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color
                                }}
                            >
                                {appointmentDetails.id}
                            </Typography>
                        </Box>
                    </Box>
                    </Grid>
                            </Grid>
                        </Box>

              <AppointmentDetails appointmentDetails={appointmentDetails}/>
            </Box>
             
          </CustomModal>
    </>

  );
};

export default Categorysale;
