import React  , {useEffect, useState}from 'react';
import {Box, Breadcrumbs, Tooltip, Link as MuiLink, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import ArrowBack from "../../assets/icon/chevron-back-outline.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useNavigate } from 'react-router-dom';
const CustomBreadcrumbs = ({back,backLabel,name}) => {
//     const backUrl = localStorage.getItem('url')
//     console.log("url come",backUrl,back)

//     sessionStorage.setItem('lastUrl', window.location.href);
// const lastUrl = sessionStorage.getItem('lastUrl');
// console.log("Last session URL:", lastUrl);
//     const clearLocalStorage = () => {
//       localStorage.removeItem('url',)
//     }
const location = useLocation();

useEffect(() => {
    const previousUrl = sessionStorage.getItem("lastUrl");
    
    // Save the previous URL only if it's not the current one
    if (previousUrl !== window.location.href) {
        sessionStorage.setItem("lastUrl", previousUrl || window.location.href);
    }
}, [location.pathname]); // Runs when URL path changes

const backUrl = sessionStorage.getItem("lastUrl") || localStorage.getItem("url");

console.log("Back URL:", backUrl,back);

const clearLocalStorage = () => {
    localStorage.removeItem("url");
};

    return (
        <Box  xs={12} sx={{display: 'flex', alignItems: 'center'}}>
            <Tooltip title="Go Back">
            <Paper
                component={Link}  
                to={ "/crm/dashboard"}  
                onClick={clearLocalStorage}
                sx={{
                    width: 'max-content',
                    p: '5px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center",
                    cursor: 'pointer',
                    mr: {lg:"20px",md:"20px",xs:"8px"},
                }}
                elevation={3}
            >
                <img src={ArrowBack} alt={'close'}/>
            </Paper>
            </Tooltip>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small"/>}
                aria-label="breadcrumb"
            >
                <MuiLink onClick={clearLocalStorage} 
                 sx={{
                    fontSize:{lg:"1rem",sm:"1rem",xs:"15px"},
                    textDecoration: 'underline', // Explicitly add underline on hover if it's missing
                 
              }} 
                component={Link} to={'/'} underline="hover" color="inherit">
                    Home 
                </MuiLink>,   
                <MuiLink   
                    component={Link}
                    onClick={clearLocalStorage}
                    to={back}
                    // underline="hover" 
                    sx={{
                        fontSize:{lg:"1rem",sm:"1rem",xs:"15px"},
                          textDecoration: 'underline', // Explicitly add underline on hover if it's missing
                       
                    }}                                            
                    color="inherit"
                >
                    {backLabel}
                </MuiLink>,
                {name && <Typography
                    key="3"
                    color='#09abf1'
                    sx={{
                        fontFamily: 'helvetica-lt-std-bold',   
                        textTransform:'capitalize',
                        fontSize:{lg:"1rem",sm:"1rem",xs:"15px"}
                    }}
                >     
                    {name}
                </Typography>}
            </Breadcrumbs>
        </Box>
    );
}

export default CustomBreadcrumbs;