import React from "react";
import BusinessDetails from "../pages/configuration/clinic-settings/business-details/businessDetails";
import ContactDetails from "../pages/configuration/clinic-settings/contact-details/contactDetails";
import Location from "../pages/configuration/clinic-settings/location/location";
import ServiceType from "../pages/configuration/clinic-settings/service-type/serviceType";
import SocialInfo from "../pages/configuration/clinic-settings/social-info/socialInfo";
import Calender from "../pages/configuration/clinic-settings/calender/calender";
import BusinessRadius from "../pages/configuration/clinic-settings/business-radius/businessRadius";
import Plan from "../pages/configuration/clinic-settings/plan/plan";
import Promo from "../pages/configuration/clinic-settings/promo/promo";

import Staff from "../pages/configuration/clinic-settings/staff/staff";
import BusinessHours from "../pages/configuration/clinic-settings/business-hours/businessHours";
import TreatmentGroup from "../pages/configuration/clinic-settings/treatment-group/treatmentGroup";
import TreatmentForm from "../pages/configuration/clinic-settings/treatment-form/treatmentForm";
import Rooms from "../pages/configuration/clinic-settings/rooms/rooms";
import BookingSettings from "../pages/configuration/clinic-settings/booking-settings/bookingSettings";
import TwilioConfig from "../pages/configuration/clinic-settings/twilio-config/twilioConfig";
import UpdatePassword from "../pages/configuration/clinic-settings/update-password/updatePassword";
import Services from "../pages/configuration/clinic-settings/services/services";
import AddRooms from "../pages/configuration/clinic-settings/rooms/addRooms";
import AddPromo from "../pages/configuration/clinic-settings/promo/addPromo";
import AddTreatmentForm from "../pages/configuration/clinic-settings/treatment-form/addTreatmentForm";
import AddTreatmentGroup from "../pages/configuration/clinic-settings/treatment-group/addTreatmentGroup";
import AddServices from "../pages/configuration/clinic-settings/services/addServices";
import AddStaff from "../pages/configuration/clinic-settings/staff/AddStaff";
import AddPlan from "../pages/configuration/clinic-settings/plan/addPlan";
import StaffCalender from "../pages/configuration/clinic-settings/staff/staffCalender";
import StaffWorkingHours from "../pages/configuration/clinic-settings/staff/staffWorkingHours";
import Events from "../pages/configuration/clinic-settings/events/events";
import AddEvents from "../pages/configuration/clinic-settings/events/addEvents";
import promo from "../pages/configuration/clinic-settings/promo/promo";
import ExtraServices from "../pages/configuration/clinic-settings/extra-service/ExtraService";
import GiftCard from "../pages/configuration/clinic-settings/gift-Cards/GiftCard";
import AddGifts from "../pages/configuration/clinic-settings/gift-Cards/AddGifts";



export const clinicConfigurationRoutes =[
    {name:'Business Details',active:"/business-details",url:'/admin/configuration/clinic/',components:<BusinessDetails />},
    {name:'Business Hours',active:"/business-hours",url:'/admin/configuration/clinic/',components:<BusinessHours />},
    {name:'Contact Details',active:"/contact-details",url:'/admin/configuration/clinic/',components:<ContactDetails />},
    {name:'Location',active:"/location",url:'/admin/configuration/clinic/',components:<Location />},
    {name:'Service Type',active:"/service-type",url:'/admin/configuration/clinic/',components:<ServiceType />},
    {name:'Updating Social Info',active:"/social-info",url:'/admin/configuration/clinic/',components:<SocialInfo />},
    {name:'Business Radius',active:"/business-radius",url:'/admin/configuration/clinic/',components:<BusinessRadius/>},
    {name:'Update Password',active:"/update-password",url:'/admin/configuration/clinic/',components:<UpdatePassword/>},
    {name:'Goto Config',active:"/twilio-config",url:'/admin/configuration/clinic/',components:<TwilioConfig/>},
    {name:'Booking Settings',active:"/booking-settings",url:'/admin/configuration/clinic/',components:<BookingSettings/>},
    {name:'Calender Settings',active:"/calender-settings",url:'/admin/configuration/clinic/',components:<Calender/>},

]


export const clinicsRoutes =[
    {name:'Rooms',active:"/rooms",url:'/admin/configuration/clinic/',components:<Rooms/>},
    // {name:'Treatment',active:"/treatments",url:'/admin/configuration/clinic/',components:<Treatment/>},
    {name:'Treatment Forms',active:"/treatment-form",url:'/admin/configuration/clinic/',components:<TreatmentForm/>},
    {name:'Group / Treatments',active:"/treatment-group",url:'/admin/configuration/clinic/',components:<TreatmentGroup/>},
    {name:'Services',active:"/services",url:'/admin/configuration/clinic/',components:<Services/>},
    {name:'Extra Services',active:"/extraservices",url:'/admin/configuration/clinic/',components:<ExtraServices/>},
    {name:'Events',active:"/events",url:'/admin/configuration/clinic/',components:<Events/>},
    {name:'Staffs',active:"/staff",url:'/admin/configuration/clinic/',components:<Staff/>},
    {name:'Bundles',active:"/plan",url:'/admin/configuration/clinic/',components:<Plan/>},
    {name:'Promo Codes',active:"/promo",url:'/admin/configuration/clinic/',components:<Promo/>},
    {name:'Gift Cards',active:"/gift",url:'/admin/configuration/clinic/',components:<GiftCard/>},

]



export const clinicsAddEdit =[
    {name:'Add Rooms',active:"/add-room",url:'/admin/configuration/clinic/',components:<AddRooms/>},
    {name:'Add Promo codes',active:"/add-promo",url:'/admin/configuration/clinic/',components:<AddPromo/>},
    {name:'Add Gift Card',active:"/add-gift",url:'/admin/configuration/clinic/',components:<AddGifts/>},
    {name:'Edit Gift Card',active:"/edit-gift/",url:'/admin/configuration/clinic/',components:<AddGifts/>},
    {name:'Edit Promo Codes',active:"/edit-promo/",url:'/admin/configuration/clinic/',components:<AddPromo/>},
    {name:'Edit Rooms',active:"/edit-room/",url:'/admin/configuration/clinic/',components:<AddRooms/>},
    // {name:'Add Treatment',active:"/add-treatment/",url:'/admin/configuration/clinic/',components:<AddTreatment/>},
    // {name:'Edit Treatment',active:"/edit-treatment/",url:'/admin/configuration/clinic/',components:<AddTreatment/>},
    {name:'Add Treatment Form',active:"/add-treatment-form",url:'/admin/configuration/clinic/',components:<AddTreatmentForm/>},
    {name:'Edit Treatment Form',active:"/edit-treatment-form/",url:'/admin/configuration/clinic/',components:<AddTreatmentForm/>},
    {name:'Add Treatment Group',active:"/add-treatment-group",url:'/admin/configuration/clinic/',components:<AddTreatmentGroup/>},
    {name:'Edit Treatment Group',active:"/edit-treatment-group/",url:'/admin/configuration/clinic/',components:<AddTreatmentGroup/>},
    {name:'Add Staff',active:"/add-staff",url:'/admin/configuration/clinic/',components:<AddStaff/>},
    {name:'schedulers Staff',active:"/scheduler",url:'/admin/configuration/clinic/',components:<StaffCalender/>},
    {name:'Edit Staff',active:"/edit-staff/",url:'/admin/configuration/clinic/',components:<AddStaff/>},
    {name:'Scheduler Services',active:"/hours",url:'/admin/configuration/clinic/',components:<StaffWorkingHours/>},
    {name:'Add Services',active:"/add-service",url:'/admin/configuration/clinic/',components:<AddServices/>},
    {name:'Edit Services',active:"/edit-service/",url:'/admin/configuration/clinic/',components:<AddServices/>},
    {name:'Add Events',active:"/add-events",url:'/admin/configuration/clinic/',components:<AddEvents/>},
    {name:'Edit Events',active:"/edit-events/",url:'/admin/configuration/clinic/',components:<AddEvents/>},

    {name:'Add Plan',active:"/add-plan",url:'/admin/configuration/clinic/',components:<AddPlan/>},
    {name:'Edit Plan',active:"/edit-plan/",url:'/admin/configuration/clinic/',components:<AddPlan/>},
 ]



export const clinicCrmConfigurationRoutes =[
    {name:'Business Details',active:"/business-details",url:'/crm/configuration/clinic/',components:<BusinessDetails/>},
    {name:'Business Hours',active:"/business-hours",url:'/crm/configuration/clinic/',components:<BusinessHours/>},
    {name:'Contact Details',active:"/contact-details",url:'/crm/configuration/clinic/',components:<ContactDetails/>},
    {name:'Location',active:"/location",url:'/crm/configuration/clinic/',components:<Location/>},
    {name:'Service Type',active:"/service-type",url:'/crm/configuration/clinic/',components:<ServiceType/>},
    {name:'Updating Social Info',active:"/social-info",url:'/crm/configuration/clinic/',components:<SocialInfo/>},
    {name:'Business Radius',active:"/business-radius",url:'/crm/configuration/clinic/',components:<BusinessRadius/>},
    {name:'Update Password',active:"/update-password",url:'/crm/configuration/clinic/',components:<UpdatePassword/>},
    {name:'Goto Config',active:"/twilio-config",url:'/crm/configuration/clinic/',components:<TwilioConfig/>},
    {name:'Booking Settings',active:"/booking-settings",url:'/crm/configuration/clinic/',components:<BookingSettings/>},
    {name:'Calender Settings',active:"/calender-settings",url:'/crm/configuration/clinic/',components:<Calender/>},

]


export const clinicsCrmRoutes =[
    {name:'Rooms',active:"/rooms",url:'/crm/configuration/clinic/',components:<Rooms/>},
    // {name:'Treatment',active:"/treatments",url:'/admin/configuration/clinic/',components:<Treatment/>},
    {name:'Treatment Forms',active:"/treatment-form",url:'/crm/configuration/clinic/',components:<TreatmentForm/>},
    {name:'Group / Treatments',active:"/treatment-group",url:'/crm/configuration/clinic/',components:<TreatmentGroup/>},
    {name:'Services',active:"/services",url:'/crm/configuration/clinic/',components:<Services/>},
    {name:'Extra Services',active:"/extraservices",url:'/crm/configuration/clinic/',components:<ExtraServices/>},
    {name:'Events',active:"/events",url:'/crm/configuration/clinic/',components:<Events/>},
    {name:'Staffs',active:"/staff",url:'/crm/configuration/clinic/',components:<Staff/>},
    {name:'Bundles',active:"/plan",url:'/crm/configuration/clinic/',components:<Plan/>},
    {name:'Promo Codes',active:"/promo",url:'/crm/configuration/clinic/',components:<Promo/>},  
    {name:'Gift Cards',active:"/gift",url:'/crm/configuration/clinic/',components:<GiftCard/>},
]
export const clinicsStaffRoutes =[
    {name:'Staff',active:"/staff",url:'/crm/configuration/clinic/',components:<Staff/>},
]


export const clinicsCrmAddEdit =[
    {name:'Add Rooms',active:"/add-room",url:'/crm/configuration/clinic/',components:<AddRooms/>},
    {name:'Add Promo codes',active:"/add-promo",url:'/crm/configuration/clinic/',components:<AddPromo/>},
    {name:'Add Gift Card',active:"/add-gift",url:'/crm/configuration/clinic/',components:<AddGifts/>},
    {name:'Edit Gift Card',active:"/edit-gift/",url:'/crm/configuration/clinic/',components:<AddGifts/>},
    {name:'Edit Promo Codes',active:"/edit-promo/",url:'/crm/configuration/clinic/',components:<AddPromo/>},
    {name:'Edit Rooms',active:"/edit-room/",url:'/crm/configuration/clinic/',components:<AddRooms/>},
    // {name:'Add Treatment',active:"/add-treatment/",url:'/admin/configuration/clinic/',components:<AddTreatment/>},
    // {name:'Edit Treatment',active:"/edit-treatment/",url:'/admin/configuration/clinic/',components:<AddTreatment/>},
    {name:'Add Treatment Form',active:"/add-treatment-form",url:'/crm/configuration/clinic/',components:<AddTreatmentForm/>},
    {name:'Edit Treatment Form',active:"/edit-treatment-form/",url:'/crm/configuration/clinic/',components:<AddTreatmentForm/>},
    {name:'Add Treatment Group',active:"/add-treatment-group",url:'/crm/configuration/clinic/',components:<AddTreatmentGroup/>},
    {name:'Edit Treatment Group',active:"/edit-treatment-group/",url:'/crm/configuration/clinic/',components:<AddTreatmentGroup/>},
    {name:'Add Staff',active:"/add-staff",url:'/crm/configuration/clinic/',components:<AddStaff/>},
    {name:'schedulers Staff',active:"/scheduler",url:'/crm/configuration/clinic/',components:<StaffCalender/>},
    {name:'Edit Staff',active:"/edit-staff/",url:'/crm/configuration/clinic/',components:<AddStaff/>},
    {name:'Scheduler Services',active:"/hours",url:'/crm/configuration/clinic/',components:<StaffWorkingHours/>},
    {name:'Add Services',active:"/add-service",url:'/crm/configuration/clinic/',components:<AddServices/>},
    {name:'Edit Services',active:"/edit-service/",url:'/crm/configuration/clinic/',components:<AddServices/>},
    {name:'Add Events',active:"/add-events",url:'/crm/configuration/clinic/',components:<AddEvents/>},
    {name:'Edit Events',active:"/edit-events/",url:'/crm/configuration/clinic/',components:<AddEvents/>},
    {name:'Add Plan',active:"/add-plan",url:'/crm/configuration/clinic/',components:<AddPlan/>},
    {name:'Edit Plan',active:"/edit-plan/",url:'/crm/configuration/clinic/',components:<AddPlan/>},
 ]
export const clinicsStaffAddEdit =[
    {name:'Add Staff',active:"/add-staff",url:'/crm/configuration/clinic/',components:<AddStaff/>},
    {name:'schedulers Staff',active:"/scheduler",url:'/crm/configuration/clinic/',components:<StaffCalender/>},
    {name:'Edit Staff',active:"/edit-staff/",url:'/crm/configuration/clinic/',components:<AddStaff/>},
    {name:'Scheduler Services',active:"/hours",url:'/crm/configuration/clinic/',components:<StaffWorkingHours/>},
]