import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, FormControl,
    FormControlLabel, FormHelperText,
    FormLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {inputStyle, labelStyle} from "../../../../components/form/formInput";
import {IOSSwitch} from "../../../../components/switch/IOSSwitch";
import Autocomplete from "@mui/material/Autocomplete";
import CustomButton from "../../../../components/custom-button/customButton";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    getAllClinicsBusinessHours,
    getAllStaffBreakTime,
    getAllStaffHolidays,
    getStaffSchedule,
    storeStaffSchedule
} from "../../../../store/clinics/clinicSettings/business-hours";
import Loader from "../../../../components/loader/loader";
import { generateTimeArray, parseTime, timeSlots} from "../../../../services/utills";

import StaffHoliday from "./staffHoliday";
import StaffBreakTime from "./staffBreakTime";
import * as yup from "yup";
import {useFormik} from "formik";
import {useNavigate} from "react-router";
import Waveloading from '../../../loading/Waveloading';



const StaffWorkingHours = ({open,setOpen,staff,handleStaffCalender}) => {
    const [loading,setLoading] = useState(false)


    const  clinicsBusinessHoursStore = useSelector(state => state.clinicsBusinessHoursStore)
    const user = useSelector(state => state?.authStore?.user)

    const {businessHours,staffHours,staffHolidays,staffBreakTimes} = clinicsBusinessHoursStore



    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()


    const validationSchema = yup.object({
        hours: yup.array().of(
            yup.object().shape({
                is_off: yup.boolean().required("Is day off is required"),
                start_time: yup
                    .date()
                    .transform((value, originalValue) =>
                        originalValue ?  parseTime(originalValue) : null
                    ),
                // .required("Start time is required").typeError("Start time is required"),
                end_time: yup
                    .date()
                    .transform((value, originalValue) =>
                        originalValue ? parseTime(originalValue) : null
                    )
                    // .required("End time is required").typeError("End time is required")
                    .min(yup.ref("start_time"), "End time must be after start time"),
            })
        ),
    });

    const {values, handleSubmit, setFieldValue,resetForm, handleChange, touched, errors} = useFormik({
        initialValues: {
                hours: [
                    {day: 'Sunday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                    {day: 'Monday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                    {day: 'Tuesday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                    {day: 'Wednesday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                    {day: 'Thursday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                    {day: 'Friday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                    {day: 'Saturday', is_off: true, start_time: "", end_time: "",per_day_appointments:1},
                ],
            },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {

            setLoading(true)
            let res = await dispatch(storeStaffSchedule({
                hours: data?.hours,
                provider_id: params?.clinic_id,
                staff_id: params?.staff_id
            }))
            if (res?.payload?.success ==="true" && handleStaffCalender){
                await  handleStaffCalender()
                setOpen()
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
            }
            setLoading(false)
        },
    })


    useEffect(() => {
        (async () => {
            if (params?.clinic_id && params?.staff_id) {
                setLoading(true)
                await dispatch(getAllClinicsBusinessHours(params?.clinic_id))
                await dispatch(getStaffSchedule({provider_id: params?.clinic_id, staff_id: params?.staff_id}))
                setLoading(false)

                 dispatch(getAllStaffHolidays({provider_id: params?.clinic_id, staff_id: params?.staff_id}))
                 dispatch(getAllStaffBreakTime({provider_id: params?.clinic_id, staff_id: params?.staff_id}))
            }
        })()
    }, [params?.clinic_id,params?.staff_id])

    const createTimeSlots = (day)=> {
        if (businessHours){
            const sourceObject = businessHours.find((sourceObject) => sourceObject.day === day);
            const start_time = sourceObject?.start_time
            const end_time = sourceObject?.end_time

            if (start_time && end_time){
               return  generateTimeArray(start_time,end_time)
            }else {
                return timeSlots
            }
        }else {
            return timeSlots
        }
    };

    useEffect(()=>{

        if (staffHours?.length){
             values?.hours.map((destinationObject,index) => {
                const sourceObject = staffHours.find((sourceObject) => sourceObject.day === destinationObject.day);

                if (sourceObject) {
                    setFieldValue(`hours.${index}.start_time`, sourceObject?.start_time || "")
                    setFieldValue(`hours.${index}.end_time`,  sourceObject?.end_time || "")
                    setFieldValue(`hours.${index}.is_off`,  sourceObject?.is_off === 1 ? true : false)
                    setFieldValue(`hours.${index}.per_day_appointments`,  sourceObject?.per_day_appointments)


                }
            });
        }
    },[staffHours,staffHours?.length])


    return (
        // <CustomModal setOpen={()=> {
        //     setOpen()
        //     resetForm()
        // }} open={open}>
                <Box sx={{p:{lg:'32px',xs:'10px'}}}>
                    {loading ? <Waveloading fullSize/> : <></>}
                    <Typography  sx={{fontSize:'22px'}}>Set up weekly staff schedule For <span style={{ fontWeight:'bold' }}>{staffHours[0]?.staff?.name}</span></Typography>

                    <form onSubmit={handleSubmit}>
                        {values?.hours.map((item, index) => (
                            <Grid key={index} container
                                  sx={{alignItems: 'center', width: "100%", py: 2}}>

                                <Grid item xs={3}>
                                    <FormLabel sx={{ fontSize:{lg:'16px', xs:'14px !important'}, ...labelStyle }}>{item?.day}</FormLabel>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                name={`hours[${index}].is_off`}
                                                sx={{m: 1}}
                                                checked={!item?.is_off}
                                                onChange={(e)=>{
                                                    handleChange({
                                                        target: { name: `hours[${index}].is_off`, value: !e.target?.checked },
                                                    })
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={7.5}>
                                    <Grid container justifyContent={'space-around'}>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                 
                                 name={`hours[${index}].start_time`}
                                                options={createTimeSlots(item?.day)}
                                                getOptionLabel={(option) => option}
                                                value={item?.start_time}
                                                onChange={(event, value) => {
                                                    setFieldValue(`hours.${index}.start_time`, value || "")
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder={'Start Time'} sx={inputStyle}/>
                                                )}
                                                getOptionDisabled={(option) => option === item?.end_time}
                                            />
                                            {errors.hours?.[index]?.start_time && touched.hours?.[index]?.start_time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors.hours?.[index]?.start_time }</FormHelperText>}

                                        </Grid>
                                        <Grid item xs={4}>
                                            <Autocomplete
                                                name={`hours[${index}].end_time`}
                                                options={createTimeSlots(item?.day)}
                                                getOptionLabel={(option) => option}
                                                value={item?.end_time}
                                                onChange={(event, value) =>
                                                    setFieldValue(`hours.${index}.end_time`, value || "")
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder={'End Time'} sx={inputStyle}/>
                                                )}
                                                getOptionDisabled={(option) => option === item?.start_time}
                                            />
                                            {errors.hours?.[index]?.end_time && touched.hours?.[index]?.end_time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors.hours?.[index]?.end_time }</FormHelperText>}

                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth sx={{}}>
                                                <TextField
                                                    fullWidth
                                                    name={`hours[${index}].per_day_appointments`}
                                                    type={"number"}
                                                    InputProps={{
                                                        inputProps: {min: 0}
                                                    }}
                                                    sx={{...inputStyle}}
                                                    placeholder={"per day appointment"}
                                                    value={item?.per_day_appointments}
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12} sx={{display: 'flex', mt: 2, justifyContent: "flex-end"}}>
                            <CustomButton mainColor={"#ED1E45"} handleClick={() => {
                                resetForm()
                                navigate(`/${user?.role_id ===2 || user?.role_id ===4 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/staff`)
                            }} val={"Cancel"}/>
                            <Button
                                sx={{
                                    backgroundColor: '#09ABF1',
                                    borderColor: '#09ABF1',
                                    color: '#fff',
                                    ml: 3,
                                    height: '44px',
                                    "&:hover": {
                                        color: '#09ABF1',
                                        borderColor: '#09ABF1',
                                    }
                                }}
                                type={"submit"}
                                variant={'outlined'}
                            >
                                Save Staff Schedule
                            </Button>
                        </Grid>
                    </form>
                     <StaffHoliday setLoading={setLoading} staff_id={params?.staff_id} staffHolidays={staffHolidays}/>
                    <StaffBreakTime setLoading={setLoading} staff_id={params?.staff_id} staffBreakTimes={staffBreakTimes}/>
                </Box>
        // </CustomModal>
    );
};

export default StaffWorkingHours;