import { Box, Typography, Grid, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import TotalMemberNamount from "./totalMemberNamount";
import DateRange from "../date-range/date-range";
import DatePicker from "../date-range/DatePicker";
import DateRangePicker from "../date-range/DatePicker";
import moment from "moment";

const MemberReportWeekly = (props, 
     onClick) => {

           const startOfWeek = moment().startOf('isoWeek').toDate();
    const endOfWeek = moment().endOf('isoWeek').toDate();
    const [localStartDate, setLocalStartDate] = useState(startOfWeek);
    const [localEndDate, setLocalEndDate] = useState(endOfWeek);
      
    const handleDateChange = async (start, end) => {
        setLocalStartDate(start);
        setLocalEndDate(end);
        // Call the callback function to update parent component
        props.fetchData(start, end);
        // fetchData()
    };
    const fetchData = async () => {
        const response = await props.fetchData(localStartDate, localEndDate);
        // Process response or perform further actions
    };
    // Call fetchData on component mount
    // useEffect(() => {
    //     fetchData();
    // }, []);

    // const handleButtonClick = async () => {
    //     await fetchData();
    // };
        
    const {
        membershipTitle,
        memberSignup,
        memberSignupTotal,
        memberShipAmountTitle,
        memberShipAmountTotal,
        memberShipRenewel,
        memberShipRenewelTotal,
        totalRenewals,
        renewalsTotal,
        totalDicline,
        totalDiclineTotal,
        totalCancellations,
        totalCancellationsTotal,
        total69Member,
        total69MemberAmount,
        total99Member,
        total99MemberAmount,
        total149Member,
        total149MemberAmount,
   

    } = props;

    const color =

        membershipTitle === "Weekly Membership"
            ? "rgb(46, 125, 50)" // Green

            : "#000"; // Default color

    const iconStyle =
        membershipTitle === "Weekly Membership"
            ? "#2E7D32"

            : "#F44336"; // Default color

    const background =
        membershipTitle === "Weekly Membership"
            ? "#E8F5E9"

            : "#FFF7F8"; // Default color




    return (
        <Box sx={{ cursor: 'pointer' }}>
            <Box
                sx={{
                    padding: {lg:'40px', xs:'20px 10px'},
                    position: "relative",
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: background,
                    height:'auto',
                    '@media (min-width:1600px)': {padding:'20px 10px', height:'600px'}
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ fontFamily: "helvetica-lt-std-bold", color: color, mb:3 }}
                >
                    {membershipTitle}
                </Typography>
                <Box sx={{ display:"flex" , flexDirection:'column', justifyContent:"space-between", alignItems:{lg:'center', xs:'start'}, '@media (min-width:1600px)': {flexDirection:'row'} , height:"50px"  }}>
 <DateRangePicker
            widthMember="100%"
            smWidth="65% !important"
        setStartDate={setLocalStartDate}
        setEndDate={setLocalEndDate}    
        startDate={localStartDate}
        endDate={localEndDate}
        onChange={handleDateChange} // Add this prop to your DateRange component if it supports it
      />
           {/* <Button sx={{height:'fit-content','@media (min-width:1600px)': {marginTop:'0'}, mt:2}} variant="contained" onClick={handleButtonClick}>
          Fetch Report
        </Button> */}
                </Box>
        
<Box >

                <Grid container sx={{ mt: 3 }}>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Box>
                            <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                                {memberSignup}   
                            </Typography>   
                            <Typography variant="body1" sx={{ color , fontSize:"20px"   }}>
                                {memberSignupTotal}
                            </Typography>
                        </Box>
                    </Grid>   
                    <Grid item lg={6} sm={6} xs={6}>
                        <Box>
                            <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>   
                                {memberShipAmountTitle}
                            </Typography>
                            <Typography variant="body1" sx={{ color , fontSize:"20px" }}>
                              $  {memberShipAmountTotal}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 3 }}>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Box>
                            <Typography  sx={{ color ,fontSize:"22px",fontWeight:900}}>
                                {memberShipRenewel}
                            </Typography>
                            <Typography variant="body1" sx={{ color , fontSize:"20px", }}>
                                {memberShipRenewelTotal}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Box>
                            <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                                {totalRenewals}
                            </Typography>
                            <Typography variant="body1" sx={{ color , fontSize:"20px" }}>
                                ${renewalsTotal}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 3 }}>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Box>
                            <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                                {totalDicline}
                            </Typography>
                            <Typography variant="body1" sx={{ color , fontSize:"20px" }}>
                                {totalDiclineTotal}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6}>
                        <Box>
                            <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                                {totalCancellations}
                            </Typography>
                            <Typography variant="body1" sx={{ color , fontSize:"20px" }}>
                                {totalCancellationsTotal}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box>
                    <TotalMemberNamount
                        color={color}
                        total69MemberTitle={"Total $69 Plan Member"}
                        total69Member={total69Member}
                        total69MemberAmount={total69MemberAmount}
                        total99MemberTitle={"Total $199 Plan Member"}
                        total99Member={total99Member}
                        total99MemberAmount={total99MemberAmount}
                        total149MemberTitle={"Total $149 Plan Member"}
                        total149Member={total149Member}
                        total149MemberAmount={total149MemberAmount}
                    />
                </Box>
     
                </Box>
                <Box sx={{ position: "absolute", bottom: "-26px", right: "-24px" }}>
                    <svg
                        id="check-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        style={{ width: "96px", color: iconStyle }}
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                </Box>
            </Box>
            <Box />
        </Box>
    );
};

export default MemberReportWeekly;
