import React, { useState } from "react";
import { TextField, IconButton, Container, Typography, Box } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getZoeyAi } from "../../../store/crm/zoey-ai";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from 'react-markdown';
import Waveloading from "../../loading/Waveloading";

const ZoeyAi = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [submitted, setSubmitted] = useState(false); 
  const [responses, setResponses] = useState([]);
   const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const ZoeyAiStore = useSelector(state => state?.ZoeyAiStore);
  const handleQuestionChange = (e) => { 
    setQuestion(e.target.value); 
  };  
  console.log("zoey ai store data",ZoeyAiStore)

  const handleIconClick = async () => {
    if (!question.trim()) return;

    setSubmitted(true);
    // setLoading(true);
    setLoading(true)
    try {
      const payload = { question };
      // Assuming `getZoeyAi` is an action you dispatch to fetch the response
      const response = await dispatch(getZoeyAi(payload));  // Wait for the response
    console.log("response data",response)
      // Assuming the response contains a `question` and `answer`
      const newResponse = {
        question,
        answer: response?.payload?.answer || '',
      };

      // Add the new response to the array state
      setResponses(prevResponses => [...prevResponses, newResponse]);
      console.log("response come",responses)

      setQuestion("");  // Clear the question input
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <Container maxWidth="sm" sx={{  height: submitted ? '120vh' : '50vh', display: 'flex', flexDirection: 'column', }}>
      { loading ? <Waveloading /> : ""}
    {submitted && (
  responses.map((response, index) => (
   
    <Box key={index} sx={{ flex: 1, mb: 2, mt: 2  ,}}>
      <Typography variant="h5" sx={{ backgroundColor: "#9b9b9b47", padding: "10px 20px", marginBottom: "15px" }}>
        {response.question}
      </Typography>
      <ReactMarkdown>
        {response.answer}
      </ReactMarkdown>
    </Box>
  ))
)}

        {!submitted && (
           
      <Box   sx={{ mt: 'auto' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> 
            <Typography variant="h3" sx={{mb:3}}>What can I help with?</Typography> 
            <TextField 
              label="Ask a question"   
              variant="outlined" 
              fullWidth
              value={question}
              style={{backgroundColor: 'white',}}
              onChange={handleQuestionChange}  
              InputProps={{ 
                endAdornment: (
                  <IconButton
                    onClick={handleIconClick}
                    disabled={!question.trim()} 
                  > 
                    <ArrowForwardIcon />
                  </IconButton>
                ),  
              }}
              sx={{ mb: 2 }}
            /> 
          </Box> 
          </Box>
        
            )}  
 
 {submitted ? ( 
 <Box sx={{ position: 'sticky', bottom: 0,  zIndex: 1, mt: 'auto',mb:0 }}>
        {/* {submitted ? ( */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> 
            {/* <Typography variant="h3" sx={{mb:3}}>What can I help with?</Typography>  */} 
            <TextField
              label="Ask a question"  
              variant="outlined"
              fullWidth
              value={question}
              style={{backgroundColor: 'white',}} 
              onChange={handleQuestionChange}
              InputProps={{ 
                endAdornment: (
                  <IconButton
                    onClick={handleIconClick}
                    disabled={!question.trim()} 
                  >
                    <ArrowForwardIcon />
                    </IconButton>
                ), 
              }}
              sx={{ mb: 2 }}
            />
          </Box>
      
      </Box>
 ):""}
    </Container>
  );
};

export default ZoeyAi;
