import React, { useEffect, useState } from 'react'
import UserLayout from '../user';
import Map from "../../assets/images/treatment.webp";
import './style.css';
import Typography from '@mui/material/Typography'
import ThreeDLogo from "../../assets/logo/logo-3d-lifestyleR-White-01.png";
import Grid from '@mui/material/Grid'
import Waveloading from '../loading/Waveloading';
import { getgiftcardId } from '../../store/clinics/clinicSettings/gift-cards';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, Button } from '@mui/material';
import html2canvas from 'html2canvas';





const Gift = () => {
  const [loading, setLoading] = useState(false)
  const [appointmentDetails, setAppointmentDetails] = useState(null)
  const navigate = useNavigate() 
  const dispatch = useDispatch()
  const { giftcard_id ,} = useParams()
  const location = useLocation();
  const { state } = location;


  // Access planResult from state
  const { planResult } = state;


  // Now you can access planResult and other data
  console.log('planResult:', planResult);
  useEffect(() => {
    (async () => {
        if (giftcard_id && giftcard_id !== undefined) {
            await fetchAppointment()

        }  
    })()
}, [giftcard_id])

const fetchAppointment = async () => {
    setLoading(true)
    let { payload } = await dispatch(getgiftcardId(giftcard_id))    
    // if (payload?.id) {
console.log("data comming  from giftcards",payload)    
        setAppointmentDetails(payload)

    // }
    setLoading(false)
}
console.log("giftcard store", state)
let formattedCode = '';
const giftCardCode = planResult?.payload?.data?.gift_card?.code;
if (giftCardCode) {
  for (let i = 0; i < giftCardCode.length; i++) {
    if (i > 0 && i % 4 === 0) {
      formattedCode += '\u00A0\u00A0'; // Add non-breaking spaces between groups
    }
    formattedCode += giftCardCode[i];
  }
}
const handlePrint = () => {
  window.print();
};

const handleSaveCard = () => {
  const card = document.getElementById('gift-card');
  html2canvas(card, { useCORS: true, logging: true, allowTaint: true }).then((canvas) => {
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = 'gift_card.png';
    link.click();
  });
};




  return (
    <>
     <UserLayout>
      {loading ? (
        <Waveloading />  
      ) : (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">
              Gift Card Success
            </Typography>
            <Box>
              {/* <Button variant='contained' onClick={handleSaveCard} sx={{ marginRight: 1 }}>Save Card</Button> */}
              <Button variant='contained' onClick={handlePrint}>Print</Button>
            </Box>
          </Box>
          <div id="gift-card" className="card">
  <div className="card-inner">
    <div className="front">
      <img src={Map} className="map-img" alt='' crossorigin="anonymous" />
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item={5.9}>
          <img src={ThreeDLogo} alt='' className='logo-3d' crossorigin="anonymous" />
        </Grid>
        <Grid item={5.9}>
          <Typography variant="h5" className='color-white'  style={{ color: '#fff', '@media print': { color: '#fff !important' }  }}>Gift Card</Typography>
        </Grid>
      </Grid>
      <Grid container className='card-no'>
        <p className='color-white' style={{ color: '#fff', fontFamily:'helvetica-lt-std-bold', fontSize:'18px', '@media print': { color: '#fff !important' }  }}>{planResult?.payload?.data?.gift_card?.title}</p>
        <Grid item lg={12} id="gift-code">
          <p className='color-white' style={{ color: '#fff', '@media print': { color: '#fff !important' }  }}>{formattedCode}</p>
        </Grid>
      </Grid>
      <Grid container className='card-holder'>
        <Grid lg item={5.9}>
          <p className='color-white' style={{ color: '#fff', fontSize:'15px', fontWeight:'700', '@media print': { color: '#fff !important' }  }}>Customer Name</p>
        </Grid>
      </Grid>
      <Grid container className='name'>
        <Grid lg item={12}>
          <p className='color-white' style={{ color: '#fff', '@media print': { color: '#fff !important' }  }}>{planResult?.payload?.data?.user?.name}</p>
        </Grid>
      </Grid>
    </div>
  </div>
</div>

        </>
      )}
    </UserLayout>

    </>
  )
}

export default Gift;