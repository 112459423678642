import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid } from "@mui/material";
import CustomTable from "../../../components/table";
import CustomButton from "../../../components/custom-button/customButton";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles } from "../../../store/roles";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";

const Roles = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rolesStore = useSelector((state) => state?.rolesStore);

  useEffect(() => {
    (async () => {
      if (!rolesStore?.roles?.length) {
        setLoading(true);
      }
      await dispatch(getAllRoles());
      setLoading(false);
    })();
  }, [dispatch, rolesStore?.roles?.length]);

  const roleHead = [
    { value: "Role", key: "name" },
    { value: "Status", key: "status" },
    { value: "Action", key: "action" },
  ];

  const handleEdit = (row, isEdit) => {
    if (isEdit) {
      navigate(`/admin/configuration/edit-role/${row?.id}`);
    }
  };

  const handleClick = () => {
    navigate("/admin/configuration/add-role");
  };
  return (
    <ConfigurationLayout>
      <Grid container p={"20px"}>
        <Grid
          item
          xs={12}
          sx={{
            mb: "16px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            flexWrap:'wrap',
            '@media (max-width: 400px)' :{
             display:'flex',
             justifyContent:'flex-start'
            },
          }}
        >
          <FormControl sx={{ mr: "10px" }}>
            <SimpleInput search={search} setSearch={setSearch} />
          </FormControl>
          <CustomButton
          mrt={"10px"}
            val={"Add Role"}
            size={"large"}
            handleClick={handleClick}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: "calc(100vh - 225px)" }}>
          {loading ? (
             <Box
             sx={{ 
                 display: "flex", 
                 justifyContent: "center", 
                 alignItems: "center", 
                 height: "60vh" 
             }}
         >
             <Loader />
         </Box>
          ) : (
            <CustomTable
              headRow={roleHead}
              tableData={rolesStore?.roles}
              search={search}
              isStatus={true}
              handleEdit={handleEdit}
              type={"role"}
            />
          )}
        </Grid>
      </Grid>
    </ConfigurationLayout>
  );
};

export default Roles;
