import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'
import Drawer from '@mui/material/Drawer'
import MuiAvatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import CloseIcon from '@mui/icons-material/Close';
import {Autocomplete, Avatar, Button, Modal, TextareaAutosize} from "@mui/material";
// import { getAllCRMCustomer } from '../../store/crm/crm';
import { useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { getAllClinics, getAllMessages, getUserDetails, ReadMessageuser, selectChat, SendMsgss } from '../../../store/user/Chat-modue';
import Waveloading from '../../loading/Waveloading';
import { getAllActiveClinicsLocations } from '../../../store/user/default-location';
import toast from 'react-hot-toast';
// import { selectChat } from '../../store/crm/chat';
// import { GetMessages, ReadMessages } from '../../store/crm/chat-module';


const ScrollWrapper = ({ children, hidden }) => {
    if (hidden) {
        return <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>;
    } else {
        return <PerfectScrollbar options={{ wheelPropagation: false }}>{children}</PerfectScrollbar>;
    }
};

const SidebarLeft = (props) => {
    const {
        store,
        hidden,
        mdAbove,
        dispatch,
        statusObj,
        userStatus,
        // selectChat,
        getInitials,
        sidebarWidth,
        setUserStatus,
        leftSidebarOpen,
        // removeSelectedChat,
        formatDateToMonthShort,
        handleUserProfileLeftSidebarToggle,
        handleLeftSidebarToggle,
    } = props;

    const [active, setActive] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const crmStore = useSelector((state) => state?.crmStore);
     const [query, setQuery] = useState('')


const [loading, setLoading] = useState(false); 
     const [message, setMessage] = useState('');
     const [selectedUser, setSelectedUser] = useState('');
     const handleOpenModal = () => setModalOpen(true);
     const handleCloseModal = () => setModalOpen(false);
       const [filteredChat, setFilteredChat] = useState([])
        const [errors, setErrors] = useState({});
           const [selectedCustomer, setSelectedCustomer] = useState(null);
           const handleCustomerChange = (event, newValue) => {
               setSelectedCustomer(newValue)
             }
             const [errorMessage, setErrorMessage] = useState('');

            //  const handleClinicSelect = (event, selectedClinic) => {
            //      if (selectedClinic) {
            //          // Check if the selected clinic already exists in store.clinicscaht?.data
            //          const clinicExists = store.clinicscaht?.data.some((clinic) => clinic.provider_id=== selectedClinic.id)
            //          console.log("id comes",clinicExists,selectedClinic.id)
            //          if (clinicExists) {
            //              setErrorMessage('Select another customer, already exist');
            //          } else {
            //              setErrorMessage('');
            //          }
            //          // Call your handleCustomerChange with the selected clinic
            //          handleCustomerChange(event, selectedClinic);
            //      }
            //  };

            const handleClinicSelect = (event, selectedClinic) => {
                if (selectedClinic) {
                    // Check if the selected clinic already exists in store.clinicscaht?.data
                    const clinicExists = store.clinicscaht?.data.some((clinic) => clinic.provider_id === selectedClinic.id);
                    console.log("id comes", clinicExists, selectedClinic.id);
                    if (clinicExists) {
                        setErrorMessage('Clinic already added');
                    } else {
                        setErrorMessage('');
                    }
                    // Call your handleCustomerChange with the selected clinic
                    handleCustomerChange(event, selectedClinic);
                } else {
                    // If selectedClinic is null (user removed the selection), clear the error message
                    setErrorMessage('');
                }
            };
            
         const validateFields = () => {
               const newErrors = {};
               if (!selectedCustomer) {
                 newErrors.selectedCustomer = 'Customer is required';
               }
               if (!message) {
                   newErrors.message = 'messsage is required';
                 }
           
               setErrors(newErrors);
               return Object.keys(newErrors).length === 0;
             }; 
     const handleSendMessage = async () => {
         setLoading(true);
     console.log("data coming",selectedCustomer)
         const newMessage = {
             user_id: selectedCustomer?.id
             ,
             name: store.clinicscaht?.data.find(contact => contact.id === selectedUser)?.name || 'Unknown User',
             lastMessage: {
                 message: message,
                 time: new Date().toISOString()  
             },
             unread_count: 0  
         };
     
         setFilteredChat(prevChats => [newMessage, ...prevChats]);
     
      const payload= await dispatch(SendMsgss({ provider_id: selectedCustomer?.id, message }));
     console.log("data",payload)
            dispatch(getAllClinics())
        dispatch(getUserDetails());
    
         setMessage('');
         setSelectedUser('');
         setLoading(false);
         handleCloseModal(); 
     };
    // const handleChatClick = (id) => {
    //     console.log("Selected chat ID:", id);
    //     dispatch(getUserDetails());
    //     setActive(id);

    //     if (!mdAbove) {
    //         handleLeftSidebarToggle();
    //     }
    // };
    const userDefaultLocationStore = useSelector(state => state?.userDefaultLocationStore?.activeClinics)

 useEffect(() => {
        (async () => {
            // if (!userDefaultLocationStore?.length) {
                // setLoading(true)
                await dispatch(getAllActiveClinicsLocations())
                // setLoading(false)
            // }
        })()

    }, [dispatch])
        const handleChatClick = (type, id) => {
            console.log("here is id",id, type)
            dispatch(selectChat(id))
         dispatch(getAllMessages(id));
         dispatch(ReadMessageuser(id))
         dispatch(getAllClinics())
     
            setActive({ type, id })
           
                const chatContainer = document.getElementById('chatArea'); // Ensure you have an ID or ref for the chat area
                if (chatContainer) {
                    chatContainer.scrollTop = chatContainer.scrollHeight; // Scroll to bottom
                }
            
            if (!mdAbove) {  
                handleLeftSidebarToggle()
            }
            // Store id in localStorage with expiration (5 minutes)
            const expirationTime = new Date().getTime() + 5 * 60 * 1000; // Current time + 5 minutes in milliseconds
            localStorage.setItem('chatId', JSON.stringify({ id, expiration: expirationTime }));
        }

    useEffect(() => {
        return () => {
            setActive(null);
            // dispatch(removeSelectedChat());
        };
    }, [dispatch]);
    const handleFilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setQuery(searchValue);
    
        if (store.title !== null) {
            if (searchValue.trim() === '') {
                // If search input is empty, reset to the original chat list
                setFilteredChat(store.data);
            } else {
                // Filter the chat list by name
                const filteredChatsArr = store.clinicscaht?.data.filter(chat =>
                    chat.name && chat.name.toLowerCase().includes(searchValue)
                );
                // Update the filtered chat state
                setFilteredChat(filteredChatsArr);
            }
        }
    };
    const handleSaveClick = () => {
        if (validateFields()) {
            handleSendMessage()
        } else {
          toast.error("Please fill all required fields.");
        }
      }
    console.log("data coming",store)
    const renderChat = () => { 
       
        if (store && store.clinicscaht?.data) {
            const arrToMap = query.length > 0 ? filteredChat : store.clinicscaht?.data;
    
            if (arrToMap.length === 0) {
                // Display message if no chats are found
                return (
                    <ListItem>
                        <Typography sx={{ color: 'text.secondary' }}>No Chats Found</Typography>
                    </ListItem>
                );
            }
     
            return arrToMap.map((chat, index) => {
                if (!chat || !chat.provider_id) return null;
    
                const activeCondition = active !== null && active.id === chat.provider_id && active.type === 'chat';
    
                return (
                    <ListItem key={index} disablePadding sx={{ '&:not(:last-child)': { mb: 1.5 } }}>
                        <ListItemButton
                            disableRipple
                            onClick={() => handleChatClick('chat', chat.provider_id)}
                            sx={{
                                px: 2.5,
                                py: 1,
                                width: '100%',
                                borderRadius: 1,
                                alignItems: 'flex-start',
                                backgroundColor: (theme) =>
                                    activeCondition ? `${theme.palette.primary.main} !important` : '',
                            }}
                        > 
                            <ListItemAvatar sx={{ m: 0 }}>   
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={<></>}
                                >
                                    {chat.profile_image ? (
                                        <MuiAvatar
                                            src={chat.profile_image}
                                            alt={chat.name}
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                border: (theme) =>
                                                    activeCondition ? `2px so0lid ${theme.palette.common.white}` : '',
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            color={chat.avatarColor}
                                            skin={activeCondition ? 'light-static' : 'light'}
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                fontSize: '1rem',
                                                border: (theme) =>
                                                    activeCondition ? `2px solid ${theme.palette.common.white}` : '',
                                            }}
                                        >
                                            {getInitials(chat.name)}
                                        </Avatar>
                                    )}
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{
                                    my: 0,
                                    ml: 4,
                                    mr: 1.5,
                                    '& .MuiTypography-root': activeCondition ? { color: 'common.white' } : {},
                                }}
                                primary={
                                    <Typography noWrap sx={{ color: !activeCondition ? 'text.secondary' : 'common.white' }}>
                                        {chat.name}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{ color: !activeCondition ? (theme) => theme.palette.text.disabled : {} }}
                                    >
                                        {chat.lastMessage ? chat.lastMessage.message : 'No messages yet'}
                                    </Typography>
                                }
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        color: activeCondition ? 'common.white' : 'text.disabled',
                                    }}
                                >
                                    {chat.lastMessage
                                        ? formatDateToMonthShort(chat.lastMessage.time, true)
                                        : new Date().toLocaleDateString()}
                                </Typography>
                                {chat.unread_count && chat.unread_count > 0 ? (
                                    <Chip
                                        color="error"
                                        label={chat.unread_count}
                                        sx={{
                                            mt: 0.5,
                                            height: 18,
                                            fontWeight: 600,
                                            fontSize: '0.75rem',
                                            '& .MuiChip-label': { pt: 0.25, px: 1.655 },
                                        }}
                                    />
                                ) : null}
                            </Box>
                        </ListItemButton>
                    </ListItem>
                );
            });
        } else {
            return (
                <ListItem>
                    <Typography sx={{ color: 'text.secondary' }}>No Chats Found</Typography>
                </ListItem>
            );
        }
    };
    const isFormInvalid = errorMessage || errors.selectedCustomer;
    return (
        <Box>
            <Drawer
                open={leftSidebarOpen}
                onClose={handleLeftSidebarToggle}
                variant={mdAbove ? 'permanent' : 'temporary'}
                ModalProps={{
                    disablePortal: true,
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    zIndex: 7,
                    height: '100%',
                    display: 'block',
                    position: mdAbove ? 'static' : 'absolute',
                    '& .MuiDrawer-paper': {
                        boxShadow: 'none',
                        overflow: 'hidden',
                        width: sidebarWidth,
                        position: mdAbove ? 'static' : 'absolute',
                        borderTopLeftRadius: theme => theme.shape.borderRadius, 
                        borderBottomLeftRadius: theme => theme.shape.borderRadius
                    },
                    '& > .MuiBackdrop-root': {
                        borderRadius: 1,
                        position: 'absolute',
                        zIndex: theme => theme.zIndex.drawer - 1
                    }
                }}
            >
               <Box  sx={{display:{xs:"none",md:"none",lg:"block",xl:"block"}}} >

                
<Box
sx={{
px: "10px",
py: "10px",
display: 'flex',
// flexDirection:{lg:"row",md:'column',xs:'column'},
alignItems: 'center',
borderBottom: theme => `1px solid ${theme.palette.divider}`,
justifyContent: 'space-between' // Equal space distribution
}}
>
{store && store.userProfile ? (
<Badge
  overlap='circular'  
  anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
  }}   
  sx={{ mr: 2 }}
  onClick={handleUserProfileLeftSidebarToggle}
  badgeContent={
      <Box
          component='span' 
          sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              color: `${statusObj[userStatus]}.main`,
              backgroundColor: `${statusObj[userStatus]}.main`,
              boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`
          }}
      />
  }
>
  <MuiAvatar
      src={store.userProfile.profile_image}
      alt={store.userProfile.name}
      sx={{ width: 40, height: 40, cursor: 'pointer' }}
  />
</Badge>
) : null}
<TextField
fullWidth
size="small"
value={query}
onChange={handleFilter}
placeholder="Search for contact..."
sx={{
'& .MuiInputBase-root': { borderRadius: 5 },
width: '70%',
mx: 2,
}}
InputProps={{
startAdornment: (
<InputAdornment position="start">
<SearchIcon fontSize="small" />
</InputAdornment>
),
}}
/>




<IconButton 
sx={{ marginLeft: '10px' }}
onClick={handleOpenModal}
>
<PersonAddIcon fontSize="large"  style={{color:"#19A2FF"}} />
</IconButton>

{!mdAbove ? (
<IconButton sx={{ p: 1, ml: 1 }} onClick={handleLeftSidebarToggle}>
  <CloseIcon sx={{ fontSize: '1.375rem' }} />
</IconButton>  
) : null}
</Box>
</Box>


<Box
sx={{
px: "10px",
py: "10px",
display:{xs:'block' ,lg:'none'},
// display: 'flex',
// flexDirection:{lg:"row",md:'column',xs:'column'},
alignItems: 'center',
borderBottom: theme => `1px solid ${theme.palette.divider}`,
// justifyContent: 'space-between' // Equal space distribution
}}
>
<Box sx={{   display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>


{store && store.userProfile ? (
<Badge
  overlap='circular'  
  anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
  }}   
  sx={{ mr: 2 }}
  onClick={handleUserProfileLeftSidebarToggle}
  badgeContent={
      <Box
          component='span' 
          sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              color: `${statusObj[userStatus]}.main`,
              backgroundColor: `${statusObj[userStatus]}.main`,
              boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`
          }}
      />
  }
>
  <MuiAvatar
      src={store.userProfile.profile_image}
      alt={store.userProfile.name}
      sx={{ width: 40, height: 40, cursor: 'pointer' }}
  />
</Badge>
) : null}
<Box>


<IconButton 
// sx={{ marginLeft: '10px' }}
onClick={handleOpenModal}
>
<PersonAddIcon fontSize="large"  style={{color:"#19A2FF"}} />
</IconButton>

{!mdAbove ? (
<IconButton sx={{ p: 1, ml: 1 }} onClick={handleLeftSidebarToggle}>
  <CloseIcon sx={{ fontSize: '1.375rem' }} />
</IconButton>  
) : null}
</Box>

</Box>


<Box sx={{  display: 'flex', justifyContent: 'space-between',}}>
<TextField
fullWidth
size="small"
value={query}
onChange={handleFilter}
placeholder="Search for contact..."
sx={{
'& .MuiInputBase-root': { borderRadius: 5 },
width: '100%',
mx: 2,
}}
InputProps={{
endAdornment: (
<InputAdornment position="start">
<SearchIcon fontSize="small" />
</InputAdornment>
),
}}
/>


</Box>
</Box>

                <Box sx={{ height: `calc(100% - 4.0625rem)` }}>
                    <ScrollWrapper hidden={hidden}> 
                        <Box sx={{ p:3}}>
                           
                            <List sx={{ mb: 3, p: 0 }} id="chatListContainer">{renderChat()}</List>
                           
                        </Box>
                    </ScrollWrapper>    
                   
                </Box>
            </Drawer>
            {loading ? <Waveloading fullSize /> : ""}
               <Modal
                open={modalOpen}
                onClose={handleCloseModal} 
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ 
                    position: 'absolute', top: '50%', left: '50%', 
                    transform: 'translate(-50%, -50%)', width: 400, 
                    bgcolor: 'background.paper', p: 4, boxShadow: 24, 
                    borderRadius: 2 
                }}>
                   
                    <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-between'}}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Send Message        
                    </Typography>  
                    
            <ClearIcon sx={{cursor:"pointer"}} onClick={handleCloseModal} />
                    </Box>       
                    {/* <Autocomplete
    fullWidth
    value={selectedUser || null} 
    onChange={(e, newValue) => setSelectedUser(newValue)}  
    options={store?.contacts || []}
    clearIcon={null} 
    getOptionLabel={(option) => option.name}  
    getOptionSelected={(option, value) => option.id === value}  
    renderInput={(params) => (
        <TextField
            {...params}
            label="Select a contact"
            variant="outlined"
            sx={{ my: 2 }}
        />
    )}
/> */}
   <Autocomplete
            fullWidth
            options={userDefaultLocationStore || []}
            getOptionLabel={(user) => user.name}
            onChange={handleClinicSelect}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Clinics"
                    margin="normal"
                    variant="outlined"
                    error={!!errorMessage}  // Use errorMessage as the error condition
                    helperText={errorMessage || errors.selectedCustomer}  // Show the dynamic error message or default error
                />
            )}
            renderOption={(props, user) => (
                <li {...props} key={user.id}>
                    {user.name}
                </li>
            )}
        />      

                    <TextareaAutosize  
                        minRows={4}
                        placeholder="Type your message here..."
                        style={{ width: '100%', padding: 10, borderRadius: 5, borderColor: '#ccc' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                            //  error={!!errors.message}
                            //   helperText={errors.message}  
                    />
                      {errors.message && (
                <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: 4, fontSize: '0.875rem' }}
                >
                    {errors.message}
                </Typography>
            )}
                     {!isFormInvalid && (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={handleSaveClick}
                    >  
                        Send Message
                    </Button>
                     )}
                </Box>
            </Modal>

        </Box>
    );
};

export default SidebarLeft;