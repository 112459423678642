import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";
import {getUserDetails} from "../../auth";


export const getAllTreatmentForms = createAsyncThunk("getAllTreatmentForms", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_treatment_form+data?.id+"/"+data?.provider_id+"/"+data?.type, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {

            return [];
        }
    } catch (err) {
        return  []
    }
});

export const getAllSubmittedTreatmentForms = createAsyncThunk("getAllSubmittedTreatmentForms", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.submitted_treatment_form+data?.id+"/"+data?.provider_id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {

            return [];
        }
    } catch (err) {
        return  []
    }
});


export const getUserChartFormSlice = createAsyncThunk("getUserChartFormSlice", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_form_detail+data?.provider+'/'+data?.user+'/'+data?.slug+'/'+data?.submit+'/'+data?.submittedId, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {
            return []
        }
    }
);

export const getSubscriptionCancle = createAsyncThunk("getSubscriptionCancle", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.Subscription_Cancle+id,{
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {

            return [];
        }
    } catch (err) {
        return []
    }
}
);
// Subscription_Cancle
export const storeChartFormData = createAsyncThunk("storeChartFormData", async (data, { dispatch }) => {
        try {
            const response = await axios.post(apis.store_form_detail, {...data},{
                headers: apiHeaders(),
            });


                return response?.data;

        } catch (err) {
            return err?.response?.data
        }
    }
);
export const updateChartFormData = createAsyncThunk("updateChartFormData", async (data, { dispatch }) => {
    try {   
        const response = await axios.post(apis.update_form, {...data},{
            headers: apiHeaders(),
        });

            return response?.data;   

    } catch (err) {
        return err?.response?.data 
    }
}
);
// form_link
export const FormLinkData= createAsyncThunk("FormLinkData", async (data, { dispatch }) => {
    try {
        const response = await axios.post(apis.form_link, {...data},{
            headers: apiHeaders(),
        });


            return response?.data;

    } catch (err) {
        return err?.response?.data
    }
}
);


export const getNearestLocations = createAsyncThunk("getNearestLocations", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_nearest_location+`${data?.postal_code}`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {
                return {};
            }
        } catch (err) {
            return err?.response?.data
        }
    }
);
export const updateUserLocation = createAsyncThunk('updateUserLocation', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.user_location, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success === 'true'){
            await dispatch(getUserDetails())
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const userChartFormSlice = createSlice({
    name: 'userChartFormSlice',
    initialState: {
        activeClinics: {},
        updated:{},

    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getUserChartFormSlice.fulfilled, (state, action) => {
            state.activeClinics = action.payload?.data || {}
        },
      
        )
        builder.addCase(updateChartFormData.fulfilled, (state, action) => {
            state.updated = action.payload?.data || {}
        })
    }
})


export default userChartFormSlice.reducer