import React, {useEffect, useState} from 'react';
import {Form, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {Button, Checkbox, FormControl, FormLabel, Grid, IconButton, Input, InputAdornment, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import Loader from "../../../../components/loader/loader";
import {getAllClinicsServices, getClinicsServices, updateserviceFilter} from "../../../../store/clinics/clinicSettings/clinics-services";
import SearchIcon from '@mui/icons-material/Search';
import CardTable from "../../../../components/card-table/service-card";
import FormAutoComplete from '../../../../components/form/FormAutoComplete';
import { Formik } from 'formik';
import { getAllClinicsTreatmentGroup } from '../../../../store/clinics/clinicSettings/clinic-treatment-group';
import Waveloading from '../../../loading/Waveloading';
import BackendSearchField from '../../../../components/pagination/backendSearch';
import BackendPagination from '../../../../components/pagination/backendPagination';


const Services = () => {
  const [loading, setLoading] = useState(false);
  const [selectedBookingTypes, setSelectedBookingTypes] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clinicServicesStore = useSelector(state => state?.clinicServicesStore);
  const { search, services, pageLimit, currentPage } = clinicServicesStore;

  const clinicTreatmentGroupStore = useSelector(state => state?.clinicTreatmentGroupStore);
  const user = useSelector(state => state?.authStore?.user);

  const handleBookingTypeChange = (event) => {
      setSelectedBookingTypes(event.target.value);
  };

  const handleButtonClick = async () => {
      setLoading(true);
      if (selectedBookingTypes.length > 0) {
          console.log("API call with selectedBookingTypes:", selectedBookingTypes);
          await dispatch(getClinicsServices({ provider_id: params?.clinic_id, category_id: selectedBookingTypes }));
      } else {
          console.log("Please select at least one option before making a call");
      }
      setLoading(false);
  };

  useEffect(() => {
      (async () => {
          if (!clinicTreatmentGroupStore?.treatmentGroups?.length) {
              setLoading(true);
              await dispatch(getAllClinicsTreatmentGroup(params?.clinic_id));
              setLoading(false);
          }
      })();
  }, [dispatch, clinicTreatmentGroupStore?.treatmentGroups?.length, params?.clinic_id]);

  useEffect(() => {
      (async () => {
          setLoading(true);
          console.log("Calling with clinic ID:", params?.clinic_id);
          await dispatch(getClinicsServices({ provider_id: params?.clinic_id }));
          setLoading(false);
      })();
  }, [dispatch, params?.clinic_id]);

  const refreshData = async () => {
      setLoading(true);
      try {
          if (selectedBookingTypes.length > 0) {
              console.log("API call with selectedBookingTypes:", selectedBookingTypes);
              await dispatch(getClinicsServices({ provider_id: params?.clinic_id, category_id: selectedBookingTypes }));
          } else {
              await dispatch(getClinicsServices({ provider_id: params?.clinic_id }));
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      setLoading(false);
  };

  const handleEdit = (row) => {
      navigate(`/${user?.role_id === 2 ? "crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-service/${row?.id}`);
  };

  const handleClick = () => {
      if (params?.clinic_id) {
          navigate(`/${user?.role_id === 2 ? "crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-service`);
      }
  };

  const handleSearch = async (val) => {
      await dispatch(updateserviceFilter({ search: val }));
      await refreshData();
  };

  const fetchPageData = async (page) => {
      setLoading(true);
      await dispatch(updateserviceFilter({ currentPage: page }));
      await refreshData();
      setLoading(false);
  };

  return (
      <Grid container p={'20px'}>
          <Grid item xs={12} sx={{ mb: '16px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
              <FormControl sx={{ minWidth: { xs: "100%", sm: "220px" }, mr: { xs: 2, sm: 1 }, mb: { xs: 2, sm: 0 } }}>
                  <Select
                      multiple
                      value={selectedBookingTypes}
                      onChange={handleBookingTypeChange}
                      renderValue={(selected) => {
                          if (selected.length === 0) {
                              return <em>Select treatment/Groups</em>;
                          } else if (selected.length <= 3) {
                              return selected.map((id) => {
                                  const type = clinicTreatmentGroupStore?.treatmentGroups?.find((type) => type.id === id) || { title: '' };
                                  return type.title;
                              }).join(', ');
                          } else {
                              const selectedTitles = selected.slice(0, 3).map((id) => {
                                  const type = clinicTreatmentGroupStore?.treatmentGroups?.find((type) => type.id === id) || { title: '' };
                                  return type.title;
                              }).join(', ');
                              return `${selectedTitles}, ...`;
                          }
                      }}
                      displayEmpty
                      input={<Input sx={{ mr: { xs: 2, sm: 1 } }} endAdornment={
                          <InputAdornment position="end">
                              <IconButton onClick={handleButtonClick} sx={{ borderRadius: "6px", color: 'white', backgroundColor: '#09ABF1', '&:hover': { backgroundColor: 'darkblue' } }}>
                                  <SearchIcon />
                              </IconButton>
                          </InputAdornment>
                      } />}
                      sx={{ '& .MuiSelect-select': { padding: '10px 14px' } }}
                  >
                      {Array.isArray(clinicTreatmentGroupStore?.treatmentGroups) ? clinicTreatmentGroupStore.treatmentGroups.map((type, index) => (
                          <MenuItem key={index} value={type.id}>
                              <Checkbox checked={selectedBookingTypes.indexOf(type.id) > -1} />
                              <ListItemText primary={type.title} />
                          </MenuItem>
                      )) : <MenuItem disabled>Loading...</MenuItem>}
                  </Select>
              </FormControl>

              <FormControl sx={{ mr: "10px", mb: { xs: 2, sm: 0 } }}>
                  <BackendSearchField
                      search={search}
                      handleSearch={handleSearch}
                      fetchData={async () => {
                          await dispatch(updateserviceFilter({ currentPage: 1 }));
                          await refreshData();
                      }}
                  />
              </FormControl>
              <Grid sx={{ '@media (min-width:480px) and (max-width: 640px) ': { marginBottom: '15px', } }}>
                  <CustomButton val={'Add Services'} size={'large'} handleClick={handleClick} />
              </Grid>
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={12} >
                  {loading ? <Waveloading /> :
                      <CardTable
                          columnServices={"column"}
                          autoServices={"500px"}
                          search={""}
                          handleEdit={handleEdit}
                          type={'service'}
                          cardData={services}
                          onDataUpdate={refreshData}
                      />
                  }

                  <Grid item xs={12} style={{ marginTop: "10px" }} >
                      {services?.meta?.last_page > 1 &&
                          <BackendPagination
                              count={services?.meta?.last_page}
                              currentPage={currentPage}
                              handleOnChange={fetchPageData}
                          />
                      }
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
  );
};

export default Services;