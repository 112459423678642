import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, useLocation,useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";
import Header from "../components/header/header";
import {scrollCSS} from "../App";
import UserSidebar from "../components/siderbar/userSidebar";
import Loader from "../components/loader/loader";


const UserRoutes = () => {
    const [sidebarOpen,setSidebarOpen] = useState(true)
    const [mobileOpen, setMobileOpen] = useState(false);

    const authStore = useSelector(state => state.authStore)
    const {user,logged,loading} = authStore
    const location =useLocation();
    const { pathname, search } = useLocation();
    // const history = useHistory();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [savedRoute, setSavedRoute] = useState(null);

    // useEffect(() => {
    //   const loginTimeout = setTimeout(() => {
    //     setIsLoggedIn(true);
    //   }, 2000);
    //   if (!logged) { 
    //     setSavedRoute(pathname + search);
    //   }
  
    //   return () => clearTimeout(loginTimeout);
    // }, []);
    // if (isLoggedIn) {
    //     console.log("path",savedRoute)
    //     if (savedRoute) {
    //       return <Navigate to={savedRoute} />;
    //     }
    // }
    

    if (loading){
        return  <Loader fullSize/>
    }

    if (logged && user?.role_id === 3) {
        if (location?.pathname === '/') {
            console.log("user route");
            return <Navigate to={"/user/user-account"}/>
        }
        return (
            location?.pathname.includes("/user/appointment/book/") ?
                <Outlet/>
                :
                <Box sx={{display: 'flex'}}>
                    <UserSidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} sidebarOpen={sidebarOpen}/>
                    <Box component="main" sx={{
                        flexGrow: 1, overflowX: 'scroll', ...scrollCSS, "&::-webkit-scrollbar ": {
                            width: "5px",
                            display: 'none'
                        },
                    }}>
                        <Header mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} admin type={'user'}
                                setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/>
                        <Box sx={{
                            backgroundColor: '#EDF2F7',
                            height: "calc(100vh - 69px)",
                            padding: {sm:'30px', xs:'7px'},
                            overflowY: 'auto',
                            ...scrollCSS
                        }}>
                            <Outlet/>
                        </Box>
                    </Box>
                </Box>
        )
    }
    if (logged && user?.role_id === 1){
        return <Navigate to={"/admin/configuration/roles"} />
    }
    if (logged && user?.role_id === 2){
        return <Navigate to={"/crm/dashboard"} />
    }
    if (logged && user?.role_id === 4){
        return <Navigate to={"/crm/calender"} />
    }


    return <Navigate to={"/login"} />
};

export default UserRoutes;