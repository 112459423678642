import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {assignCrmStaff, getProviderStaff, removeCrmStaff} from "../../../../store/crm/staff";
import {changeAppointmentStatus, getAllAppointment, getAppointmentDetails, getAppointmentHistory, getAppointmentcalender} from "../../../../store/crm/appointment";
import {MySwal, successConfirmation} from "../../../../services/utills";
import Loader from "../../../../components/loader/loader";
import CustomButtoncolor from "../../../../components/custom-button/customColorButton";
import CustomModal from "../../../../components/modal/customModal";
import Box from "@mui/material/Box";
import {Avatar, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {ReactComponent as Close} from "../../../../assets/icon/close-outline.svg";
import {useNavigate} from "react-router";
import Waveloading from '../../../loading/Waveloading';
import {apiHeaders} from "../../../../lib/apiHeader"
import { getCRMCustomerBookingsDetails } from '../../../../store/crm/crm';
const AppointmentStaffStatus = ({appointment,label,isFetch,isAppointment,value,isNoShow,mainColor,fetchAppointment,fetchCalenderBookings, fwidth, onRescheduleSuccess}) => {

    const [loading, setLoading] = useState(false)
    const [staffModal, setStaffModal] = useState(false)
    const [selectedStaff, setSelectedStaff] = useState(null) 


    const isStaff = value === 'CheckIn' && !appointment?.staff?.id
    const dispatch = useDispatch()
    const navigate = useNavigate()  
       
    const crmStaffStore = useSelector(state => state?.crmStaffStore)
    const {crmStaffs = []} = crmStaffStore

    /********************* Staff **************************/
 const scrollRef = useRef(null); 
    useEffect(() => {  

        (async () => {
            if (!crmStaffs?.length && staffModal && (value === 'CheckIn' || !value)) {
                await dispatch(getProviderStaff())
                setLoading(false)
            }
        })()
    }, [staffModal])       



    const handleOpen = () => {
        if (!crmStaffs?.length) {
            setLoading(true)
        }
        if (appointment?.id && !value){
            if (appointment?.staff?.id){
                setSelectedStaff(appointment?.staff)
            }else {
                setSelectedStaff(null)
            }
        }
        setStaffModal(true)
    }
    const handleClose = () => {
        setSelectedStaff(null)
        setStaffModal(false)
    }
    console.log("data customer id",appointment)
    const handleAssign = async () => {

        if (appointment?.id) {
            setLoading(true)
            let finalData = {
                appointment_id: appointment?.id,
                staff_id: selectedStaff ? selectedStaff?.id : null
                
            }

            if (isStaff){
                finalData['status'] = 'CheckIn'
            }
            let res = await dispatch(assignCrmStaff(finalData))
            console.log("appoint id",appointment)
            if (res?.payload?.success === 'true') {
                if(isAppointment){
                    await dispatch (getAppointmentHistory({
                        user_id:appointment?.customer?.id,type:'Appointments'
                    }))
                    await dispatch(getCRMCustomerBookingsDetails({id:appointment?.customer?.id,type:'Appointments'}))
                }
         
               
               
                if (isFetch) {
                    await dispatch(getAppointmentDetails(appointment?.id))
                    await  dispatch(getAllAppointment({start_date:"null",end_date:"null"}))
                }      
                if (fetchCalenderBookings){   
                    await fetchCalenderBookings()
                    await dispatch(getAppointmentcalender(appointment?.id))
                }

              
                await successConfirmation(
                    isStaff ?
                        'Staff Assigned And Check-In Successfully.'
                        : "Staff Assigned Successfully."
                )
                handleClose()
            }

            setLoading(false)
        }
    }

    const handelremoveStaff = async () => {   
        if (appointment?.id) {
            setLoading(true);
    
            let finalData = {
                appointment_id: appointment?.id,
                staff_id: selectedStaff ? selectedStaff?.id : null
            };
            
            if (isStaff){
                finalData['status'] = 'CheckIn'
            }
    
            // Define the API endpoint URL
            const API_ENDPOINT = 'https://api.3dlifestyle.ca/api/crm/appointments/remove-staff';
    
            try {
                // Call the removeCrmStaff API
                const res = await fetch(API_ENDPOINT, {  
                    method: 'POST',
                    headers: {
                        // Include other headers if needed
                        'Content-Type': 'application/json',
                        ...apiHeaders(),
                    },
                    body: JSON.stringify(finalData),
                });    
    
                const result = await res.json(); // Assuming the response is JSON
    console.log("succes",result,result?.payload?.success)
                if (result?.success === 'true') {
                    await dispatch(getAppointmentcalender(appointment?.id))
                    // Reload data if necessary
                    if (isFetch) {
                        await dispatch(getAppointmentDetails(appointment?.id));
                    } else {
                        
                        dispatch(getAppointmentDetails(appointment?.id));
                    }
                    if (fetchCalenderBookings) {
                        await fetchCalenderBookings();    
                    }




                    
    
                    await dispatch(getAllAppointment({start_date:"null",end_date:"null"}))
                    await dispatch (getAppointmentHistory({
                        user_id:appointment?.customer?.id,type:'Appointments'
                    }))
                    await dispatch (getCRMCustomerBookingsDetails({
                        id:appointment?.customer?.id,type:'Appointments'
                    }))
                    // Show success confirmation message
                    await successConfirmation(               
                            " Assigned Staff Remove Successfully."
                    );
    
                    // Close modal or popup
                    handleClose();
                }
            } catch (error) {
                console.error("Error in removeCrmStaff:", error);
                // Handle error here
            }
    
            setLoading(false);
        }  
    };
    
    

    /********************* Status **************************/

    const handleChangeStatus = async () => {

        if (isStaff){
            handleOpen()
        }else {
            if (appointment?.id) {
                let finalData = {
                    appointment_id: appointment?.id,
                    status: value
                }

                let confirm = await MySwal.fire({
                    icon: 'question',
                    title: 'Confirmation',
                    showConfirmButton: false,
                    html: <Box sx={{mb: 4}}>
                        <Typography sx={{}}>
                            Are you sure? you want to change appointment status to {label}.
                        </Typography>
                        <Box sx={{mt: 3,}}>
                            <Button
                                sx={{
                                    backgroundColor: "#fff",
                                    minWidth: "max-content",
                                    border: "1px solid #666666",
                                    borderRadius: '10px',
                                    fontSize: '16px',
                                    px: '20px',
                                    mx: '10px',
                                    color: "#666666",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        border: "1px solid #666666",
                                    },
                                }}
                                onClick={() => MySwal.clickConfirm()}
                            >
                                Yes,Change it.
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "#fff",
                                    minWidth: "max-content",
                                    border: "1px solid #666666",
                                    borderRadius: '10px',
                                    px: '10px',
                                    mx: '10px',
                                    fontSize: '16px',
                                    color: "#666666",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        border: "1px solid #666666",
                                    },
                                }}
                                onClick={() => MySwal.close()}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                })
                if (!!confirm?.isConfirmed) {
                    if (value === 'CheckOut'){
                        navigate(`/crm/appointment/checkout/${appointment?.id}`)
                    }else {
                        setLoading(true)
                        let res = await dispatch(changeAppointmentStatus(finalData))
                        if (res?.payload?.success === "true") {
                            
                            if (isFetch) {
                                await dispatch(getAppointmentDetails(appointment?.id))
                                await dispatch( getAllAppointment())
                            }
                           
                            if (fetchCalenderBookings){
                                await dispatch(getAppointmentcalender(appointment?.id))
                                await fetchCalenderBookings()
                            }
                            if(isAppointment){
                                await dispatch (getAppointmentHistory({
                                    user_id:appointment?.customer?.id,type:'Appointments'
                                }))
                                await dispatch(getCRMCustomerBookingsDetails({id:appointment?.customer?.id,type:'Appointments'}))
                            }
                     
                           
                             
                            if (onRescheduleSuccess) {
                                onRescheduleSuccess();
                              }
                            
                             
                            

                            await successConfirmation('Appointment Status Changed Successfully')

                           
                        }
                        setLoading(false)
                    }
                }
            }
        }
    }  
    const handleCheckbox = async (e) => {
        const {checked} = e.target
        if (checked){
            await handleChangeStatus()
        }
    }
    const handleStaffSelection = (staff) => {
        if (selectedStaff?.id === staff.id) {
            setSelectedStaff(null); // Unselect the staff if it's already selected
        } else {
            setSelectedStaff(staff); 
            scrollRef.current.scrollIntoView({ behavior: "smooth" });// Select the staff
        }
    };


    return (
        <Box>
            {loading ? <Waveloading fullSize/> : ''}
            {value ?
                <Box>
                    {isNoShow ?
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={(e) => handleCheckbox(e)}/>} label={label}/>
                        </FormGroup>
                        : mainColor ?
                            <CustomButtoncolor
                                handleClick={() => handleChangeStatus()}
                                mainColor={mainColor}
                                style={{borderRadius: "4px", padding: '10px 15px',  width: fwidth}}
                                val={label}
                            />
                            :
                            <CustomButtoncolor
                                handleClick={() => handleChangeStatus()}
                                style={{borderRadius: "4px", padding: '10px 15px', width: fwidth}}
                                val={label}
                            />
                    }
                </Box>
                :
                <Box>
                    <CustomButtoncolor
                        style={{borderRadius: "4px", padding: '10px 15px', width: fwidth}}
                        mainColor={"#323681"}
                        val={appointment?.staff?.id ? "Edit Staff":"Assign Staff"}
                        handleClick={() => handleOpen()}
                        size={"medium"}
                    />
                </Box>
            }
            <CustomModal
                customWidth={'943px'}
                isCloseHide
                setOpen={() => handleClose()}
                open={!loading && staffModal}
            >
                <Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: '15px 15px'
                    }}>
                        {crmStaffs?.length > 0 &&
                            <Typography
                                sx={{fontSize: '18px', textAlign: 'center'}}
                            >
                                Select Staff Member
                            </Typography>}
                        <IconButton    
                            size="small"
                            onClick={() => handleClose()}
                        >
                            <Close/>
                        </IconButton>
                    </Box>
                    {isStaff && <Typography sx={{color:'red',textAlign:'center',mt:"-20px",mb:"16px"}}>Please choose  staff and then CheckIn</Typography>}
                    {crmStaffs?.length > 0 ?
                        <Grid container spacing={3}>
                            {crmStaffs.map((staff, index) => (
                                <Grid item key={index} sx={{cursor: 'pointer',mt: selectedStaff?.id === staff?.id ? '-59px':''}}>
                                    {selectedStaff?.id === staff?.id && <CheckCircleIcon
                                        sx={{
                                            color: '#128D64',
                                            fontSize: '35px',
                                            position: 'relative',
                                            zIndex: '1',
                                            top:'57px',
                                            m: '10px'
                                        }}
                                    />}
                                    <Avatar
                                        onClick={() => handleStaffSelection(staff)}
                                        sx={{width: '160px', height: '160px'}}
                                        variant="rounded"
                                        src={staff?.profile_image}
                                    />
                                    <Typography
                                        sx={{
                                            textAlign:'center',
                                            mt:'10px',
                                            maxWidth:'160px',
                                        }}>
                                        {staff?.name}
                                    </Typography>
                                </Grid>
                            ))}
                            <Grid ref={scrollRef} item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                <CustomButtoncolor
                                    style={{borderRadius: "0px", minWidth: '200px',}}
                                    mainColor={"#235449"}
                                    val={isStaff ? 'Assign Staff and Check-In'  :"Assign Staff"}
                                    handleClick={() => handleAssign()}
                                    size={"medium"}
                                />

{appointment?.staff?.id && (
    <div style={{marginLeft:"20px"}}>
  <CustomButtoncolor
                            style={{borderRadius: "0px", minWidth: '200px', marginLeft: '20px'}}
                            mainColor={"#FF6347"} // Adjust color as needed
                            val={"Remove Staff"}
                            handleClick={() => handelremoveStaff()}
                            size={"medium"}
                        />
    </div>
                      
                    )}
                            </Grid>
                        </Grid>
                        :
                        <Typography>No Staff Found</Typography>
                    }
                </Box>
            </CustomModal>
        </Box>
    );
}

export default AppointmentStaffStatus;