import React, {Fragment, useEffect, useState} from 'react';
import {MenuItem, Box, Typography, IconButton, Avatar, Menu, Autocomplete, TextareaAutosize, Modal, Button, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAvatar from "@mui/material/Avatar";
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from "@mui/material/Badge";
import ChatLog from "./chatLog";
import SendMsgForm from "./sendMsgForm";
import { getAllMessages, getUserDetails, ReadMessageuser, SendMsgss } from '../../../store/user/Chat-modue';
import { useSelector } from 'react-redux';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Waveloading from '../../loading/Waveloading';
// import { GetMessagess } from '../../../store/user/Chat-modue';

// import { GetMessages } from '../../store/crm/chat-module';
const ChatWrapperStartChat = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    borderRadius: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor:"#F1F5F9"
}))

const ChatContent = (props) => {
    const {
        store,
        hidden,
        SendMsgs,
        mdAbove,
        dispatch,
        statusObj,
        getInitials,
        sidebarWidth,
        userProfileRightOpen,
        handleLeftSidebarToggle,
        handleUserProfileRightSidebarToggle
    } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [errorMessage, setErrorMessage] = useState('');
const [loading, setLoading] = useState(false); 
   const [modalOpen, setModalOpen] = useState(false);
 
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
     const [errors, setErrors] = useState({});
     const [selectedCustomer, setSelectedCustomer] = useState(null);
       const [message, setMessage] = useState('');
       const isFormInvalid = errorMessage || errors.selectedCustomer;
        
                  const handleCustomerChange = (event, newValue) => {
                      setSelectedCustomer(newValue)
                    }
        //   const [selectedUser, setSelectedUser] = useState('');
    const validateFields = () => {
        const newErrors = {};
        if (!selectedCustomer) {
          newErrors.selectedCustomer = 'Customer is required';
        }
        if (!message) {
            newErrors.message = 'messsage is required';
          }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      }; 

console.log("store 2,",store)  
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
     const authStore = useSelector(state => state?.authStore);
        const { user } = authStore;

    useEffect(() => {
        // dispatch(getUserDetails());
        const provider_id=store?.userProfile?.provider_id
        dispatch(getAllMessages(user?.default_provider_id));  
        //  dispatch(ReadMessageuser(user?.id));
       
    }, [dispatch]);


 const userDefaultLocationStore = useSelector(state => state?.userDefaultLocationStore?.activeClinics)
 const handleClinicSelect = (event, selectedClinic) => {
    if (selectedClinic) {
        // Check if the selected clinic already exists in store.clinicscaht?.data
        const clinicExists = store.clinicscaht?.data.some((clinic) => clinic.provider_id=== selectedClinic.id)
        console.log("id comes",clinicExists,selectedClinic.id)
        if (clinicExists) {
            setErrorMessage('Select another customer, already exist');
        } else {
            setErrorMessage('');
        }
        // Call your handleCustomerChange with the selected clinic
        handleCustomerChange(event, selectedClinic);
    }
};

       const handleSendMessage = async () => {
             setLoading(true);
         console.log("data coming",selectedCustomer)
             const newMessage = {
                 user_id: selectedCustomer?.id
                 ,
                 name: store.clinicscaht?.data.find(contact => contact.id === selectedUser)?.name || 'Unknown User',
                 lastMessage: {
                     message: message,
                     time: new Date().toISOString()  
                 },
                 unread_count: 0  
             };
         
             setFilteredChat(prevChats => [newMessage, ...prevChats]);
         
          const payload= await dispatch(SendMsgss({ provider_id: selectedCustomer?.id, message }));
         console.log("data",payload)
                dispatch(getAllClinics())
            dispatch(getUserDetails());
        
             setMessage('');
             setSelectedUser('');
             setLoading(false);
             handleCloseModal(); 
         };
    const handleSaveClick = () => {
        if (validateFields()) {
            handleSendMessage()
        } else {
          toast.error("Please fill all required fields.");
        }
      }

    const handleClose = () => { 
        setAnchorEl(null) 
    } 
    const handleStartConversation = () => { 
        if (!mdAbove) {   
            handleLeftSidebarToggle()
        }
    }
    console.log("selected chat",store)
    useEffect(() => {
        
        // Only call the function if store.Chatlist.length > 0, !selectedChat, and screen size is above 400px (mdAbove)
        if ( !store.selectedChat ) {
            handleLeftSidebarToggle(); // Automatically open the sidebar
        }
      }, [ store.selectedChat]);

    const renderContent = () => {
        if (store) {  
            console.log("store data",store.selectedChat)
            const selectedChat = store.selectedChat
            console.log("selected chat in messages",store?.UserMessages)    
            const getChat = store.Messages

            if (!selectedChat) {
                return (  
                    <ChatWrapperStartChat  
                        sx={{
                            ...(mdAbove ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {})
                        }}
                    > 
                        <MuiAvatar   
                            sx={{
                                mb: 5,    
                                pt: 8,
                                pb: 7,
                                px: 7.5,
                                width: 110,    
                                height: 110,       
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3]
                            }}   
                        >
                            <ChatBubbleOutlineOutlinedIcon sx={{ width: 50, height: 50, color: 'action.active' }} />
                        </MuiAvatar>   
                        <Box
                            onClick={handleStartConversation}
                            sx={{  
                                px: 6,
                                py: 2.25,
                                borderRadius: 5,
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3],
                                cursor: mdAbove ? 'default' : 'pointer'  
                            }}
                        >
                            <Typography sx={{ fontWeight: 600 }} onClick={handleOpenModal}>Start Conversation</Typography>
                        </Box>
                        {loading ? <Waveloading fullSize /> : ""}
                        <Modal
                open={modalOpen}
                onClose={handleCloseModal} 
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ 
                    position: 'absolute', top: '50%', left: '50%', 
                    transform: 'translate(-50%, -50%)', width: 400, 
                    bgcolor: 'background.paper', p: 4, boxShadow: 24, 
                    borderRadius: 2 
                }}>
                   
                    <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-between'}}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Send Message        
                    </Typography>  
                    
            <ClearIcon sx={{cursor:"pointer"}} onClick={handleCloseModal} />
                    </Box>       
                   
   <Autocomplete
            fullWidth
            options={userDefaultLocationStore || []}
            getOptionLabel={(user) => user.name}
            onChange={handleClinicSelect}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Clinics"
                    margin="normal"
                    variant="outlined"
                    error={!!errorMessage}  // Use errorMessage as the error condition
                    helperText={errorMessage || errors.selectedCustomer}  // Show the dynamic error message or default error
                />
            )}
            renderOption={(props, user) => (
                <li {...props} key={user.id}>
                    {user.name}
                </li>
            )}
        />      

                    <TextareaAutosize 
                        minRows={4}
                        placeholder="Type your message here..."
                        style={{ width: '100%', padding: 10, borderRadius: 5, borderColor: '#ccc' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                            //  error={!!errors.message}
                            //   helperText={errors.message}  
                    />
                      {errors.message && (
                <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: 4, fontSize: '0.875rem' }}
                >
                    {errors.message}
                </Typography>
            )}
                     {!isFormInvalid && (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                        onClick={handleSaveClick}
                    >  
                        Send Message
                    </Button>
                     )}
                </Box>
            </Modal>
                    </ChatWrapperStartChat>
                )     
            } else {  
                return (
                    <Box   
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            height: '100%',
                            backgroundColor: "#fff",  
                            paddingBottom:'80px'
                        }}  
                    > 
                     <Box
                            sx={{
                                py: 1,
                                px: { lg: 5 },
                                display: 'flex',
                                alignItems: 'center',   
                                justifyContent: 'space-between',
                                borderBottom: theme => `1px solid ${theme.palette.divider}`   
                            }}
                        >     
                            <Box sx={{ display: 'flex',}}>
                                {mdAbove ? null : (
                                    <IconButton onClick={handleLeftSidebarToggle} sx={{  }}>
                                        <MenuIcon />
                                    </IconButton> 
                                )}  
                                <Box
                                    onClick={handleUserProfileRightSidebarToggle}
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                >
                                    <Badge
                                        overlap='circular'
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right' 
                                        }}   
                                        sx={{ mr: 4.5 }}
                                        badgeContent={
                                            <Box
                                                component='span'
                                                sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    color: `${statusObj[selectedChat.status]}.main`,
                                                    boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`,
                                                    backgroundColor: `${statusObj[selectedChat.status]}.main`
                                                }}
                                            />  
                                        }
                                    >
                                        {selectedChat.profile_image ? (
                                            <MuiAvatar
                                                src={selectedChat.profile_image}   
                                                alt={selectedChat.name}  
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        ) : (
                                            <Avatar
                                                skin='light'
                                                color={'blue'}
                                                sx={{ width: 40, height: 40, fontSize: '1rem' }}
                                            >
                                                {getInitials(selectedChat.name)}
                                            </Avatar>
                                        )}
                                    </Badge> 
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: 'text.secondary' }}>{selectedChat.name}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.disabled' }}>
                                            {selectedChat.email}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {/*{mdAbove ? (*/} 
                                {/*    <Fragment>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <PhoneOutlinedIcon />*/}
                                {/*        </IconButton>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <VideocamOutlinedIcon sx={{ fontSize: '1.5rem' }} />*/}
                                {/*        </IconButton>*/} 
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <SearchIcon />*/}
                                {/*        </IconButton>*/}
                                {/*    </Fragment>*/}
                                {/*) : null}*/}
                                <IconButton size='small' onClick={handleClick} sx={{ color: 'text.secondary', display:'none' }}>
                                    <MoreVertOutlinedIcon />
                                </IconButton>
                                {/* <Menu
                                    open={open}   
                                    sx={{ mt: 2 }}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{    
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                > */}
                                    {/* <MenuItem onClick={handleClose}>View Contact</MenuItem>
                                    <MenuItem onClick={handleClose}>Mute Notifications</MenuItem>
                                    <MenuItem onClick={handleClose}>Block Contact</MenuItem>
                                    <MenuItem onClick={handleClose}>Clear Chat</MenuItem>
                                    <MenuItem onClick={handleClose}>Report</MenuItem> */}
                                {/* </Menu> */}
                            </Box>
                        </Box>

                        {/* {selectedChat && store.userProfile ? ( */}
                        {/* userContact: store.userProfile */}
              
                        {/* {getChat ?( */}  
                            <ChatLog hidden={hidden} data={{ ...selectedChat,  chat:store.Messages, userContact: store.userProfile }} />
                         {/* ) : null} */}

                        <SendMsgForm store={store} dispatch={dispatch} SendMsgs={SendMsgs} />

                        {/*<UserProfileRight*/}
                        {/*    store={store}*/}
                        {/*    hidden={hidden}*/}
                        {/*    statusObj={statusObj}*/}
                        {/*    getInitials={getInitials}*/}
                        {/*    sidebarWidth={sidebarWidth}*/}
                        {/*    userProfileRightOpen={userProfileRightOpen}*/}
                        {/*    handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle}*/}
                        {/*/>*/}
                    </Box>
                )   
            }
        } else {       
            return null
        }
    }

    return renderContent()
};

export default ChatContent;