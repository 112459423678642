import {
    Box, Button,
    Divider,
    Grid, Link as MuiLink,
    Typography,
  } from "@mui/material";
  import React, {useState} from "react";
  import ribbon from "../../assets/images/ribbon.svg";
  import redRibbon from "../../assets/images/red-badg.svg";
import ribbonorange from "../../assets/images/ribbon-orange.svg";
  import CustomButtoncolor from "../../components/custom-button/customColorButton";
  import moment from "moment";
  import {redirect, useLocation, useNavigate} from "react-router";
  import {AppointmentAllStatusArray, MySwal, successConfirmation} from "../../services/utills";
  import AppointmentStaffStatus from "../../pages/crm/appointment/AppointmentStaffStatus/appointmentStaffStatus";
  import AppointmentReschedule from "./AppointmentReschedule";
  import {Link} from "react-router-dom";
  import CheckIcon from '@mui/icons-material/Check';
  import TreatmentFormButton from "./treatmentForm";
  import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
  import AppointmentRescheduleDate from "./AppointRescheduleDate";
import SetEmailModal from "../modal/SetEmailModal";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import EmailIcon from '@mui/icons-material/Email';
  
  export const outercontainer = {
    marginBottom: "15px",
    padding: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 19px -2px rgba(189, 189, 189, 0.25)",
  
    '@media (max-width: 600px) ': {
       position:'relative'
    },
    '@media (max-width: 400px)' :{
      padding:'12px'
    },

    borderRadius: "10px",
  };
  export const bookingwrapper = {
    display: 'flex',
    flexDirection:{sm:'row' ,xs:'column'},
    justifyContent: "space-between",
  };
  export const ribbonwrapper = {
    display: "flex",
    alignItems:'flex-start',
    width:'fit-content',
    gap: "8ppx",
  };
  
  export const wallet = {
    display: "flex",
    alignItems:'center',
    marginTop: "8%",
    gap: "6px",
    '@media (max-width: 470px)' :{
        marginTop: "3%",
      },
  };
  
  export const boxwrapper = {
    border: "1px solid black",
    padding: "10px",
    height: "13vh",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "8px",
    // '@media  (max-width: 600px) ': {
    //     position:'absolute',
    //     top:'125px',
    //     right:'20px',
    // },
    '@media (min-width:1200px) and (max-width: 1330px) ': {
        marginLeft:'10px',
    }
  };
  export const box = {
    display: "block",
    margin: "15px auto",
    '@media (max-width: 600px) ': {
        margin: "5px auto",
    }
    
  };
  
  export const status = {
    alignItems: "center",
    display: "flex",
    gap: "25px",
  };
  export const bookingtext = {
    display: "flex",
    flexDirection: "column",
  };
  export const datacolor = {
    color: "#2F80ED",
  
  };
  export const content = {
    fontFamily:"helvetica-lt-std-bold",
    fontSize: "13px",
    color: "#000000",
    fontWeight: "400",
    overflowWrap:"anywhere"
  
  };
  export const heading = {
    fontSize: "18px",
    color: "#000",
    fontWeight: "400",
  };
  export const textul = {
    color: "#000000",
    fontSize: "25px",
    fontWeight: "700",
    '@media (max-width: 600px)':{
        fontSize: "20px",
    }
  };
  
  export const email = {
    fontsize: "15px",
  };
  export const btnwrapper = {
    marginTop:'14px',
    display: "flex",
    alignItems:'center',
    marginLeft:'8px',
    gap:'15px',
    flexWrap:'wrap',
  };
  export const stylebtn = {
    borderRadius: "4px",
    padding: '10px 14px',
    height:'fit-content',
    '@media (max-width: 450px)': {
        fontSize:'18px',
        width:'100%'
    },
  };
export const buttonStyle = {
    padding: '10px 8px',
    // Add other styles here
};
  export const mySwalButton ={
    backgroundColor: "#fff",
    minWidth: "max-content",
    border: "1px solid #666666",
    borderRadius: '10px',
    fontSize: '16px',
    px: '20px',
    mx: '10px',
    color: "#666666",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #666666",
    },
  }
  
  export const handleEditReschedule = async (redirect,appointment) => {
    let confirm = await MySwal.fire({
      icon: 'question',
      title: 'Confirmation',
      showConfirmButton: false,
      html: <Box sx={{mb: 4}}>
        
        <Typography sx={{}}>
          Are you sure? You want to Edit this appointment .
        </Typography>
        <Box sx={{mt: 3,}}>
          <Button
              sx={{...mySwalButton}}
              onClick={() => MySwal.clickConfirm()}
          >
            Yes
          </Button>
          <Button
              sx={{...mySwalButton}}
              onClick={() => MySwal.close()}
          >
            No
          </Button>
        </Box>
      </Box>
    })
    if (!!confirm?.isConfirmed) {
      let givenDate = moment(appointment?.date);
      let today = moment(new Date());
/*
      if (givenDate.isSameOrAfter(today, 'day')) {
*/
        redirect(`/crm/appointment/reschedule/edit/${appointment?.id}`)
     /* } else {
        await successConfirmation("Warning", 'Booking date is over Due.', 'warning')
      }*/
    }
  }
  const CardAppointment = ({appointment,index,fetchAppointment}) => {
      const navigate = useNavigate()
      const { pathname } = useLocation();
      const handleAppointmentClick = (url) => {
          navigate(url)
      }
      const handleClick = (itemId) => {
        navigate(`/crm/customer-relationship-management`, {  
          state: { value: itemId, },  
        })    
    };  

    const [expanded, setExpanded] = useState(false); 


    const handleToggleExpand = () => {
      setExpanded(!expanded);
    };
  
    const MAX_LENGTH = 30;
    // console.log("appointment",appointment)
      return (
        <Box sx={outercontainer}>
               <Box sx={ribbonwrapper}>
                               {appointment?.customer?.customer_batch && (
                                   appointment?.customer?.customer_batch !== "New User" ? (
                                   <>
                                       {appointment?.customer?.customer_batch.includes("Expire/Cancel") ? (
                                           <>
                                               <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                               <Typography style={{ color: "#FF0000" }}>
                                                   {appointment?.customer?.customer_batch}
                                               </Typography>
                                           </>
                                       ) : (
                                           <>
                                               {appointment?.customer?.customer_batch.includes("Declined") ? (
                                                   <>
                                                       <img
                                                       style={{ marginTop: "4px",width:"20px" }}
                                                       src={ribbonorange} alt={'member'} />
                                                       <Typography style={{ color: "#f55d0b"}}>
                                                           {appointment?.customer?.customer_batch}
                                                       </Typography>
                                                   </>   
                                               ) : (
                                                   <>
                                                       <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                                       <Typography style={{ color: "#219653" }}>
                                                           {appointment?.customer?.customer_batch}
                                                       </Typography>
                                                   </>
                                               )}
                                           </>
                                       )}
                                   </>
                               ) : (
                                   <>
                                       <Typography style={{ color: "#09ABF1" }}>
                                           {appointment?.customer?.customer_batch}
                                       </Typography>
                                   </>
                               ))}  
                </Box>


            <Grid key={index} container sx={{  }} >
              <Grid item sm={4} md={2.5} xl={2} xs={7.5}>
                  <Box sx={{marginTop:{xs: "10%",md: "10%"}}}>
                      <Typography sx={heading}  >Customer Name:</Typography> 
                      <Box style={{marginTop: "4px"}}>
                          <div>
                               <Typography sx={{...textul, textTransform: 'capitalize',cursor:"pointer"}}  onClick={()=>handleClick(appointment?.customer?.name)}>{appointment?.customer?.name}</Typography>
                          </div>
            
                          <div>
                              <MuiLink href="#" color="inherit">
                                  Member ID {appointment?.customer?.id}
                              </MuiLink>
                          </div>
                      </Box>

                  </Box> 
                 
              </Grid>
              <Grid item sm={7} md={2.5} xl={2} xs={4.5} sx={{ display:{sm:'none', xs:'block',} }}>

                                          <Box
                      sx={{
                          display: 'flex',
                          alignItems: "center",
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: '10px',
                          float:'right',
                      }}
                  >
                      {
                          appointment?.plan?.id && appointment?.current_session && <Box
                              sx={{
                                  ...boxwrapper,
                                  height: 'max-content',
                                  borderColor: appointment?.status_color || 'black',
                                  cursor: 'pointer',
                              }}
                              onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                          >
                              <Box
                                  sx={{
                                      ...box,
                                      height: 'max-content',
                                      my: '5px',
                                  }}
                              >
                                  <Typography
                                      sx={{
                                          ...content,
                                          background: appointment?.status_color || 'black',
                                          p:'1px 12px',
                                          borderRadius:'13px',
                                          color:'#fff',
                                      }}>
                                      Session No
                                  </Typography>
                                  <Typography
                                      variant="h6"
                                      sx={{
                                          color: appointment?.status_color || 'black',
                                      }}
                                  >
                                      {appointment?.current_session} / {appointment?.plan?.no_of_bookings}
                                  </Typography>
                              </Box>
                          </Box>
                      }
                      <Box
                          onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                          sx={{
                              ...boxwrapper,
                              height: 'max-content',
                              borderColor: appointment?.status_color || 'black',
                              cursor: 'pointer', 
                              marginLeft:{lg:0,sm:5,xs:0},
                              marginTop:{sm:1,xs:'10px' },
                              width:{sm:'auto',xs:'auto'},
                              display:'flex',
                              alignItems:'start',    
                          }}  
                      >
                          <Box   
                              sx={{
                                  ...box,
                                  height: 'max-content',
                                  m: appointment?.status === 'Confirmed' ? '0px' : '15px auto',
                                  my: appointment?.plan?.id ? '5px' : '',
                                  cursor: 'pointer',

                                  
                              }}
                              onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                          >
                              {appointment?.status === 'Confirmed' && <CheckIcon
                                  sx={{
                                      fontSize: "40px",
                                      fontWeight: "bold",
                                      color: "#27AE60" || appointment?.status_color || 'black',
                                  }}
                              />}
                              <Typography
                                  sx={{
                                      ...content,
                                      background: appointment?.status_color || 'black',
                                      p:{xs:'1px 6px',sm:'1px 12px'},
                                      borderRadius:'13px',
                                      color:'#fff',

                                  }}>
                                  Booking No
                              </Typography>
                              <Typography
                                  variant="h6"
                                  sx={{
                                      color: appointment?.status_color || 'black',
                                  }}
                              >
                                  {appointment.id}
                              </Typography>
                          </Box>
                      </Box>
                  </Box> 
                 
              </Grid>
              <Box sx={{padding: "14px", pb: '0', marginTop:'10px', height:'140px', display:{xs:'none',sm:'block'}}}> 
                  <Divider
                      orientation="vertical"
                      style={{borderColor: "#9A9797", heigth: "100%", width: "2px"}}
                  />
              </Box>
  
              <Grid item xs={12} sm={6} md={3.5} xl={2.5} sx={{marginTop:{xs: "4.5%",sm: "0px"}}}>
                  <Box sx={wallet}>
                      <Typography sx={heading}><AccountBalanceWalletIcon sx={{color:'#09ABF1'}}/> &nbsp;</Typography>
                      <Typography sx={content}>${appointment?.customer?.wallet_point}</Typography>
                  </Box>
                  <Box sx={wallet}>
                      <Typography sx={heading}><LocalPhoneIcon sx={{color:'#09ABF1'}}/> &nbsp;</Typography>
                      <Typography 
                      sx={content}
                        component="a" 
                        style={{cursor:"pointer"}} 
    href={`tel:${appointment?.customer?.phone}`
    }>{appointment?.customer?.phone}</Typography>
                  </Box>
                  <Box sx={wallet}>
                      <Typography sx={heading}><EmailIcon sx={{color:'#09ABF1'}}/>&nbsp;</Typography>
                      <Typography sx={content}  
                      style={{cursor:"pointer"}} 
                       component="a" 
    href={`mailto:${appointment?.customer?.email}`}>{appointment?.customer?.email}</Typography>
                  </Box>

   {appointment?.appointment_customer_note && typeof appointment.appointment_customer_note === 'object' && appointment.appointment_customer_note !== null && (
    <Box >
        <Typography sx={heading}>Appointment Notes:</Typography> 

 {appointment?.appointment_customer_note && typeof appointment.appointment_customer_note === 'object' 
                  && appointment.appointment_customer_note !== null && (
  
        <Typography
        sx={content}
        onClick={appointment.appointment_customer_note?.description.length > MAX_LENGTH ? handleToggleExpand : null}
        style={{
          cursor: appointment.appointment_customer_note?.description.length > MAX_LENGTH ? 'pointer' : 'default',
          maxWidth: '100%',   
        }}
        
      >
        {expanded
          ? appointment.appointment_customer_note?.description
          : appointment.appointment_customer_note?.description.slice(0, MAX_LENGTH) + (appointment.appointment_customer_note?.description.length > MAX_LENGTH ? '...' : '')}
      </Typography>
  )}
    </Box>
)}
                
                
              </Grid>
              <Box sx={{padding: "14px", pb: '0',display:{xs:'none',sm:'block'}}}>
                  <Divider
                      orientation="vertical"   
                      style={{
                          borderColor: "#9A9797",     
                          height: "100%",
                          width: "2px",
                      }}
                  />
              </Box>
  
              <Grid item lg={5} xl={2.8}>
                  <Box sx={{ mt:'6%' }}>
                  {
                      appointment?.plan?.id && <Box sx={{...wallet, mt: "7px"}}>
                          <Box>
                              <Typography sx={content}>Bundle ID:</Typography>
                          </Box>
                          <Box>
                              <Typography sx={content}>#{appointment?.plan?.id}</Typography>
                          </Box>
                      </Box>
                  }
                  {
                      appointment?.plan?.id ? <Box sx={{...wallet, mt: "5px"}}>
                              <Box>
                                  <Typography sx={content}>Bundle Name:</Typography>
                              </Box>
                              <Box>
                                  <Typography sx={content}>{appointment?.plan?.title}</Typography>
                              </Box>
                          </Box> :
                          <Box sx={{mt:'7px',display:"flex"}}>
                              <Box>
                                  <Typography
                                      sx={{
                                          ...heading,
                                          textTransform: 'capitalize',
                                          fontFamily:"helvetica-lt-std-bold",
                                      }}
                                  >
                                      {appointment?.services?.length > 1 ? "Services" : "Service"}:
                                  </Typography>
                              </Box>
                              <Box
                                  sx={{
                                      ml:"16px",
                                  }}
                              >
                                  {appointment?.services?.map((service, serviceIndex) => (
                                      <Typography
                                          sx={{
                                            fontFamily:"helvetica-lt-std-bold",
                                            fontSize: "18px",
                                            color: "#000000",
                                            fontWeight: "400",
                                            overflowWrap:"anywhere",
                                              textTransform: 'capitalize',
                                          }}
                                          key={serviceIndex}
                                      >
                                          {service?.name}
                                      </Typography>
                                  ))}
                              </Box>
                          </Box>
                  }
                      <div style={{display: "flex", gap: "2px", marginTop:'10px'}}>
                          <Typography sx={{...heading, mr: '8px'}}> <AccessTimeFilledIcon sx={{color:'#09ABF1'}}/>&nbsp;</Typography>
                          {
                              appointment?.date && appointment?.start_time && appointment?.end_time ?
                                  <div
                                      style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          fontFamily: "helvetica-lt-std-bold",
                                      }}>
                                      <MuiLink href="#" sx={{...datacolor, fontSize: '16px'}}>
                                          {moment(appointment?.date).format('MMMM DD, YYYY')}
                                      </MuiLink>
                                      <MuiLink
                                          href="#"
                                          sx={{
                                              ...datacolor,
                                              fontSize: '16px',
                                              fontFamily: "helvetica-lt-std-bold",
                                          }}
                                      >
                                          {appointment?.start_time} - {appointment?.end_time}
                                      </MuiLink>
                                  </div> :
                                  <div
                                      style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          fontFamily: "helvetica-lt-std-bold",
                                      }}>
                                      <MuiLink href="#" sx={{
                                          ...datacolor,
                                          fontSize: '20px',
                                          color: '#CB3838',
                                          textDecorationColor: '#CB3838'
                                      }}>
                                          Set Date and Time
                                      </MuiLink>
                                  </div>
                          }
                      </div>
                  </Box>

                  <Box sx={{...status, mt: appointment?.status === 'Completed' ? '16px' : '5px'}}>
                      {appointment?.status !== 'Completed' && appointment?.date &&
                          <div>
                              <AppointmentStaffStatus label={"Mark as No Show"} isNoShow value={'No Show'} appointment={appointment}/>
                          </div>}
                  </Box>

        

                        
              </Grid>
              <Box sx={{padding: "14px", pb: '0',display:{xs:'none',sm:'block'}}}>
                  <Divider
                      orientation="vertical"
                      style={{borderColor: "#9A9797", heigth: "100%", width: "2px"}}
                  />
              </Box>
  
              <Grid item lg={4.5} xl={3.4} sx={bookingwrapper}>
                  <Box sx={bookingtext }>
                      <Box style={{marginTop: "14px"}}>
                          {appointment?.status === 'Completed' &&
                                  <Box>
                                      <MuiLink
                                          component={Link}
                                          to={`/crm/invoices/${appointment?.id}`}
                                          underline="hover"
                                          color={'#1976D2'}
                                          sx={{
                                              fontFamily: 'helvetica-lt-std-roman',
                                          }}
                                      >
                                      
                                     <LocalPrintshopOutlinedIcon style={{color:"#1976D2"}} />  Print Invoice
                                      </MuiLink>
                                  </Box>}
                          <Box sx={wallet}>
                              <Typography sx={heading}> {"Invoice ID"}:</Typography>
                              <Typography
                                  sx={content} >{appointment?.invoices?.invoices_methods?.length > 0 &&
                                  <Typography 
                                  sx={content}
                                 key={index}
                                
                                 as={'span'}
                                 style={{cursor:"pointer",textDecoration: 'underline'}} 
                                 onClick={() => handleAppointmentClick(`/crm/invoices/${appointment?.id}`)}>
                                      {appointment?.invoices?.invoices_methods[0]?.id}
                                  </Typography>
                              }</Typography>
                          </Box>
                          <Box sx={{...wallet,alignItems:"center"}}>
                              <Typography sx={heading}>Payment:</Typography>
                              <Typography
                                  sx={{
                                      ...content,
                                      background: appointment?.payment_status === 'Paid' || appointment?.payment_status === 'Pre Paid' ? '#27AE60' : '#EB5757',
                                      p:'1px 12px',
                                      borderRadius:'13px',
                                      color:'#fff',
                                  }}
                              >
                                  {appointment?.payment_status}
                              </Typography>
                             
                          </Box>
                          <Box sx={wallet}>
                              <Typography sx={heading}>Amount:</Typography>
                              <Box>
                                  <Typography sx={content}>
                                      ${appointment?.grand_total}
                                  </Typography>
                                  {appointment?.futurePayment && <Typography sx={content}>
                                      Future Payment: {appointment?.futurePayment}
                                  </Typography>}
                              </Box>
                          </Box>
                          <Box sx={wallet}>
                              <Typography sx={heading}>Booking Status:</Typography>
                              <Box>
                                  <Typography
                                      sx={{
                                          ...content,
                                          background: appointment?.status_color,
                                          p:{xs:'2px 10px',sm:'2px 12px'},
                                          borderRadius:'13px',
                                          color:'#fff',
                                      }}
                                  >
                                      {appointment?.status && (AppointmentAllStatusArray[appointment?.status] || appointment?.status)}
                                  </Typography>
                              </Box>
                          </Box>
                      </Box>
                  </Box>
                  <Box
                      sx={{
                          display: 'flex',
                          alignItems: "center",
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: '10px'
                      }}
                  >
                      {
                          appointment?.plan?.id && appointment?.current_session && <Box
                              sx={{
                                  ...boxwrapper,
                                  height: 'max-content',
                                  borderColor: appointment?.status_color || 'black',
                                  cursor: 'pointer',
                              }}
                              onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                          >
                              <Box
                                  sx={{
                                      ...box,
                                      height: 'max-content',
                                      my: '5px',
                                  }}
                              >
                                  <Typography
                                      sx={{
                                          ...content,
                                          background: appointment?.status_color || 'black',
                                          p:'1px 12px',
                                          borderRadius:'13px',
                                          color:'#fff',
                                      }}>
                                      Session No
                                  </Typography>
                                  <Typography
                                      variant="h6"
                                      sx={{
                                          color: appointment?.status_color || 'black',
                                      }}
                                  >
                                      {appointment?.current_session} / {appointment?.plan?.no_of_bookings}
                                  </Typography>
                              </Box>
                          </Box>
                      }
                      <Box sx={{ display: {sm:'block', xs:'none'} }}>
                      <Box
                          onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                          sx={{
                              ...boxwrapper,
                              height: 'max-content',
                              borderColor: appointment?.status_color || 'black',
                              cursor: 'pointer', 
                              marginLeft:{lg:0,sm:5,xs:0},
                              marginTop:{sm:1,xs:'10px' },
                              width:{sm:'auto',xs:'auto'},
                              display:'flex',
                              alignItems:'start',    
                              
                          }}  
                      >
                          <Box   
                              sx={{
                                  ...box,
                                  height: 'max-content',
                                  m: appointment?.status === 'Confirmed' ? '0px' : '15px auto',
                                  my: appointment?.plan?.id ? '5px' : '',
                                  cursor: 'pointer',

                                  
                              }}
                              onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                          >
                              {appointment?.status === 'Confirmed' && <CheckIcon
                                  sx={{
                                      fontSize: "40px",
                                      fontWeight: "bold",
                                      color: "#27AE60" || appointment?.status_color || 'black',
                                  }}
                              />}
                              <Typography
                                  sx={{
                                      ...content,
                                      background: appointment?.status_color || 'black',
                                      p:{xs:'1px 6px',sm:'1px 12px'},
                                      borderRadius:'13px',
                                      color:'#fff',

                                  }}>
                                  Booking No
                              </Typography>
                              <Typography
                                  variant="h6"
                                  sx={{
                                      color: appointment?.status_color || 'black',
                                  }}
                              >
                                  {appointment.id}
                              </Typography>
                          </Box>
                      </Box>
                      </Box>

                  </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt:2 }}>
                   <Grid container sx={{ display:'flex', gap:'4px' }} >
                   <Grid item lg={1.5} xl={1.5} sm={3.8} md={2.8} xs={5.8}>
                     <CustomButtoncolor
                        // sx={{ crmButton }}
                        mainColor={"#09ABF1"}
                        size="md"  
                        val="Book Now"
                        handleClick={() => {  
                          localStorage.setItem("url", pathname);
                          console.log("issue ",appointment?.customer?.id)
                          navigate(
                            `/crm/add-appointment/user/${appointment?.customer?.id}`
                          );
                        }}
                        fwidth={'100%'}
                        fsize={'13px'}
                         style={stylebtn}
                        // style={crmButton}
                      />
                </Grid>
                {appointment?.status !== 'Void' &&  appointment?.status !== 'Canceled' &&  appointment?.status !== 'Completed' && appointment?.date &&
                <Grid item lg={1.5} xl={1.1} sm={3.8} md={2.8} xs={5.8}>
                 <AppointmentStaffStatus
                      fetchAppointment={fetchAppointment}  
                      appointment={appointment}
                      style={stylebtn}
                      fwidth={'100%'}
                      fsize={'13px'}      
                  />
                </Grid>
                }
                    {(appointment?.status === 'Pending' ) && appointment?.date &&
                        <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>

                            <AppointmentStaffStatus    
                              isFetch 
                                label={"Confirm"}
                                value={'Confirmed'}
                                mainColor={"#323681"}
                                style={buttonStyle}
                                fetchAppointment={fetchAppointment}
                                appointment={appointment}
                                fwidth={'100%'}
                                fsize={'13px'}
                            />
                        </Grid>
                    }
                {(appointment?.status === 'Pending' || appointment?.status === 'Confirmed'|| appointment?.status === 'No Show') && appointment?.date &&
                <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>
                
                      <AppointmentStaffStatus
                        isFetch
                          label={"Check-In"}
                          value={'CheckIn'}
                          style={stylebtn}
                          fetchAppointment={fetchAppointment}
                          appointment={appointment}
                          fwidth={'100%'}
                          fsize={'13px'}
                      />
                </Grid>
                 }
                {appointment?.status === 'CheckIn' && appointment?.date &&
                <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>
                 <AppointmentStaffStatus
                      label={"Check-Out"}
                      value={'CheckOut'}
                      mainColor={"#219653"}
                      fetchAppointment={fetchAppointment}
                      appointment={appointment}
                      style={stylebtn}
                      fwidth={'100%'}
                      fsize={'13px'}
                  />
                </Grid>
  }
                {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' && appointment?.status !== 'Completed' &&
                <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>
                 <AppointmentReschedule appointment={appointment} fwidth={'100%'}  fsize={'13px'}/>
                </Grid>
                }
                {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' && appointment?.status !== 'Completed' &&
                <Grid item xl={1.5} lg={2} sm={3.8} md={2.8} xs={5.8}>
                  <CustomButtoncolor
                      mainColor={"#000080"}
                      handleClick={() => handleEditReschedule(navigate, appointment)}
                    //   style={{borderRadius: "4px", padding: '10px 15px'}}
                      val={"Edit Appointment"}
                      style={stylebtn}
                      fwidth={'100%'}
                      fsize={'13px'}
                      paddin={"10px 5px !important"}
                  />
                </Grid>
                }
                <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>
                <CustomButtoncolor
                      handleClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/notes/${appointment?.customer?.id}`)}
                      mainColor={"#A533B8"}
                      style={stylebtn}
                      val={"Notes"}
                      fwidth={'100%'}    
                      fsize={'13px'}
                  />
                </Grid>
                <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>
                <CustomButtoncolor
                      handleClick={() => handleAppointmentClick(`/crm/appointment/attachments/${appointment?.id}`)}
                      mainColor={"#BB641F"}
                      style={stylebtn}
                      val={"Attachments"}
                      fwidth={'100%'}
                      fsize={'13px'}
                  />
                </Grid>
                {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' &&  appointment?.status !== 'Completed' && appointment?.date &&
                <Grid item lg={1.5} xl={1} sm={3.8} md={2.8} xs={5.8}>
                 <AppointmentStaffStatus
                   isFetch
                      label={"Cancel/Refund"}
                      value={'Canceled'}
                      mainColor={"#EB5757"}
                      fetchAppointment={fetchAppointment}
                      appointment={appointment}
                      style={stylebtn}
                      fwidth={'100%'}
                      fsize={'13px'}
                  />
                </Grid>

                 }
                  <Grid item xs={5.8} sm={3.8} md={2.8} lg={1.5} xl={1.5}>
                      <SetEmailModal
                        styles={{ fontSize: "15px", }}
                        customer_id={appointment?.customer?.id}
                      />
                    </Grid>
                  {/* {appointment?.status !== 'Completed' && appointment?.date && <AppointmentStaffStatus
                      label={"Refund"}
                      value={'Refund'}
                      mainColor={"#EB5757"}
                      appointment={appointment}
                  />} */} 
                  <Grid item lg={1.5} xl={1.1} sm={3.8} md={2.8} xs={5.8}>
                  <TreatmentFormButton fwidth={'100%'} data={appointment}  fsize={'17px'}/>
                  </Grid>

                <Grid item xl={1.5} lg={2} sm={3.8} md={2.8} xs={5.8}>
                <CustomButtoncolor
                      handleClick={() => handleAppointmentClick(`/crm/appointment/history/${appointment?.id}`)}
                      mainColor={"#323681"}
                      style={stylebtn}
                      val={"Booking History"}
                      fwidth={'100%'}
                    // //   fsize={'11px'}
                    //   paddin={"10px 5px !important"}
                    fsize={'13px'}
                  />
                </Grid>
                <Grid item lg={2} xl={1.5} sm={3.8} md={2.8} xs={5.8}>
                <CustomButtoncolor
                      handleClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                      mainColor={"#000000"}
                      style={stylebtn}
                      val={"Booking Details"}
                      fwidth={'100%'}
                      fsize={'13px'}
                  />
                </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Box>
 
      );
  };
  
  export default CardAppointment;
  