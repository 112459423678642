import React, {useEffect} from "react";
import {Box, Button, FormControl, Grid, Menu, MenuItem, Typography} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import CardAppointment from "../../../components/card-appointment/CardAppointment";
import {useDispatch, useSelector} from "react-redux";
import {getAllAppointment, updateCrmFilter} from "../../../store/crm/appointment";
import FilterInput from "../../../components/form/filterInput";
import Loader from "../../../components/loader/loader";
import {getBookingStatus} from "../../../store/booking-widget";
import BackendPagination from "../../../components/pagination/backendPagination";
import BackendSearchField from "../../../components/pagination/backendSearch";
import {scrollCSS} from "../../../App";
import {vectorWrapper} from "../pos/posStyles";
import Vector from "../../../assets/images/Vector.png";
import Waveloading from "../../loading/Waveloading";
import AddIcon from '@mui/icons-material/Add';
const Appointment = () => {
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const bookingWidgetStore = useSelector(state => state?.bookingWidgetStore)
    const {bookingStatus} = bookingWidgetStore

    const navigate = useNavigate();
    const {state} = useLocation()
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);

    const crmAppointmentStore = useSelector((state) => state?.crmAppointmentStore)
    const {appointments = {},filterBy,pageLimit,currentPage,search:crmSearch} = crmAppointmentStore
 console.log("state consile",state)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); 
    };
    const handleClose = () => {
        setAnchorEl(null);
    };  

    const AddAppointment = () => {
        navigate("/crm/add-appointment");
    }; 

    const locationStateValue = state?.searchvalue;
    useEffect(() => {
        (async () => {    
            if(locationStateValue)
           console.log(" this one call")

           const params = {
                  filterBy:"Upcoming",
                    search:locationStateValue,
                    currentPage:1
                  };
             await dispatch(updateCrmFilter(params))
             await fetchAppointment()
            dispatch(getBookingStatus())
        })()
    }, [])

    const  fetchAppointment = async () => {
        
        let filterValue = state?.value || filterBy;
        let start_date = state?.startDate ? new Date(state.startDate) : null;
        let end_date = state?.endDate ? new Date(state.endDate) : null;
    
        // Check if start_date and end_date are valid Dates
        if (start_date instanceof Date && !isNaN(start_date) && end_date instanceof Date && !isNaN(end_date)) {
          start_date = start_date.toISOString().split('T')[0];
          end_date = end_date.toISOString().split('T')[0];
        } else {
          start_date = null;
          end_date = null;
          
        }
        setLoading(true)
        const params = {
            start_date: start_date,
            end_date: end_date,

          };
          console.log("fetch api", params);
        await dispatch(getAllAppointment(params))
        setLoading(false) 
    }
    const handleFilter = async (value) => {
        await dispatch(updateCrmFilter({filterBy:value,currentPage:1}))
        handleClose()
        await fetchAppointment()
    }

   const fetchPageData = async(page) => {
       await dispatch(updateCrmFilter({currentPage:page}))
       await fetchAppointment()
   }
   const handleLimitChange =async (val)=>{
       await dispatch(updateCrmFilter({pageLimit:val,currentPage:1}))
       await fetchAppointment()
   }

   const handleSearch = async (val) => {
       await dispatch(updateCrmFilter({search:val}))

   }

//    useEffect(() => {
//     (async () => {
//       let filterValue = state?.value || filterBy;
//       let start_date = state?.startDate ? new Date(state.startDate) : null;
//       let end_date = state?.endDate ? new Date(state.endDate) : null;
//       let searchValue =state?.searchvalue|| crmSearch;
  
//       // Check if start_date and end_date are valid Dates
//       if (start_date instanceof Date && !isNaN(start_date) && end_date instanceof Date && !isNaN(end_date)) {
//         start_date = start_date.toISOString().split('T')[0];
//         end_date = end_date.toISOString().split('T')[0];
//       } else {
//         start_date = null;
//         end_date = null;
//       }
//       const params = {
//         start_date: start_date,
//         end_date: end_date, 
//         filterValue:filterValue,
//         search:searchValue
//       };
//       console.log("before api", params)
//       console.log("useeffect 2 call")
//       await dispatch(updateCrmFilter(params));
  
//       await fetchAppointment(start_date, end_date);
  
//       dispatch(getBookingStatus());
//     })();  
//   }, [state, dispatch, filterBy,crmSearch]);

console.log("issue",state?.value ,state)

    return (
        <Grid container>  
            {loading ? <Waveloading fullSize /> :""}
            <Grid
                item
                xs={12}
                sx={{
                    mb: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100F%",
                    flexWrap:{sm:'nowrap',xs:'wrap'},
                    
                }}
            >
                <Box sx={{display: 'flex', alignItems: "baseline",mb:{sm:'0px',xs:'15px'}}}>
                    <FilterInput  
                        limit={pageLimit}
                        setLimit={handleLimitChange}   
                    />
                    <Typography sx={{fontSize: '14px', color: '#404040',ml:{xs:1,lg:0}}}>
                        Showing {appointments?.meta?.from} to {appointments?.meta?.to} of {appointments?.meta?.total} entries
                    </Typography>
                </Box>
                <Box sx={{display:'flex',alignItems:'center',flexWrap:{sm:'nowrap',xs:'wrap'},'@media (max-width:450px)':{
                       justifyContent:"center",
                    }}}>
                    <CustomButton style={{ width:'85%' }}
                        val={"Add New Appointment"} 
                        size={"large"}
                        startIcon={<AddIcon />}
                        handleClick={AddAppointment}
                    />

                    <FormControl sx={{mr: {sm:"10px",xs:"0px"}, ml:{sm:"10px",xs:"0px"},mt:{sm:'0px',xs:'15px'},mb:{sm:'0px',xs:'15px'}, mr:{xs:'5px'}, width:{sm:'100%',xs:'80%'}}}>
                        <BackendSearchField
                            search={crmSearch}
                            handleSearch={handleSearch}
                            fetchData={async ()=>{
                                await dispatch(updateCrmFilter({currentPage:1}))
                                await fetchAppointment()
                            }} 
                        />
                    </FormControl>  

                    <Box
                        variant="outlined"
                        sx={vectorWrapper}
                        onClick={handleClick}
                    >
                        <img src={Vector} alt=""/>  
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                width: '24ch',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                "ul":{
                                    maxHeight: 60 * 4.5,
                                    ...scrollCSS,
                                    overflowY: 'scroll',
                                },
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {  
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {bookingStatus?.length > 0 ? bookingStatus.map((item,index)=>(
                                <MenuItem sx={{background:item === filterBy ? "#09ABF1":''}} key={index} onClick={()=>handleFilter(item)}>{item}</MenuItem>
                            ))
                            : <MenuItem  onClick={handleClose}>Empty</MenuItem>
                        }
                    </Menu>    
                </Box> 
            </Grid> 
            <Grid item xs={12}>    
                { 
                    appointments?.data?.length > 0 ?     
                        appointments?.data.map((appointment, index) => (
                        <CardAppointment fetchAppointment={fetchAppointment} appointment={appointment} key={index}/>
                    )):
                        <Typography sx={{textAlign:'center',mt:'30px'}}>
                            {filterBy === 'Upcoming' && "Upcoming"} No Appointment  Available
                        </Typography>
                } 

            </Grid>
            <Grid item xs={12}> 
                {appointments?.meta?.last_page > 1 &&
                    <BackendPagination
                        count={appointments?.meta?.last_page}
                        currentPage={currentPage}
                        handleOnChange={fetchPageData}
                    />
                }
            </Grid>
        </Grid>
    );
}

export default Appointment;
