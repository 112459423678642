import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({ val, size, borderColor,handleClick, mainColor, subColor,style,height,mrt,width,startIcon }) => {
  return (
    <Button
      size={size}
      sx={{
        backgroundColor: mainColor || "#09ABF1",
        borderColor: borderColor ||mainColor || "#09ABF1",
          textTransform: "capitalize",
        color: subColor || "#fff",
        "&:hover": {
          color: mainColor || "#09ABF1",
          borderColor:borderColor,
        },
        mt:{sm:'20px', xs:mrt, lg:'0',md:'10px'}
        ,
      }}
      variant={"outlined"}
      onClick={handleClick}
      style={style}
      startIcon={startIcon}
      height={height}
      width={width}
    >
      {val}
    </Button>
  );
};

export default CustomButton;
