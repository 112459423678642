import { Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Modal, Radio, RadioGroup, Select, Typography, Container, Button, Tooltip, Avatar, TextField, Checkbox } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CustomButtoncolor from '../custom-button/customColorButton';
import { storeAppointmentNotes } from '../../store/crm/appointment/notes';
import { getAppointmentDetails, getAppointmentcalender } from '../../store/crm/appointment';
import { successConfirmation } from '../../services/utills';
import { getStaff } from '../../store/crm/reports';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../custom-button/customButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Waveloading from '../../pages/loading/Waveloading';
import { useParams } from 'react-router';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getCrmNotes, storeCrmNotes } from '../../store/crm/crm/crm-details';
import { getAllTags, storeAppointmentAttachment, storeAppointmentGallery, storeTag } from '../../store/crm/appointment/attachment';
import ImageUploader from '../image-uploader/imageUploader';
import CustomModal from './customModal';
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos"; 
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Webcam from "react-webcam";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',  
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { 
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": { 
        color:'#000!important',
    }
}
 
const AddAttachmentcheckout = ({appointmentDetails}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);  
    const [files,setFiles] = useState([])
    const [loading,setLoading] = useState(false)
    // const [selectedServices,setSelectedServices] = useState({})
    const [cameraFacingMode, setCameraFacingMode] = useState("user");
    const [tagId,setTagId] = useState({})
    const [comments,setComments] = useState('') 
    const [isMilestone,setIsMilestone] = useState(false)    
    const [tag,setTag] = useState('')
    const [isAddTag,setIsAddTag] = useState(false) 
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const webcamRef = useRef(null);
    const dispatch = useDispatch()
    const crmAppointmentAttachmentStore = useSelector(state => state?.crmAppointmentAttachmentStore)
    const {tags=[]}= crmAppointmentAttachmentStore 

   console.log("id",appointmentDetails)
    const handleSubmit = async () => {
      if (files?.length){
      // if (files?.length && selectedServices?.id && comments?.length){

          setLoading(true)
          const formData = new FormData();

          formData.append("booking_id", appointmentDetails?.id);
          // formData.append("service_id", selectedServices?.id); 
          if (tagId?.id) {
            formData.append("tag_id", tagId?.id);
          }
          formData.append("comment", comments); 
          formData.append("is_milestone", isMilestone ? "1" :'0');
          
          files.forEach(value => formData.append("files" + '[]', value))
          let res = await  dispatch(storeAppointmentGallery({data:formData,booking_id:appointmentDetails?.id}))
   
          setLoading(false) 
          if (res?.payload?.success === 'true'){
              // setSelectedServices({})
               dispatch(getAppointmentDetails(appointmentDetails?.id))
               dispatch(getCrmNotes(appointmentDetails?.customer?.id))
              setFiles([])
              setComments('')
              await successConfirmation(`Gallery ${isMilestone ? " and Milestone " :"" }Added Successfully`)
              handleClose();
            //   if (setActive){
            //       setActive(1)
            //   } 
          }         
      }else {
          await successConfirmation("Error",`All Fields are required`,'error')
          handleClose(); 

      }
    } 

    const handleRemove = (file) => { 
        if (files?.length) {
            let finalData = files.filter((item) => file?.path !== item?.path && file?.size !== item?.size)
            setFiles(finalData)
        }
    }   

    useEffect(()=>{
        (async ()=>{
            if (!tags?.length > 0){       
                await dispatch(getAllTags())
            }
        })()
    },[])

    const handleSave = (item) => {
        let finalData = [...files,...item]
        let uniqueFiles = finalData.filter((file, index, self) =>
            index === self.findIndex((f) => (
                f.name === file.name && f.size === file.size
                ))
        );

        setFiles(uniqueFiles)

    }

    const handleSaveTag = async () => { 
      if (tag?.length > 2){
          setLoading(true)

          let res = await  dispatch(storeTag({tag_name:tag}))
          setLoading(false)

          if(res?.payload?.success === 'true'){
              await successConfirmation('Success',"Tag Added Successfully")
              setIsAddTag(false)
              
          }else {
              await successConfirmation('Error',"Tag Already Exist",'error')

          }
      }
    } 

const toggleCameraFacingMode = () => {
    setCameraFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
    const capturePhoto = () => { 
        const imageSrc = webcamRef.current.getScreenshot();
    
        // Convert Base64 to Blob
        const byteCharacters = atob(imageSrc.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });
    
        // Convert Blob to File
        const file = new File([blob], `captured_photo_${Date.now()}.png`, { type: "image/png" });
    
        setFiles([...files, file]); // Store as File 
        setIsCameraOpen(false); // Close webcam after capture
    };
    

  return (  
                                 
<>

{/* <CustomButtoncolor
    handleClick={handleOpen}
     mainColor={"#09ABF1"}
     size="md"
     val='Add Notes'
     style={{
      padding:'7px 5px',
      fontSize: "18px",
      minWidth:'max-content',
      borderRadius: "6px!important",
      width: "100%",
      "@media (minWidth: 420px)": {
          width: 'auto'
      },
  }}                                  
  /> */}
<Box>

{/* <Button variant="contained"
sx={{ backgroundColor:'#09ABF1', color:'#fff',px:4 }}

>
Add A Photo
  
</Button> */}
<a href="#"  onClick={handleOpen} style={{  textDecoration: 'underline' }}>
                                                        Upload!
                                                    </a>
</Box>

 
    <Modal
 
       style={{}} 
       open={open}  
       onClose={handleClose}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
        <Container maxWidth="xl" >
        {/* {loading ? <Waveloading fullSize/>:''} */} 
        <Box sx={style}>     
       
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
                    <IconButton       
                            size="small"
                            onClick={() => setOpen(false)}    
                            style={{ color: "red" }}    
                        > 
                           
                            <CloseRoundedIcon  style={{fontSize: '2rem' }} /> 
                        </IconButton>
                    </Box>
        <Box 
        sx={{background:"#fff",
          // Adjust the height as needed 
          borderRadius:'8px',
          p:'24px', 
          maxHeight: 'calc(100vh - 100px)',
             overflowY: 'auto', 
             "&::-webkit-scrollbar": {
               width: "10px",
               height: "10px"
             },
             "&::-webkit-scrollbar-track": { 
               background: "#fff"
             },
             "&::-webkit-scrollbar-thumb": {
               borderRadius: "4px",
               background: "rgba(0, 0, 0, 0.2)",

               boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
             }
             }}>
            <Typography sx={{fontSize:'20px'}}>Add New Gallery</Typography>
            {loading? <Waveloading fullSize/>:''}
            {/* <Grid item xs={12}  sx={{marginTop:"10px", display:"flex",justifyContent:"space-between"}}>
                <Box>
                    <Typography>Take Photo</Typography>
                </Box>
                <Box>
<CameraAltOutlinedIcon   style={{cursor:"pointer"}} onClick={() => setIsCameraOpen(true)} />
                </Box>

                {/* <CustomButton val={"Take Photo"}  /> */}
              {/* </Grid> */}

             
              {/* {isCameraOpen && (
      <Box sx={{ marginTop: "20px", position: "relative" }}>
        <Webcam
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={{ facingMode: cameraFacingMode }} 
          style={{ height: "149px", width: "250px", borderRadius: "8px" }}
        />

        {/* Flip Camera Button */}
        {/* <IconButton
          onClick={toggleCameraFacingMode}
          sx={{
            position: "absolute",
            top: 0,
           left:11,
            background: "rgba(255,255,255,0.8)",
            borderRadius: "50%",
          }}
        >
          <FlipCameraIosIcon />
        </IconButton> */}

        {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: "10px" }}>
          <CustomButton val={"Capture"} handleClick={capturePhoto} />
          <CustomButton val={"Cancel"} handleClick={() => setIsCameraOpen(false)} />
        </Box>
      </Box> */}

            <Box sx={{display:'flex',gap:"20px",flexWrap:'wrap',my:'24px'}}> 
                {files?.length > 0 && files.map((file,key)=>(
                    <Box key={key}>  
                        <IconButton
                            sx={{   
                                fontSize: "15px",     
                                position: "relative", 
                                p:"5px", 
                                zIndex:'1',
                                background:"#EB5757!important",   
                                m:'5px',    
                                right:'-111px' 
                            }}
                            onClick={() => handleRemove(file) 
                        }
                        >
                            <DeleteOutlineIcon sx={{color:'#fff',fontSize:'16px'}}/>
                        </IconButton>
                        <Avatar
                           src={file instanceof File ? URL.createObjectURL(file) : file} 
                            sx={{
                                borderRadius:'8px' ,
                                height:'149px',
                                width:'149px',
                                mt:'-36px'  
                            }}
                        />
                    </Box>
                ))}   
                <ImageUploader handleSave={handleSave} />
            </Box> 
            <Grid
                container
                spacing={3}
                sx={{
                    flexDirection:'column', 
                }}
            >
                {/*<Grid item xs={12} xl={6}>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <FormLabel sx={{...labelStyles}}>Service Name</FormLabel>*/}
                {/*        <Select*/}
                {/*            size={'small'}*/}
                {/*            value={selectedServices?.id || ''}*/}
                {/*            displayEmpty*/}
                {/*            MenuProps={MenuProps}*/}
                {/*        >*/}
                {/*            {appointmentDetails?.services?.length > 0 &&appointmentDetails?.services.map((service,key) => (*/}
                {/*                <MenuItem*/}
                {/*                    key={key}*/}
                {/*                    value={service?.id}*/}
                {/*                    onClick={()=>setSelectedServices(service)}*/}
                {/*                >*/}
                {/*                    {service?.name}*/}
                {/*                </MenuItem>*/}
                {/*            ))}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Comments</FormLabel>
                        <textarea
                                className="MuiInputBase-input MuiOutlinedInput-input"
                                rows={3}
                                placeholder={""}
                                value={comments}
                                onChange={(e)=>setComments(e.target.value)}
                                style={{
                                    width: '100%',
                                    resize: 'vertical',
                                    padding: '8px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    fontFamily: 'inherit',
                                    fontSize: '1rem',
                                }}
                            />
                        {/* <TextField
                            size={'small'}
                            variant="outlined"
                            placeholder={""}
                            value={comments}
                            onChange={(e)=>setComments(e.target.value)}
                        /> */}
                    </FormControl>
                </Grid> 
                <Grid
                    xs={12}
                    md={6}
                    item
                    sx={{
                        display:"flex",
                        JustifyContent:'space-between',
                    }}
                >
                    <FormControl fullWidth sx={{maxWidth:'50%',alignItems:'centers'}}>
                        <FormLabel sx={{...labelStyles}}>Gallery Tag</FormLabel>
                        <Select
                            size={'small'}
                            value={tagId?.id || ''}
                            displayEmpty
                            MenuProps={MenuProps}
                        >
                            {tags?.length > 0 ?
                                tags?.map((service,key) => (
                                <MenuItem
                                    key={key}
                                    value={service?.id}
                                    onClick={()=>setTagId(service)}
                                >
                                    {service?.tag_name}
                                </MenuItem>  
                            ))
                                : <MenuItem>
                                    Add Tag
                                </MenuItem>
                            }
                        </Select>
                    </FormControl>
                   <Box
                       sx={{
                           width:'50%',
                           display:"flex",
                           alignItems:'flex-end',
                           justifyContent:'flex-end'
                       }}
                   >     
                       <CustomButton
                           val={"Add Tag"}
                           handleClick={()=>setIsAddTag(true)}
                       />
                   </Box>
                </Grid>
                 <Grid xs={12} item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={isMilestone}
                                            onChange={e => {
                                                setIsMilestone(e?.target?.checked)
                                            }}
                                        />
                                    }
                                        label={"Add Into Milestone"}
                                    />
                                </Grid>
               {/* take photo option */}
                 {/* Take Photo button */}
              

            {/* Show Camera Stream */}
           
       
                <Grid xs={12} item>
                    <CustomButton val={'Save'} handleClick={handleSubmit}/>
                </Grid>
            </Grid>
            <CustomModal
                customWidth={'500px'}
                setOpen={() => setIsAddTag(false)} 
                open={isAddTag}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize:'18px', 
                            fontFamily: 'helvetica-lt-std-bold',
                            textAlign:"center",
                        }}
                    >
                        Add New Tag
                    </Typography>

                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Tag</FormLabel>
                        <TextField
                            size={'small'}
                            variant="outlined"
                            placeholder={""}
                            value={tag}
                            onChange={(e)=>setTag(e.target.value)}
                        />
                    </FormControl>

                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center',
                            mt:'24px',
                        }}
                    >
                        <CustomButton
                            val={'Save'}
                            size={'large'}
                            handleClick={handleSaveTag}
                        />
                    </Box>
                </Box>
            </CustomModal>
        </Box>
        </Box>
        </Container>
       

     </Modal>
     </>
  )
}

export default AddAttachmentcheckout