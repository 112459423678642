import React, {useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Avatar,
    Box, Button,
    Card, Checkbox, Collapse,
    Divider, FormControl,
    FormControlLabel, FormLabel, Grid, IconButton,
    Input,
    InputAdornment, Modal,
    Radio,
    RadioGroup, Table, TableBody, TextField, Tooltip,
    Typography,    TableCell,
    TableRow,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";

import {tableCellClasses} from "@mui/material/TableCell";
import {styled, useTheme} from '@mui/material/styles';
import {inputStyle, labelStyle} from "../../../../components/form/formInput";
import CustomButton from "../../../../components/custom-button/customButton";
import arrow from "../../../../assets/icon/chevron-back-outline.png";
import {ReactComponent as Close} from "../../../../assets/icon/close-outline.svg";
import {ReactComponent as Braintree} from '../../../../assets/icon/braintree.svg'
import UserCardsList from "./payment/userCardsList";
import CardPayment from "./payment/CardPayment";
import {
    bookingCheckoutwrapper,
    bookingcontent,
    bookingwrapper,
    treatmentcontent,
    walletcontent
} from "./payment/appointmentPayment";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {appointmentTime, MySwal, successConfirmation} from "../../../../services/utills";
import ClearIcon from '@mui/icons-material/Clear';
import {
    crmCheckoutAppointment,
    crmCreateCard,
    getAllExistingUserCards,
    getAppointmentById,
    getCrmAllPlans,
    getServicesCategory,
} from "../../../../store/crm/appointment/book-appointment";
import Loader from "../../../../components/loader/loader";
import CustomPagination from "../../../../components/pagination/pagination";
import {booking, content, customerside, removeCard, wrappercard} from "./addAppointment";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {useLocation} from "react-router-dom";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchField from "../../../../components/form/SearchField";
import SubmitButton from "../../../../components/custom-button/submitButton";
import FormAutoComplete from "../../../../components/form/FormAutoComplete";
import * as Yup from "yup";
import {useFormik} from "formik";
import MenuItem from "@mui/material/MenuItem";
import {getAllProducts} from "../../../../store/crm/inventory";
import CustomModal from "../../../../components/modal/customModal";
import AddServices from "../../../configuration/clinic-settings/services/AddService";
import CustomBreadcrumbs from '../../../../components/breadcrumbs/breadcrumbs';
import Waveloading from '../../../loading/Waveloading';
import {contentstatus} from "../../crmpage/CRMPage";
import upload from "../../../../assets/images/cloud-upload-outline.png";
import AddAttachmentcheckout from '../../../../components/modal/AddAttachmentcheckout';


const modalStyle ={
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius:'5px',
    transform: 'translate(-50%, -50%)',
    width: 450,
    background: '#fff',
    p: '10px 24px 24px 24px',
    '@media  (max-width: 500px) ': {
        width:'90%'
    },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: '16px',
        paddingTop:'3px',
        paddingBottom:'3px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const AppointmentCheckout = () => {
    const [tip, setTip] = useState(0.0)
    const [loading, setLoading] = useState(false)
    const [discount, setDiscount] = useState(0.0)
    const [walletBalance, setWalletBalance] = useState(0.0)
    const [futurePayment, setFuturePayment] = useState('now')
    const [isDiscount, setIsDiscount] = useState('fix')
    const [paymentModal, setPaymentModal] = useState(null)
    const [allInvoices, setAllInvoices] = useState({})
    const [brainTree, setBrainTree] = useState(null)
    const [useCard, setUseCard] = useState(false);
    const [userCards, setUserCards] = useState([]);
    const [addExtraService, setAddExtraService] = useState(false);
    const [appointmentDetails, setAppointmentDetails] = useState({});

    /******************** Step 2 States ***********************/
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [bookingType, setBookingType] = useState('')
    const [selectedServices, setSelectedServices] = useState([])
    const [invoiceDate, setInvoiceDate] = useState('')
    const [isExtra, setIsExtra] = useState(false)
    const [consultationType, setConsultationType] = useState('Booking');

    const [apiValue, setApiValue] = useState(1); 
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setApiValue(checked ? 1 : 0);
      };
    /******************** Pagination States ***********************/
    const [servicePage, setServicePage] = useState(1);
    const serviceItemsPerPage = 5

    const mainDivRef = useRef(null);


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const {appointment_id} = useParams()


    const bookAppointmentStore = useSelector((state) => state?.bookAppointmentStore)
    const inventoryStore = useSelector(state => state?.inventoryStore)
    const authStore = useSelector(state => state?.authStore)
    const {servicesCategory = [], crmAllPlans = []} = bookAppointmentStore
    const {customer = {}} = appointmentDetails
    const {user} = authStore
    const {provider_payment = [], booking_setting = {}} = user
    const {
        products = [],
    } = inventoryStore

    let appointmentType = pathname.includes('/crm/appointment/checkout') ? 'checkout' : 'invoice'
    const isTaxPercentage = booking_setting?.default_tax_in_percentage === 1

    const [tax, setTax] = useState(booking_setting?.tax_booking_amount || 0)
    useEffect(() => {
        const handleWindowResize = () => {
    
          if (window.innerWidth >= 768) {
            setBookingType('service');
          } else {
            setBookingType(''); 
          }
        };
    
      
        handleWindowResize();
  
        window.addEventListener('resize', handleWindowResize);
    
       
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    useEffect(() => {
        (async () => {
            dispatch(getAllProducts())
        })()
    }, [])
    const serviceCategoryContainerRef = useRef(null);
    useEffect(() => {

        (async () => {
            setLoading(true)
            await dispatch(getServicesCategory())
            // dispatch(getCrmAllPlans())
            let {payload} = await dispatch(getAppointmentById(appointment_id))
            if (payload?.id) {
                setAppointmentDetails(payload)
                if (payload?.tip) {
                    setTip(parseFloat(payload?.tip))
                }
              /*  if (payload?.tax) {
                    setTax(parseFloat(payload?.tax))
                }*/
                if (payload?.discount) {
                    setDiscount(parseFloat(payload?.discount))
                }

                if (payload?.discount_type) {
                    setIsDiscount(payload?.discount_type === 'Fixed' ? 'fix' : 'percentage')
                }
                setBookingType("service")
                setSelectedServices(payload?.services)
                setConsultationType(payload?.type)
                setSelectedCategory(payload?.category)
                setFilteredData(payload?.category?.services)

                let userCard = await dispatch(getAllExistingUserCards(payload?.customer?.id))

                if (userCard?.payload?.data?.id) {
                    if (userCard?.payload?.data?.payment_method_id === 4) {
                        setUserCards(userCard?.payload?.data?.cards?.cards || [])
                    } else {
                        setUserCards(userCard?.payload?.data?.cards || [])
                    }
                } else {
                    setUserCards([])
                }
            }
            setLoading(false)

        })()

    }, [])

    const handleRemoveService = (serviceToRemove) => {
        const updatedServices =  removeCard(selectedServices, serviceToRemove);
        setSelectedServices(updatedServices);
      };


    const serviceValidationSchema = Yup.object({
        name: Yup.string().required("name is Required"),
        service_quantity: Yup.string().typeError("Is Quantity is Required").required("Quantity is Required"),
        booking_cost: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ).required("member_price is Required"),
        time_duration: Yup.string().required("Time duration is Required"),

    });

    const {
        values,
        handleSubmit,
        setFieldValue,
        handleChange,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            time_duration: null,  
        },
        validationSchema: serviceValidationSchema,
        onSubmit: async (data) => {

        }
    })
    // const handleGetServices = async (category) => {
    //     setSelectedCategory(category)
    //     setFilteredData(category?.services)

    // }

    const handleGetServices = async (category, fromServiceAdded = false) => {
        console.log("modal off call", category);
        setSelectedCategory(category); 
    
       
        // setSelectedCategoryId(category?.category_id);
    
       
        setFilteredData(category?.services);
       
        if (fromServiceAdded && category?.services?.length > 0) {
       
            setTimeout(() => {
                const lastService = category.services[category.services.length - 1];
                handleSelectData(lastService);
            }, 0);
        }
    };  
    const handleBookingTypeChange = (type) => {
        if (type === bookingType) {
            setBookingType('')
            setFilteredData([])
            setSelectedCategory('')
        } else {
            setBookingType(type)
            if (type === 'plan') {
                // setFilteredData(crmAllPlans)
            } else {
                setFilteredData([])
                setSelectedCategory('')
            }
        }
        setSelectedServices([])
    }

    const handleSelectData = (data) => {
        let finalData = {...data, "service_quantity": 1, booking_cost: null};
    
        if (bookingType === 'plan') {
            setSelectedServices([finalData]);
            if (mainDivRef.current) {
                mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
              }
        } else {
            if (selectedServices?.length > 0) {
                if (isExist(selectedServices, finalData)) {
                    const finalRes = removeCard(selectedServices, finalData);
                    setSelectedServices(finalRes);
                } else {
                    setSelectedServices([...selectedServices, finalData]);
                    if (mainDivRef.current) {
                        mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
                      }
                }
            } else {
                setSelectedServices([finalData]);
                if (mainDivRef.current) {
                    mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
            } 
        }
    };
    const isExist = (arr, foundItem) => {

        if (arr?.length) {
            return arr.some((item) => bookingType === 'plan' ? foundItem?.plan_id === item?.plan_id : foundItem?.id === item?.id)
        } else {
            return false
        }

    }

    const handleCloseModal = (method) => {
        setPaymentModal(null)
        if (method?.payment_method_id || method?.id) {
            let obj = {...allInvoices}
            delete obj[method?.payment_method_id || method?.id]
            setAllInvoices(obj)
        }
    }
    const handleUpdateQuantity = (service, type) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            let currentQuantity = +arr[index].extra_quantity || 1; // The || 0 handles undefined or null values
            if (type === 'add') {
                arr[index].extra_quantity = currentQuantity + 1;
            } else if (currentQuantity > 1) {
                arr[index].extra_quantity = currentQuantity - 1;
            }
            setSelectedServices(arr)
        }
    }
    const handleServicesQuantity = (e, service) => {
        let arr = [...selectedServices];
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const { value } = e?.target;
            if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                arr[index].extra_quantity = value;
                setSelectedServices(arr);
            }
        }
    }
    const handleServicesQuantityBlur = (e, service) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const {value} = e?.target
            if (value === '') {
                arr[index].extra_quantity = 0
                setSelectedServices(arr)
            }
        }
    }
    const handleProductPrice = (e, service) => {
        let arr = [...selectedServices];
        let index = arr.findIndex(item => item.id === service?.id);

        if (index !== -1) {
            const { value } = e?.target;
            if (value === '' || (!isNaN(value) && value.trim() !== '')) {
                // Create a new object for the updated service, updating selling_price
                let updatedService = {
                    ...arr[index],
                    product: {
                        ...arr[index].product,
                        selling_price: value
                    }
                };

                // Replace the old service object with the updated one
                arr[index] = updatedService;

                setSelectedServices(arr);
            }
        }
    }


    const handleProductPriceBlur = (e, service) => {
        let arr = [...selectedServices]
        let index = arr.findIndex(item => item.id === service?.id);
        if (index !== -1) {
            const {value} = e?.target
            if (value === '') {
                arr[index].product.selling_price = 0
                setSelectedServices(arr)
            }
        }
    }
    const getServicePrice = (service) => {
        // If booking_cost is explicitly an empty string, return it directly.
        if (typeof service?.booking_cost === 'string') {
            return service.booking_cost.trim();
        }
        // If the service is free, return "Free".
        if (service?.is_free === 1) {
            return "Free";
        }
        if(service?.booking_cost === 0){
            return service?.booking_cost
        }
        // Determine the final price based on whether the customer is a member or not.
        if (customer?.is_membered === 1) {
            if(service?.extra_quantity != null){
                let finalPrice = ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }else{
                let finalPrice = parseFloat(service?.member_price) + ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }
        } else {
            if(service?.extra_quantity != null){
                let finalPrice = ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }else{
                let finalPrice = parseFloat(service?.regular_price) + ((parseFloat(service?.extra_quantity) || 0) * (parseFloat(service?.product?.selling_price) || 0));
                return finalPrice.toFixed(2);
            }
        }
    }
    
    const handleUpdatePrice = (e, service) => {
        const { value } = e.target;
        // Directly use the value if it is a valid float string or an empty string
        const isValidFloatString = value === '' || (!isNaN(value) !== '');
        
        setSelectedServices(prevServices => {
            return prevServices.map(item => {
                if (item.id === service?.id) {
                    return {
                        ...item,
                        // Update booking_cost with the raw string if it's valid, otherwise keep the previous value
                        booking_cost: isValidFloatString ? value : item.booking_cost,
                    };
                }
                return item;
            });
        });
    };
    
    
    
    
    
    
    const handleServiceAdded = (category) => {
        console.log("selected id",category)
        setAddExtraService(false);
        setBookingType('');
       
        handleGetServices(category,true)
        
    };   
    const getSubTotal = () => {
        let total = 0
        selectedServices.forEach((item) => {
            console.log("Item Price"+item?.booking_cost);
            console.log("Item Extra Quantity"+item?.extra_quantity);

            if (item?.is_free === 0 || item?.booking_cost !== null) {
                if (item?.booking_cost !== null) {
                    if(item?.extra_quantity == null || item?.extra_quantity == undefined){
                        total = total + ((parseFloat(item?.booking_cost) || 0) + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0)))
                    }else{
                        total = total + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0))
                    }
                } else {
                    if (item?.extra_quantity > 0){
                        total = total +  ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0))
                    }else{
                        if (customer?.id && customer?.is_membered === 1) {
                            total = total + (parseFloat(item?.member_price) + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0)))
                        } else {
                            total = total + (parseFloat(item?.regular_price) + ((parseFloat(item?.extra_quantity) || 0) * (parseFloat(item?.product?.selling_price) || 0)))
                        }
                    }
                }
            }
        })

        return parseFloat(total).toFixed(2)
    }
    const getTotalTex = () => {
        let total = getSubTotal()
        total = total - discount
     /*   if (consultationType == "Consultation" || consultationType == "Virtual Consultation") {
            return parseFloat("0.00").toFixed(2)
        }*/
        if (booking_setting?.id) {
            // let taxAmount = tax > 0 ? parseFloat(tax) : parseFloat(booking_setting?.tax_booking_amount)
            let taxAmount =   parseFloat(tax)
            if (isTaxPercentage) {
                return parseFloat((((total / 100) * taxAmount)|| 0).toFixed(2))
            } else {
                return parseFloat(taxAmount || 0).toFixed(2)
            }
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }
    const scrollToServiceCategoryContainer = () => {
        if (serviceCategoryContainerRef.current) {
          serviceCategoryContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
    const getTotalDiscount = () => {
        if (discount) {
            if (isDiscount === 'fix') {
                return parseFloat(discount).toFixed(2)
            } else {
                return parseFloat(((getSubTotal() / 100) * discount)).toFixed(2)
            }
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }


    const getTotalTip = () => {
        if (tip > 0) {
            return parseFloat(tip).toFixed(2)
        } else {
            return parseFloat("0.00").toFixed(2)
        }
    }


    const handleTaxChange = (e) => {

        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            // const intValue = parseInt(inputValue, 10);
            if (parseFloat(inputValue) <= (parseFloat(booking_setting?.tax_booking_amount)+1)) {
                setTax(inputValue)
            }
        } else if (inputValue === '') {
            setTax(inputValue)
        }
    }
    const handleDiscountChange = (e) => {

        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            // const intValue = parseInt(inputValue, 10);
            // if (intValue <= parseFloat(getGrandTotal())){
            setDiscount(inputValue )
            // }
        } else if (inputValue === '') {
            setDiscount(inputValue)

        }
    }
    const handleTipChange = (e) => {

        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            // const intValue = parseInt(inputValue, 10);
            // if (intValue <= parseFloat(getGrandTotal())){
            setTip(inputValue)
            // }
        } else if (inputValue === '') {
            setTip(inputValue)

        }
    }
    const getGrandTotal = () => {
        return parseFloat(parseFloat(getSubTotal()) + parseFloat(getTotalTex()) + parseFloat(getTotalTip()) - parseFloat(getTotalDiscount())).toFixed(2)
    }


    const totalDue = () => {
      //  if (appointmentDetails?.payment_status === 'Paid')
        let grandFinal = parseFloat(getGrandTotal())

        if (Object.entries(allInvoices)?.length > 0) {
            Object.entries(allInvoices).forEach(([key, value], index) => {
                if (value?.amount) {
                    let amount = parseFloat(value?.amount)
                    grandFinal = grandFinal - amount
                }
            })

            if (appointmentDetails?.payment_status === 'Paid' && parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount())) {
                return 0.00
            } else {
                return (parseFloat(grandFinal) - parseFloat(getTotalPaidAmount())).toFixed(2)

            }
        } else {

            if (
                appointmentDetails?.payment_status === 'Paid' &&
                parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount())
            ) {
                return 0.00;
            } else if (
                appointmentDetails?.plan &&
                appointmentDetails?.plan.id !== undefined
            ) {
                return parseFloat(appointmentDetails?.due_amount);
            } else {
                return (
                    parseFloat(getGrandTotal()) - parseFloat(getTotalPaidAmount())
                ).toFixed(2);
            }

        }
    }

    const getTotalPaidAmount = () => {
        let amount = 0

        if (appointmentDetails?.total_paid) {
            amount = appointmentDetails?.total_paid
        }
        return (parseFloat(amount)).toFixed(2)
    }
    const getTotalRemainingAmount = () => {
        let amount = 0

        if (appointmentDetails?.remaining_amount) {
            amount = appointmentDetails?.remaining_amount
        }
        return (parseFloat(amount)).toFixed(2)
    }


    const handleWalletChange = async (e) => {
        const inputValue = e.target.value;
        if (!isNaN(inputValue) && inputValue.trim() !== '') {
            // const intValue = parseInt(inputValue, 10);
            if (inputValue <= parseFloat(customer?.wallet_point)) {
                setWalletBalance(inputValue)
                // await handleInputChange(intValue,method);
            }
        } else if (inputValue === '') {
            setWalletBalance('');
            // await handleInputChange('', method); // If needed
        }
    };


    const handleClickMethod = async (method, e) => {
        const {checked} = e?.target
        if (checked) {
            if (parseFloat(totalDue()) > 0) {
                if (method?.id === 2) {
                    /********** Wallet *************/
                    let value = 0
                    let walletBalance = parseFloat(customer?.wallet_point)
                    let total = parseFloat(totalDue())
                    if (total >= walletBalance) {
                        value = walletBalance
                    } else {
                        value = total
                    }
                    setWalletBalance(value)
                    await handleInputChange(value, method)
                } else {
                    if (method?.labels?.length) {
                        const labels = method?.labels
                        let finalObj = {
                            payment_method_id: method?.id,
                            name: method?.name
                        }
                        labels.forEach((label) => {
                            if (label.toLowerCase() === 'amount') {
                                finalObj['amount'] = totalDue()
                            } else {
                                finalObj[label] = ""
                            }
                        })
                        setAllInvoices(prevState => {

                            if (prevState[method?.id]) {
                                return {
                                    ...prevState,
                                    [method?.id]: finalObj
                                };
                            } else {
                                return {
                                    ...prevState,
                                    [method?.id]: finalObj
                                };
                            }
                        });
                    }
                    setPaymentModal(method?.id)
                }
            }
        } else {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{mb: 4}}>
                    <Typography sx={{}}>
                        Are you sure? You don't want to use this Method.
                    </Typography>
                    <Box sx={{mt: 3,}}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                let obj = {...allInvoices}
                delete obj[method?.id || method?.payment_method_id]
                setAllInvoices(obj)
            } else if (confirm?.isDenied) {
                // setPaymentModal(method?.id)
            }
        }

    }

    const handleInputChange = async (value, method, isClose) => {
        if (parseFloat(value) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    return {
                        ...prevState,
                        [method?.id]: {amount: value, payment_method_id: method?.id, name: method?.name}
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: {amount: value, payment_method_id: method?.id, name: method?.name}
                    };
                }  
            });
            if (isClose) {
                handleCloseModal()
            }
        } else {
            await successConfirmation("Warning", "Amount Increase", "warning")
        }
    }


    const handleSaveWallet = async (value, method) => {
        if (parseFloat(value) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.payment_method_id]) {
                    return {
                        ...prevState,
                        [method?.payment_method_id]: {
                            amount: value,
                            payment_method_id: method?.payment_method_id,
                            name: method?.name
                        }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.payment_method_id]: {
                            amount: value,
                            payment_method_id: method?.payment_method_id,
                            name: method?.name
                        }
                    };
                }
            });
        } else if (parseFloat(value) > parseFloat(totalDue())) {
            if (parseFloat(value) <= (parseFloat(allInvoices[method?.payment_method_id]?.amount) + parseFloat(totalDue()))) {
                setAllInvoices(prevState => {
                    if (prevState[method?.payment_method_id]) {
                        return {
                            ...prevState,
                            [method?.payment_method_id]: {
                                amount: value,
                                payment_method_id: method?.payment_method_id,
                                name: method?.name
                            }
                        };
                    } else {
                        return {
                            ...prevState,
                            [method?.payment_method_id]: {
                                amount: value,
                                payment_method_id: method?.payment_method_id,
                                name: method?.name
                            }
                        };
                    }  
                });
            } else {
                await successConfirmation("Warning", "Amounts Increased", "warning")
                setWalletBalance(allInvoices[method?.payment_method_id]?.amount)

            }

        } else if (value === '' || value === 0) {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{mb: 4}}>
                    <Typography sx={{}}>
                        Are you sure? you don't want to use Wallet Balance?
                    </Typography>
                    <Box sx={{mt: 3,}}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                handleCloseModal(method)
            } else {
                setWalletBalance(allInvoices[method?.payment_method_id]?.amount)
            }
        }
    }
    const handleChangeOption = async (e) => {
        const value = e?.target?.value
        if (parseFloat(getGrandTotal()) >= 0) {
            if (value === 'future') {
                if (Object.entries(allInvoices)?.length > 0) {
                    let confirm = await MySwal.fire({
                        icon: 'question',
                        title: 'Confirmation',
                        showConfirmButton: false,
                        html: <Box sx={{mb: 4}}>
                            <Typography sx={{}}>
                                Are you sure? You want to do Future Payment. Your selected method will Reset
                            </Typography>
                            <Box sx={{mt: 3,}}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '20px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.clickConfirm()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        px: '10px',
                                        mx: '10px',
                                        fontSize: '16px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    })
                    if (!!confirm?.isConfirmed) {
                        setFuturePayment(e.target.value)
                        setAllInvoices({"1": {amount: getGrandTotal(), payment_method_id: 1, name: "Future Payment"}})
                    }
                } else {
                    setFuturePayment(e.target.value)
                    setAllInvoices({"1": {amount: getGrandTotal(), payment_method_id: 1, name: "Future Payment"}})
                }
            } else {
                setFuturePayment(e.target.value)
                setAllInvoices({})
            }
        }
    }
 
    const handleBrainTree = async (data, method) => {
        setBrainTree(data)
        await handleInputChange(data?.amount, method, true)

    }
    const handleSquare = async (token, amount, method) => {
        if (parseFloat(amount) <= parseFloat(totalDue())) {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    return {
                        ...prevState,
                        [method?.id]: {amount: amount, payment_method_id: method?.id, name: method?.name, token: token}
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: {amount: amount, payment_method_id: method?.id, name: method?.name, token: token}
                    };
                }
            });
            handleCloseModal()

        } else {
            await successConfirmation("Warning", "Amount Increase", "warning")
        }
        // await handleInputChange(amount,method,true)

    }
    const handleLabelsInputChange = async (value, target, method, bookingType) => {
        if (target === 'Amount' || target === 'amount') {
            if (!isNaN(value) && value.trim() !== '') {
                const floatValue = parseFloat(value);
                // if (parseFloat(totalDue()) === 0 || parseFloat(totalDue()) > floatValue){
                // if (floatValue <= parseFloat(floatValue)) {
                setAllInvoices(prevState => {
                    if (prevState[method?.id]) {
                        let prevMethod = prevState[method?.id] || {};
                        return {
                            ...prevState,
                            [method?.id]: { ...prevMethod, [target]: floatValue }
                        };
                    }
                })
                // }
                // }
            } else if (value === '') {
                setAllInvoices(prevState => {
                    if (prevState[method?.id]) {
                        let prevMethod = prevState[method?.id] || {};
                        return {
                            ...prevState,
                            [method?.id]: { ...prevMethod, [target]: value }
                        };
                    }
                })
            }
        } else {
            setAllInvoices(prevState => {
                if (prevState[method?.id]) {
                    let prevMethod = prevState[method?.id] || {};
                    return {
                        ...prevState,
                        [method?.id]: { ...prevMethod, [target]: value }
                    };
                } else {
                    return {
                        ...prevState,
                        [method?.id]: { amount: value, payment_method_id: method?.id, name: method?.name }
                    };
                }
            });
        }
    }
    

    

    const handleConfirm = async (invoice, method) => {
        let errorMessage = '';
        let isValuesMissing = false;
    
        // Check if all required fields are filled
        Object.entries(invoice).forEach(([key, value]) => {
            if (key !== 'name' && key !== "payment_method_id" && !value.toString().length) {
                isValuesMissing = true;
                errorMessage = 'All fields required.';
            }    
        });
    
        // Specific check for E-Transfer/CC (External Device) method  
        if (method?.name === "E-Transfer/CC (External Device)") {
            // Check if transaction ID is provided for E-Transfer/CC
            if (!invoice.transactionId || invoice.transactionId.trim() === '') {
                isValuesMissing = true;
                errorMessage = 'Transaction ID field is required.';
            }
        }
    
        // Perform the rest of the validation only if all required fields are filled
        if (!isValuesMissing) {
            if (parseFloat(invoice?.amount) >= 0) {
                // Your amount validation logic here
                if (parseFloat(totalDue()) === 0 || parseFloat(invoice?.amount) <= (parseFloat(totalDue()) + parseFloat(invoice?.amount))) {
                    handleCloseModal();
                } else {
                    errorMessage = 'Invalid Amount.';  
                }
            } else {
                errorMessage = 'Amount field is required.';
            }
        }
        if (errorMessage) {
            await successConfirmation("Error", errorMessage, 'error');
            return;
        } 
    };

    const isValidDate = (date) => {
        const dateFormat = 'YYYY-MM-DD';
        const parsedDate = moment(date, dateFormat, true);

        return parsedDate.isValid() && parsedDate.year() > 2000;
    };  

    const handleBookAppointment = async () => {

        if (invoiceDate !== '' && isValidDate(invoiceDate) === false) {
            await successConfirmation("Error", 'Invalid Date', 'error')
            return
        }
        let plan = appointmentDetails?.plan_id == null ? 0 : 1;

        let current_session;
        let total_session;

        if (plan === 1) {
            current_session = appointmentDetails?.current_session;
            total_session = appointmentDetails?.plan?.no_of_bookings;
        }

        if (current_session === total_session) {
            plan = 0;
        }
        if (parseFloat(totalDue()) === 0 || plan === 1) {


            setLoading(true)

            let finalInvoices = []
            finalInvoices = Object.values(allInvoices);
            if (futurePayment === 'now') {

                if (allInvoices['3'] && customer?.id) {

                    let finalData = {
                        user_id: customer?.id,
                        number: brainTree?.number,
                        expirationDate: brainTree?.expirationDate,
                        cvv: brainTree?.cvv,
                    }

                    if (brainTree?.token) {
                        let idToUpdate = 3;
                        let newProperty = {"token": brainTree?.token};

                        finalInvoices = finalInvoices.map(item => {
                            if (item.payment_method_id === idToUpdate) {
                                return {...item, ...newProperty};
                            }
                            return item;
                        });
                    } else {


                        let res = await dispatch(crmCreateCard(finalData))

                        if (res?.payload?.data?.token) {

                            let idToUpdate = 3;
                            let newProperty = {"token": res?.payload?.data?.token};

                            finalInvoices = finalInvoices.map(item => {
                                if (item.payment_method_id === idToUpdate) {
                                    return {...item, ...newProperty};
                                }
                                return item;
                            });
                        }
                    }
                }
            }

            let finalPayload = {
                service_ids: selectedServices,
                isDiscountInPercentage: !!isDiscount === 'percentage',
                discount: getTotalDiscount(),
                tax: getTotalTex(),
                is_email_send:apiValue,
                booking_id: appointmentDetails?.id,
                tip: getTotalTip(),
                invoices: finalInvoices,
            }

            if (appointmentType === "checkout") {
                finalPayload['status'] = 'CheckOut'
            } else {

            }

            let appointmentResult

            appointmentResult = await dispatch(crmCheckoutAppointment(finalPayload))


            setLoading(false)

            if (appointmentResult?.payload?.success === 'true') {

                let confirm = await MySwal.fire({
                    icon: 'success',
                    title: 'Confirmation',
                    showConfirmButton: false,
                    html: <Box sx={{mb: 4}}>
                        <Typography sx={{}}>
                            {appointmentType === "checkout" ?
                                "Your Sale has been Confirmed and checkout successfully"
                                : "Your Invoice has been updated Successfully"

                            }
                        </Typography>
                        <Typography sx={{}}>
                            Do you want to see invoice?
                        </Typography>
                        <Box sx={{mt: 3,}}>
                            <Button
                                sx={{
                                    backgroundColor: "#fff",
                                    minWidth: "max-content",
                                    border: "1px solid #666666",
                                    borderRadius: '10px',
                                    fontSize: '16px',
                                    px: '20px',
                                    mx: '10px',
                                    color: "#666666",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        border: "1px solid #666666",
                                    },
                                }}
                                onClick={() => MySwal.clickConfirm()}
                            >
                                Yes
                            </Button>
                            <Button  
                                sx={{
                                    backgroundColor: "#fff",
                                    minWidth: "max-content",
                                    border: "1px solid #666666",
                                    borderRadius: '10px',
                                    px: '20px',
                                    mx: '10px',
                                    fontSize: '16px',
                                    color: "#666666",
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        border: "1px solid #666666",
                                    },
                                }}
                                onClick={() => MySwal.close()}  
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                })
                if (!!confirm?.isConfirmed) {
                    navigate(`/crm/invoices/${appointmentResult?.payload?.data?.id}`)
                } else {
                    navigate('/crm/appointments')
                }
            } else {
                const errors = appointmentResult?.payload?.errors
                if (errors?.length > 0) {
                    await MySwal.fire({
                        icon: 'error',
                        title: 'Error', 
                        showConfirmButton: false,
                        html: <Box sx={{mb: 4}}> 
                            {errors.map((item, index) => (
                                <Typography key={index}>
                                    {item}
                                </Typography>   
                            ))}
                            <Box sx={{mt: 3,}}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '30px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {  
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    OK  
                                </Button>
                            </Box>
                        </Box>
                    })
                }
            }
        } else {
            await successConfirmation('Invalid', 'Invalid amount or something went wrong.Please Review all prices again or reset payment ways', 'error')
            // setAllInvoices({})
            // setFuturePayment('reset')
        }
    }


    return (
        <Grid
            container
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Grid
                item
                xs={12}
                sx={{mb: '24px', display:"flex",}}
                
            >
            {/* <CustomBreadcrumbs
                        back={'/crm/calender'}
                           backLabel={'Calendar'}
                        name={ "Appointment Checkout" || null}
                    /> */} 
    <CustomBreadcrumbs
    back={appointmentType === 'checkout' ? '/crm/calender' : '/crm/point-of-sale'}
    backLabel={appointmentType === 'checkout' ? 'Calendar' : 'Pos'}
    name={appointmentType === 'checkout' ? 'Appointment Checkout' : customer?.name}
/>

                <Typography
                    sx={{
                        ...bookingcontent,
                        textAlign: 'center',
                        fontSize: "24px",
                        ml:"40px"
                   
                    }}  
                >
                    {appointmentType === "checkout" ? "" : 'Edit Invoice'}
                </Typography>  
            </Grid> 
            <Grid item xs={12} lg={2}>
                {loading ? <Waveloading fullSize/> : ""}
                <Box>
                    <FormControl sx={{mb: '16px'}}>
                        <CustomButton
                            handleClick={() => setAddExtraService(true)}
                            mainColor={'#27AE60'}
                            val={"Add Extra Service"}
                        />

                        <CustomModal
                            open={addExtraService}  
                            setOpen={()=>setAddExtraService(false)}
                            customWidth={"800px"}   
                        >
                            <AddServices onClose={handleServiceAdded} clinicId={authStore?.user?.id}/>
                        </CustomModal>
<Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
 <Autocomplete
  disablePortal
  id="service-category-selector"
  options={servicesCategory}  
  getOptionLabel={(option) => option.category_name}
  sx={{ width: '100%' }}  
  renderInput={(params) => <TextField {...params} label="Service Categories:" />}
  onChange={(event, newValue) => {
    if (newValue) {
      handleBookingTypeChange('service');
      handleGetServices(newValue); 
      scrollToServiceCategoryContainer();
    }
  }}  
/>

</Box>
<Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
<Autocomplete
  disablePortal
  id="plan-selector"
  options={crmAllPlans} 
  getOptionLabel={(option) => option.plan_name} 
  sx={{ width: '100%' }}
  renderInput={(params) => <TextField {...params} label="Bundle:" />}  
  onChange={(event, newValue) => {
    if (newValue) {
    
      handleBookingTypeChange('plan');
     
      scrollToServiceCategoryContainer();
    }
  }}    
/>
  </Box>
                        <List
                            sx={{
                                // mt: '24px',
                                width: "100%",
                                ".MuiListItemIcon-root ": {
                                    minWidth: "37px",
                                },
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton
                                sx={{
                                    py: '0',
                                }}
                                onClick={() => handleBookingTypeChange('service')}
                            >
                                <ListItemText primary="Service Categories:"/>
                                <Checkbox
                                    checked={bookingType === 'service'}
                                />
                            </ListItemButton>
                            <Collapse in={bookingType === 'service'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {servicesCategory?.length > 0 && servicesCategory.map((category, index) => (
                                        category?.is_show === 1 && (  
                                        <ListItemButton
                                            sx={{py: '0', pl: '30px'}}
                                            key={index}
                                            onClick={() => {
                                                handleGetServices(category);
                                                scrollToServiceCategoryContainer();
                                              }}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={selectedCategory?.category_id === category?.category_id}
                                                />}
                                                label={category?.category_name}
                                            />
                                        </ListItemButton>
                                        )
                                    ))}
                                </List>
                            </Collapse> 
                            {/*<ListItemButton*/}
                            {/*    sx={{*/}
                            {/*        py: '0',*/}
                            {/*    }}*/}
                            {/*    onClick={() => handleBookingTypeChange('plan')}*/}  
                            {/*>*/}
                            {/*    <ListItemText primary="Plan:"/>*/}
                            {/*    <Checkbox*/}
                            {/*        checked={bookingType === 'plan'}*/}
                            {/*    />*/}
                            {/*</ListItemButton>*/}
                        </List>
                    </FormControl>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                lg={6}
                sx={{background: '#fff', p: '24px', borderRadius: '4px'}}
            > 
                {
                    isExtra ?
                        <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Grid item xs={12} lg={5.5}>  
                                <FormControl fullWidth sx={{mb: "16px"}}>
                                    <FormLabel style={{fontsize: '6px'}}>
                                        Add Service Name
                                    </FormLabel>
                                    <TextField
                                        fullWidth
                                        name=""
                                        sx={inputStyle}
                                        placeholder=""
                                    />  
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={5.5}>
                                <FormAutoComplete
                                    name={"time_duration"}
                                    options={appointmentTime()}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    label={'Service Duration Time'}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5.5}>
                                <FormControl fullWidth sx={{mb: "16px"}}>
                                    <FormLabel style={{fontsize: '10px'}}>Price</FormLabel>
                                    <TextField
                                        fullWidth
                                        label=''
                                        sx={inputStyle}
                                        placeholder=""
                                    />

                                </FormControl>
                            </Grid>
                            <Grid item xs={12}
                                  sx={{display: "flex", gap: '20px', justifyContent: 'flex-end', alignItem: 'center'}}>
                                <CustomButton
                                    mainColor={"#ED1E45"}
                                    val={"Cancel"}
                                    handleClick={() => setIsExtra(false)}
                                />
                                <SubmitButton val={'Save'}/>
                            </Grid>
                        </Grid>
                        :
                        <>
                      
                        <Grid container style={{marginTop: '20px',}} ref={serviceCategoryContainerRef}>
                            <Grid item xs={12} sx={customerside}>
                                <Typography sx={content}>
                                    Select service from the menu below
                                </Typography>
                                <FormControl sx={{mr: "10px", ml: "10px"}}>
                                    <SearchField
                                        totalData={bookingType === 'plan' ? crmAllPlans : selectedCategory?.services}
                                        setFilterData={setFilteredData}
                                        setPage={setServicePage} 
                                        // isServices
                                        isServices={bookingType !== 'plan'} // Assuming crmAllPlans is not services data
                                        allServicesCategories={servicesCategory}
                                    />
                                </FormControl>
                            </Grid>
                            </Grid>


                            <Grid item xs={12} ref={serviceCategoryContainerRef}>
                                <Grid container sx={wrappercard} style={{marginTop: '20px', gap: '20px', alignItems:{xs:'center',sm:'start'}, display:'flex', justifyContent:'center'}}>

                            
                            {
                                filteredData?.length > 0 && filteredData?.slice((servicePage - 1) * serviceItemsPerPage, servicePage * serviceItemsPerPage)
                                    ?.map((service, index) => (
                                        <Grid
                                            key={index}
                                            item
                                            sx={{width: '215px', cursor: 'pointer'}}
                                            onClick={() => handleSelectData(service)}
                                        >
                                            <Checkbox
                                                checked={isExist(selectedServices, service)}
                                                sx={{
                                                    top: "3px",
                                                    zIndex: '1'
                                                }}
                                            />
                                            <Card sx={{mt: '-40px', height: '100%'}}>
                                                <Avatar
                                                    src={service?.image}
                                                    sx={{
                                                        width: "215px",
                                                        height: "125px",
                                                    }}
                                                    variant={'rounded'}
                                                />
                                                <Box
                                                    sx={{
                                                        p: '8px',
                                                        // display: "flex",
                                                        // flexDirection: "column",
                                                        // justifyContent: "space-between",
                                                        // height: "50%",
                                                    }}>
                                                    <Typography style={{
                                                        fontSize: '13.7px',
                                                        fontWeight: '700',
                                                        fontFamily: 'helvetica-lt-std-bold',
                                                        textAlign: 'center'
                                                    }}>
                                                        {service?.name || service?.plan_name}
                                                    </Typography>
                                                    <Box
                                                        sx={{display: 'flex', justifyContent: 'center', mt: '8px'}}>
                                                        <div style={{textAlign: 'center'}}>
                                                            <Typography sx={{fontSize: '12px'}}>Regular Price</Typography>
                                                            <Typography style={{
                                                                fontSize: '17px',
                                                                fontWeight: '700',
                                                                fontFamily: 'helvetica-lt-std-bold',
                                                                color: customer?.is_membered !== 1 ? '#04B4FF' : '#000'
                                                            }}>
                                                                ${service?.regular_price}
                                                            </Typography>
                                                        </div>
                                                        <Box sx={{p: '2px 10px'}}>
                                                            <Divider
                                                                orientation='vertical'
                                                                style={{
                                                                    borderColor: '#8D8D8D',
                                                                }}
                                                            />
                                                        </Box>
                                                        <div style={{textAlign: 'center'}}>
                                                            <Typography sx={{fontSize: '12px'}}>
                                                                Member’s Price
                                                            </Typography>
                                                            <Typography style={{
                                                                fontSize: '17px',
                                                                fontWeight: '700',
                                                                fontFamily: 'helvetica-lt-std-bold',
                                                                color: customer?.is_membered === 1 ? '#04B4FF' : '#000'
                                                            }}>
                                                                ${service?.member_price}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    ))
                            }
                            {filteredData?.length > serviceItemsPerPage &&
                                <Grid item xs={12}
                                      sx={{display: 'flex', justifyContent: 'center', mt: '40px'}}>
                                    <CustomPagination   
                                        totalData={filteredData}
                                        page={servicePage}
                                        setPage={setServicePage}
                                        itemsPerPage={serviceItemsPerPage}/>
                                </Grid>
                            }
                            </Grid>
                            </Grid>
                            </>
                }
           </Grid>
            <Grid sx={booking} item xs={12} lg={3.8} sm={10} md={10}>
                <Card sx={{p: 2}}>
                    <Box sx={bookingwrapper}>
                        <Typography sx={{boxShadow: 'lg', ...bookingcontent}}>Booking Details</Typography>
                        {customer?.id &&
                            <Typography sx={walletcontent}>
                                Wallet Balance: ${customer?.wallet_point || "0.00"}
                            </Typography>
                        }
                    </Box>
                    <Box sx={bookingCheckoutwrapper}>
                        <Typography sx={walletcontent}>{customer?.name}</Typography>
                    </Box>
                    <Box sx={bookingCheckoutwrapper}>
                        <Typography sx={walletcontent}>{customer?.email}</Typography>
                    </Box>
                    {customer?.id && customer.outstanding_balance > 0 && (
                        <Typography sx={contentstatus} style={{ color: "red" }}>
                            Outstanding Balance: ${parseFloat(customer.outstanding_balance).toFixed(2)}
                        </Typography>
                    )}
                    <Box sx={{background: "#EDF2F7", mt: 2, p: '12px', borderRadius: '4px'}}>
                        {
                            selectedServices.map((service, index) => (
                                <Card sx={{mb: 2, p: 2}} key={index}>
                                    <Box sx={{...bookingwrapper, alignItems: 'flex-start'}}>
                                        <Box sx={{display: 'flex', gap: '5px'}}>
                                   
                                            <Typography
                                                sx={{
                                                    ...treatmentcontent,
                                                    // ml:service?.qty_enable === 1 ? '16px' : ''
                                                }}
                                            >
                                                {service?.name}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Input
                                            type='text'
                                            inputMode='decimal'
                                                startAdornment={
                                                    <InputAdornment
                                                        position="start"
                                                    >
                                                        {service?.is_free === 1 ? "" : "$"}
                                                    </InputAdornment>
                                                }
                                                sx={{
                                                    ...inputStyle,
                                                    width: '80px',
                                                    p: '5px',
                                                    '&::before': {
                                                        content: 'none',
                                                    },
                                                }}
                                                disabled={bookingType === 'service' && service?.qty_enable === 1} // Correctly placed disable logic
                                                value={getServicePrice(service)}
                                                onChange={(e) => handleUpdatePrice(e, service)}
                                            />

                                        </Box>
                                        <Box style={{marginLeft:"3px"}}>   
                                        <ClearIcon style={{color:"red"}} onClick={() => handleRemoveService(service)} />
                                        </Box>
                                       
                                    </Box>
                                    {bookingType === 'service' && service?.qty_enable === 1 &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '20px',
                                                flexWrap:'wrap'
                                            }}
                                        >
                                            <Autocomplete
                                                sx={{
                                                    mt: 2,
                                                    width: '150px',
                                                    minWidth: '150px',
                                                }}
                                                options={products}
                                                value={service?.product}
                                                disableCloseOnSelect={false}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option?.id === value?.id
                                                }}
                                                getOptionLabel={(option) => option?.name || ''}
                                                renderInput={(params) => {
                                                    const {inputProps, helperText, error, ...rest} = params;
                                                    return (
                                                        <TextField
                                                            {...rest}
                                                            inputProps={{...inputProps}}
                                                            placeholder={"Select Product"}
                                                            sx={{...inputStyle}}
                                                        />
                                                    )
                                                }}
                                                onChange={(event, newValue) => {
                                                    let arr = [...selectedServices]
                                                    let index = arr.findIndex(item => item.id === service?.id);
                                                    if (index !== -1) {
                                                        arr[index].product = newValue
                                                        arr[index].extra_quantity = 0
                                                        setSelectedServices(arr)
                                                    }
                                                }}
                                                renderOption={(props, option, {selected}) => (
                                                    <MenuItem  {...props} key={option?.id}>
                                                        <Checkbox
                                                            style={{marginRight: 8}}
                                                            checked={selected}
                                                        />
                                                        {option?.name}
                                                    </MenuItem>
                                                )}
                                            />
                                            {service?.product?.id && <Box
                                                sx={{
                                                    display:'flex',
                                                    justifyContent:'space-between',
                                                    alignItems:'flex-end',
                                                    width:"100%",
                                                }}
                                            >
                                                <Box>
                                                    <Typography>Quantity</Typography>
                                                    <Box sx={{display: 'flex', gap: '5px'}}>
                                                        <Box
                                                            sx={{
                                                                backgroundColor: '#EFEDED',
                                                                padding: '4px 12px',
                                                                height: '34px',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleUpdateQuantity(service, 'sub')}
                                                        >
                                                            <Typography sx={{alignItems: 'center'}}>-</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Input
                                                            type='text'
                                                                sx={{
                                                                    ...inputStyle,
                                                                    width: '50px',
                                                                    p: '5px',
                                                                    ".MuiInputBase-input": {
                                                                        height: "14px",
                                                                    },
                                                                    '&::before': {
                                                                        content: 'none',
                                                                    },
                                                                }}
                                                                value={service?.extra_quantity || 1}
                                                                onChange={(e) => handleServicesQuantity(e, service)}
                                                                onBlur={(e) => handleServicesQuantityBlur(e, service)}
                                                            />
                                                        </Box>
                                                       <Box
                                                            sx={{
                                                                backgroundColor: '#EFEDED',
                                                                padding: '4px 12px',
                                                                height: '34px',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleUpdateQuantity(service, 'add')}
                                                        >
                                                            <Typography sx={{alignItems: 'center'}}>+</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                    <Box>
                                                    <Typography>Unit Price</Typography>
                                                        <Box sx={{ display: 'flex', gap: '5px' }}>
                                                                <Input
                                                                    type='text'
                                                                    sx={{
                                                                        ...inputStyle,
                                                                        width: '50px',
                                                                        p: '5px',
                                                                        ".MuiInputBase-input": {
                                                                            height: "14px",
                                                                        },
                                                                        '&::before': {
                                                                            content: 'none',
                                                                        },
                                                                    }}
                                                                    value={service?.product?.selling_price}
                                                                    onChange={(e) => handleProductPrice(e, service)}
                                                                    onBlur={(e) => handleProductPriceBlur(e, service)}
                                                                />
{/*
                                                           // <Typography>× {service?.product?.selling_price}</Typography>
*/}
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                    <Input
                                                    type='text'
                                                        startAdornment={
                                                            <InputAdornment
                                                                position="start"
                                                            >
                                                                {"$"}
                                                            </InputAdornment>
                                                        }
                                                        sx={{
                                                            ...inputStyle,
                                                            width: '80px',
                                                            p: '5px',
                                                            '&::before': {
                                                                content: 'none',
                                                            },
                                                        }}
                                                        disabled
                                                        value={((parseFloat(service?.extra_quantity) || 1) * (parseFloat(service?.product?.selling_price) || 0))}
                                                        // onChange={(e) => handleUpdatePrice(e, service)}F
                                                    />
                                                </Box>
                                            </Box>}
                                        </Box>
                                    }
                                </Card>
                            ))}
                        <Card sx={{p: 2}}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '14px'}}>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={{fontFamily: 'helvetica-lt-std-bold'}}>Sub Total</Typography>
                                    <Typography sx={{fontFamily: 'helvetica-lt-std-bold'}}>
                                        ${getSubTotal()}
                                    </Typography>
                                </Box>
                                <Box sx={bookingwrapper}>
                                    <div style={{display: "flex", alignItems: 'center', gap: '40px'}}>
                                        <Typography>Discount</Typography>
                                        <RadioGroup
                                            row
                                            value={isDiscount}
                                            onChange={(e) => setIsDiscount(e?.target?.value)}
                                        >
                                            <FormControlLabel
                                                value="fix"
                                                size="small"
                                                control={<Radio/>}
                                                label="Fix"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />
                                            <FormControlLabel
                                                value="percentage"
                                                size="small"
                                                control={<Radio/>}
                                                label="%"
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 16,
                                                    },
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                    <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                        <Input
                                            type='text'
                                            startAdornment={<InputAdornment position="start">
                                                {isDiscount === 'fix' ? "$" : ""}
                                            </InputAdornment>}
                                            endAdornment={<InputAdornment position="end">
                                                {isDiscount !== 'fix' ? "%" : ""}
                                            </InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                maxWidth: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={discount}
                                            onChange={(e) => handleDiscountChange(e)}
                                        />
                                        <Typography
                                            sx={bookingwrapper}
                                        >
                                            {isDiscount === 'fix' ? "$" : ""}{getTotalDiscount()}{isDiscount !== 'fix' ? "%" : ""}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Tax {isTaxPercentage ? "in %" : ""}</Typography>
                                    <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                        <Input
                                        type='text'
                                            startAdornment={<InputAdornment position="start">
                                                {isTaxPercentage ? "" : "$"}
                                            </InputAdornment>}
                                            endAdornment={<InputAdornment position="end">
                                                {isTaxPercentage ? "%" : ""}
                                            </InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                width: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={tax}
                                            onChange={handleTaxChange}
                                        />
                                        <Typography sx={bookingwrapper}>${getTotalTex()}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Tip</Typography>
                                    <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                        <Input
                                        type='text'
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            sx={{
                                                ...inputStyle,
                                                width: '80px',
                                                p: '5px',
                                                '&::before': {
                                                    content: 'none',
                                                },
                                            }}
                                            value={tip}
                                            onChange={(e) => handleTipChange(e)}
                                        />
                                        <Typography sx={bookingwrapper}>${getTotalTip()}</Typography>
                                    </Box>
                                </Box>
                                <Divider/>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={bookingwrapper}>Grand Total</Typography>
                                    <Typography sx={{
                                        fontSize: "22px",
                                        fontFamily: 'helvetica-lt-std-bold',
                                        fontWeight: '700'
                                    }}>
                                        ${getGrandTotal()}
                                    </Typography>
                                </Box>
                                {parseFloat(getTotalPaidAmount()) > 0 &&
                                    <Box sx={bookingwrapper}>
                                        <Typography sx={bookingwrapper}>Paid Amount</Typography>
                                        <Typography sx={{
                                            fontSize: "22px",
                                            fontFamily: 'helvetica-lt-std-bold',
                                            fontWeight: '700'
                                        }}>
                                            - ${getTotalPaidAmount()}
                                        </Typography>
                                    </Box>}
                                {Object.entries(allInvoices)?.length > 0 &&
                                    Object.entries(allInvoices).map(([key, value], index) => (
                                        <Box sx={bookingwrapper} key={index}>
                                            <Typography sx={bookingwrapper}>{value?.name}</Typography>
                                            <Box sx={{display: 'flex', alignItems: "center", gap: '20px'}}>
                                                {value?.payment_method_id === 2 && <Input
                                                type='text'
                                                    startAdornment={<InputAdornment
                                                        position="start">$</InputAdornment>}
                                                    sx={{
                                                        ...inputStyle,
                                                        width: '80px',
                                                        p: '5px',
                                                        '&::before': {
                                                            content: 'none',
                                                        },
                                                    }}
                                                    onBlur={() => handleSaveWallet(walletBalance, value)}
                                                    value={walletBalance}
                                                    onChange={(e) => handleWalletChange(e)}
                                                />}
                                                <Typography sx={bookingwrapper}>-${value?.amount}</Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                                <Divider/>
                                <Box sx={bookingwrapper}>
                                    <Typography sx={{...bookingwrapper, fontFamily: 'helvetica-lt-std-bold'}}>
                                        Due Amount
                                    </Typography>
                                    <Typography
                                        sx={{
                                            ...bookingwrapper,
                                            fontFamily: 'helvetica-lt-std-bold',
                                        }}
                                    >
                                        ${totalDue()}
                                    </Typography>
                                </Box>
                                {appointmentDetails?.payment_status === 'Paid' && parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount()) &&
                                    <Divider/>}
                               {/* {appointmentDetails?.payment_status === 'Paid' && parseFloat(getGrandTotal()) < parseFloat(getTotalPaidAmount()) &&
                                    <Box sx={bookingwrapper}>
                                        <Typography sx={{
                                            ...bookingwrapper,
                                            fontFamily: 'helvetica-lt-std-bold',
                                            color: '#27AE60',
                                        }}>
                                            Wallet Point will add
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...bookingwrapper,
                                                fontFamily: 'helvetica-lt-std-bold',
                                                color: '#27AE60',
                                            }}
                                        >
                                            ${(parseFloat(getTotalPaidAmount()) - parseFloat(getGrandTotal())).toFixed(2)}
                                        </Typography>
                                    </Box>
                                }*/}
                            </div>
                        </Card>
                        <Card sx={{mt: 2, minHeight: '100px'}} ref={mainDivRef}>
                            <Box
                                sx={{
                                    px: 2,
                                    pt: 2,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FormControl>
                                    <FormLabel sx={{...labelStyle, mb: '5px'}}>Select Your Payment Method</FormLabel>
                                    <RadioGroup
                                        defaultValue="now"
                                        row
                                        value={futurePayment}
                                        onChange={handleChangeOption}
                                     
                                    >
                                        <FormControlLabel
                                            value="now"
                                            size="small"
                                            control={<Radio/>}
                                            label="Pay Now"
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {Object.entries(allInvoices)?.length > 0 && futurePayment !== 'future' && <Box
                                    sx={{
                                        mt: '-5px',
                                        mr: '-5px',
                                    }}
                                >
                                    <Tooltip title={"Reset Payment Ways"}>
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                setAllInvoices({})
                                            }}
                                        >
                                            <RestartAltIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>}
                            </Box>
                            {
                                futurePayment === "now" &&
                                <Box sx={{pb: 2}}>
                                    <Box sx={{p: "5px", background: '#EDF2F7'}}> </Box>
                                    <Box sx={{
                                        px: 2,
                                        pt: 1,
                                        display: 'flex',
                                        columnGap: '40px',
                                        flexWrap: 'wrap',
                                        alignItems: 'center'
                                    }}>
                                        {provider_payment?.length && provider_payment.map((method, index) => {
                                            if (method?.id !== 1) {
                                                if (customer?.id && parseFloat(customer?.wallet_point) > 0 && method?.id === 2) {
                                                    return <FormControlLabel
                                                        key={index}
                                                        value={method?.name}
                                                        size="small"
                                                        control={
                                                            <Checkbox
                                                                checked={!!allInvoices[method?.id]?.amount}
                                                                onChange={(e) => handleClickMethod(method, e)}
                                                            />
                                                        }
                                                        label={"Use Wallet"}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 20,
                                                            },
                                                        }}
                                                    />
                                                } else if (method?.id !== 2) {
                                                    return <FormControlLabel
                                                        key={index}
                                                        value={method?.name}
                                                        size="small"
                                                        control={
                                                            <Checkbox
                                                                checked={!!allInvoices[method?.id]?.amount}
                                                                onChange={(e) => handleClickMethod(method, e)}
                                                            />
                                                        }
                                                        label={method?.name}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 20,
                                                            },
                                                        }}
                                                    />
                                                }
                                            }
                                        })}
                                        {provider_payment?.length && provider_payment.map((method, index) => {
                                            if (paymentModal === method?.id) {
                                                return <Modal
                                                    key={index}
                                                    open={paymentModal === method?.id}
                                                >
                                                    <Box sx={modalStyle}>
                                                        {method?.is_gateway === 1 ?
                                                            <Grid container>
                                                                <Grid item xs={12} sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                    {useCard && <Box sx={{
                                                                        width: 'max-content',
                                                                        cursor: 'pointer'
                                                                    }} onClick={() => setUseCard(false)}>
                                                                        <img src={arrow} alt={'back-arrow'}/>
                                                                    </Box>}
                                                                    {method?.payment_gateway === 'BrainTree' && !useCard &&
                                                                        <Braintree style={{marginTop: '-8px'}}/>
                                                                    }
                                                                    {!useCard &&
                                                                        <Typography sx={{fontSize: '18px'}}>
                                                                            Setup Pay
                                                                        </Typography>
                                                                    }
                                                                    <IconButton
                                                                        size="small"
                                                                        sx={{p: '0'}}
                                                                        onClick={() => handleCloseModal(method)}
                                                                    >
                                                                        <Close/>
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {useCard ?
                                                                        // <UserCardsList
                                                                        //     userCards={userCards}
                                                                        //     setUseCard={setUseCard}
                                                                        //     handleBrainTree={handleBrainTree}
                                                                        //     handleSquare={handleSquare}
                                                                        //     paymentMethod={method}
                                                                        //     totalDue={totalDue()}
                                                                        // />
                                                                        ""
                                                                        :
                                                                        
                                                                        <CardPayment
                                                                            userCards={userCards}
                                                                            setUseCard={setUseCard}
                                                                            useCard={useCard}
                                                                            setLoading={setLoading}
                                                                            handleBrainTree={handleBrainTree}
                                                                            handleSquare={handleSquare}
                                                                            paymentMethod={method}
                                                                            totalDue={totalDue()}
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            : 
                                                            
                                                                method?.labels?.length > 0 ?
                                                                <Grid container sx={{alignItems: "center",}}>
                                                                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                        <IconButton size="small" onClick={() => handleCloseModal(method)}>
                                                                            <Close/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{mt: '-49px'}}>
                                                                        <Typography sx={{fontSize: "18px", textAlign: "center"}}>
                                                                            {"Enter " + method?.name}
                                                                        </Typography>
                                                                    </Grid>
                                                            
                                                                    {
                                                                        Object.entries(allInvoices[method?.id])?.length > 0 &&
                                                                        Object.entries(allInvoices[method?.id])
                                                                            .map(([key, value], index) => 
                                                                                key !== 'name' && key !== "payment_method_id" && key !== "transactionId" && key !== "institution_name" && key !== "approval_date" && key !== "approval_code" && ( // Exclude 'transactionId' from dynamic rendering
                                                                                    <Grid key={index} item xs={12} sx={{marginTop: '24px'}}>
                                                                                        <FormControl fullWidth>
                                                                                            <FormLabel sx={labelStyle}>{key}</FormLabel>
                                                                                            <TextField
                                                                                                value={value || ''}
                                                                                                fullWidth
                                                                                                onChange={(e) => handleLabelsInputChange(e?.target?.value, key, method)}
                                                                                                sx={{...inputStyle}}
                                                                                                placeholder={`Enter ${key}`}
                                                                                                type='number' // Change type to text
                                                                                                inputProps={{
                                                                                                    style: {
                                                                                                        'moz-appearance': 'textfield',
                                                                                                        'webkit-appearance': 'none', 
                                                                                                        'appearance': 'none', 
                                                                                                    },  
                                                                                                    step: 'any' 
                                                                                                }}
                                                                                            />
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                )
                                                                            )
                                                                    }
                                                            
                                                                    { // Handle 'Transaction Id' separately
                                                                        method?.name === "E-Transfer/CC (External Device)" && (
                                                                            <Grid item xs={12} sx={{marginTop: '24px'}}>
                                                                                <FormControl fullWidth>
                                                                                    <FormLabel sx={labelStyle}>Transaction Id</FormLabel>
                                                                                    <TextField
                                                                                        value={allInvoices[method?.id]?.transactionId || ''}
                                                                                        fullWidth
                                                                                        onChange={(e) => handleLabelsInputChange(e.target.value, 'transactionId', method)} // Handle change specifically for 'transactionId'
                                                                                        sx={{...inputStyle}}
                                                                                        placeholder="Enter Transaction Id"
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                      {
                                                                            method?.name === "3rd Party Financing" && (
                                                                                <>
                                                                                <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth>
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Institution Name
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            value={allInvoices[method?.id]?.institution_name || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'institution_name', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                            placeholder="Enter Institution Name"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                               <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth>
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Approval Date
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            value={allInvoices[method?.id]?.approval_date || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'approval_date', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                             type='date'
                                                                                            placeholder="Enter Approval Date"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                               <Grid item xs={12} sx={{ marginTop: '24px' }}>
                                                                                    <FormControl fullWidth>
                                                                                        <FormLabel sx={labelStyle}>
                                                                                            Approval Code
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            value={allInvoices[method?.id]?.approval_code || ''}
                                                                                            fullWidth
                                                                                            onChange={(e) => handleLabelsInputChange(e.target.value, 'approval_code', method, bookingType)}
                                                                                            sx={{ ...inputStyle }}
                                                                                           
                                                                                            placeholder="Enter Approval Code"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                            
                                                                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
                                                                        <CustomButton handleClick={() => handleConfirm(allInvoices[method?.id], method)} val={"Confirm"}/>
                                                                    </Grid>
                                                                </Grid>
                                                            
                                                            
                                                                :
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    sx={{alignItems: "center",}}
                                                                >
                                                                    <Grid item>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "18px",
                                                                                textAlign: "center"
                                                                            }}
                                                                        >
                                                                            Payment Method Not configure. Contact your
                                                                            provider
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                    </Box>
                                                </Modal>
                                            }
                                        })}
                                    </Box>
                                    <Box sx={{display:"flex",gap:"3",marginLeft:"19px", marginTop:"10px"}}>
                                              <Box>
                                              <FormControlLabel
                                                          
                                                            size="small"      
                                                            control={
                                                                <Checkbox   
                                                                checked={apiValue === 1}
                                                                onChange={handleCheckboxChange}
                                                                />
                                                            }         
                                                            label={"Send Notification"}  
                                                            sx={{   
                                                               '& .MuiSvgIcon-root': {  
                                                                    fontSize: 20,  
                                                                      },
                                                            }}
                                                        />  
                                            </Box>
                                            </Box>
 
                                    <Box sx={{marginTop: "10px", px: 2,}}>
                                        {appointmentType !== "checkout" && <Box>
                                            <FormControl fullWidth sx={{mb: "16px"}}>
                                                <FormLabel sx={labelStyle}>Invoice Date (Optional)</FormLabel>
                                                <TextField
                                                    fullWidth
                                                    sx={inputStyle}
                                                    type={'date'}
                                                    placeholder={"Select Invoice Date"}
                                                    value={invoiceDate}
                                                    onChange={(e) => setInvoiceDate(e?.target?.value)}
                                                />
                                            </FormControl>
                                        </Box>}
                                        {!appointmentDetails?.is_milestone_added &&
                                            <Box>
                                                <Typography sx={{ color: 'red', fontSize: "12px", marginBottom:"15px"}}>
                                                    Note: Please make sure to upload the client's before and after images.
                                                    {/* <a href="#"  style={{  textDecoration: 'underline' }}>
                                                        Upload!
                                                    </a> */}
                                                    <AddAttachmentcheckout appointmentDetails={appointmentDetails}   />
                                                </Typography>
                                            </Box>      
                                        }
                                        <CustomButton size={'large'} mainColor={'#27AE60'}
                                                style={{width:"200px", "&:hover": {
                                                borderColor:"#27AE60",
                                                },}}
                                            handleClick={handleBookAppointment}

                                            val={appointmentType === "checkout" ? ' and Checkout' : "Confirm and Save Invoice"}
                                        />
                                    </Box>
                                </Box>
                            }
                        </Card>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
};

export default AppointmentCheckout;