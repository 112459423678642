import React, {useEffect, useState} from 'react';
import ConfigurationLayout from "../configurationLayout";
import {Box, FormControl, Grid} from "@mui/material";
import SimpleInput from "../../../components/form/simpleInput";
import CustomButton from "../../../components/custom-button/customButton";
import Loader from "../../../components/loader/loader";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllMembership} from "../../../store/super-admin/membership";
import TableData from '../../../components/table/TableData';

const Membership = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allMembership = useSelector((state) => state?.membershipStore?.memberships);


    useEffect(() => {
        (async () => {
            if (!allMembership?.length) {
                setLoading(true);
                await dispatch(getAllMembership());
                setLoading(false);
            }
        })();
    }, []);


    console.log("membership plan",allMembership)

    const roleHead = [
        { value: "Membership Name", key: "plan_title" },
        { value: "Plan Price", key: "plan_price" },
        { value: "Wallet Point", key: "wallet_point" },
        { value: "Status", key: "status" },
        { value: "Action", key: "action" },
    ];

    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/admin/configuration/edit-membership/${row?.id}`);
        }
    };

    const handleClick = () => {
        navigate("/admin/configuration/add-membership");
    };

    return (
        <ConfigurationLayout>
            <Grid container p={"20px"}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: "16px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        flexWrap:'wrap',
                        '@media (max-width: 520px)' :{
                            display:'flex',
                            justifyContent:'flex-start'
                           },
                    }}
                >
                    <FormControl sx={{ mr: "10px" }}>
                        <SimpleInput search={search} setSearch={setSearch} />
                    </FormControl>
                    <CustomButton
                    mrt={"10px"}
                        val={"Add New Membership Plan"}
                        size={"large"}
                        handleClick={handleClick}
                    />
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(100vh - 225px)" }}>
                    {loading ? (
                      <Box
                      sx={{ 
                          display: "flex", 
                          justifyContent: "center", 
                          alignItems: "center", 
                          height: "60vh" 
                      }}
                  >
                      <Loader />
                  </Box>
                    ) : (
                        <TableData
                            headRow={roleHead}
                            tableData={allMembership}
                            search={search}
                            isStatus={true}
                            handleEdit={handleEdit}
                            type={"membership"}
                        />
                    )}
                </Grid>
            </Grid>
        </ConfigurationLayout>
    );
};

export default Membership;