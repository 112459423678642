import React, { useEffect, useState } from "react";
import PublicLayout from "../../layout/PublicLayout";
import "./styles.scss";
import PublicHeader from "../../components/public-header";
import PublicFooter from "../../components/public-footer";
import FormInput, {inputStyle, labelStyle} from "../../components/form/formInput";
import FormPasswordInput from "../../components/form/formPasswordInput";
import ReCAPTCHA from 'react-google-recaptcha';
import FormButton from "../../components/form/formButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPublicClinic } from "../../store/public";
import FromDropdown from "../../components/form/fromDropdown";
import {Box, CardMedia, FormControl, FormLabel, TextField, Typography} from "@mui/material";
import { registerUser } from "../../store/auth";
import { useLocation, useNavigate } from "react-router";
import toast from "react-hot-toast";
import Loader from "../../components/loader/loader";
import FormInputPhone from "../../components/form/formInputPhone";
import {  useParams } from "react-router";
import homeoutline from '../../assets/images/homeoutline.png'
import booking from "../../assets/images/Vector (Stroke).png";
import menu from '../../assets/images/homeIcon.png'
import plan from "../../assets/images/wallet2.png";
import profile from "../../assets/images/user.png";
import Footer from "../home/Footer";


const Register = () => {
  const [loading, setLoading] = useState(false);
  const [isRefferal, setIsRefferal] = useState(false);

  const allClinics = useSelector((state) => state.publicStore.clinics);
const location = useLocation();
const localStorageKey = "selectedLocation";

const savedLocationString = localStorage.getItem(localStorageKey); 
const savedLocation = JSON.parse(savedLocationString);    



  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams()

  const handleHome = () => {
    navigate("/home");
  }; 

  const initialValues = {
    name: "",
    default_provider_id: "",
    email: "",    
    phone: "",
    countryCode:'+1',
    social_login: 0, 
    postal_code: "",
    password: "",
  };

  const validationSchema = Yup.object({    
    name: Yup.string().required(
      "Name Is Required, The Name Must Not Contain Only Whitespace"
    ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email Is Required"),
    postal_code: Yup.string().required("Postal Code Is Required"),
/*
    postal_code: Yup.string().matches(/^(?:\d{5}|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/, "Postal code is invalid").required("Postal Code Is Required"),
*/
    phone: Yup.string().when('countryCode', {
      is: '+92',
      then: Yup.string()
          .matches(/^[1-9][0-9]\d{8}$/, 'Invalid pakistani phone number')
          .required('Phone number is required'),
      otherwise: Yup.string()
          .matches(/^(\d{3}-?)?\d{3}-?\d{4}$/, 'Invalid canada phone number')
          .required('Phone number is required')
    }),
    countryCode: Yup.string().required("Country code is required"),
    password: Yup.string()
      // .min(8, "Password must be 8 characters long")
/*      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")*/
      // .matches(/[^\w]/, 'Password requires a symbol')
      .required("Password is Required"),
      default_provider_id: Yup.number()
      .when([], {
        is: () => !savedLocation?.id, // If savedLocation.id does not exist
        then: Yup.number().required("Clinic is Required"), // Add required validation
        otherwise: Yup.number(), // No validation when savedLocation.id exists
      }),
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(fetchAllPublicClinic());
      setLoading(false);
    })();
  }, [dispatch]);

  const onSubmit = async (
    data,
    { setSubmitting, setFieldError, setFieldTouched }
  ) => {
    setLoading(true);

    let finalData = {
      name: data?.name,
      default_provider_id: data?.default_provider_id || savedLocation?.id, 
      email: data?.email,
      phone: data?.countryCode+data?.phone,
      social_login: 0,
      postal_code: data?.postal_code, 
      password: data?.password,
    }
    console.log("data in paylod", finalData)
    if (params?.referral_code){
      finalData["referral_code"] = params?.referral_code
    }
    let res = await dispatch(registerUser(finalData));
    setLoading(false);
    if (res?.payload?.errors) {
      const errors = res?.payload?.errors;
      for (const [key, value] of Object.entries(errors)) {

        setFieldTouched(key, true);
        setFieldError(key, value);
      }
    }
    if (res?.payload?.data?.success) {
      toast.success(res?.payload?.data?.message, { duration: 4000 });
      localStorage.setItem("registeredEmail", data?.email);
      navigate("/verification");
    }
    setSubmitting(false);
  };

 
  return (
    <PublicLayout isRegister={true}>
      {loading ? <Loader fullSize/>:""}
        <div className={"register-card register-container"}>
          <PublicHeader 
            heroText={"Create your account"}
            heroSubText={"Welcome to 3D lifestyle!"}
            to={"/login"}
          />
          <Formik 
            initialValues={initialValues}  
            validationSchema={validationSchema}  
            validateOnChange={true}
            onSubmit={onSubmit}
          >
            {({
              values,
              isSubmitting,
              handleSubmit,  
              errors,
              handleChange, 
              touched,
            }) => {
              return (      
                <form onSubmit={handleSubmit}>
                  <FormInput
                    name={"name"}
                    label={"Full Name*"} 
                    placeholder={"Type your full name"}
                    errors={errors}     
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                  />
                  <FormInput
                    name={"email"}   
                    label={"Email*"}
                    placeholder={"Type your e-mail"}    
                    errors={errors}
                    touched={touched}  
                    values={values}
                    handleChange={handleChange}       
                  />
                  <FormPasswordInput
                    name={"password"}
                    label={"Password*"}   
                    placeholder={"Type your password"}  
                    errors={errors}
                    touched={touched}
                    values={values}   
                    handleChange={handleChange}
                  />
                  <Box  
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        mr: "4px",
                         maxWidth: "185px",
                      }}
                    >
                      <FormInput
                        name={"postal_code"}
                        label={"Postal Code*"}
                        placeholder={"Postal Code"}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                      />
                    </Box>
    <FromDropdown 
    name={"default_provider_id"}
    label={"Select Clinic*"}  
    errors={errors} 
    textWrap={"wrap"}
    options={allClinics}
    touched={touched}
    values={values}
    handleChange={handleChange}
    id={savedLocation?.id}
    clinic={savedLocation?.name}
/>
                  </Box>
                  <FormInputPhone
                      name={"phone"}
                      label={"Phone No*"}
                      placeholder={values?.countryCode ==='+1' ? "xxx-xxx-xxxx" : "3xxxxxxxxx"}
                      errors={errors}
                      touched={touched}
                      values={values}   
                      handleChange={handleChange}
                  />   
                  {params?.referral_code && <FormControl fullWidth sx={{mb:"16px"}}>
                    <FormLabel sx={labelStyle}>Refferal Code</FormLabel>
                    <TextField
                        fullWidth
                        sx={{...inputStyle}}
                        disabled
                        value={params?.referral_code}
                    />
                  </FormControl>
                  }
{/*
                  <ReCAPTCHA sitekey="6Lfa19klAAAAAJ8E6IYp28Kg09-IdVTyLUOdbKSK" />
*/}
                 <Box sx={{mt:'16px'}}>
                 <FormButton disable={isSubmitting} text={"Register"} />
                 </Box>
                </form>
              );
            }}
          </Formik>
          <PublicFooter
          
            heading={"Already have an account?"}
            toText={"Sign In"}
            to={"/login"}
          />

<Footer />
{/* <Box
        sx={{
          display: { sm: "none", xs: "block" },
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)",
          mt:{xs:12,sm:0}

        }}
      >
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            zIndex: "999",
            // boxShadow: "0px -18.000001907348633px 37px 0px rgba(0, 0, 0, 0.5)",
            borderRadius: "11px",

            width: "100%",
            p: { xs: "10px 15px 20px 15px", sm: "10px 30px 30px 30px" },
            border: "1px solid lightgray",
            '@media (max-width:340px)':{
              p:"10px 5px 10px 5px"
            }
          }}
          className="footer-wrappper"
        >
          <Box
            sx={{
              backgroundColor: "rgba(239, 230, 224, 1)",
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mr: { xs: "8px", sm: "0px" },
              '@media (max-width:340px)':{
                // marginLeft:"6px",  
                p:"5px"
              }
            }}
          >
            <CardMedia
              sx={{ width: { xs: "20px", sm: "30px" } }}
              component="img"
              alt="Descriptive alt text"
              image={homeoutline}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Home
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mt: "5px",
            }}
          >
            <CardMedia
              sx={{
                width: { xs: "18px", sm: "26px" },
              }}
              component="img"
              alt="Descriptive alt text"
              image={booking}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Bookings
            </Typography>
          </Box>
          <Box
            onClick={handleHome}
            sx={{
              borderRadius: "7px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardMedia
              sx={{
                width: { xs: "80%", sm: "100%" },
                mt: { xs: "-30px", sm: "-40px" },
                '@media (max-width:319px)':{
                  width:'48px',
                  position:'relative',
                  right:'5px'

                },
              }}
              component="img"
              alt="Descriptive alt text"
              image={menu}
            />
          </Box>
          <Box
            sx={{
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardMedia
              sx={{ width: { xs: "20px", sm: "30px" } }}
              component="img"
              alt="Descriptive alt text"
              image={plan}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Member
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              ml: { xs: "5px", sm: "0px" },
              '@media (max-width:340px)':{
                ml:'0px',
              } 
            }}
          >
            <CardMedia
              sx={{ width: { xs: "20px", sm: "30px"} }}
              component="img"
              alt="Descriptive alt text"
              image={profile}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Profile
            </Typography>
          </Box>
        </Box>
                </Box> */}
        </div>
    </PublicLayout>
  );
};

export default Register;
