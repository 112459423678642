import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel, FormLabel,
    Grid, IconButton,
    Link, MenuItem, Modal,
    Select,
    Tab,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Tabs, TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import logo from '../../../assets/images/logo.jpg';
import Loader from '../../../components/loader/loader';
import {styled, useTheme} from "@mui/material/styles";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {
    TreatmentSatisfied, updateCallLogFilters, treatmentCallLogData, getTreatmentManagerCallLogsData, storeCallLogsNotes
} from '../../../store/crm/call-log';
import { useDispatch, useSelector } from 'react-redux';
import BackendPagination from '../../../components/pagination/backendPagination';
import FilterInput from '../../../components/form/filterInput';
import DateRange from '../../../components/date-range/date-range';
import {ReactComponent as Close} from "../../../assets/icon/close-outline.svg";
import CustomButton from "../../../components/custom-button/customButton";
import {MySwal, successConfirmation} from "../../../services/utills";
import {confirmationButton} from "./Appointment";
import moment from "moment/moment";
import ViewNotes from "./viewNotes";
import {modalScroll, modalStyle} from "../../../components/modal/customModal";
import Waveloading from '../../loading/Waveloading';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
export const logowrap = {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    // flexWrap:'wrap',
    '@media (max-width: 500px)': {
        flexDirection:'column',
        height:'fit-content'
        
          }
  };
    
  export const heading = {
    fontFamily: 'helvetica-lt-std-light',
    fontWeight: '700!important',  
    fontSize: '30px!important',
    '@media (max-width: 468px)': {
        fontSize:'20px!important'
        
          }
  };
  
  export const labeldesign = {
    fontFamily: 'helvetica-lt-std-bold',
    fontSize: '18px!important',
    marginBottom:'10px'
  };
  
  export const wrapper = {
    background: 'white',
    padding: '15px 28px',
    borderRadius: "8px"
  };
  export const btnstyle ={
    color: "#4BADE8",
    borderColor: "#4BADE8",
    textTransform: "capitalize",
    fontSize: "14px",
    "&:hover": {
      backgroundColor:  "#4BADE8",
      borderColor:  "#4BADE8",
      color: "#fff",  
    },
    '@media (max-width: 468px)': {
      width:'100%!important',
      fontSize:'18px',
      
        },

  variant:"outlined"
 
}
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#D8F3FF",
        color: "#27272E",
        fontSize: "16px",
        fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingTop:'7px',
        paddingBottom:'7px',
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
  }));  
  

const Treatment = () => {
    const [innerTab, setInnerTab] = useState(0);
    const [noteType, setNoteType] = useState('Staff')
    const [loading, setLoading] = useState(false);
    const [showNotes, setShowNotes] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(null);
    const [comments, setComments] = useState('');


    const dispatch = useDispatch()
    const callLogStore = useSelector(state => state?.callLogStore)
    const {
        treatmentCallLogs=[],
        treatmentManagerCallLogs=[],
        treatmentStartTime=null,
        treatmentEndTime=null,
        treatmentPageLimit,
        treatmentFilterBy,
        treatmentSearch,
        treatmentCurrentPage,
    } = callLogStore
     const tableRef = useRef(null);

    const handleSubmit = async () => {
        if (isModalOpen && comments) {
            let finalData = {
                booking_id: isModalOpen,
                type: noteType,
                description: comments,
            };
            setLoading(true);
            await dispatch(storeCallLogsNotes(finalData));
            await fetchTreatmentCallLogs()
            handleModalClose()
            setLoading(false);
        }
    };
    const handleCheckboxChange = async ( id,value) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? You want to do this?
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={confirmationButton}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes. Do it.
                    </Button>
                    <Button
                        sx={confirmationButton}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            const finalPayload = {
                booking_id: id,
                is_satisfied: value
            };
            setLoading(true);
           let {payload} =  await dispatch(TreatmentSatisfied(finalPayload));
            if (payload?.success ==='true'){
                await fetchTreatmentCallLogs()
                setLoading(false);
            }else {
                setLoading(false);
                await successConfirmation("Error",'Try Again. Something went wrong','error')
            }
        }
    };

    const handleInnerTabChange = async (event, newValue) => {
        setInnerTab(newValue);
        if (newValue === 1){
            await fetchTreatmentManagerCallLogs()
        }
    }

    const handleModalClose = () => {
        setIsModalOpen(null);
    };

    useEffect(() => {
        (async () => {
            await fetchTreatmentCallLogs()
        })();
    }, []);

    const fetchTreatmentCallLogs = async () => {
        setLoading(true);
        await dispatch(treatmentCallLogData());
        setLoading(false);
    } 
    const fetchTreatmentManagerCallLogs = async () => {
        setLoading(true);
        await dispatch(getTreatmentManagerCallLogsData());
        setLoading(false);
    }

    const fetchPageData = async (page) => {
        await dispatch(updateCallLogFilters({currentPage: page}))
        await fetchTreatmentCallLogs()
    }
    const handleLimitChange = async (val) => {


        await dispatch(updateCallLogFilters({pageLimit: val, currentPage: 1}))
        await fetchTreatmentCallLogs()
    }
  const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
const handlePrint = () => {
    const tableToPrint = tableRef.current;
    if (tableToPrint) {
      const printWindow = window.open('', '', 'width=800,height=600');
      printWindow.document.write(`<html><head><title>Print</title></head><body>${tableToPrint.outerHTML}</body></html>`);
      printWindow.document.close();
      printWindow.print();
    }
  };
  const handleDownload = () => {
    const table = tableRef.current;

    if (!table) {
      console.error('Table not found.');
      return;
    }

  
const pdf = new jsPDF('p', 'pt', 'a4');

// Get the actual width and height of the table
const tableWidth = table.offsetWidth;
const tableHeight = table.offsetHeight;

// Calculate the scale factor to fit the table in the PDF
const scaleFactor = 550 / tableWidth; // Assuming the PDF width is 550pt

html2canvas(table, { scale: scaleFactor }).then((canvas) => {
const imgData = canvas.toDataURL('image/jpeg');

// Add the image to the PDF
pdf.addImage(imgData, 'JPEG', 25, 25, tableWidth * scaleFactor, tableHeight * scaleFactor);

// Save the PDF
pdf.save('table.pdf');
});
};

    return (
        <Grid
            container
            sx={{
                background: 'white',
                padding: '15px 28px',
                borderRadius: "8px"
            }}
        >
            {loading ? <Waveloading fullSize/> : ""}
            <Grid item lg={12} sx={logowrap}>
    <Typography sx={heading}>Treatment Call Log Activity</Typography>
                <img src={logo} alt="loading"  width={'20%'} 
     style={{ 
    display: isMobile ? 'none' : 'block',
    height: 'fit-content',
  }}/>
            </Grid>
            <Grid item lg={12}>
                <div>
                    <Grid container spacing={4} style={{marginTop: "6px"}}>
                        <Grid item lg={7} sm={12} xs={12} sx={logowrap}>   
                            <Grid item lg={3} sm={12}xs={12}>
                                <Typography sx={labeldesign}>Follow Up List For:</Typography>
                            </Grid>
                            <Grid item lg={8} sm={12}xs={12}>
                                <FormControl style={{width: "100%"}}>
                                    <DateRange
                                        color={'#EDF2F7'}
                                        setStartDate={async (val)=>{
                                            await dispatch(updateCallLogFilters({startTime: val,type:'treatment'}))
                                        }}
                                        startDate={treatmentStartTime}
                                        setEndDate={async (val)=>{
                                            await dispatch(updateCallLogFilters({endTime: val, currentPage: 1,type:'treatment'}))
                                            if(val) {
                                                await fetchTreatmentCallLogs()
                                            }
                                        }}
                                        endDate={treatmentEndTime}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item lg={4}> </Grid>
                        <Grid item lg={8} xs={12} >
                            <div style={{display: 'flex', gap: '5px' ,flexDirection:{xs:'column',sm:'row'},flexWrap:'wrap'}}>
                                <Button
                                    variant="outlined"
                                    size="large"   
                                    sx={btnstyle}  
                                    startIcon={<LocalPrintshopOutlinedIcon   
                                        style={{fontSize: '20px',color: 'inherit',}}/>}
                                        onClick={handlePrint}
                                >
                                    Print
                                </Button>
                                <Button   variant="outlined"
                                    sx={{
                                      
                                        color: '#4CAF50', // Green color for the button text
                                        borderColor: '#4CAF50', // Green color for the button border
                                        '&:hover': {
                                          backgroundColor: '#4CAF50', // Green background color on hover
                                          color: '#FFF', // White text color on hover
                                        },
                                        '@media (max-width: 468px)': {
                                         width:'100%!important',
                                         fontSize:'15px',
                                        },
                                      }}
                                    size="large" 
                                    onClick={handleDownload}
                                    startIcon={
                                        <CloudDownloadOutlinedIcon sx={{ color: 'inherit', mr:"7px" }}/>
                                    }>
                                    download report
                                </Button>
                                {/* <Button
                                    variant="outlined"
                                    sx={btnstyle}
                                    size="large"
                                    startIcon={<EmailOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}>
                                    Email Reports
                                </Button> */}
                            </div>
                        </Grid>
                        <Grid item lg={4}> </Grid>
                        
                    </Grid>
                </div>
                <Modal
                    open={showNotes!==null}
                    onClose={()=>setShowNotes(null)}
                >
                    <Box sx={{...modalStyle, width: {xs:'95%',sm:'550px'}}}>
                         <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: '15px 15px'}}>
                            <IconButton
                                size="small"
                                sx={{}}
                                onClick={() => setShowNotes(null)}
                            >
                                <Close/>
                            </IconButton>
                        </Box>
                        <Box sx={{p:"0 20px 20px 20px",...modalScroll}}>
                            <ViewNotes notes={showNotes}/>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={isModalOpen!==null}
                    onClose={handleModalClose}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 500,
                            minHeight:"400px",
                            bgcolor: 'background.paper',
                            p: '43px 32px',
                            borderRadius: '6px',
                            '@media (max-width: 480px)': {
                                width: "95%",
                            },
                        }}
                    >
                        {loading ? <Waveloading fullSize/> : ''}
                        <Grid container spacing={1}>
                            <Grid item lg={12} style={{display: "flex", justifyContent: "center"}}>
                                <Typography
                                    sx={{
                                        fontFamily: 'helvetica-world-bold',
                                        fontSize: '20px',
                                        mb: "24px",
                                        textAlign: "center",
                                    }}
                                >
                                    Add New Notes
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{mb:"8px"}}>
                                <Select
                                    sx={{width: '100%'}}
                                    value={noteType}
                                    onChange={(e) => {
                                        setNoteType(e.target.value);
                                    }}
                                >
                                    <MenuItem  value={"Staff"}>Staff Notes</MenuItem>
                                    <MenuItem  value={"Medical"}>Medical Notes</MenuItem>
                                </Select>
                            </Grid>


                            <Grid item xs={12} sx={{mb:"24px"}}>
                                <FormControl fullWidth>
                                    <FormLabel>Notes</FormLabel>
                                    <TextField
                                        minRows={2}
                                        multiline
                                        variant="outlined"
                                        placeholder={`Type ${noteType || "notes"}`}
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                xs={12}
                                item
                                sx={{
                                    display:'flex',
                                    gap:'20px',
                                    alignItems:'center'
                                }}
                            >
                                <CustomButton
                                    mainColor={'#ED1E45'}
                                    val={'Cancel'}
                                    style={{width:'100%', height: "42px"}}
                                    handleClick={() => handleModalClose()}
                                />
                                <CustomButton
                                    val={'Save Notes'}
                                    style={{width:'100%', height: "42px"}}
                                    handleClick={() => handleSubmit()}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
            <Grid item lg={12} xs={12}>
                                <Tabs
                                    value={innerTab}
                                    onChange={handleInnerTabChange}
                                    sx={{
                                        ".MuiTabs-indicator": {
                                            background: '#000000'
                                        },
                                        '@media screen and (max-width: 768px)': {
                                            '.MuiTabs-scroller': {
                                              overflow: 'scroll !important',
                                            },
                                          },
                                        ".Mui-selected": {
                                            color: '#000!important',
                                            fontWeight: '600'
                                        }
                                    }}
                                >
                                    <Tab
                                        label="Treatment Follow Up List"
                                        sx={{
                                            fontSize:'18px',
                                            color: '#000',
                                            textTransform: "capitalize",
                                            fontFamily:innerTab ===0? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                                        }}
                                    />
                                    <Tab
                                        label="Manager Follow Up List"
                                        sx={{
                                            fontSize:'18px',
                                            color: '#000',
                                            textTransform: "capitalize",
                                            fontFamily:innerTab ===1? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                                        }}
                                    />
                                </Tabs>
                                {innerTab === 0 && (
                                    <TableContainer sx={{mt: '24px', overflowX:'auto', maxWidth: '100%'}}>
                                        {loading === true && <Waveloading fullSize/>}
                                        <Table stickyHeader aria-label="customized table"  ref={tableRef} sx={{ width:{sm:'100%', xs:'1350px'}}}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>
                                                       ID
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Customer Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Treatment Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Booking Time
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Tell #
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Email
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Satisfied Customer?
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Notes
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {treatmentCallLogs?.data?.length ? (
                                                    treatmentCallLogs?.data.map((item,index) => (
                                                        <StyledTableRow key={index} style={{backgroundColor: "#F5FCFF"}}>
                                                            <StyledTableCell>
                                                                {item?.id}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.service_name}

                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Typography>
                                                                    {moment(item?.date)?.format('YYYY-MM-DD')}
                                                                </Typography>
                                                                <Typography>
                                                                    {item?.start_time} - {item?.end_time}
                                                                </Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.phone}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.email}
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align="center"
                                                                sx={{
                                                                    paddingTop: "40px!important",
                                                                    minWidth: "100px",
                                                                    display: "flex",
                                                                    paddingBottom: "37px!important",
                                                                    padding: "36px",
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={item?.is_satisfied === 'No'}
                                                                            onClick={() => handleCheckboxChange( item.id,"No")}
                                                                            name="no"
                                                                            size="small"
                                                                        />
                                                                    }
                                                                    label="No"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={item?.is_satisfied === 'Yes'}
                                                                            onClick={()=>handleCheckboxChange(item?.id,"Yes")}
                                                                            name="no"
                                                                            size="small"
                                                                        />
                                                                    }
                                                                    label="Yes"
                                                                />

                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {(item?.notes?.Medical?.length > 0 ||  item?.notes?.Staff?.length > 0) &&
                                                                    <Link
                                                                        href="#"
                                                                        onClick={()=>setShowNotes(item?.notes)}
                                                                        sx={{mr:'10px'}}
                                                                    >
                                                                        View Notes
                                                                    </Link>
                                                                }
                                                                <Link
                                                                    href="#"
                                                                    onClick={()=>setIsModalOpen(item?.id)}
                                                                >
                                                                    Add Notes
                                                                </Link>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                height:'300px'
                                                            }}
                                                            colSpan={9}
                                                            align="center"
                                                        >
                                                             Treatment data not found.
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        {treatmentCallLogs?.data?.length > 0 && (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                mt: '24px'
                                            }}>
                                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                    <FilterInput
                                                        isBorder
                                                        limit={treatmentPageLimit}
                                                        setLimit={handleLimitChange}
                                                    />
                                                    <Typography>Showing {treatmentCallLogs?.meta?.from} to {treatmentCallLogs?.meta?.to} of {treatmentCallLogs?.meta?.total} entries</Typography>
                                                </div>
                                                <BackendPagination
                                                    count={treatmentCallLogs?.meta?.last_page}
                                                    currentPage={treatmentCurrentPage}
                                                    handleOnChange={fetchPageData}
                                                />
                                            </Box>
                                        )}
                                    </TableContainer>
                                )}
                                {innerTab === 1 && (
                                    <TableContainer sx={{mt: '24px'}}>
                                        {loading === true && <Waveloading fullSize/>}
                                        <Table stickyHeader aria-label="customized table"  ref={tableRef}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>
                                                        ID
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Aesthetician Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Treatment Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Completion Date
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Customer Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Tell #
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Email
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Notes
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {treatmentManagerCallLogs?.data?.length ? (
                                                    treatmentManagerCallLogs?.data?.map((item,index) => (
                                                        <StyledTableRow key={index} style={{backgroundColor: "#F5FCFF"}}>
                                                            <StyledTableCell>
                                                                {item?.id}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.service_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.date}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.phone}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.email}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Link href="#"
                                                                      onClick={()=>setShowNotes(item?.notes)}
                                                                >
                                                                    View Notes
                                                                </Link>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                height:'300px'
                                                            }}
                                                            colSpan={9}
                                                            align="center"
                                                        >
                                                             Treatment  data not found.
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        {treatmentManagerCallLogs?.data?.length > 0 && (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                mt: '24px'
                                            }}>
                                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                    <FilterInput
                                                        isBorder
                                                        limit={treatmentPageLimit}
                                                        setLimit={handleLimitChange}
                                                    />
                                                    <Typography>Showing {treatmentManagerCallLogs?.meta?.from} to {treatmentManagerCallLogs?.meta?.to} of {treatmentManagerCallLogs?.meta?.total} entries</Typography>
                                                </div>
                                                <BackendPagination
                                                    count={treatmentManagerCallLogs?.meta?.last_page}
                                                    currentPage={treatmentCurrentPage}
                                                    handleOnChange={fetchPageData}
                                                />
                                            </Box>
                                        )}
                                    </TableContainer>
                                )}
                        </Grid>
        </Grid>
    )
};

export default Treatment


