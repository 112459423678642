import React from 'react';
import {Box, Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StatisticCard = ({title,color,value,isAmount,onClick,icon, padding,height}) => {
    return (
        <Card 
        sx={{
          borderRadius: '10px',
          padding: padding ? padding : { lg: "2px 36px", sm: "2px 70px", xs: "2px 46px" },
          height: height ? height :"100%",
          display: 'flex',
          flexDirection: 'row', 
          justifyContent: 'space-between',
          cursor: onClick ? 'pointer' : '',
          alignItems:"center",
          boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)', // Adding a shadow for card
         
        }}
        onClick={onClick}
      > 
 
 {icon && (
        <Box
          sx={{
            display: 'flex', // Center the content horizontally and vertically  
            justifyContent: 'center',     
            alignItems: 'center',
            borderRadius: '50%', // Circular shape 
            backgroundColor: '#19a2ff05', // Light background color
            width: '60px', // Size of the box
            height: '60px', // Size of the box
            boxShadow: '0px 0px 10px rgba(15, 75, 239, 0.15)', // Shadow effect
            '&:hover': {
              boxShadow: '0px 0px 15px rgba(11, 122, 233, 0.2)', // Hover effect 
              backgroundColor: 'rgba(11, 122, 233, 0.1)', // Darker background on hover  
            },
          }}
        >
          {icon} {/* Icon inside the circle */}
        </Box>
      )}
  
<Box sx={{marginTop:"20px"}}>

<Typography style={{fontSize:"13px", color: '#6B7280',}}> {title}</Typography>
       
        <CardContent> 
          <Typography
            sx={{
              fontSize: '30px',
              color: color,
              textAlign: 'center',
              '@media (min-width:1200px) and (max-width: 1330px)': {
                fontSize: '35px',
              },
            }}
          >
            {isAmount && "$"}{value}
          </Typography>
        </CardContent>  
              
</Box>
      </Card>
      
    );
};

export default StatisticCard;