import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom'; // Import both useLocation and useNavigate hooks
import { Box, Paper, Tooltip } from '@mui/material';
import ArrowBack from "../../assets/icon/chevron-back-outline.png";
import {Link} from "react-router-dom";
const CustomBread = ({ crmCustomerDetails }) => {
    console.log("issue fixed",crmCustomerDetails)
    const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate hook to programmatically navigate
    const pathnames = location.pathname.split('/').filter(x => x);

    const isLastSegmentNumeric = !isNaN(pathnames[pathnames.length - 1]);
    const targetSegmentIndex = isLastSegmentNumeric ? pathnames.length - 2 : pathnames.length - 1;
    const targetPathSegment = pathnames[targetSegmentIndex];
    const breadcrumbLabel = targetPathSegment.replace(/-/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
 console.log("id", crmCustomerDetails)
    const items = [ 
      { label: 'Home', href: '/crm/dashboard' },
      { label: 'Appointment', href: '/crm/appointments' }, 

      {   
        label:  crmCustomerDetails?.customer?.name , 
        onClick: () => navigate(`/crm/appointments`, {   
          state: {searchvalue: crmCustomerDetails?.customer?.name },      
        }),     
      },    
      {         
        label: 'Appointment History',      
        href: `/crm/appointment/history/${crmCustomerDetails?.id}`   
      },              
      { label: breadcrumbLabel, href: location.pathname },         
           
    ];    
 
        
    return (  
      // <div>
      //   {items.map((item, index) => (    
      //     <React.Fragment key={index}>  
      //       {item.onClick ? (
      //         <button onClick={item.onClick} style={{ all: 'unset', cursor: 'pointer' }}>
      //           {item.label}  
      //         </button>     
      //       ) : ( 
      //         <a href={item.href} style={{ textDecoration:'none',color:'#4F4F4F',fontWeight: index === items.length - 1 ? 'bold' : '500', }}>{item.label}</a>   
      //       )}
      //       {index < items.length - 1 && <span> <ArrowForwardIosIcon sx={{fontSize:'12px'}}/> </span>}     
      //     </React.Fragment>  
      //   ))}                 
      // </div>
      
      <div>
        <Box  xs={12} sx={{display: 'flex', alignItems: 'center',flexWrap:"wrap"}}>
                  <Tooltip title="Go Back">
                            <Paper
                                component={Link}  
                                to={'/crm/appointments'}
                                // onClick={clearLocalStorage}
                                sx={{
                                    width: 'max-content',
                                    p: '5px',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    cursor: 'pointer',
                                    mr: {lg:"20px",md:"20px",xs:"8px"},
                                }}
                                elevation={3}
                            >
                                <img src={ArrowBack} alt={'close'}/>
                            </Paper>
                            </Tooltip>
  {items.map((item, index) => (
    <React.Fragment key={index}>
      {item.href ? (
        <a
          href={item.href}
          style={{
            textDecoration: index === items.length - 1 ? 'none' : 'underline', // Remove underline for the last item
            color: index === items.length - 1 ? '#09ABF1' : '#4F4F4F', // Change last item color
            fontWeight: index === items.length - 1 ? 'bold' : '500',
          }}
        >
          {item.label}
        </a>
      ) : (
        <button
          onClick={item.onClick}
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            color: index === items.length - 1 ? '#09ABF1' : '#4F4F4F', // Change last item color
            fontWeight: '500',
            cursor: 'pointer',
            textDecoration: index === items.length - 1 ? 'none' : 'underline', // Remove underline for the last item
          }}
        >
          {item.label}
        </button>
      )}
      {index < items.length - 1 && <span> <ArrowForwardIosIcon sx={{ fontSize: '12px' }} /> </span>}
    </React.Fragment>
  ))}
  </Box>
</div>

    );    
};   

export default CustomBread;
