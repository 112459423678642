import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Avatar,
  Breadcrumbs,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Loader from "../../../../components/loader/loader";
import CustomButtoncolor from "../../../../components/custom-button/customColorButton";
import { tabHistory } from "../../appointment/appointment-details/appointmentHistory/appointmentHistory";
import {
  getCRMCustomerBookingsDetails,
  getCRMCustomerDetails,
} from "../../../../store/crm/crm";
import { btndesign, crmButton } from "../CRMPage";
import { scrollCSS } from "../../../../App";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CrmProfileInfo from "./crmProfileInfo";
import MakeMemberModal from "../crmMembership/MakeMemberModal";
import SetEmailModal from "../../../../components/modal/SetEmailModal";
import SetWalletModal from "../../../../components/modal/SetWallet";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Appointment from "../../appointment/appointment-details/appointmentHistory/appointment";
import Invoices from "../../appointment/appointment-details/appointmentHistory/invoices";
import Treatment from "../../appointment/appointment-details/appointmentHistory/treatment";
import WalletHistory from "../../appointment/appointment-details/appointmentHistory/walletHistory";
import ActivityLogs from "../../appointment/appointment-details/appointmentHistory/activityLogs";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/breadcrumbs";
import CrmGalleryLayout from "./crmGallery/crmGalleryLayout";
import CrmAttachments from "./crmAttachment/crmAttachments";
import CrmAddAttachment from "./crmAttachment/crmAddAttachment";
import CrmAddReminder from "./crmReminders/crmAddReminder";
import CrmReminder from "./crmReminders/crmReminder";
import CrmNotes from "./crmNotes/crmNotes";
import CrmAddNotes from "./crmNotes/crmAddNotes";
import AppointmentSubmittedForm from "../../appointment/appointment-details/appointment-submitted-form/appointmentSubmittedForm";
import TreatmentFormButton from "../../../../components/card-appointment/treatmentForm";
import Waveloading from "../../../loading/Waveloading";
import ResponsiveDrawer from "../../../../components/siderbar/ResponsiveDrawer";
import CustomBread from "../../../../components/breadcrumbs/BreadCrumb";
import { getAppointmentActivity } from "../../../../store/crm/appointment";

const tabData = [
  {
    id: 0,
    title: "Profile Info",
    url: "/crm/customer-relationship-management/customer/profile-info/",
  },
  {
    id: 1,
    title: "Booking History",
    url: "/crm/customer-relationship-management/customer/history/",
  },
  {
    id: 2,
    title: "Notes",
    url: "/crm/customer-relationship-management/customer/notes/",
  },
  {
    id: 3,
    title: "Attachments",
    url: "/crm/customer-relationship-management/customer/attachments/",
  },
  {
    id: 5,
    title: "Photo Gallery",
    url: "/crm/customer-relationship-management/customer/gallery/",
  },
  {
    id: 4,
    title: "Reminders",
    url: "/crm/customer-relationship-management/customer/reminders/",
  },
  {
    id: 6,
    title: "Submitted Forms",
    url: "/crm/customer-relationship-management/customer/submitted-forms/",
  },
];

const CrmCustomerDetails = () => {
  const [active, setActive] = useState(0);
  const [historyActive, setHistoryActive] = useState(0);
  const [reminderActive, setReminderActive] = useState(null);
  const [noteActive, setNoteActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [treatmentLoading, setTreatmentLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user_id } = useParams();

  const crmStore = useSelector((state) => state?.crmStore);
  const {
    crmCustomerDetails = {},
    invoices = [],
    appointmentsHistory = [],
    treatments = [],
    walletHistory = {},
    allCrmCustomer = {},
    
  } = crmStore;
  const crmAppointmentStore = useSelector((state)=>state?.crmAppointmentStore)
    const {Activity=[]} = crmAppointmentStore

  useEffect(() => {
    if (
      pathname ===
      `/crm/customer-relationship-management/customer/profile-info/${user_id}`
    ) {
      setActive(0);
    } else if (
      pathname ===
      `/crm/customer-relationship-management/customer/history/${user_id}`
    ) {
      setActive(1);
    } else if (
      pathname ===
      `/crm/customer-relationship-management/customer/notes/${user_id}`
    ) {
      setActive(2);
    } else if (
      pathname ===
      `/crm/customer-relationship-management/customer/attachments/${user_id}`
    ) {
      setActive(3);
    } else if (
      pathname ===
      `/crm/customer-relationship-management/customer/reminders/${user_id}`
    ) {
      setActive(4);
    } else if (
      pathname ===
      `/crm/customer-relationship-management/customer/gallery/${user_id}`
    ) {
      setActive(5);
    } else if (
      pathname ===
      `/crm/customer-relationship-management/customer/submitted-forms/${user_id}`
    ) {
      setActive(6);
    }
  }, [pathname]);

  useEffect(() => {
    if (user_id && user_id != crmCustomerDetails?.id) {
      (async () => {
        setLoading(true);
        await dispatch(getCRMCustomerDetails(user_id));
        setLoading(false);
      })();
    }
  }, [user_id]);

  useEffect(() => {
    if (user_id && user_id != crmCustomerDetails?.id) {
      (async () => {
        setInvoiceLoading(true);
        await dispatch(
          getCRMCustomerBookingsDetails({ id: user_id, type: "Invoices" })
        );
        setInvoiceLoading(false);
      })();
    }
  }, [user_id]);
  useEffect(() => {
    if (user_id && user_id != crmCustomerDetails?.id) {
      (async () => {
        setAppointmentLoading(true);
        await dispatch(
          getCRMCustomerBookingsDetails({ id: user_id, type: "Appointments" })
        );
        setAppointmentLoading(false);
      })();
    }
  }, [user_id]);
  useEffect(() => {
    if (user_id && user_id != crmCustomerDetails?.id) {
      (async () => {
        setTreatmentLoading(true);
        await dispatch(
          getCRMCustomerBookingsDetails({ id: user_id, type: "Treatments" }) 
        );
        setTreatmentLoading(false);
      })();
    }
  }, [user_id]);
  useEffect(()=>{
    (async ()=>{

        if (user_id && user_id != crmCustomerDetails?.id){
          setWalletLoading(true);
            await dispatch(getAppointmentActivity(user_id))
            setWalletLoading(false);
            console.log("api call 1 ")
   
        }
    })()
},[user_id])
  useEffect(() => {
    if (user_id && user_id != crmCustomerDetails?.id) {
      (async () => {
        setWalletLoading(true);
        await dispatch(
          getCRMCustomerBookingsDetails({ id: user_id, type: "WalletHistory" })
        );
        setWalletLoading(false);
      })();
    }
  }, [user_id]);
  const handleShowParent = () => {
    setIsVisible(true);
  };
  const redirect = (to) => {
    navigate(to);
  }
  const drawer = () => (
    <Box
      sx={{
        display: { md: "block", xs: "none", sm: "none" },
        maxHeight: "100vh",
        minHeight: "50vh",
        overflowY: "auto",
        background: "#fff",
        width: "250px",
        p: "0",
        borderRadius: "7px",
        ...scrollCSS,
      }}
    >
      <List
        sx={{
          width: "100%",
          p: "0",
          ".MuiListItemIcon-root ": {
            minWidth: "37px",
          },
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {tabData?.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={() => redirect(item?.url + user_id)}
            sx={{
              borderBottom: "1px solid #E8E8E8",
              py: "16px",
              pl: "24px",
              span: {
                fontFamily: pathname.includes(item?.url)
                  ? "helvetica-lt-std-bold"
                  : "helvetica-lt-std-roman",
                fontSize: "19px",
              },
              "&:hover": {
                span: {
                  fontFamily: "helvetica-lt-std-bold",
                },
              },
            }}
          >
            {<ListItemText primary={item?.title} />}
          </ListItemButton>
        ))}
      </List>
    </Box>
  ); 

  return (
    <Box>
      {loading && active !== 2 ? <Waveloading fullSize /> : ""}
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
         <CustomBread  crmCustomerDetails={crmCustomerDetails}/>
        </Grid>  
      </Grid>
      <Box sx={{ display:'flex', justifyContent:'end', mb:2 }}>  
          <ResponsiveDrawer       
            style={{}}               
            tabData={tabData}    
            user_id={user_id} 
            pathname={pathname}
            redirect={redirect}
          />
        </Box>
      <Box  
        sx={{
          display: "flex",
          gap: { xs: "0px", sm: "20px" },
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Box>{drawer()}</Box>
        </Box>
        <Grid container>
          {active === 0 && (
            <Grid
              item
              xs={12}
              sx={{
                background: "#FFFFFF", 
                borderRadius: "7px",
                p: { xs: "8px", sm: "32px" },
              }}
            >
              <CrmProfileInfo crmCustomerDetails={crmCustomerDetails} />
            </Grid>
          )}
          {active === 1 && (
            <Grid   
              item
              xs={12}
              sx={{   
                background: "#FFFFFF",   
                borderRadius: "4px",     
                p: { xs: "8px", sm: "32px" },
              }}
            >
              <Box sx={{ background: "#fff", minHeight: "100px" }}> 
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "max-content",
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >  
                  <Tabs
                    value={historyActive}
                    onChange={(e, newValue) => {
                      setHistoryActive(newValue);
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                      ".MuiTabs-indicator": {
                        background: "#000000",
                      },
                      ".Mui-selected": {
                        color: "#000!important",
                        fontWeight: "600",
                      },
                    }}
                  >
                    {tabHistory?.length > 0 &&
                      tabHistory.map((item, index) => (
                        <Tab
                          sx={{
                            fontSize: "20px",
                            color: "#000",
                            textTransform: "capitalize",
                            fontFamily:
                              historyActive === item?.id
                                ? "helvetica-lt-std-bold"
                                : "helvetica-lt-std-roman",
                          }}
                          label={item?.title}
                          key={item?.id}
                        />
                      ))}
                  </Tabs>
                </Box>

                <Box
                  sx={{
                    background: "#F1F5F9",
                    borderRadius: "8px",
                    px: { sm: "32px", xs: "8px" },
                    pt: { sm: "32px", xs: "8px" },
                    mt: "24px",
                    minHeight: "300px",
                    pb: historyActive === 3 ? "32px" : "",
                  }}
                >
                  {historyActive === 0 && !loading && appointmentLoading ? (
                    <Waveloading />
                  ) : (
                    ""
                  )}
                  {historyActive === 1 && !loading && invoiceLoading ? (
                    <Waveloading />
                  ) : (
                    ""
                  )}
                  {historyActive === 2 && !loading && treatmentLoading ? (
                    <Waveloading />
                  ) : (
                    ""
                  )}  
                  {historyActive === 3 && !loading && walletLoading ? (
                    <Waveloading />
                  ) : (   
                    ""
                  )}  
                  {historyActive === 0 && !appointmentLoading && ( 
                    <Appointment appointmentHistory={appointmentsHistory}  onShowParent={handleShowParent} />
                  )}
                  
                  {historyActive === 1 && !invoiceLoading && (
                    <Invoices appointmentInvoices={invoices} />
                  )}
                  {historyActive === 2 && !treatmentLoading && (
                    <Treatment treatments={treatments} />
                  )}
                   {historyActive === 3 && !walletLoading && (
                    <ActivityLogs  activity={Activity?.data} />
                  )}
                  {historyActive === 4 && !walletLoading && (
                    <WalletHistory walletHistory={walletHistory} />
                  )}
                </Box>
              </Box>
            </Grid>
          )}
          {active === 2 && (
            <Grid item xs={12}>  
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ background: "#EDF2F7" }}>
                  <CrmNotes /> 
                </Grid>
                <Grid item xs={12} sm={6} sx={{ background: "#EDF2F7" }}>
                  <CrmAddNotes appointmentDetails={crmCustomerDetails} />
                </Grid>
              </Grid> 
            </Grid> 
          )}
          {active === 3 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ background: "#EDF2F7" }}>
                  <CrmAttachments />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ background: "#EDF2F7" }}>
                  <CrmAddAttachment appointmentDetails={crmCustomerDetails} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {active === 5 && (
            <Grid item xs={12}>
              <CrmGalleryLayout />
            </Grid>
          )}
          {active === 4 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ background: "#EDF2F7" }}>
                  <CrmReminder />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ background: "#EDF2F7" }}>
                  <CrmAddReminder />
                </Grid>
              </Grid>
            </Grid>
          )}
          {active === 6 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ background: "#EDF2F7" }}>
                  {crmCustomerDetails?.customer_id &&
                    crmCustomerDetails?.provider_id && (
                      <AppointmentSubmittedForm
                        customerID={crmCustomerDetails?.customer_id}
                        providerId={crmCustomerDetails?.provider_id}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>   



    </Box>
  );
};

export default CrmCustomerDetails;




