import React, { useEffect, useState } from 'react';
import toast from "react-hot-toast";
import { Box, FormControl, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getGhlByID, storeGHL, updateghl } from "../../../store/menus";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import { getAllClinics } from '../../../store/clinics';

const Addghl = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const clinicStore = useSelector((state) => state.clinicStore);
console.log("clinic ",clinicStore?.clinics?.data?.data)
    // State variables for form fields
    const [staff, setStaff] = useState('');
    const [message, setMessage] = useState('');
    const [event, setEvent] = useState(0);

    // Fetch clinics on component mount
    useEffect(() => {
        dispatch(getAllClinics());
    }, [dispatch]);

    // Fetch existing GHL data if editing
    useEffect(() => {
        if (params?.ghl_id) {
            setLoading(true);
            dispatch(getGhlByID(params.ghl_id))
                .then((res) => {
                    if (res?.payload?.data) {
                        const data = res.payload.data;
                        setStaff(data.provider_id || '');
                        setMessage(data.url || '');
                        setEvent(data.is_event_url || '');
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [params?.ghl_id, dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Basic validation
        if (!staff || !message || !event) {
            toast.error('All fields are required');
            setLoading(false);
            return;
        }

        const payload = {
            provider_id: staff,
            url: message,
            is_event_url: event,
            id: params?.ghl_id,
        };

        let res;
        if (params?.ghl_id) {
            res = await dispatch(updateghl(payload));
        } else {
            res = await dispatch(storeGHL(payload));
        }

        if (res?.payload?.errors) {
            const { errors } = res.payload;
            console.log(errors);
            // Handle errors as needed
        } else if (res?.payload?.success === "true") {
            toast.success(`Ghl ${params?.ghl_id ? "updated" : "stored"} successfully`, { duration: 3000 });
            navigate('/admin/configuration/webhook');
        }

        setLoading(false);
    };

    const handleEventChange = (event) => {
        setEvent(event.target.value);
    };

    return (
        <ConfigurationLayout>
            <Box sx={{ height: "calc(100vh - 125px)", backgroundColor: "white" }}>
                {loading ? <Loader /> :
                    <form onSubmit={handleSubmit}>
                        <Grid container p={3} sx={{ justifyContent: 'space-between', width: '100%', alignItems: "center" }}>
                        <Grid item lg={6} md={6} sm={6}>
  <FormControl sx={{ minWidth: "500px", mt: { xs: 2, sm: 1 } }}>
    <FormLabel>Provider Name</FormLabel>
    <Select value={staff} onChange={(e) => setStaff(e.target.value)} displayEmpty>
      {Array.isArray(clinicStore?.clinics?.data?.data) 
        ? clinicStore?.clinics?.data?.data?.map((service) => (
            <MenuItem key={service.id} value={service.id}>
              {service.name}
            </MenuItem>
          ))
        : null}
    </Select>
  </FormControl>
</Grid>

                            <Grid item lg={6} md={6} sm={6}>
                                <FormControl fullWidth> 
                                    <FormLabel>Webhook Url</FormLabel>
                                    <TextField
                                        fullWidth
                                        name="message"
                                        variant='outlined'
                                        placeholder="Webhook URL"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6}>
                                <FormControl  sx={{
                                    minWidth: "500px",
                                    mt: { xs: 2, sm: 1 },
                                }}>
                                    <FormLabel>Event Url</FormLabel>
                                    <Select
                                        value={event}
                                        onChange={handleEventChange}
                                        displayEmpty
                                    >
                                        <MenuItem value="1">True</MenuItem>
                                        <MenuItem value="0">False</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: "20px" }}>
                                <Box sx={{ mr: '16px' }}>
                                    <CustomButton
                                        mainColor={'#ED1E45'}
                                        val={'Cancel'}
                                        handleClick={() => {
                                            navigate('/admin/configuration/webhook');
                                        }}
                                    />
                                </Box>
                                <CustomButton
                                    val={params?.ghl_id ? "Update ghl" : "Add ghl"}
                                    type="submit"
                                    handleClick={handleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default Addghl;
