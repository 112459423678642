import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, TextField, TextareaAutosize, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Label } from '@mui/icons-material';
import { setWallet } from '../../store/crm/crm';
import { useFormik } from 'formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Loader from "../loader/loader";
import {successConfirmation} from "../../services/utills";
import Waveloading from '../../pages/loading/Waveloading';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const wrapper={
    alignItems:"center",
  }
  const heading={
    fontSize:"18px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'400',
    textAlign:"center",
    width:'100%'

  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}
 export const textareaStyle = {

  height: '200px',
  border:"none",
    background: "#EDF2F7",
    borderRadius: '4px',
    outline:"none",
      "&:focus": {
        borderColor: "#EDF2F7",
        outline: "none",
      },
      "&:hover": {
        borderColor: "#EDF2F7",
        outline: "none",
    }
    
};


const SetWalletModal= ({ customer_id,reFetchData}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // console.log("id get",customerId, bookingId )
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validate = (values) => {
        const errors = {};

        if (!values.passcode) {
            errors.passcode = "passcode is required";
        }


        if (!values.waletnotes) {
            errors.waletnotes = "waletnotes is required";
        }
        if (!values.wallet_points) {
            errors.wallet_points = "Wallet points are required";
        }
    

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            passcode: "",
            waletnotes: "",
            wallet_points: ""
        },
        validate,
        onSubmit: async (values) => {
            try {
                const finalPayload = {
                    customer_id: customer_id,
                    passcode: values.passcode,
                    notes: values.waletnotes,
                    wallet_points: values.wallet_points


                };
                setLoading(true)

                let {payload} = await  dispatch(setWallet(finalPayload));

                if (payload?.success ==='true'){
                    toast.success('wallet update successfully');
                    reFetchData()
                    setLoading(false)
                    handleClose();
                }else {
                    setLoading(false)

                    if (payload?.data?.errors){
                        await successConfirmation('Error',payload?.data?.errors || 'Somethings went wrong.','error')
                    }
                }
            } catch (error) {
                setLoading(false)
                console.log('Error wallet update', error);
            }
        },
    });


    const handleCloseModal = () => {
        handleClose();
    };


    return (
        <>
            <CustomButtoncolor
                handleClick={handleOpen}
                mainColor={"#219653"}
                size="md"
                val='Set wallet'
                style={{
                    padding: '7px 5px',
                    fontSize: "18px",
                    minWidth: 'max-content',
                    borderRadius: "6px!important",
                    width: "100%",
                    "@media (minWidth: 420px)": {
                        width: 'auto',
                    },
                    '@media (max-width:319px)':{
                        fontSize: "15px"
                    }
                }}
            />
            {loading?<Waveloading fullSize/>:''}
            <Modal
                style={{}}  
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >   
                <Box sx={style}>
                    <Grid container spacing={1} sx={wrapper}>

                        <Grid item lg={12} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                            <ArrowBackIosNewIcon onClick={handleCloseModal}/>
                            <Typography
                                id="modal-modal-title"
                                sx={heading}

                            >
                                Change Wallet Point
                            </Typography>
                        </Grid>
                        <Box style={{marginTop: '26px', width: "100%", padding: "0px 3px 0px 10px"}}>
                            <Grid item lg={12} md={12} sm={12}>

                                <FormControl fullWidth>   
                                    <Typography sx={{mb:"10px",mt:"5px"}}>Passcode:</Typography>
                                    <TextField
                                        fullWidth
                                        name="passcode"
                                        sx={inputStyle}
                                        placeholder=""
                                        value={formik.values.passcode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.passcode && formik.errors.passcode && (
                                        <Typography color="error">{formik.errors.passcode}</Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} > 
                                <FormControl fullWidth>
                                    <Typography sx={{mb:"10px",mt:"5px"}}>Wallet Points:</Typography> 
                                    <TextField 
                                        fullWidth       
                                        name="wallet_points"
                                        sx={inputStyle}
                                        placeholder="" 
                                        value={formik.values.wallet_points}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    
                                    {formik.touched.wallet_points && formik.errors.wallet_points && (
                                        <Typography color="error">{formik.errors.wallet_points}</Typography>
                                    )}
                                </FormControl>
                            </Grid>    
                            <Grid item lg={12} md={12} sm={12} >
                                <Typography sx={{mb:"10px",mt:"5px"}}>Wallet Notes:</Typography>
                                <FormControl fullWidth>   
                                    <TextareaAutosize
                                        style={textareaStyle}
                                        rows={6}  
                                        name='waletnotes'
                                        placeholder=""
                                        value={formik.values.waletnotes}
                                        onChange={formik.handleChange}  
                                        onBlur={formik.handleBlur}       
                                    />
                                    {formik.touched.waletnotes && formik.errors.waletnotes && (
                                        <Typography color="error">{formik.errors.waletnotes}</Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid
                                item
                                lg={12}
                                md={12}  
                                sm={12}
                                sx={{...btnwrappe,mt:"24px"}}
                            >   
                                <CustomButtoncolor
                                    mainColor={"#4F4F4F"}
                                    size="md"
                                    val='Cancel'
                                    style={{
                                        padding: '7px 5px',
                                        fontSize: "18px",
                                        minWidth: 'max-content',
                                        borderRadius: "6px!important",
                                        width: "15%",
                                        "@media (minWidth: 420px)": {
                                            width: 'auto'
                                        },
                                    }}
                                    handleClick={handleCloseModal}
                                />
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{
                                        height: "50px", width: "92px",
                                        backgroundColor: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
                                        borderRadius: '8px',
                                    }}
                                    onClick={formik.handleSubmit}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Box>

                    </Grid>

                </Box>
            </Modal>
        </>
    )
}

export default SetWalletModal;

