import React, {useEffect, useState} from 'react';
import {Box, FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllPaymentMethods} from "../../../store/payments-methods";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";
import TableData from '../../../components/table/TableData';

const PaymentMethods = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const paymentMethodsStore = useSelector(state => state?.paymentMethodStore)


    useEffect(() => {
        (async () => {
            if (!paymentMethodsStore?.paymentMethods?.data?.length){
                setLoading(true)
            }
            await dispatch(getAllPaymentMethods())
            setLoading(false)
        })()
    }, [dispatch,paymentMethodsStore?.paymentMethods?.data?.length])

    const handleClick = () => {
        navigate('/admin/configuration/add-payment-methods')
    }


    const roleHead = [
        {value: "Name", key: 'name'},
        {value: "Gateway", key: 'is_gateway'},
        {value: "Status", key: 'status'},
        {value: "Number of Input", key: 'is_input'},
        {value: "Action", key: 'action'}
    ]

    const handleEdit = (row, isEdit) => {
        if (isEdit && row?.id > 4) {
            navigate(`/admin/configuration/edit-payment-methods/${row?.id}`)
        }

    }

    return (
        <ConfigurationLayout>
            <Grid container p={'20px'}>
                <Grid item xs={12} sx={{
                    mb: '16px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    flexWrap:'wrap',
                    '@media (max-width: 430px)' :{
                        display:'flex',
                        justifyContent:'flex-start'
                       },
                }}>
                    <FormControl sx={{mr: "10px"}}>
                        <SimpleInput
                            search={search}
                            setSearch={setSearch}
                        />
                    </FormControl>
                    <CustomButton val={'Add Payment'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                    {loading ? (
                        <Box
                        sx={{ 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            height: "60vh" 
                        }}
                    >
                        <Loader />
                    </Box>
                    ) :<TableData
                        headRow={roleHead}
                        search={search}
                        isStatus={true}
                        isGateway={true}
                        isInput={true}  
                        tableData={paymentMethodsStore?.paymentMethods?.data}
                        type={"paymentMethod"}
                        handleEdit={handleEdit}
                    />}
                </Grid>
            </Grid>
        </ConfigurationLayout>
    );
};

export default PaymentMethods;