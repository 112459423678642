import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, FormHelperText, FormLabel, Grid, IconButton, Modal, TextareaAutosize, TextField, Tooltip, Typography} from "@mui/material";
import FormDateInput from "../../../../components/form/FormDateInput";
import {MdDeleteOutline} from "react-icons/md";
import {confirmation, handleDelete, parseTime, timeSlots} from "../../../../services/utills";
import * as yup from "yup";
import * as Yup from "yup";
import {useFormik} from "formik";
import {storeBlockHour, storeStaffBreakTime, UpdateBlockHour} from "../../../../store/clinics/clinicSettings/business-hours";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {inputStyle} from "../../../../components/form/formInput";
import Loader from "../../../../components/loader/loader";
import CustomButtoncolor from '../../../../components/custom-button/customColorButton';
import Waveloading from '../../../loading/Waveloading';
import ClearIcon from '@mui/icons-material/Clear';
import { MdModeEditOutline } from "react-icons/md";
import toast from "react-hot-toast";
import { getBlockhourId } from '../../../../store/crm/calender';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: '41px 23px',
    borderRadius:'6px',
    '@media (max-width: 480px)': {
      width: "95%",
      p: '31px 13px',
    },
    gap:"5px"
  };


  const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": {
        color:'#000!important',
    }
}
const StaffUpdateModal = ({ onRescheduleSuccess, id }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    
    // ✅ Formik Initialization
    const formik = useFormik({
        initialValues: {
            date: '',
            start_time: '',
            end_time: '',
            notes: '',
        },
        validationSchema: yup.object({
            notes: yup.string().min(3, "Notes is Too Short").required('Notes is required'),
            date: yup.date().typeError("Staff holiday date is required").required("Staff holiday date is required"),
            start_time: yup.date().transform((value, originalValue) => originalValue ? parseTime(originalValue) : null)
                .required("Start time is required").typeError("Start time is required"),
            end_time: yup.date().transform((value, originalValue) => originalValue ? parseTime(originalValue) : null)
                .required("End time is required").typeError("End time is required")
                .min(yup.ref("start_time"), "End time must be after start time"),
        }),

        onSubmit: async (data, { setFieldTouched, setFieldError }) => {
            setLoading(true)
            setOpen(false);
            setTimeout(async () => {
            let res = await dispatch(UpdateBlockHour({ ...data,id }))
          
            if (res?.payload?.errors) {
                const { errors } = res?.payload
                if (errors) toast.error(errors, { duration: 5000 });

                ["date", "start_time", "end_time", "notes"].forEach(field => {
                    if (errors?.[field]) {
                        setFieldTouched(field, true);
                        setFieldError(field, errors?.[field]);
                    }
                }); 

                setLoading(false);
            } else if (res?.payload?.success === "true") {
                formik.resetForm();
                onRescheduleSuccess && onRescheduleSuccess();
                setLoading(false); 
                // setOpen(false);
                // toast.success(res?.payload?.success?.message) 
            }
        }, 5000); 
        },
    });

    // ✅ Fetch Data and Set Formik Values
    const handleOpen = async () => {
       
        setLoading(true);

        let res = await dispatch(getBlockhourId(id));
        console.log("Data comes", res);
       
        if (res?.payload?.data?.id) {
            formik.setValues({
                date: res.payload.data.date || '',
                start_time: res.payload.data.start_time || '',
                end_time: res.payload.data.end_time || '',
                notes: res.payload.data.notes || ''
            });
        }

        setLoading(false);
        setOpen(true);
    };

    return (
        <Grid container>
            {/* Edit Button */}
            <Tooltip title="Edit">
                <IconButton size="small" onClick={handleOpen}>
                    <MdModeEditOutline color={"#12B104"} fontSize={'20px'} />
                </IconButton>
            </Tooltip>

            {loading && <Waveloading fullSize />}

            {/* Modal */}
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Grid item xs={12} sx={{ mt: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant={'h4'} sx={{ mb: 1 }}>Update Block Hours</Typography>
                        <ClearIcon style={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                {/* Date Input */}
                                <Grid item xs={12} lg={12} mb={'16px'}>
                                    <FormDateInput 
                                        name="date"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        values={formik.values}
                                        handleChange={formik.handleChange}
                                    />
                                </Grid>

                                {/* Start Time */}
                                <Grid item xs={12} lg={12} mb={'16px'}>
                                    <Autocomplete
                                        name="start_time"
                                        options={timeSlots}
                                        getOptionLabel={(option) => option}
                                        value={formik.values.start_time}
                                        renderInput={(params) => (
                                            <TextField {...params} sx={inputStyle} placeholder={"Start Time"} />
                                        )}
                                        onChange={(event, newValue) => formik.setFieldValue("start_time", newValue)}
                                    />
                                    {formik.errors.start_time && formik.touched.start_time && 
                                        <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
                                            {formik.errors.start_time}
                                        </FormHelperText>
                                    }
                                </Grid>

                                {/* End Time */}
                                <Grid item xs={12} lg={12} mb={'16px'}>
                                    <Autocomplete
                                        name="end_time"
                                        options={timeSlots}
                                        getOptionLabel={(option) => option}
                                        value={formik.values.end_time}
                                        renderInput={(params) => (
                                            <TextField {...params} sx={inputStyle} placeholder={"End Time"} />
                                        )}
                                        onChange={(event, newValue) => formik.setFieldValue("end_time", newValue)}
                                    />
                                    {formik.errors.end_time && formik.touched.end_time && 
                                        <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
                                            {formik.errors.end_time}
                                        </FormHelperText>
                                    }
                                </Grid>

                                {/* Notes */}
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel sx={{ ...labelStyles }}>Notes:</FormLabel>
                                        <textarea
                                            className="MuiInputBase-input MuiOutlinedInput-input"
                                            rows={3}
                                            name="notes"
                                            value={formik.values.notes}
                                            onChange={formik.handleChange}
                                            style={{
                                                width: '100%',
                                                resize: 'vertical',
                                                padding: '8px',
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderRadius: '4px',
                                                fontFamily: 'inherit',
                                                fontSize: '1rem',
                                            }}
                                        />
                                        {formik.errors.notes && formik.touched.notes &&
                                            <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
                                                {formik.errors.notes}
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>

                                {/* Save Button */}
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        sx={{
                                            backgroundColor: '#09ABF1',
                                            borderColor: '#09ABF1',
                                            color: '#fff',
                                            mt: '16px',
                                            height: '44px',
                                            "&:hover": { color: '#09ABF1', borderColor: '#09ABF1' }
                                        }}
                                        type="submit"
                                        variant="outlined"
                                    >
                                        Update Block Hours
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    );
};


export default StaffUpdateModal;