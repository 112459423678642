import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Hidden from '@mui/material/Hidden'; // Import Hidden
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import { updateCrmCalenderFilter } from '../../store/crm/calender';
// import { updateCrmCalenderFilter } from 'your/actions'; // Import your action

const CrmPage = ({ crmStaffs, handleStaffChange, padding, paddingselect }) => {
  const dispatch = useDispatch();
  const crmCalenderStore = useSelector(state => state?.crmCalenderStore);

  const handleChange = (event) => {
    const newValue = event.target.value;
    dispatch(updateCrmCalenderFilter({ selectedStaff: newValue }));
    handleStaffChange(event, newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'max-content',
            maxWidth: '100%',
            overflowX: 'auto',
            background:"#edf2f7",
          }}
        >
          {/* Display Tabs on larger screens */}
          <Hidden smUp implementation="css">
            <FormControl>
              <Select
                value={crmCalenderStore.selectedStaff} 
                onChange={handleChange}
                sx={{
                  fontSize: '18px',
                  color: '#000',
                  textTransform: 'capitalize',
                  fontFamily: 'helvetica-lt-std-roman',
                  '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {  // Targeting a child class
                    padding:paddingselect
                },
                  
                }}
                
              >
                <MenuItem value="all">All</MenuItem>
                {crmStaffs?.map((item, index) => (
                  <MenuItem value={item?.id} key={index}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Hidden>

          {/* Display Tabs on smaller screens */}
      
        </Box>
      </Grid>
    </Grid>
  );
};

export default CrmPage;
