import React, { useCallback, useEffect, useState } from "react";
import UserLayout from "../../user";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import batch from "../../../assets/icon/batch.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DescriptionIcon from '@mui/icons-material/Description';
import {
  FormControl,
  FormLabel,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import useStyles from "./style";
import DateRange from "../../../components/date-range/date-range";
import { getAppointmentTypes, getGiftCardReports, getGiftCardReportstoken, getSaleReport, getSalesReports, getSalesReportsByType } from "../../../store/crm/reports";
import moment from "moment";
import Loader from "../../../components/loader/loader";
import { successConfirmation } from "../../../services/utills";
import { getAppointmentDetails } from "../../../store/crm/appointment";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import CustomModal from "../../../components/modal/customModal";
import cloudIcon from "../../../assets/images/cloud-download.png";
import { useLocation, useNavigate } from "react-router";
import { Divider, Link as MuiLink } from "@mui/material";
import Waveloading from "../../loading/Waveloading";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import ribbonorange from "../../../assets/images/ribbon-orange.svg";
import {
  content,
  handleEditReschedule,
  heading,
  ribbonwrapper,
  textul, wallet
} from "../../../components/card-appointment/CardAppointment";
import { getProviderStaff } from "../../../store/crm/staff";

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  // marginRight: "5px",
  // marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },

  ".MuiSelect-select": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  height: "44px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

export const boxwrapper = {
  border: "1px solid black",
  padding: "10px",
  height: "13vh",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "8px",
};

export const box = {
  display: "block",
  margin: "15px auto",
};
const statusColorMapping = {

  Pending: '#56CCF2',
  Confirmed: '#323681',
  UnConfirmedPending: '#56CCF2',
  ConfirmedPending: '#323681',
  CheckIn: '#F2C94C',
  Completed: '#219653',
  CheckOut: '#219653',
  Canceled: '#EB5757',
  Refund: '#EB5757',
  NoShow: '#9A9797',
  PendingConfirmation: '#56CCF2', 

}; 

const GiftCardReport = () => {
   
        const [dataFetched, setDataFetched] = useState(false);
        const [loadingSecondTable ,setloadingSecondTable ] = useState(false);
        const [startDate, setStartDate] = useState();
        const [endDate, setEndDate] = useState();
        const [staff, setStaff] = React.useState('');
        const classes = useStyles();
        const [selectedRow, setSelectedRow] = useState(false);
        const [loading, setLoading] = useState(false);
        const [age, setAge] = useState("");
        const [serviceName, setServiceName] = useState("");
        const [selectedBookingType, setSelectedBookingType] = useState("");
        const [selectedStatus, setSelectedStatus] = useState("");
        const [selectedRadio, setSelectedRadio] = useState("Booking");
        const [appointmentDetail, setAppointmentDetail] = useState(null)
        const reportsStore = useSelector(state => state?.reportsStore)
      
        const [shouldFetch, setShouldFetch] = useState(true);
        const dispatch = useDispatch();
        const { state } = useLocation()
        const {
            salesReports =[],
            giftCard = [],
          appointmentTypes = [],
          salesBooking = [],
          DetailsReports=[],
        } = reportsStore
       console.log("gift",giftCard,DetailsReports)
        const navigate = useNavigate()
      
        const crmAppointmentStore = useSelector((state) => state?.crmAppointmentStore)
        const { appointmentDetails = [] } = crmAppointmentStore
        const crmStaffStore = useSelector(state => state?.crmStaffStore)
        const {crmStaffs = []} = crmStaffStore
      
      
        const handleChange = (event) => {
          setStaff(event.target.value);
        };
        const handleChangeStatus = (event) => {
          setSelectedStatus(event.target.value);
        };
        const handleChanges = (event) => {
          setServiceName(event.target.value);
        };
        const validationSchema = Yup.object().shape({
          start_date: Yup.date().required("Start Date is required"),
          end_date: Yup.date().required("End Date is required"),
        });
      
      
      
      
        const handleSubmit = useCallback(async (values) => {
          const payload = {
            start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
            end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
            booking_type: selectedBookingType,
            // date_type: selectedRadio,
            status: selectedStatus,
            staff_id :staff,
            service_name :serviceName,
          };
       
      
          try {
            setLoading(true);
            await dispatch(getGiftCardReports(payload));
            // Additional success checks can be added here if needed
            setLoading(false);
            setDataFetched(true);
            return true; // Indicates success
          } catch (error) {
            console.error(error);
            setLoading(false);
            return false; // Indicates failure
          }
        }, [setLoading, dispatch, setDataFetched]);
        useEffect(() => {
          (async () => {
            dispatch(getAppointmentTypes())
          })()
        }, [])
        const handleBookingTypeChange = (event) => {
          setSelectedBookingType(event.target.value);
        };
       
        const handleRadioChange = (event) => {
          setSelectedRadio(event.target.value);
        };
        useEffect(() => {
          formik.setFieldValue('selectedBookingType', selectedBookingType);
        }, [selectedBookingType]);
        const today = new Date();
        const formik = useFormik({
          initialValues: {
            start_date: today,
            end_date: today,  
      
          }, 
          validationSchema: validationSchema,
          onSubmit: async (values) => {
            const payload = {
              start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
              end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null, 
              booking_type: selectedBookingType, 
              // date_type: selectedRadio,
              status: selectedStatus,
              staff_id :staff,
              search :serviceName,
      
            };
            try {
              setSelectedRow(false);
              setLoading(true);
              await dispatch(getGiftCardReports(payload));
              setLoading(false);
              setDataFetched(true);
            } catch (error) {
              console.error(error);
            }
          },
        });
      
        const handleRowClick = async (startDate, endDate, type) => {
          setSelectedRow(true);
          const payload = {
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            type: type,
            // date_type: selectedRadio,
            booking_type: selectedBookingType,
            status: selectedStatus,
            staff_id :staff,
            search :serviceName,
      
          };
      
          try {
            setloadingSecondTable(true);
            await dispatch(getGiftCardReportstoken(payload));
            setloadingSecondTable(false);
      
          } catch (error) {
            console.error(error);
          }
      
        };
        useEffect(() => {
          const fetchData = async () => {
            if (state && shouldFetch) {
              let startDateValue = state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : null;
              let endDateValue = state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : null;
      
              formik.setFieldValue('start_date', startDateValue);
              formik.setFieldValue('end_date', endDateValue);
      
              // Update the selectedBookingType based on the incoming data
              if (appointmentTypes.length > 0) {
                const matchingType = appointmentTypes.find((type) => type === state.value);
                if (matchingType) {
                  setSelectedBookingType(matchingType);
                }
              }
      
              let payload = {
                start_date: startDateValue,
                end_date: endDateValue,
                // date_type: selectedRadio,
                staff_id :staff,
                search :serviceName,
      
                // status:state.statusval,
              };
              const validValues = ["Active", "Redeemed", "Expired"];
              if(state?.status){
                if (validValues.includes(state.status)) {
                  payload.status = state.status;
                  setSelectedStatus(state.status);
                }
              }else{
                if (validValues.includes(state.value)) {
                  payload.status = state.value;  
                  setSelectedStatus(state.value);
                } else {
                  payload.booking_type = state.value;
                  setSelectedStatus("");
                }
              }
      
      
              try {
                setLoading(true);
                await dispatch(getSalesReports(payload));
                setLoading(false);
                setDataFetched(true);
      
      
                setShouldFetch(false);
              } catch (error) {
                console.error(error);
              }
            }
          };
      
          fetchData();
        }, [shouldFetch, state, selectedRadio, selectedBookingType, appointmentTypes]);
      
      
        function ButtonWithIcon() {
          return (
              <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: { md: 0, xs: 2 }, ml: { md: 2, xs: 1 } }}
                  className={classes.buttons}
      
              >
                <img src={cloudIcon} alt="" className={classes.buttonIcons} /> Download Excel
              </Button>
          );
        }
      
        const handleAppointmentDetail = async (id) => { 
      
          let { payload } = await dispatch(getAppointmentDetails(id))
      
          if (payload?.id) {
            setAppointmentDetail(payload)
          } else {
            setAppointmentDetail(null)
          }
        }
        const handleAppointmentClick = (url) => {
          navigate(url)
        }
        const status = appointmentDetails?.status;
        const background = statusColorMapping[status] || 'gray';
        const color = statusColorMapping[status] || 'gray';
        const borderColor = statusColorMapping[status] || 'gray';
      
        return (
      
            <>
                    <Typography variant="h5" color="initial">
                    <DescriptionIcon sx={{color:'#09ABF1',marginTop:'-3px'}}/>  Gift Card Report
                    </Typography>
              <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important" }}>
                <Grid
                    container
                    sx={{
                      alignItems: { md: "start", xs: "center" },
                      justifyContent: "space-between",
                      mt: 3,
                      flexWrap: "wrap",
                    }}
                >
                  <form onSubmit={formik.handleSubmit}>
      
                    <Grid item lg={12} xs={12} sx={{ display: "flex", flexWrap: "wrap", gap: '10px' }}>
                      <FormControl
                          sx={{ width: { xs: "100%", sm: "220px" }, mt: { xs: 2, sm: 1 } }}
                      >
                        <FormLabel>Search</FormLabel>                   
                        <TextField 
                            variant="outlined"
                            name={"search"} 
                            sx={inputStyle}
                            placeholder={"Name/Card Number"}
                            value={serviceName}
                            onChange={handleChanges}
                        /> 
                      </FormControl>
                      <FormControl
                          sx={{
                            minWidth: { xs: "100%", sm: "220px" },  
                            mt: { xs: 2, sm: 1 },
                          }}
                      >
                        <FormLabel>Select Date</FormLabel>
                        <DateRange
                            setStartDate={(date) => formik.setFieldValue('start_date', date)}
                            startDate={formik.values.start_date}
                            setEndDate={(date) => formik.setFieldValue('end_date', date)}
                            endDate={formik.values.end_date}
                            onBlur={formik.handleBlur}
                        />
                        {(formik.touched.start_date || formik.touched.end_date) && (!formik.values.start_date || !formik.values.end_date) && (
                            <div style={{ color: "red" }}>Date field is required</div>
                        )}
                      </FormControl>
        

      
                      <FormControl sx={{ minWidth: { xs: '100%', sm: '220px' }, mt: { xs: 2, sm: 1, md: "12px" } }}>
                        <FormLabel>Status</FormLabel>
                        <Select
                            value={selectedStatus}
                            onChange={handleChangeStatus}
                            displayEmpty
                            sx={selectStyle}
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Redeemed">Redeemed</MenuItem>
                          <MenuItem value="Expired">Expired</MenuItem>
                         
                          
                        </Select>
                      </FormControl>
    
                    </Grid>
                    <Grid item lg={12} xs={12} sx={{ display: "flex" , flexWrap:{sm:'nowrap', xs:'wrap'},justifyContent:{sm:'start', xs:'end'}}}>
                      {/* <RadioGroup
                          row
                          aria-labelledby="demo-form-control-label-placement"
                          name="position"
                          value={selectedRadio}
                          onChange={handleRadioChange}
                          defaultValue="top"
                          sx={{ mt: 2 }}
                      >
                        <FormControlLabel
                            value="Created"
                            control={<Radio />}
                            sx={{ mr: { xs: 1, md: 5 } }} 
                            label="By Created Date"
                        />
                        <FormControlLabel
                            value="Booking"
                            control={<Radio />}
                            label="By Booking  Date"
                        />
                      </RadioGroup> */}
                      <Box sx={{ mt: 2, ml: {sm:0, xs:0} }}>
                        <Button variant="contained" color="primary" type="submit">
                          Fetch Report  
                        </Button>
                      </Box>
                    </Grid>
                  </form>
                </Grid>
                {/* Buttons */}
              </Container>
              <Box sx={{mt: 2 }}>
                <UserLayout> 
                  {loading ? (  
                      <Waveloading />
                  ) : dataFetched ? (<>
                    <TableContainer component={Paper} >
                      <Table sx={{}} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell
                                align="left"
                                sx={{
                                  fontWeight: "700",
                                  borderBottom: 0,
                                }}
                            >
                              Appointment Type
                            </TableCell>
                           
                            <TableCell
                                align="left"
                                sx={{ fontWeight: "700", borderBottom: 0 }}
                            >
                              Total <span style={{ fontSize: "12px" }}>(exc tax and wallet)</span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "Total")}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    className={classes.odd}
                          >
                            <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                            Total number of gift cards sold.
      
                            </TableCell> 
                            <TableCell align="left">
                              <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"20px"
                                  }}
                              >
                         {giftCard?.data?.total_sold || 0}       
                              </Typography>
                            </TableCell>
                           
                          </TableRow>
      
     
                          <TableRow onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "Sales")}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    className={classes.odd}
                          >
                            <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                            Total revenue from gift card sales
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"20px"
                                  }}
                              >
                             $ {giftCard?.data?.total_revenue || 0}
                              </Typography>
                            </TableCell>
                           
                          </TableRow>
                          

                          <TableRow onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "Redeemed")}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    className={classes.odd}
                          >
                            <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                            Number of redeemed gift cards.
      
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                    fontSize:"20px"
                                  }}
                              >
                        {giftCard?.data?.used_cards || 0}       
                              </Typography>
                            </TableCell>
                           
                          </TableRow>
         <TableRow onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date,"Remaning")}
sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
 className={classes.odd}
    >
                            <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                            Remaining balance across active gift cards
                            </TableCell>
                            <TableCell align="left">
                              <Typography 
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",  
                                    color: "#00BD06",
                                    fontSize:"20px"
                                  }}
                              >
                          $ {giftCard?.data?.remaining_balance || 0}     
                              </Typography>
                            </TableCell>
                           
                          </TableRow>
                        </TableBody>
                      </Table> 
                    </TableContainer>
                  </>) : "" 
                  }
          
      
                  <div style={{ marginTop: "50px" }}>
      
      
                    {selectedRow ? ( 
                        <>  
                          {loadingSecondTable ? (
                              <Waveloading />
                          ) : (
      
                            <>
      
                            {/* <div style={{display:'flex',justifyContent:"flex-end",marginBottom:"10px"}}>
                            <ReactHTMLTableToExcel
                                // id="test-table-xls-button"
                                // style={{ border: "none !important" }}
                                // style={{border:"none!important"}}
                                table="saleReportPrint"
                                filename="SaleReport" 
                                sheet="tablexlsx"
                                buttonText={<ButtonWithIcon />}
                                className="custom-excel-button"
                            />
                          </div> */}
<TableContainer
  component={Paper}
  sx={{
    marginTop: "10px",
    maxHeight: 'calc(100vh - 250px)',
    overflowY: 'auto',
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "10px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "rgba(0, 0, 0, 0.2)",
      boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
    }
  }}
>
  <Table
    aria-label="simple table"
    id="printableTable"
  >
    <TableHead className={classes.tableHead} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <TableRow>
        <TableCell
          align="left"
          sx={{
            fontWeight: "700",
            borderBottom: 0,
          }}
        >
          Gift Card Number
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
            Card Holder (if applicable)
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
          Purchase Date
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
          Initial Value
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
          Redeemed Amount
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
          Remaining Balance
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
          Expiry Date
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontWeight: "700", borderBottom: 0 }}
        >
          Status
        </TableCell>
      </TableRow>
    </TableHead>

    <TableBody sx={{
      mt: 5,
      boxShadow: "none",
      maxHeight: 'calc(100vh - 250px)',
      overflowY: 'auto',
    }}>
      {DetailsReports?.data?.length ? (
        DetailsReports?.data?.map((item, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            className={classes.odd}
          >
            <TableCell component="th" scope="row"
              sx={{
                cursor: item?.id ? 'pointer' : 'default',
              }}
              style={{ color: '#2F80ED' }}
            >
              {item?.gift_card_number}
            </TableCell>
            <TableCell align="left">
              {item?.recipient_name}
            </TableCell>
            <TableCell align="left">{item?.purchase_date}</TableCell>
            <TableCell align="left">${item.initial_value}</TableCell>
            <TableCell align="left">${item?.redeemed_amount}</TableCell>
            <TableCell align="left">${item.remaining_balance}</TableCell>
            <TableCell align="left">
              {item?.expiry_date}
            </TableCell>
            <TableCell align="left">
              <div
                style={{
                  background: item?.status === 'Active' ? '#219653' :
                    item?.status === 'Expired' ? 'red' :
                      item?.status === 'Redeemed' ? 'blue' : 'gray',
                  borderRadius: '13px',
                  color: '#fff',
                  dispaly: "flex",
                  alignItems: 'center',
                  padding: '2px 12px',
                  textAlign: "center",
                  height: item?.status === 'Partial Paid' ? "41px" : "fit-content"
                }}>
                {item?.status}
              </div>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell
            sx={{
              height: '300px'
            }}
            colSpan={9}
            align="center"
          >
            no data
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>

                              </>
                          )}
      
                        </>
                    ) : ""}
                  </div>
                </UserLayout>
              </Box>
              <CustomModal
                  customWidth={"1000px"}
                  open={appointmentDetail !== null}
                  setOpen={() => setAppointmentDetail(null)}
              >
                <Box sx={{ background: '#F1F5F9' }}>
                  <Box 
                      sx={{
                        background: '#F1F5F9',
                        px: {xs:'10px',sm:'35px'},
                        mt: appointmentDetails?.status === 'Completed' ? '35px' : "20px"
                      }}>
                    <Grid container sx={{ background: '#fff', p: {xs:'10px',sm:'35px'}, borderRadius: '7px', flexDirection:{xs:'column',md:'row'} }}>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography>Customer Name:</Typography>
                          <Box>
                            <div>
                              <Typography sx={{
                                ...textul,
                                textTransform: 'capitalize',
                                cursor: "pointer"
                              }} onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${appointmentDetails?.customer?.id}`)}>{appointmentDetails?.customer?.name}</Typography>
                            </div>
                            <Box sx={ribbonwrapper}>
                              {
                                  appointmentDetails?.customer?.customer_batch && (
                                      appointmentDetails?.customer?.customer_batch !== "New User" ? (
                                          <>
                                            {appointmentDetails?.customer?.customer_batch.includes("Expire/Cancel") ? (
                                                <>
                                                  <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                                  <Typography style={{ color: "#FF0000" }}>
                                                    {appointmentDetails?.customer?.customer_batch}
                                                  </Typography>
                                                </>
                                            ) : (
                                                <>
                                                  {appointmentDetails?.customer?.customer_batch.includes("Declined") ? (
                                                      <>
                                                        <img style={{ marginTop: "4px",width:"20px" }} src={ribbonorange} alt={'member'} />
                                                        <Typography style={{  color: "#f55d0b" }}>
                                                          {appointmentDetails?.customer?.customer_batch}
                                                        </Typography>
                                                      </>
                                                  ) : (
                                                      <>
                                                        <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                                        <Typography style={{ color: "#219653" }}>
                                                          {appointmentDetails?.customer?.customer_batch}
                                                        </Typography>
                                                      </>
                                                  )}
                                                </>
                                            )}
                                          </>
                                      ) : (
                                          <>
                                            <Typography style={{ color: "#09ABF1" }}>
                                              {appointmentDetails?.customer?.customer_batch}
                                            </Typography>
                                          </>
                                      ))}
                            </Box>
                            <div>
                              <MuiLink href="#" color="inherit">
                                Member ID {appointmentDetails?.customer?.id}
                              </MuiLink>
                            </div>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item sx={{ px: '24px', dispaly:{xs:'none',md:'block'} }}>
                        <Divider
                            orientation="vertical"
                            style={{ borderColor: "#9A9797", height: "100%", width: "2px" }}
                        />
                      </Grid>
                      <Grid
                          item
                          xs={12}
                          sm={8}
                          md={3}
                          sx={{
      
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            my: {xs:'15px',md:'0px'}
                          }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ minWidth: '100px' }}>Wallet:</Typography>
                          <Typography sx={content}>
                            ${appointmentDetails?.customer?.wallet_point}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ minWidth: '100px' }}>Phone:</Typography>
                          <Typography sx={content}>{appointmentDetails?.customer?.phone}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography sx={{ ...heading, minWidth: '100px' }}>Email:</Typography>
                          <Typography sx={content}>{appointmentDetails?.customer?.email}</Typography>
                        </Box>
                      </Grid>
                      <Grid item sx={{ display: "flex", marginLeft:{xs:"none",md: "auto"} }} xs={12} sm={4} md={2}>
                        <Box
                            sx={{
                              ...boxwrapper,
                              height: 'max-content',
                              borderColor,
                              cursor: 'pointer',
                            }}
                        >
                          <Box
                              sx={{
                                ...box,
                                height: 'max-content',
                                // background: appointmentDetails?.status === 'Confirmed' ? 'green' : 'red',
                                // my: appointment?.plan?.id ? '5px' : '',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentDetails?.id}`)}
                          >
      
                            <Typography sx={{ ...content, color: "#fff", background, borderRadius: "22px", padding: '2px 6px' }}>
                              Booking No
                            </Typography> 
                            <Typography
                                variant="h6"
                                sx={{
                                  color
                                }}
                            >
                              {appointmentDetails.id}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
      
                  <AppointmentDetails appointmentDetails={appointmentDetails} />
                </Box>
              </CustomModal>
            </>
        );
      };

export default GiftCardReport