import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { Avatar, Button, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import {
    addAppointmentMileStone,
    deleteAppointmentGallery,
    getAppointmentGallery
} from "../../../../../store/crm/appointment/attachment";
import { useParams } from "react-router-dom";
import { scrollCSS } from "../../../../../App";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MySwal, successConfirmation } from "../../../../../services/utills";
import Loader from "../../../../../components/loader/loader";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from "moment";
import {
    addCrmCustomerMileStone,
    deleteCrmCustomerGallery,
    deleteCrmCustomerGalleryFile,
    getCrmCustomerGallery
} from "../../../../../store/crm/crm/crm-details";
import Waveloading from '../../../../loading/Waveloading';
import CustomModal from '../../../../../components/modal/customModal';

const CrmGallery = () => {
    const [attachmentDetails, setAttachmentDetails] = useState(null)
    const [onHover, setOnHover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeView, setActiveView] = useState("card")
    const [checkedAttachments, setCheckedAttachments] = useState([]);
    const [modalImage,setModalImage]=useState(null)


    const crmDetailsStore = useSelector(state => state?.crmDetailsStore)
    const { customerGalleries = [] } = crmDetailsStore


    const dispatch = useDispatch()
    const { user_id } = useParams()

    useEffect(() => {
        (async () => {
            await dispatch(getCrmCustomerGallery(user_id))
          })()
    }, [])


    const handleDelete = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{ mb: 4 }}>
                <Typography sx={{}}>
                    Are you sure? you want to remove this gallery.
                </Typography>
                <Box sx={{ mt: 3, }}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(deleteCrmCustomerGallery({ id: id, customer_id: user_id }))
            if (res?.payload?.success === "true") {
                await dispatch(getCrmCustomerGallery(user_id))
                await successConfirmation('Gallery Deleted Successfully')
            }
            setLoading(false)
        }
    }
    const handleDeleteFile = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{ mb: 4 }}>
                <Typography sx={{}}>
                    Are you sure? you want to remove this gallery file.
                </Typography>
                <Box sx={{ mt: 3, }}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(deleteCrmCustomerGalleryFile({ id: id, customer_id: user_id }))
            if (res?.payload?.success === "true") {
                await dispatch(getCrmCustomerGallery(user_id))
                await successConfirmation('Gallery File Deleted Successfully')
            }
            setLoading(false)
        }
    }
    const handleMoveMilestone = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{ mb: 4 }}>
                <Typography sx={{}}>
                    Are you sure? you want to add  this gallery to milestone?
                </Typography>
                <Box sx={{ mt: 3, }}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Add It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(addCrmCustomerMileStone({
                id: id,
                customer_id: user_id
            }))
            if (res?.payload?.success === "true") {
                await successConfirmation('Image Added Into Milestone Successfully')
            }
            setLoading(false)
        }
    }

    const handleCheckboxToggle = async (id) => {
        if (checkedAttachments.includes(id)) {
            setCheckedAttachments(checkedAttachments.filter(attachmentId => attachmentId !== id));
          } else {
            setCheckedAttachments([...checkedAttachments, id]);
          }

        try {
            let confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: <Box sx={{ mb: 4 }}>
                    <Typography sx={{}}>
                        Are you sure? you want to add  this gallery to milestone?
                    </Typography>
                    <Box sx={{ mt: 3, }}>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '16px',
                                px: '20px',
                                mx: '10px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.clickConfirm()}
                        >
                            Yes,Add It.
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: "#fff",
                                minWidth: "max-content",
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                px: '10px',
                                mx: '10px',
                                fontSize: '16px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => MySwal.close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            })
            if (!!confirm?.isConfirmed) {
                setLoading(true)
                let res = await dispatch(addCrmCustomerMileStone({
                    id: id,
                    customer_id: user_id
                }))
                if (res?.payload?.success === "true") {
                    await successConfirmation('Image Added Into Milestone Successfully')
                }
                setLoading(false)
            }
            else {
                setCheckedAttachments(checkedAttachments.filter(attachmentId => attachmentId !== id));
              }
        } catch (error) {
        }
    };


    const handleSelectedGallery = (attachment) => {

        if (attachmentDetails?.id) {
            setAttachmentDetails(attachmentDetails?.id === attachment?.id ? null : attachment)
            // setActiveView("card")
        } else {
            setAttachmentDetails(attachment)
            setActiveView("list")
        }
    }
    return (
        <Box
            sx={{
                background: "#fff",
                minHeight: '100px',
                borderRadius: "8px",
                p: '24px',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    mb: "32px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    Gallery
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: '2px',
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={() => setActiveView("card")}
                    >
                        <DashboardIcon
                            sx={{ color: activeView === 'card' ? '#09ABF1' : '#000' }}
                        />
                    </IconButton>
                    <IconButton
                        onClick={() => setActiveView("list")}
                    >
                        <ViewStreamIcon
                            sx={{ color: activeView === 'list' ? '#09ABF1' : '#000' }}
                        />
                    </IconButton>
                </Box>
            </Box>

            {loading ? <Waveloading fullSize /> : ""}

            {activeView === 'list' &&
                <Grid
                    container
                    sx={{
                        gap: '20px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                        ...scrollCSS,
                    }}
                >
                    {
                        customerGalleries?.data?.length > 0 ?
                            customerGalleries?.data.map((attachment) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={attachment?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSelectedGallery(attachment)}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {attachment?.tag?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(attachment)}
                                        >
                                            {attachmentDetails?.id === attachment?.id ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}

                                        </IconButton>
                                    </Box>
                                    {attachmentDetails?.id === attachment?.id && <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap: 'wrap',
                                              
                                            }}

                                        >
                                            {attachment?.files?.length > 0 ?
                                                attachment?.files.map((file, index) => (
                                                    <Box
                                                        key={index}
sx={{ position: 'relative'}}
                                                    >
                                                        <Checkbox
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '0px',
                                                                right: '0px',
                                                                zIndex: '2',
                                                                pointer: "cursor",
                                                            }}
                                                            checked={file?.is_milestone === 1 || checkedAttachments.includes(file?.id)}
                                                            onChange={() => handleCheckboxToggle(file?.id)}
                                                        />
                                                        <Avatar
                                                            src={file?.file}
                                                            onClick={()=>setModalImage(file?.file)}
                                                            sx={{
                                                                cursor: 'pointer',
                                                                height: '180px',
                                                                width: '180px',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: 'space-between',
                                                            mt: "8px"
                                                        }}>
                                                            <Typography>
                                                                {moment(file?.created_at).format("YYYY-MM-DD")}
                                                            </Typography>
                                                            <IconButton
                                                                sx={{
                                                                    fontSize: "15px",

                                                                    p: "5px",

                                                                    background: "#EB5757",
                                                                    border: '1px solid #EB5757',



                                                                    "&:hover": {
                                                                        border: '1px solid #EB5757',
                                                                        background: '#fff',
                                                                        "svg": {
                                                                            color: '#EB5757'
                                                                        }
                                                                    }
                                                                }}
                                                                onMouseEnter={() => {
                                                                    setOnHover(true)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setOnHover(false)
                                                                }}
                                                                onClick={() => handleDeleteFile(file?.id)}
                                                            >
                                                                <DeleteOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                ))
                                                :
                                                <Avatar
                                                    sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    variant="rounded"
                                                />
                                            }

                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "16px"
                                        }}>

                                            <Typography
                                                sx={{
                                                    fontFamily: 'helvetica-lt-std-bold',
                                                    background: '#F2F2F2',
                                                    mr: '10px',
                                                    pl: '5px',
                                                    pr: '3px',
                                                }}
                                            >
                                                Comments
                                            </Typography>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {attachment?.comment}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                    }
                                </Grid>
                            ))
                            :
                            <Grid xs={12} item>
                                <Typography sx={{ textAlign: 'center' }}>None</Typography>
                            </Grid>
                    }
                    <Grid xs={12} item>
                        <Typography style={{ marginTop: "20px", fontWeight: 600, fontFamily: 'helvetica-lt-std-bold' }}>Appointment</Typography>
                    </Grid>           {
                        customerGalleries?.appointments?.length > 0 ?
                            customerGalleries?.appointments.map((attachment) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={attachment?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleSelectedGallery(attachment)}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {attachment?.tag?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(attachment)}
                                        >
                                            {attachmentDetails?.id === attachment?.id ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}

                                        </IconButton>
                                    </Box>
                                    {attachmentDetails?.id === attachment?.id && <Box>
                                        <Box
                                            sx={{ 
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            {attachment?.files?.length > 0 ?
                                                attachment?.files.map((file, index) => (
                                                    <Box
                                                         key={index}
                                                    >
                                                          <Checkbox
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '0px',
                                                                right: '0px',
                                                                zIndex: '2',
                                                                pointer: "cursor",
                                                            }}
                                                            checked={file?.is_milestone === 1 || checkedAttachments.includes(file?.id)}
                                                            onChange={() => handleCheckboxToggle(file?.id)}
                                                        />
                                                        <Avatar
                                                            src={file?.file}
                                                            // onClick={() => handleMoveMilestone(file?.id)}
                                                            onClick={()=>setModalImage(file?.file)}
                                                            sx={{
                                                                cursor: 'pointer',
                                                                height: '180px',
                                                                width: '180px',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: 'space-between',
                                                            mt: "8px"
                                                        }}>
                                                            <Typography>
                                                                {moment(file?.created_at).format("YYYY-MM-DD")}
                                                            </Typography>
                                                            <IconButton
                                                                sx={{
                                                                    fontSize: "15px",

                                                                    p: "5px",

                                                                    background: "#EB5757",
                                                                    border: '1px solid #EB5757',



                                                                    "&:hover": {
                                                                        border: '1px solid #EB5757',
                                                                        background: '#fff',
                                                                        "svg": {
                                                                            color: '#EB5757'
                                                                        }
                                                                    }
                                                                }}
                                                                onMouseEnter={() => {
                                                                    setOnHover(true)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setOnHover(false)
                                                                }}
                                                                onClick={() => handleDelete(attachment?.id)}
                                                            >
                                                                <DeleteOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                ))
                                                :
                                                <Avatar
                                                    sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    variant="rounded"
                                                />
                                            }

                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "16px"
                                        }}>

                                            <Typography
                                                sx={{
                                                    fontFamily: 'helvetica-lt-std-bold',
                                                    background: '#F2F2F2',
                                                    mr: '10px',
                                                    pl: '5px',
                                                    pr: '3px',
                                                }}
                                            >
                                                Comments
                                            </Typography>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {attachment?.comment}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                    }
                                </Grid>
                            ))
                            :
                            ""
                    }

                </Grid>
            }
            {activeView === 'card' &&
                <Grid
                    container
                    sx={{
                        gap: '30px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                        ...scrollCSS,
                    }}
                >
                    {customerGalleries?.data?.length > 0 ?
                        customerGalleries?.data.map((attachment) => (
                            <Grid
                                key={attachment?.id}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => !onHover && handleSelectedGallery(attachment)}
                            >
                                <IconButton
                                    sx={{
                                        fontSize: "15px",
                                        position: "relative",
                                        p: "5px",
                                        zIndex: '1',
                                        background: "#EB5757",
                                        border: '1px solid #EB5757',
                                        m: '5px',
                                        left: "9rem",
                                        top: "0px",
                                        "&:hover": {
                                            border: '1px solid #EB5757',
                                            background: '#fff',
                                            "svg": {
                                                color: '#EB5757'
                                            }
                                        }
                                    }}
                                    onMouseEnter={() => {
                                        setOnHover(true)
                                    }}
                                    onMouseLeave={() => {
                                        setOnHover(false)
                                    }}
                                    onClick={() => onHover && handleDelete(attachment?.id)}
                                >
                                    <DeleteOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
                                </IconButton>
                                <Box
                                    sx={{
                                        height: '185px',
                                        mt: "-41px",
                                        width: '185px',
                                        p: '10px',
                                        border: '1px solid #BDBDBD',
                                        borderRadius: '4px',
                                    }}
                                >
                                    {
                                        attachment?.files?.length > 0 ?
                                            attachment?.files.map((file, index) => index < 2 && (
                                                <Avatar
                                                    key={index}
                                                    src={file?.file}
                                                    sx={{
                                                        height: attachment?.files?.length > 1 ? '145px' : '100%',
                                                        width: attachment?.files?.length > 1 ? '145px' : '100%',
                                                        position: "relative",
                                                        top: index === 1 ? "-128px" : '',
                                                        left: index === 1 ? "17px" : '',
                                                    }}
                                                    variant="rounded"
                                                />
                                            ))
                                            :
                                            <Avatar
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                                variant="rounded"
                                            />
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: "center",
                                        mt: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            maxWidth: '185px',
                                            textTransform: 'capitalize',
                                            fontFamily: 'helvetica-lt-std-bold',
                                        }}
                                    >
                                        {attachment?.tag?.tag_name}
                                    </Typography>

                                    {/*<Typography>*/}
                                    {/*    {moment(attachment?.created_at).format("YYYY-MM-DD")}*/}
                                    {/*</Typography>*/}
                                </Box>
                            </Grid>
                        ))
                        :
                        ""
                    }
                    <Grid xs={12} item>
                        <Typography style={{ marginTop: "20px", fontWeight: 600, fontFamily: 'helvetica-lt-std-bold' }}>Appointment </Typography>
                    </Grid>


                    {customerGalleries?.appointments?.length > 0 ?
                        customerGalleries?.appointments.map((attachment) => (
                            <Grid
                                key={attachment?.id}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => !onHover && handleSelectedGallery(attachment)}
                            >
                                <IconButton
                                    sx={{
                                        fontSize: "15px",
                                        position: "relative",
                                        p: "5px",
                                        zIndex: '1',
                                        background: "#EB5757",
                                        border: '1px solid #EB5757',
                                        m: '5px',
                                        left: "9rem",
                                        top: "0px",
                                        "&:hover": {
                                            border: '1px solid #EB5757',
                                            background: '#fff',
                                            "svg": {
                                                color: '#EB5757'
                                            }
                                        }
                                    }}
                                    onMouseEnter={() => {
                                        setOnHover(true)
                                    }}
                                    onMouseLeave={() => {
                                        setOnHover(false)
                                    }}
                                    onClick={() => onHover && handleDelete(attachment?.id)}
                                >
                                    <DeleteOutlineIcon sx={{ color: '#fff', fontSize: '20px' }} />
                                </IconButton>
                                <Box
                                    sx={{
                                        height: '185px',
                                        mt: "-41px",
                                        width: '185px',
                                        p: '10px',
                                        border: '1px solid #BDBDBD',
                                        borderRadius: '4px',
                                    }}
                                >
                                    {
                                        attachment?.files?.length > 0 ?
                                            attachment?.files.map((file, index) => index < 2 && (
                                                <Avatar
                                                    key={index}
                                                    src={file?.file}
                                                    sx={{
                                                        height: attachment?.files?.length > 1 ? '145px' : '100%',
                                                        width: attachment?.files?.length > 1 ? '145px' : '100%',
                                                        position: "relative",
                                                        top: index === 1 ? "-128px" : '',
                                                        left: index === 1 ? "17px" : '',
                                                    }}
                                                    variant="rounded"
                                                />
                                            ))
                                            :
                                            <Avatar
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                                variant="rounded"
                                            />
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: "center",
                                        mt: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            maxWidth: '185px',
                                            textTransform: 'capitalize',
                                            fontFamily: 'helvetica-lt-std-bold',
                                        }}
                                    >
                                        {attachment?.tag?.tag_name}
                                    </Typography>

                                    {/*<Typography>*/}
                                    {/*    {moment(attachment?.created_at).format("YYYY-MM-DD")}*/}
                                    {/*</Typography>*/}
                                </Box>
                            </Grid>
                        ))
                        :
                        <Typography sx={{width:"100%",textAlign:"center"}}>  No apppointment data</Typography>
                    
                    }
                </Grid>
            }
               <CustomModal
                customWidth={"1000px"}
                open={modalImage!==null}
                setOpen={() => setModalImage(null)}
            >
                <Box>

            <Avatar
                 src={modalImage}
                // onClick={()=>handleMoveMilestone(file?.id)}
                sx={{
                    cursor:'pointer',
                    height: '100%',
                    width: '100%',
                }}
                variant="rounded"
            />
            </Box>
            </CustomModal>
        </Box>
    );
};

export default CrmGallery;