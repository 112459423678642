import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {FormControl, Grid, Typography} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import Loader from "../../../../components/loader/loader";
import {clinicstaff, getAllClinicsStaff, updatestaff} from "../../../../store/clinics/clinicSettings/clinic-staff";
import CardTable from "../../../../components/card-table/Cardtabel";
import Box from "@mui/material/Box";
import BackendPagination from '../../../../components/pagination/backendPagination';
import Waveloading from '../../../loading/Waveloading';

const Staff = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('')

    const params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clinicsStaffSliceStore = useSelector(state => state?.clinicsStaffSliceStore)
    const user = useSelector(state => state?.authStore?.user)
 console.log ("issue ", clinicsStaffSliceStore?.staffs)
    const {currentPage} = clinicsStaffSliceStore  
    console.log("call",clinicsStaffSliceStore)
    useEffect(() => {
        (async () => {
            if (!clinicsStaffSliceStore?.staffs?.length) {
                setLoading(true)
            }
            await dispatch(clinicstaff(params?.clinic_id))
            setLoading(false)
        })()
    }, [clinicsStaffSliceStore?.staffs?.length, params?.clinic_id])
    const refreshData = async () => {
        setLoading(true);
        try {
            await dispatch(clinicstaff(params?.clinic_id))
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false); 
    };
    const handleEdit = (row) => {
        // Determine the role-based path segment ('crm' for roles 2 and 4, 'admin' otherwise)
        const rolePath = user?.role_id === 2 || user?.role_id === 4 ? "crm" : "admin";
        navigate(`/${rolePath}/configuration/clinic/${params?.clinic_id}/edit-staff/${row?.id}`);
    }

    const handleSchedule = (row) => {
        // Determine the role-based path segment ('crm' for roles 2 and 4, 'admin' otherwise)
        const rolePath = user?.role_id === 2 || user?.role_id === 4 ? "crm" : "admin";
        navigate(`/${rolePath}/configuration/clinic/${params?.clinic_id}/hours/${row?.id}`);
    }

    const handleClick = (section) => {
        if (params?.clinic_id) {
            // Determine the role-based path segment ('crm' for roles 2 and 4, 'admin' otherwise)
            const rolePath = user?.role_id === 2 || user?.role_id === 4 ? "crm" : "admin";
            navigate(`/${rolePath}/configuration/clinic/${params?.clinic_id}${section}`);
        }
    }



    const fetchPageData = async(page) => {
        console.log("call issue",page)
        await dispatch(updatestaff({ currentPage : page }))
        await refreshData() 
  
    }
console.log("roleid",user?.role_id,user)
    return (
        <Grid container p={'20px'}>
            <Grid item xs={12}
                  sx={{mb: '16px', display: 'flex', justifyContent: {sm:'flex-end', xs:'flex-start'},flexWrap:'wrap', alignItems: 'center', width: '100%'}}>
                <FormControl sx={{mr: "10px", mb:{sm:0, xs:1}}}>
                    <SimpleInput
                        search={search}
                        setSearch={setSearch}
                    />
                </FormControl>
                <Box sx={{mr: '10px', mb:{sm:0, xs:1}}}>
                {/* staff schedule */}
                {user?.role_id !== 4 ? 
                    <CustomButton val={ 'Staff scheduler'} size={'large'} handleClick={() => handleClick('/scheduler')}/>:<CustomButton val={ 'staff schedule'} size={'large'} handleClick={() => handleClick('/scheduler')}/>}
                </Box>
                {user?.role_id !== 4 ? <CustomButton val={'Add Staff'} size={'large'} handleClick={() => handleClick('/add-staff')}/> : null}
  

                {/* <CustomButton val={'Add Staff'} size={'large'} handleClick={() => handleClick('/add-staff')}/> */}
            </Grid>
            <Grid container>

           
            <Grid item xs={12}>
            {loading ? <Waveloading /> :
                clinicsStaffSliceStore?.staffs?.data?.length > 0 ?

                    <CardTable
                        type={'staff'}
                        search={search}
                        cardData={clinicsStaffSliceStore?.staffs?.data}
                        handleEdit={handleEdit}
                        handleSchedule={(data) => handleSchedule(data)} 
                        staffW={"100%"}
                    />
                    :<Typography sx={{textAlign:'center',mt:'30px'}}>
                        No staffs available
                    </Typography>
                }
            </Grid>
          
        </Grid>
        <Grid item xs={12} style={{marginTop:"10px" }} >
            {clinicsStaffSliceStore?.staffs?.meta?.last_page > 1 &&   
            <BackendPagination
                count={clinicsStaffSliceStore?.staffs?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData}
            />
        }
        
        </Grid>
        </Grid>
    );
};

export default Staff;