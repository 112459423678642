import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';


const PriceSelectorModal = ({ open, onClose, onSelectPrice, service }) => {
    const handlePriceSelect = (event) => {
      const selectedPriceId = event.target.value;    
      const selectedPrice = service.prices.find(price => price.id === selectedPriceId);
    
      if (selectedPrice) {
        const numericPrice = parseFloat(selectedPrice.price);
        const giftCardId = selectedPrice.gift_card_id; // Assuming gift_card_id is a property of selectedPrice
        onSelectPrice(numericPrice, giftCardId); // Pass both numericPrice and giftCardId to onSelectPrice
      } else {
        console.error(`Selected price with id ${selectedPriceId} not found.`);
      }
     
      onClose();
    };
  
    // Ensure service and prices are defined and valid
    if (!service || !service.prices || !Array.isArray(service.prices) || service.prices.length === 0) {
      return null; // Render nothing if service or prices are not available
    }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
        
        <Typography variant="h6" align="center" gutterBottom>Select a Price</Typography>
        
        <FormControl fullWidth>
          <InputLabel id="price-select-label">Price</InputLabel>
          <Select
            labelId="price-select-label"
            id="price-select"
            value="" // Default value, adjust as per your logic
            onChange={handlePriceSelect}
          >
            {service.prices.map((price) => (
              <MenuItem key={price.id} value={price.id}>
                {price.price} 
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={onClose} color="primary" variant="contained">OK</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PriceSelectorModal;


