import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import { apiHeaders } from "../../../lib/apiHeader";
import toast from "react-hot-toast";

// Thunk to fetch chat list

export const getZoeyAi= createAsyncThunk('getZoeyAi', async (data,{dispatch}) => {
    try {
      const response = await axios.post(apis.gpt_api, {...data},{   
        headers: apiHeaders()   
      })  
    
      if (response?.data?.success==='true'){
        // await dispatch(getAllManual())      
  
        return  response.data   
      }else {   
        return response?.data
      }  
    }catch (err){   
      console.log(err)  
      if (err?.response?.status!==200){
        return {
          data :err?.response,   
          errors:true  
        }       
      }
    }
  })


export const ZoeyAiSlice = createSlice({ 
  name: 'ZoeyAiSlice',
  initialState: {
    Chatlist: [],
  
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getZoeyAi.fulfilled, (state, action) => {
        state.Chatlist = action.payload || [];
      })
     
  },
});



export default ZoeyAiSlice.reducer;
