import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import Box from "@mui/material/Box";
import { Grid, Typography, Button } from "@mui/material";
import TimelineContent from "@mui/lab/TimelineContent";
import {getBorderColor} from "../../../../../services/utills";
import moment from "moment";
import { useNavigate } from "react-router";
import Divider from "@mui/material/Divider";
import AppointmentDetails from "../appointmentDetails";
import { getAppointmentDetails } from "../../../../../store/crm/appointment";
import Waveloading from "../../../../loading/Waveloading";
import CustomModal from "../../../../../components/modal/customModal";
import { useDispatch } from "react-redux";
  
const Treatment = ({activity = [] }) => {
    const [appointmentDetails, setAppointmentDetail] = useState(null)
    const [loading, setLoading] = useState(null)
    const dispatch = useDispatch()
console.log("activity data",activity)
const handleAppointmentDetail = async (booking_id) => {
    setLoading(true)
    let {payload} = await dispatch(getAppointmentDetails(booking_id))
    setLoading(false)
    if (payload?.id){
      setAppointmentDetail(payload)
    }else {    
      setAppointmentDetail(null)
    } 
  }   

  const processedActivity = activity.map(item => ({
    ...item,
    activity: item.activity
        .replace(/Booking #\s*(\d+)/g, 'Booking #<span style="color: #09ABF1">$1</span>')
        .replace(/Invoice #\s*(\d+)/g, 'Invoice #<span style="color:#09ABF1">$1</span>'),
    description: item.description
        .replace(/Booking #\s*(\d+)/g, 'Booking #<span style="color: #09ABF1">$1</span>')
        .replace(/Invoice #\s*(\d+)/g, 'Invoice #<span style="color:#09ABF1">$1</span>'),
}));

    return (
        <Box>
              {loading? <Waveloading fullSize/> :""}
        <Timeline
            sx={{
                p: "0",
                my: "0",
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
            position="right"
        >
            {processedActivity.length === 0 ? (
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    No Activity Found
                </Typography>
            ) : (
                processedActivity.map((item, index) => (
                    <TimelineItem sx={{ minHeight: "200px" }} key={index}>
                        <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <TimelineConnector
                                sx={{
                                    background: "#2F80ED",
                                    width: "16px",
                                    borderRadius: "10px",
                                    border: "6px solid #fff",
                                    maxHeight: "50px",
                                    mb: "-10px",
                                    borderTop: "6px solid #fff",
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            />
                            <TimelineDot
                                variant="outlined"
                                sx={{
                                    mx: "auto",
                                    my: "0",
                                    borderWidth: "5px",
                                    borderColor: '#2F80ED',
                                    zIndex: "10",
                                }}
                            />
                            <TimelineConnector
                                sx={{
                                    background: "#2F80ED",
                                    width: "16px",
                                    borderRadius: "10px",
                                    border: "6px solid #fff",
                                    borderBottomLeftRadius: "0",
                                    borderBottomRightRadius: "0",
                                    borderBottom: '0',
                                    mt: "-7px",
                                }}
                            />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pl: { xs: "0px", sm: "32px" }, pb: { xs: "8px", sm: "32px" }, pt: 0, pr: 0 }}>
                            <Box
                                sx={{
                                    p: { xs: "12px", sm: "24px" },
                                    background: "#fff",
                                    height: "100%",
                                    borderRadius: "10px",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "right" , flexWrap:{sm:'nowrap', xs:'wrap'}}}>
                                    <Grid item lg={4} style={{ marginTop: '10px' }}>
                                        <Typography variant="h6">
                                            {moment(item.created_at).format("dddd MMMM D, YYYY")}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {moment(item.created_at).format("hh:mm A")}
                                        </Typography>
                                        <Typography
                                            sx={{ fontWeight: 'bold' }}
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: item.activity }}
                                        />
                                    </Grid>
                                    <Divider sx={{ height: 100, m: '10px', borderColor: 'blue', display:{sm:'block', xs:'none'} }} orientation="vertical" />
                                    <Grid item lg={7}>
                                        <Typography   onClick={() => item?.booking_id && handleAppointmentDetail(item?.booking_id)} sx={{ marginTop: '15px' }} variant="body1"
                                            dangerouslySetInnerHTML={{ __html: item.description }}
                                        />
                                        {item?.booking_id ? (
                                            <>

                                       <Box sx={{ display: "flex" }}>
                                                   <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                        Date:
                                                    </Typography>
                                                    <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                                                        {item?.date}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex" }}>
                                                    <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                        Time:
                                                    </Typography>
                                                    <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                                                        {item?.time}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex" }}>
                                                    <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                        Service Name:
                                                    </Typography>
                                                    <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                                                        {item?.service_name}
                                                    </Typography>
                                                </Box>
                                            </>
                                        ) : null}
                                    </Grid>
                                </Box>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                ))
            )}
        </Timeline>
        <CustomModal
              customWidth={"1000px"}
              open={appointmentDetails !== null}
              setOpen={() => setAppointmentDetail(null)}
          >
            <Box>
              <AppointmentDetails appointmentDetails={appointmentDetails}/>
            </Box>
          </CustomModal>
    </Box>
    );
}

export default Treatment;
