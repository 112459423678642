import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, TextField, TextareaAutosize, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Label } from '@mui/icons-material';
import { RemoveAccount, setEmail } from '../../store/crm/crm';
import { useFormik } from 'formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 480px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const wrapper={
    alignItems:"center",
  }
  const heading={
    fontSize:"18px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'400',
    textAlign:"center",
    width:'100%'

  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}
 export const textareaStyle = {

  height: '250px',
  border:"none",
    background: "#EDF2F7",
    borderRadius: '4px',
    outline:"none",
      "&:focus": {
        borderColor: "#EDF2F7",
        outline: "none",
      },
      "&:hover": {
        borderColor: "#EDF2F7",
        outline: "none",
    }
    
};

const RemovalAccountModal = () => {
  const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const validate = (values) => {
      const errors = {};
  
      if (!values.message) {
        errors.message = "Message is required";
      }
  
      if (!values.subject) {
        errors.subject = "Subject is required";
      }
  
      return errors;
    };
  
    const formik = useFormik({
      initialValues: {
        message: "",
        subject: "",
      },
      validate,
      onSubmit: (values) => {
      handleSentemail(values.message, values.subject);
      },
    });
  
    const handleSentemail= async (message, subject) => {
      try {
        const payload = {
          message: message,
          subject: subject,
        };
        dispatch(RemoveAccount(payload));
        toast.success('Set email successfully'); // Display success toast
        handleClose();
      } catch (error) {
        console.log('Error sending email', error);
      }
    };
    const handleCloseModal = () => {
      handleClose(); 
    };
    
  return (
    <>
      <CustomButtoncolor
      handleClick={handleOpen}
       mainColor={"#09ABF1"}
       size="md"
       val='Request for Account Removal'
       style={{
        padding:'7px 5px',
        fontSize: "10px",
        minWidth:'max-content',
        borderRadius: "6px!important",
        width: "max-content",
        "@media (max-width: 420px)": {
          padding:'7px 0px',
            width: '320px'
        },
    }}                                  
    />
   <Modal
   style={{}}
     open={open}
     onClose={handleClose}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <Box sx={style}>
     <Grid container spacing={1}  sx={wrapper}>
     <Grid item lg={12} style={{display:"flex", justifyContent:"space-between",width:"100%" }}>
      <ArrowBackIosNewIcon onClick={handleCloseModal} />
       <Typography
         id="modal-modal-title"
         sx={heading}
         
       >
   Request
       </Typography>
     </Grid>
     <Box style={{marginTop:'26px',width:"100%",padding:"0px 3px 0px 10px"}}>
     <Grid item lg={12} md={12} sm={12} style={{marginBottom:"10px"}} >
                            
       <FormControl fullWidth>
<Typography>Subject :</Typography> 
  <TextField
          fullWidth
          name="subject"
           sx={inputStyle}
           placeholder="" 
           value={formik.values.subject}
           onChange={formik.handleChange}
           onBlur={formik.handleBlur}              
           />
            {formik.touched.subject && formik.errors.subject && (
    <Typography color="error">{formik.errors.subject}</Typography>
  )}
    </FormControl>
  </Grid>
  <Grid item lg={12} md={12} sm={12} style={{marginTop:"10px",marginBottom:"15px"}} >
<Typography> Reason : </Typography> 
<FormControl fullWidth>
    <TextareaAutosize
      style={textareaStyle}
      rows={6}
      name='message'
      placeholder=""
      value={formik.values.waletnotes}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}   
    />
    {formik.touched.waletnotes && formik.errors.waletnotes && (
      <Typography color="error">{formik.errors.waletnotes}</Typography>
    )}
  </FormControl>
  </Grid>
    <Grid item lg={12} md={12}sm={12} sx={btnwrappe}>
    <CustomButtoncolor
       mainColor={"#4F4F4F"}
       size="md"
       val='cancel'
       style={{
        padding:'7px 5px',
        fontSize: "18px",
        minWidth:'max-content',
        borderRadius: "6px!important",
        width: "15%",
        "@media (minWidth: 420px)": {
            width: 'auto'
        },
        
    }}  
    handleClick={handleCloseModal}                                
    />
  <Button  variant="contained"  size="large"
   style={{height:"50px",width:"92px",
   backgroundColor:'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
   borderRadius:'8px'}}  onClick={formik.handleSubmit}>
         submit
       </Button>
   </Grid>
     </Box>

   </Grid>
 
     </Box>
   </Modal>
   </>
  )
}

export default RemovalAccountModal;

