import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import FormInput from "../../../../components/form/formInput";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Container,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { getAllClinicsTreatmentGroup } from "../../../../store/clinics/clinicSettings/clinic-treatment-group";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPromocodeId, storePromo, updatePromoCode } from "../../../../store/clinics/clinicSettings/clinic-promo";
import Waveloading from "../../../loading/Waveloading";
import toast from "react-hot-toast";

const top100Films = ["Percentage", "Fixed"];

export const inputStyle = {
  background: "#EDF2F7",
  borderRadius: "4px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #EDF2F7",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#EDF2F7",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#EDF2F7",
      outline: "none",
    },
  },
};
export const labelStyle = {
  fontSize: "16px",
  color: "#27272e!important",
  letterSpacing: ".3px",
  textTransform: "capitalize",
};
export const selectStyle = {
  background: "#EDF2F7",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const AddPromo = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [endDate, setEndDate] = useState("");
  const [discounttype, setDiscounttype] = useState("");
  const [age, setAge] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [discountValue, setDiscountValue] = useState(null);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    code: false,
    title: false,
    endDate: false,
    age: false,
    discountValue: false,
    discounttype:false
  });

  const user = useSelector(state => state?.authStore?.user)
  const navigate = useNavigate()
  const reportsStore = useSelector((state) => state?.reportsStore);
  const { CategoryService = [] } = reportsStore;
  const params = useParams();
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChangesss = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(
        typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSelectAll = () => {
    if (selectedValues.length === (clinicTreatmentGroupStore?.treatmentGroups || []).length) {
      setSelectedValues([]);
    } else {
      setSelectedValues((clinicTreatmentGroupStore?.treatmentGroups || []).map(service => service.id));
    }
  };

  const handleChanges = (event) => {
    setAge(event.target.value);
  };
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  console.log("prams",params)
  const clinicTreatmentGroupStore = useSelector(
      (state) => state?.clinicTreatmentGroupStore
  );
  console.log("data", clinicTreatmentGroupStore?.treatmentGroups);
  // Function to generate random code
  const generateRandomCode = () => {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCode = "";
    for (let i = 0; i < 6; i++) {
      randomCode += characters.charAt(
          Math.floor(Math.random() * characters.length)
      );
    }
    setCode(randomCode); // Update the state with the generated code
  };
  useEffect(() => {
    (async () => {
      setLoading(true)
      await dispatch(getAllClinicsTreatmentGroup(params?.clinic_id));
      setLoading(false)
    })();
  }, [dispatch]);

  const handleSubmit = async () => {
    const newErrors = {
      code: !code,
      title: !title,
      endDate: !endDate,
      age: !age,
      discountValue: !discountValue,
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      provider_id: params?.clinic_id,
      title: title,
      discount_value: discounttype,
      discount_code: code,
      discount_type: discountValue,
      applied_to_ids: selectedValues,
      end_date: endDate,
      id: params?.room_id || "",
      status:status,
    };
    console.log("payload", payload);
    setLoading(true)

    let res
    if (params?.room_id) {
      res = await dispatch(updatePromoCode(payload))
    } else {
      res = await dispatch(storePromo(payload))
    }
    if (res?.payload?.errors) {
      const {errors} = res?.payload
      toast.error("errors")
    } else if (res?.payload?.success === "true") {
      toast.success(`Promo Code ${params?.room_id ? "updated" : "Stored"} Successfully`, {duration: 3000})
      navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/promo`)
    }
    setLoading(false)

  };
  console.log("data", CategoryService);
  // getPromocodeId
  useEffect(() => {
    (async () => {
      if (params?.room_id) {
        setLoading(true);
        let res = await dispatch(getPromocodeId(params?.room_id));
        console.log("data comes",res)
        if (res?.payload?.data?.id) {
          setCode(res.payload.data.discount_code || '');
          setTitle(res.payload.data.title || '');
          setDiscountValue(res.payload.data.discount_type || '');
          setDiscounttype(res.payload.data.discount_value || '');
          setSelectedValues(res.payload.data.applied_to_ids|| []);
          setEndDate(res.payload.data.end_date || '');
          setStatus(res.payload.data.status || '');
        }
        setLoading(false);
      }
    })();
  }, [ dispatch]);
  return (

      <Box sx={{ sm:'20px', xs:'2px' }}>
        { loading ? <Waveloading/> :
            <>
              <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',p:{sm:5, xs:1}, m:2, borderRadius:'12px' }}>
                <Grid
                    container
                    sx={{ justifyContent: "space-between", pb: "0" }}
                >
                  <Grid item lg={9.9} md={12} sm={12} xs={12}>
                    <FormControl fullWidth sx={{ mb: "16px" }}>
                      <Grid container>
                        <Grid item lg={5.9} md={12} sm={12} xs={12}>
                          <FormLabel sx={{ ...labelStyle, mt: "10px" }}>
                            Discount Code
                          </FormLabel>
                        </Grid>
                        <Grid item lg={5.9} md={12} sm={12} xs={12}>
                          <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                ...labelStyle,
                                float: "right",
                                background: "#09ABF1",
                                boxShadow: "none",
                                color:'#fff',
                                mb:1,
                                "&:hover": {
                                  backgroundColor: "#09ABF1",
                                  boxShadow: "none",
                                },
                              }}
                              onClick={generateRandomCode}
                          >
                            Generate Random Code
                          </Button>
                        </Grid>
                      </Grid>
                      <TextField
                          sx={inputStyle}
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          error={errors.code}
                          helperText={errors.title ? "Discount code is required" : ""}
                          required
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;', p:{sm:5, xs:1}, m:2,  borderRadius:'12px' }}>
                <Grid container>
                  <Grid item lg={9.9} md={12} sm={12} xs={12} sx={{ mb: "16px" }}>
                    <FormControl fullWidth>
                      <FormLabel sx={{ ...labelStyle, mt: "10px" }}>Title</FormLabel>
                      <TextField
                          sx={inputStyle}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          error={errors.title}
                          helperText={errors.title ? "Title is required" : ""}
                          required
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container sx={{ display: "flex", pt: "0" }}>
                  <Grid item lg={4.9} md={5.9} sm={12} xs={12}>
                    <FormLabel sx={labelStyle}>Discount Type</FormLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        getOptionLabel={(option) => option}
                        sx={{ maxHeight: "250px", overflow: "auto", ...inputStyle }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!errors.discountValue}
                                helperText={errors.discountValue ? "Discount Type is required" : ""}
                            />
                        )}
                        value={discountValue}
                        onChange={(event, newValue) => {
                          setDiscountValue(newValue || ""); // Extract the value
                          setErrors((prev) => ({ ...prev, discountValue: false }));
                        }}
                        required
                    />
                  </Grid>

                  <Grid item lg={4.8} md={5.9} sm={12} xs={12} sx={{ ml: 2 }}>
                    <FormLabel sx={labelStyle}>Discount Value</FormLabel>
                    <FormControl fullWidth>
                      <TextField
                          sx={{ ...inputStyle, mt: "1px" }}
                          value={discounttype}
                          onChange={(e) => setDiscounttype(e.target.value)}
                          error={errors.discounttype}
                          helperText={errors.discounttype ? "Discount Value is required" : ""}
                          required
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>

                  <Grid item lg={9.9} md={12} sm={12} xs={12} sx={{ mb: "16px" }}>

                    <FormControl
                        sx={{
                          minWidth: { xs: "100%" },
                          mt: { xs: 2, sm: 1 },
                        }}
                    >
                      <FormLabel>Applies To</FormLabel>
                      <Select
                          multiple
                          value={selectedValues}
                          onChange={handleChangesss}
                          displayEmpty
                          sx={selectStyle}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em></em>;
                            }
                            const displayedValues = selected.map(id => {
                              const service = (Array.isArray(clinicTreatmentGroupStore?.treatmentGroups) ? clinicTreatmentGroupStore.treatmentGroups : []).find(service => service.id === id);
                              return service ? service.title : '';
                            });
                            return displayedValues.length > 3 ? `${displayedValues.slice(0, 3).join(', ')}...` : displayedValues.join(', ');
                          }}
                      >
                        <MenuItem value="select-all">
                          <Checkbox
                              checked={selectedValues.length === (clinicTreatmentGroupStore?.treatmentGroups || []).length}
                              indeterminate={selectedValues.length > 0 && selectedValues.length < (clinicTreatmentGroupStore?.treatmentGroups || []).length}
                              onChange={handleSelectAll}
                          />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                        {(Array.isArray(clinicTreatmentGroupStore?.treatmentGroups) ? clinicTreatmentGroupStore.treatmentGroups : []).map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                              <Checkbox checked={selectedValues.indexOf(service.id) > -1} />
                              <ListItemText primary={service.title} />
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item lg={4.9} md={5.9} sm={12} xs={12} sx={{}}>
                    <FormControl fullWidth sx={{ mb: "16px" }}>
                      <FormLabel sx={labelStyle}>End Date</FormLabel>
                      <TextField
                          type="date"
                          sx={{ ...inputStyle, mt: "15px" }}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                            setErrors((prev) => ({ ...prev, endDate: false }));
                          }}
                          error={errors.endDate}
                          helperText={errors.endDate ? "End Date is required" : ""}
                          required
                      />
                    </FormControl>
                  </Grid>
                  {params?.room_id ?
                      <Grid item lg={4.9} md={5.9} sm={12} xs={12} sx={{ marginLeft: "15px", }}>
                        <FormControl fullWidth sx={{ mb: "16px" }}>
                          <FormLabel sx={labelStyle}>Status</FormLabel>
                          <Select
                              value={status}
                              onChange={handleChange}
                              displayEmpty
                              sx={{...selectStyle,mt: "15px" }}

                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="In-Active">In Active</MenuItem>
                            <MenuItem value="Expired">Expired</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> :""}

                  <Grid item lg={8.9} md={12} sm={12} xs={12} sx={{ mt: "16px" }}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                      {params?.room_id ? 'Update Promo' : 'Add Promo'}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
        }
      </Box>

  );
};

export default AddPromo;
   