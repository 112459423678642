import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import TimelineContent from "@mui/lab/TimelineContent";
import {getBorderColor} from "../../../../../services/utills";
import moment from "moment";
import { useNavigate } from "react-router";
  
const Treatment = ({treatments}) => {
    const navigate = useNavigate()

    const handleAppointmentClick = (url) => {
        navigate(url)
    }

    return (
        <Box>
            {treatments?.length > 0 ?
                <Timeline
                sx={{
                    p: "0",
                    my: "0",
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}
                position="right"
            >
                {treatments?.length > 0 &&
                    treatments.map((treatment, index) => (  
                        <TimelineItem sx={{minHeight: "200px"}} key={index}>
                            <TimelineSeparator sx={{ display:{xs:'none',sm:'flex'}, }}>
                                <TimelineConnector
                                    sx={{
                                        background: "#2F80ED",
                                        width: "16px",
                                        borderRadius: "10px",
                                        border: "6px solid #fff",
                                        maxHeight: "50px",
                                        mb: "-10px",
                                        borderTop: index === 0 ? "6px solid #fff" : "0",
                                        borderTopLeftRadius: index === 0 ? '10px' : "0",
                                        borderTopRightRadius: index === 0 ? '10px' : "0",
                                    }}
                                />
                                <TimelineDot
                                    variant="outlined"
                                    sx={{
                                        mx: "auto",
                                        my: "0",
                                        borderWidth: "5px",
                                        borderColor: getBorderColor(treatment?.status),
                                        zIndex: "10",
                                    }}
                                />
                                <Box
                                    sx={{
                                        height: "75px",
                                        width: "90px",
                                        mt: "-8px",
                                        background: "#fff",
                                        borderRadius: "5px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        cursor:"pointer"
                                    }}
                                    onClick={() => handleAppointmentClick(`/crm/appointment/details/${treatment?.id}`)}
                                >
                                    <Typography sx={{fontSize: "13px"}}>Booking No</Typography>
                                    <Typography sx={{fontSize: "13px", fontWeight: "600"}}>
                                        {treatment?.id}
                                    </Typography>
                                </Box>
                                <TimelineConnector
                                    sx={{
                                        background: "#2F80ED",
                                        width: "16px",
                                        borderRadius: "10px",
                                        border: "6px solid #fff",
                                        borderBottomLeftRadius: "0",
                                        borderBottomRightRadius: "0",
                                        borderBottom: '0',
                                        mt: "-7px",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent sx={{pl: {xs:"0px",sm:"32px"}, pb: {xs:"8px",sm:"32px"}, pt: 0, pr: 0}}>
                                <Box
                                    sx={{ 
                                        p: {xs:"12px",sm:"24px"},
                                        background: "#fff",  
                                        height: "100%",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <Box sx={{ display:"flex",alignItems:"right",marginTop:"1px",alignItems:'center', mt:2, }}>
                                    <Typography
                                                  variant="body2"
                                                  color="initial"
                                                  sx={{
                                                    color: "#000",
                                                    fontSize: '18px',
                                                    fontFamily: 'helvetica-lt-std-bold'
                                                  }}
                                              >
                                                Default Clinic: 
                                              </Typography>
                                     <Typography
                                                         variant="body2"
                                                        color="initial"
                                                        sx={{
                                                            color: "#56CCF2",
                                                            fontSize: '16px',
                                                            fontFamily: 'helvetica-lt-std-bold',
                                                             ml:'5px'
                                                        }}
                                                    >
                                                       {treatment?.provider_name}
                                                    </Typography>
                                    </Box>

                                    <Box style={{display:"flex",alignItems:"right",marginTop:"1px"}}>


                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                color: "#000",
                                                fontSize: '18px',
                                                fontFamily: 'helvetica-lt-std-bold'
                                            }}
                                        >
                                            Reference Clinic:
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                ml: 1,
                                                color: "#56CCF2",
                                                fontSize: '16px',
                                                fontFamily: 'helvetica-lt-std-bold'
                                            }}
                                        >
                                            {treatment?.reference_clinic_name}
                                        </Typography>
                                    </Box>

                                    <Grid
                                        container
                                        sx={{display: "flex", justifyContent: "space-between"}}
                                    >
                                        <Grid item xs={12} md={12} xl={2.5} >
                                            <Box>
                                                <Typography  
                                                    variant="body2"
                                                    color="initial"
                                                    sx={{color: "#000", textDecoration:'underline', fontWeight:600}}
                                                >
                                                    Appointment Date:
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    color="initial"
                                                    sx={{
                                                        mt: 1, fontFamily: "helvetica-lt-std-bold",
                                                        color:treatment.date ? '#000' : '#F25022',
                                                    }}
                                                >
                                                     {treatment?.date ? moment(treatment?.date).format("dddd MMMM D, YYYY") : 'Date and time not set'}
                                                   
                                                </Typography>
                                                {treatment?.date && <Typography
                                                    variant="h5"
                                                    color="initial"
                                                    sx={{fontFamily: "helvetica-lt-std-bold"}}
                                                >
                                                    {treatment?.start_time} -{" "}
                                                    {treatment?.end_time}
                                                </Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} xl={9}  sx={{ml:{xs:0,xl:1, lg:1, md:3}}}>
                                            <Box>
                                                <Typography
                                                    variant="body2"
                                                    color="initial"
                                                    sx={{color: "#000", textDecoration:'underline', fontWeight:600}}
                                                >
                                                    Treatment Invoice:
                                                </Typography>
                                                <Box sx={{display: "flex",justifyContent:"space-between", mt: 3}}>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                    >
                                                        {treatment?.service_names}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ml: 1}}
                                                    >
                                                        ${treatment?.grand_total}
                                                    </Typography>                                             </Box>
                                            </Box>
                                            <Box>
                                                <Box sx={{display: "flex", mt: 3}}>
                                                    <Typography variant="body2" color="initial">
                                                        Aesthetician:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ml: 1}}
                                                    >
                                                        {treatment?.aesthetician}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        
                                    </Grid>


                                    <Grid container sx={{display: 'flex', justifyContent: 'space-between',mt:{xs:3,xl:0}}}>
                                        <Grid xs={0} xl={2.9}>  

                                        </Grid>
                                        <Grid item xs={12} xl={2.9}>
                                            <Typography variant="body2" color="initial" sx={{color: "#000", textDecoration:'underline', fontWeight:600, mb:1}}>
                                                Notes
                                            </Typography>
                                            <Typography  variant="body2" color="initial">
                                                Customer Notes
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} xl={5.9}>
                                            {treatment?.notes?.Customer?.length > 0 ?
                                                treatment?.notes?.Customer.map((staff, staffIndex) => (
                                                    <Box key={staffIndex} sx={{mt: 1}}>
                                                        <Typography variant="body2" color="initial" sx={{mt: 1}}>
                                                            <Typography as={"span"} sx={{fontFamily: "helvetica-lt-std-bold"}}>
                                                                [{moment(staff?.created_at).format('h:mm A')},
                                                                {moment(staff?.created_at).format('YYYY-MM-D')}] {" "}
                                                                {staff?.staff?.name}:
                                                            </Typography>
                                                            {staff?.description}
                                                        </Typography>
                                                    </Box>
                                                ))  
                                                :
                                                <Box sx={{mt: '28px'}}>
                                                    <Typography variant="body2" color="initial">
                                                        <span style={{fontFamily: "helvetica-lt-std-bold"}}></span>
                                                        None
                                                    </Typography>
                                                </Box>
                                            }   
                                        </Grid>
                                    </Grid>


                                    {/* <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Grid xs={0} xl={2.9}>

                                        </Grid>
                                        <Grid item xs={12} xl={2.9}>
                                            <Box sx={{display: "flex",mt:2}}>
                                                <Typography variant="body2" color="initial">
                                                Staff Notes
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} xl={5.9}>
                                            {treatment?.notes?.Staff?.length > 0 ?
                                                treatment?.notes?.Staff.map((staff, staffIndex) => (
                                                    <Box key={staffIndex} sx={{mt: 1}}>
                                                        <Typography variant="body2" color="initial" sx={{mt: 1}}>
                                                            <Typography as={"span"} sx={{fontFamily: "helvetica-lt-std-bold"}}>
                                                                [{moment(staff?.created_at).format('h:mm A')},
                                                                {moment(staff?.created_at).format('YYYY-MM-D')}] {" "}
                                                                {staff?.staff?.name}:
                                                            </Typography>
                                                            {staff?.description}
                                                        </Typography>
                                                    </Box>
                                                ))  
                                                :
                                                <Box sx={{mt: '15px'}}>
                                                    <Typography variant="body2" color="initial">
                                                        <span style={{fontFamily: "helvetica-lt-std-bold"}}></span>
                                                        None
                                                    </Typography>
                                                </Box>
                                            }   
                                        </Grid>
                                    </Grid> */}

                                    <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Grid xs={0} xl={2.9}>

                                        </Grid>
                                        <Grid item xs={12} xl={2.9}>
                                            <Box sx={{display: "flex",mt:2}}>
                                                <Typography variant="body2" color="initial">
                                                    Medical/Staff Notes
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} xl={5.9}>
                                            <Box sx={{mt: '16px'}}>
                                                {treatment?.notes?.Medical?.length > 0 ?
                                                    treatment?.notes?.Medical.map((medical, medicalIndex) => (
                                                        <Box key={medicalIndex}>
                                                            <Typography variant="body2" color="initial" sx={{mt: 1}}>
                                                                <Typography as={"span"} sx={{fontFamily: "helvetica-lt-std-bold"}}>
                                                                    [{moment(medical?.created_at).format('h:mm A')},
                                                                    {moment(medical?.created_at).format('YYYY-MM-D')}] {" "}
                                                                    {medical?.staff?.name}:
                                                                </Typography>
                                                                {medical?.description}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                    :
                                                    <Box>
                                                        <Typography variant="body2" color="initial">
                                                            <span style={{fontFamily: "helvetica-lt-std-bold"}}></span>
                                                            None
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    ))
                }
            </Timeline>:
            <Typography
                sx={{
                    textAlign: 'center',
                    fontFamily: "helvetica-lt-std-bold",
                }}
            >
                No Treatment List
            </Typography>
            }
        </Box>
    );
}

export default Treatment;
