import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import { getReferralPoint, updateReferralPoint } from '../../../store/roles';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/loader/loader';
import { successConfirmation } from '../../../services/utills';
import * as Yup from 'yup';
import toast from 'react-hot-toast';


const ReferalPoint = () => {
    const [loading, setLoading] = useState(false);
    const rolesStore = useSelector((state) => state?.rolesStore);
    const dispatch = useDispatch();
    const decimalNumberRegex = /^\d+(\.\d+)?$/;

    const validationSchema = Yup.object({
      booking_referrer_point: Yup.string()
        .matches(decimalNumberRegex, 'Booking Referrer Point must be a number')
        .required('Booking Referrer Point is required'),
      booking_referral_point: Yup.string()
        .matches(decimalNumberRegex, 'Booking Referral Point must be a number')
        .required('Booking Referral Point is required'),
      membership_referrer_point: Yup.string()
        .matches(decimalNumberRegex, 'Membership Referrer Point must be a number')
        .required('Membership Referrer Point is required'),
      membership_referral_point: Yup.string()
        .matches(decimalNumberRegex, 'Membership Referral Point must be a number')
        .required('Membership Referral Point is required'),
    });

    useEffect(() => {
        setLoading(true);
        dispatch(getReferralPoint())
            .finally(() => setLoading(false));
    }, [dispatch]);

    const handleSubmit = async (values, { setSubmitting }) => {
        setLoading(true);
        try {
          // Attempt to dispatch the update action
          const response = await dispatch(updateReferralPoint(values));
       console.log("issue ", response?.payload )
        
          if (response?.payload) { 
            successConfirmation('Referral Points Updated Successfully');
          } else {
           
            throw new Error('Unexpected response payload'); 
          }
        } catch (error) {
          console.error('Update failed', error);
          toast.error("Setting Not Updated",{duration:3000}) 
        } finally {
          setSubmitting(false);
          setLoading(false);
        }
      };
      
    const handleChange =() =>{     
        console.log("handel change data" )  
    }
 
    if (loading) {
        return <Box
        sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "60vh" 
        }}
    >
        <Loader />
    </Box>;
    }
    console.log("referal data", rolesStore?.refralpoint)

    return (
        <Formik
        initialValues={{
            booking_referrer_point: rolesStore?.refralpoint?.booking_referrer_point || '',
            booking_referral_point:rolesStore?.refralpoint?.booking_referral_point || '',
            membership_referrer_point: rolesStore?.refralpoint?.membership_referrer_point || '',
            membership_referral_point: rolesStore?.refralpoint?.membership_referral_point || '',
           
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        >
            {({isSubmitting, values, handleChange, handleBlur}) => (
                <Form>
                    <Grid container spacing={4}>
                    <Grid item xs={6}>
                    <TextField
                        type="text"
                        name="booking_referrer_point"
                        label="Booking Referrer Point"
                        variant="outlined"
                        fullWidth
                        value={values.booking_referrer_point}
                        onChange={handleChange}
                        onBlur={handleBlur} 
                    />
                    <ErrorMessage name="booking_referrer_point" component="div" className="error" style={{ color: 'red' }}/>
                </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="text"
                                name="booking_referral_point"
                                label="Booking Referral Point"
                                variant="outlined"
                                fullWidth
                                value={values.booking_referral_point}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                            />
                            <ErrorMessage name="booking_referral_point" component="div" className="error"  style={{ color: 'red' }}/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="text"
                                name="membership_referrer_point"
                                label="Membership Referrer Point"
                                variant="outlined"
                                fullWidth
                                value={values.membership_referrer_point}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                            />
                            <ErrorMessage name="membership_referrer_point" component="div" className="error" style={{ color: 'red' }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="text"
                                name="membership_referral_point"
                                label="Membership Referral Point"
                                variant="outlined"
                                fullWidth
                                value={values.membership_referral_point}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                            />
                            <ErrorMessage name="membership_referral_point" component="div" className="error" style={{ color: 'red' }} />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        style={{ marginTop: '16px' }}
                    >
                        {isSubmitting ? 'Updating...' : 'Update Settings'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default ReferalPoint;
