import React, {useState, useRef , useEffect} from 'react';
import {Box, Button, TextField, Typography} from "@mui/material";
import CustomModal from "../modal/customModal";
import CustomButtoncolor from "../custom-button/customColorButton";
import {useDispatch} from "react-redux";
import {PlayArrow} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {FormLinkData, getAllSubmittedTreatmentForms, getAllTreatmentForms} from "../../store/user/chart-form";
import Loader from "../loader/loader";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SimpleInput from '../form/simpleInput';

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { MySwal } from '../../services/utills';
import {mySwalButton} from "../../components/card-appointment/CardAppointment";
import Waveloading from '../../pages/loading/Waveloading';
import LinkModal from './LinkModal';

const TreatmentFormButton = ({data,crmStyle, completed,fwidth,fsize }) => {
    const [loading, setLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([]);
    const [rescheduleModal, setRescheduleModal] = useState(false) 
    const [treatmentForm, setTreatmentForm] = useState(null)
    const [allTreatmentForms, setAllTreatmentForms] = useState([])
    const [allSubmittedForm, setAllSubmittedForm] = useState([])
    const [search, setSearch] =useState('')
    const [active, setActive] = useState(4)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLinkUrl, setSelectedLinkUrl] = useState(null);
  
    const scrollableRef = useRef(null);
 
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            if (scrollableRef.current) {  
                scrollableRef.current.scrollBy(0, 10);  
            }   
        }
    }     
    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('keydown', handleKeyDown);
            scrollableElement.tabIndex = "0"; 
            scrollableElement.style.outline = "none"; 
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);   
  
    const openModal = (linkUrl) => {
      setSelectedLinkUrl(linkUrl);
      setIsModalOpen(true);  
    }
    
    const closeModal = () => {  
      setSelectedLinkUrl(null);
      setIsModalOpen(false);
    }   

    const handleChanges = (event) => {
        if (event.key === 'Enter') {   
          performSearch();          
        } else {   
          setSearch(event.target.value)
        }
      }  

      const performSearch = () => {
        let results = [];
        results = results.concat(filterForms(allTreatmentForms?.consent, search));
        results = results.concat(filterForms(allTreatmentForms?.customer_service_form, search));
        results = results.concat(filterForms(allTreatmentForms?.chart_form, search));
        results = results.concat(allSubmittedForm?.consent?.filter(form =>
          form.name.toLowerCase().includes(search.toLowerCase())
        ));
        
      
      }

      const filterForms = (forms, search) => {
        return forms?.filter(form =>  
          form.name.toLowerCase().includes(search.toLowerCase())         
        ) || [];      
      }

   
      const filteredForms = filterForms(allTreatmentForms?.consent, search); 
      const filteredCustomerForm = filterForms(allTreatmentForms?.customer_service_form, search);
      const filteredChartForm = filterForms(allTreatmentForms?.chart_form, search); 
      const filteredSubmitForm= allSubmittedForm?.consent?.filter(form =>
        form.name.toLowerCase().includes(search.toLowerCase())    
      ) 

      const allFilteredForms = [  
        ...filteredForms,
        ...filteredCustomerForm ,
        ...filteredChartForm,    
      ]

    const defaultId = data?.customer_id || data?.id 
    const customerId = data?.customer_id || data?.customer?.id   
    const requestType = data?.customer_id ? "CRM" : "Appointment"
   
    const dispatch = useDispatch()  
    const navigate = useNavigate()   

    const handleFetchForm = async () => { 
   
        setLoading(true)
        let finalData = {id: defaultId, provider_id: data?.provider?.id, type: requestType}
        setTreatmentForm(finalData)
        let {payload} = await dispatch(getAllTreatmentForms(finalData))
        setLoading(false)
        setAllTreatmentForms(payload)
    }
  const handleRowClick = async (userid,formid) => {
        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? You want to send Email .
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{...mySwalButton }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{...mySwalButton}}
                        onClick={() => MySwal.close()}
                    >
                        No
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            const payload = {
   
                user_id: userid,
                form_id: formid
              };
          
            setLoading(true);
          dispatch(FormLinkData(payload));
          setLoading(false);
           
            }else {
                setRescheduleModal(true)
            }
 }

    const handleSubmitted = async () => {
          setLoading(true)
        setActive(3)
        let {payload}= await dispatch(getAllSubmittedTreatmentForms({id: customerId, provider_id: data?.provider?.id}))
        setLoading(false);
        setAllSubmittedForm(payload)

    }  

    return(
        <Box>
            <CustomButtoncolor   
                handleClick={() => handleFetchForm()}
                mainColor={"#A533B8"}
                style={{
                    borderRadius: "4px",  
                    padding: '10px 15px', 
                    width:fwidth,
                    fontSize: "17px !important",
                    ...crmStyle,
                    '@media (max-width:450px)':{
                        fontSize:'13px'
                    }
                }}
                val={"Client Form"}
            />
            <CustomModal 
                customWidth={"800px"}
                open={treatmentForm !== null}
                setOpen={() => setTreatmentForm(null)}
            >
                {
                    loading ? <Box
                            sx={{
                                minHeight: '300px',
                            }} 
                        >
                            <Waveloading/>
                        </Box> : 
                        <Box
                        ref={scrollableRef}
                            sx={{
                                minHeight: '300px',    
                                overflow: 'auto' 
                            }}
                        >
                      <Box 
                            sx={{
                            display: "flex", 
                            alignItems: 'center',
                            marginBottom:"8px",
                            justifyContent: 'center',
                           }} >


            <TextField 
                 sx={{  
                    width:"80%",
                    borderRadius:"22px",
                    border:"1px solid black",
                       // background: "#EDF2F7",
                       borderRadius:'4px',
                       ".MuiOutlinedInput-notchedOutline": {
                           borderWidth: "0!important"
                       },
                       ".MuiInputBase-input":{
                        
                           height: "11px",
                           minHeight: 'unset',
       
                           "&:focus":{  
                               "input":{
                                   height:'11px'
                               }
                           },
                           "&:hover":{
                               borderColor: "#EDF2F7",
                               outline: "none",
                           }
                       }
                   }}
            
                variant="outlined"
                name={""}
                // sx={inputStyle}
                placeholder={"search"}
                value={search}
                onChange={handleChanges}
              />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: 'center',
                                    gap: '20px',
                                   flexWrap:'wrap',
                                    justifyContent: 'center',
                                    mt:2
                                }}
                            >  
                                <CustomButtoncolor
                                    mainColor={"#498AEE"}
                                    active={active===4}
                                    style={{
                                        borderRadius: "4px",
                                        padding: '10px 15px',
                                    }}
                                    val={"Consent Form"}
                                    handleClick={() => setActive(4)}
                                />
                                <CustomButtoncolor
                                    active={active===1}
                                    mainColor={"#323681"}
                                    style={{borderRadius: "4px", padding: '10px 15px'}}
                                    val={"Customer/Service Form"}
                                    handleClick={() => setActive(1)}

                                /> 

                                <CustomButtoncolor
                                    mainColor={"#F6A53C"}  
                                    active={active===2}
                                    style={{borderRadius: "4px", padding: '10px 15px'}}
                                    val={"Chart Form"}
                                    handleClick={() => setActive(2)}

                                />
                                <CustomButtoncolor
                                    mainColor={"#219653"}
                                    active={active===3}
                                    style={{borderRadius: "4px", padding: '10px 15px'}}
                                    val={"Submitted Form"}
                                    handleClick={() => handleSubmitted()}

                                />   
                            </Box>      

                            {active === 4 &&   
                                <Box> 
                                    <Typography
                                        sx={{
                                            my: '25px',
                                            fontFamily: "helvetica-lt-std-bold",
                                        }}  
                                    >  
                                        Consent Form:  
                                    </Typography>
                                    {
                                        filteredForms?.length > 0? 
                                        filteredForms.map((form, index) => (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        background: '#EDF2F7',  
                                                        p: '5px 10px',
                                                        borderRadius: '5px',
                                                        mt: '5px',
                                                        mb: '10px',
                                                        display: 'flex',
                                                        height:{sm:'35px', xs:'auto'},
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent:'space-between',
                                                    }}
                                                    key={index}
                                                    onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}`)}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <PlayArrow
                                                            sx={{
                                                                fontSize: '1.3rem',
                                                            }} 
                                                        />
                                                        <Typography
                                                            sx={{
                                                                ml: '10px',
                                                            }}
                                                        >
                                                            {form?.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AttachEmailIcon
                                                   onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRowClick(form?.customer_id,form?.treatment_form_id)}}
                                                        sx={{
                                                            fontSize: '1.3rem',
                                                        }}
                                                    /> 
                                                </div>
                                                   {/* {
                                                        form?.is_submitted &&
                                                       <CheckCircleOutlineIcon
                                                            sx={{
                                                                color: '#27AE60',
                                                                fontSize: '1.8rem'
                                                            }}
                                                        />
                                                    }*/}
                                                </Box>
                                                </Box>
                                            )) :
                                            <Typography
                                                sx={{
                                                    fontFamily: "helvetica-lt-std-bold",
                                                    textAlign: 'center',
                                                    mt: '24px'
                                                }}
                                            >
                                                No Consent Form Found
                                            </Typography>
                                    }
                                </Box>
                            }

                            {active === 1 &&
                                <Box> 
                                    <Typography  
                                        sx={{
                                            my: '25px',
                                            fontFamily: "helvetica-lt-std-bold", 
                                        }}
                                    >   
                                        Customer/Service Form:
                                    </Typography>
                                    {   filteredCustomerForm?.length ? 
                                        filteredCustomerForm?.map((form, index) => (  
                                            <Box
                                            sx={{    
                                                width: '100%',
                                                background: '#EDF2F7',   
                                                p: '5px 10px',
                                                borderRadius: '5px', 
                                                mt: '5px',
                                                mb: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: {sm:'35px', xs:'auto'},
                                                cursor: 'pointer',
                                                justifyContent: 'space-between',
                                            }}
                                            key={index}
                                            onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}`)}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <PlayArrow
                                                    sx={{
                                                        fontSize: '1.3rem',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        ml: '10px',
                                                    }}
                                                >
                                                    {form?.name}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AttachEmailIcon
                                                    onClick={(e) => { 
                                                        e.stopPropagation(); 
                                                        handleRowClick(form?.customer_id,form?.treatment_form_id)} }
                                                        sx={{
                                                            fontSize: '1.3rem',
                                                        }}
                                                    /> 
                                                </div>
                                                {/*{
                                                    form?.is_submitted &&
                                                    <CheckCircleOutlineIcon
                                                        sx={{
                                                            color: '#27AE60',
                                                            fontSize: '1.8rem',
                                                            marginLeft: '10px',
                                                        }}
                                                    />
                                                }*/}
                                            </Box>
                                        </Box>
                                        
                                            )) :
                                            <Typography
                                                sx={{
                                                    fontFamily: "helvetica-lt-std-bold",
                                                    textAlign: 'center',
                                                    mt: '24px'
                                                }}
                                            >
                                                No Customer/Service Form Found.
                                            </Typography>
                                    }
                                </Box>
                            }

                            {active === 2 &&
                                <Box> 
                                    <Typography
                                        sx={{
                                            my: '25px',
                                            fontFamily: "helvetica-lt-std-bold",
                                        }}
                                    >
                                        Chart Form:
                                    </Typography>
                                    {
                                        filteredChartForm?.length ?
                                        filteredChartForm?.map((form, index) => (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        background: '#EDF2F7',
                                                        p: '5px 10px',
                                                        borderRadius: '5px',
                                                        mt: '5px',
                                                        mb: '10px',
                                                        height:'35px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent:'space-between',
                                                    }}
                                                    key={index}
                                                    onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}`)}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <PlayArrow
                                                            sx={{
                                                                fontSize: '1.3rem',
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                ml: '10px',
                                                            }}
                                                        >
                                                            {form?.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AttachEmailIcon
                                                      onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        handleRowClick(form?.customer_id,form?.treatment_form_id)
                                                      }}
        
                                                        sx={{
                                                            fontSize: '1.3rem',
                                                        }}
                                                    /> 
                                                </div>
                                                    {
                                                        form?.is_submitted &&
                                                        <CheckCircleOutlineIcon
                                                            sx={{
                                                                color: '#27AE60',
                                                                fontSize: '1.8rem'
                                                            }}
                                                        />
                                                    }
                                                </Box>
                                                </Box>
                                            )) :
                                            <Typography
                                                sx={{
                                                    fontFamily: "helvetica-lt-std-bold",
                                                    textAlign: 'center',
                                                    mt: '24px'
                                                }}
                                            >
                                                No Chart Form Found.
                                            </Typography>
                                    }
                                </Box>
                            }
                            {active === 3 &&
                                <Box>
                                    <Typography
                                        sx={{
                                            my: '25px',
                                            fontFamily: "helvetica-lt-std-bold",
                                        }}
                                    >
                                       Consent Submitted Form:
                                    </Typography>
                                    {
                                        filteredSubmitForm?.length ?
                                        filteredSubmitForm?.map((form, index) => (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        background: '#EDF2F7',
                                                        p: '5px 10px',
                                                        borderRadius: '5px',
                                                        mt: '5px', 
                                                        mb: '10px',
                                                        height:'auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent:'space-between',
                                                    }}
                                                    key={index}
                                                   
                                                    onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1&submitted_id=${form?.submitted_id}`)}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }} 
                                                    >
                                                        <PlayArrow
                                                            sx={{
                                                                fontSize: '1.3rem',
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                ml: '10px',
                                                            }}
                                                        >
                                                            {form?.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AttachEmailIcon
                                                    onClick={(e) =>{  e.stopPropagation()
                                                        handleRowClick(form?.customer_id,form?.treatment_form_id)} }
                                                        sx={{
                                                            fontSize: '1.3rem',
                                                        }}
                                                    /> 
                                                </div>
                                                    {
                                                        form?.is_submitted &&
                                                      /*  <CheckCircleOutlineIcon
                                                            sx={{
                                                                color: '#27AE60',
                                                                fontSize: '1.8rem'
                                                            }}
                                                        />*/
                                                        <Typography variant="subtitle1" sx={{fontFamily: "helvetica-lt-std-bold" , ml:"5px"}}>{form?.submitted_time}</Typography>

                                                    }
                                                </Box>
                                                </Box>
                                            )) :
                                            <Typography
                                                sx={{
                                                    fontFamily: "helvetica-lt-std-bold",
                                                    textAlign: 'center',
                                                    mt: '24px'
                                                }}
                                            >
                                                Empty.
                                            </Typography>
                                    }
                                    <Typography
                                        sx={{
                                            my: '25px',
                                            fontFamily: "helvetica-lt-std-bold",
                                        }}
                                    >
                                        Customer Service Submitted  Form:
                                    </Typography>
                                    {
                                        allSubmittedForm?.customer_service_form?.length ?
                                            allSubmittedForm?.customer_service_form?.map((form, index) => (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        background: '#EDF2F7',
                                                        p: '5px 10px',
                                                        borderRadius: '5px',
                                                        mt: '5px',
                                                        mb: '10px',
                                                        height:'auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent:'space-between',
                                                    }}
                                                    key={index}
                                                    // onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}`)}
                                                    onClick={() => {
                                                        openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1&submitted_id=${form?.submitted_id}`, '_blank')
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <PlayArrow
                                                            sx={{
                                                                fontSize: '1.3rem',
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                ml: '10px',
                                                            }}
                                                        >
                                                            {form?.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AttachEmailIcon
                                                    onClick={(e) =>  {e.stopPropagation()
                                                     handleRowClick(form?.customer_id,form?.treatment_form_id)}}
                                                        sx={{
                                                            fontSize: '1.3rem',
                                                        }}
                                                    /> 
                                                </div>
                                                    {
                                                        form?.is_submitted &&
                                                       /* <CheckCircleOutlineIcon
                                                            sx={{
                                                                color: '#27AE60',
                                                                fontSize: '1.8rem'
                                                            }}
                                                        />*/
                                                        <Typography variant="subtitle1" sx={{fontFamily: "helvetica-lt-std-bold" , ml:"5px"}}>{form?.submitted_time}</Typography>

                                                    }
                                                </Box>
                                                </Box>
                                            )) :
                                            <Typography
                                                sx={{
                                                    fontFamily: "helvetica-lt-std-bold",
                                                    textAlign: 'center',
                                                    mt: '24px'
                                                }}
                                            >
                                                Empty.
                                            </Typography>
                                    }
                                    <Typography
                                        sx={{
                                            my: '25px',
                                            fontFamily: "helvetica-lt-std-bold",
                                        }}
                                    >
                                        Submitted Chart  Form:
                                    </Typography>
                                    {
                                        allSubmittedForm?.chart_form?.length ?
                                            allSubmittedForm?.chart_form?.map((form, index) => (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        background: '#EDF2F7',
                                                        p: '5px 10px',
                                                        borderRadius: '5px',
                                                        mt: '5px',
                                                        mb: '10px',
                                                        height:'35px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        justifyContent:'space-between',
                                                    }}
                                                    key={index}
                                                    openModal
                                                    onClick={() => {
                                                        openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1&submitted_id=${form?.submitted_id}`, '_blank')
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <PlayArrow
                                                            sx={{
                                                                fontSize: '1.3rem',
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                ml: '10px',
                                                            }}
                                                        >
                                                            {form?.name}
                                                        </Typography>
                                                    </Box>
                                                    {
                                                        form?.is_submitted &&
                                                        /*<CheckCircleOutlineIcon
                                                            sx={{
                                                                color: '#27AE60',
                                                                fontSize: '1.8rem'
                                                            }}
                                                        />*/
                                                        <Typography variant="subtitle1" sx={{fontFamily: "helvetica-lt-std-bold"}}>{form?.submitted_time}</Typography>
                                                    }
                                                </Box>
                                            )) :
                                            <Typography
                                                sx={{
                                                    fontFamily: "helvetica-lt-std-bold",
                                                    textAlign: 'center',
                                                    mt: '24px'
                                                }}
                                            >
                                                Empty.
                                            </Typography>
                                    }
                                </Box>
                            }
                    <Box>
  <Typography  
    sx={{
      my: '25px',
      fontFamily: "helvetica-lt-std-bold",  
    }}
  >
    Search Results:
  </Typography>
  {allFilteredForms.length > 0 ?
    allFilteredForms.map((form, index) => (
        <Box
        sx={{  
            width: '100%',
            background: '#EDF2F7',     
            p: '5px 10px',  
            borderRadius: '5px',      
            mt: '5px',
            mb: '10px',
            display: 'flex',
            height:{sm:'35px', xs:'auto'},
            alignItems: 'center',
            cursor: 'pointer',
            justifyContent:'space-between',
        }}
        key={index}
        onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}`)}
    >
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >   
            <PlayArrow  
                sx={{     
                    fontSize: '1.3rem',
                }} 
            />  
            <Typography  
                sx={{
                    ml: '10px',
                }}
            >
                {form?.name}
            </Typography>
        </Box>
        <Box
    sx={{
        display: 'flex',
        alignItems: 'center',
    }}
>
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <AttachEmailIcon
       onClick={(e) => {
            e.stopPropagation();
            handleRowClick(form?.customer_id,form?.treatment_form_id)}}
            sx={{
                fontSize: '1.3rem',
            }}
        /> 
    </div>
       {/* { 
            form?.is_submitted &&   
           <CheckCircleOutlineIcon
                sx={{
                    color: '#27AE60',   
                    fontSize: '1.8rem'
                }}    
            />   
        }*/}  
    </Box>
    </Box>
    )) :
    <Typography>No matching forms found.</Typography>
  }
                     </Box>  
                        </Box>
                }
            </CustomModal>
            <LinkModal open={isModalOpen} onClose={closeModal} linkUrl={selectedLinkUrl} />
        </Box>
    );
};  

export default TreatmentFormButton;