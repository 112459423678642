import React from 'react';
import {Pagination, PaginationItem, useMediaQuery, useTheme} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import { consultatinDashboard } from '../../store/crm/dashboard';
import { styled } from "@mui/material/styles";

// import { useMediaQuery, useTheme } from "@mui/material";
const StyledPagination = styled(Pagination)`
  & ul {
    flex-wrap: nowrap !important;
    overflow-x: auto;
  }
  
`;

const BackendPagination = ({ count, handleOnChange, currentPage, executeScroll }) => {
    const handleChange = (event, newPage) => {
        handleOnChange(newPage);
        if (executeScroll && typeof executeScroll === 'function') {
            executeScroll();
        }
    };

    // Custom pagination rendering logic
    const renderItem = (item) => {
        const { type, page, selected } = item;

        // Show only the first 3 pages, dots, and the last page
        if (type === 'page') {
            if (page <= 3 || page === count) {
                return (
                    <PaginationItem
                        sx={{ background: '#fff' }}
                        {...item}
                    />
                );
            } else if (page === 4 && count > 4) {
                return (
                    <PaginationItem
                        sx={{ background: '#fff' }}
                        {...item}
                        disabled
                    >
                        ...
                    </PaginationItem>
                );
            } else {
                return null; // Hide other pages
            }
        }

        // Handle previous and next arrows
        if (type === 'previous' || type === 'next') {
            return (
                <PaginationItem
                    sx={{ background: '#fff' }}
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                />
            );
        }

        return <PaginationItem sx={{ background: '#fff' }} {...item} />;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    console.log("width",isMobile)
    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            {/* <Pagination

                count={count}
                page={currentPage}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                renderItem={(item) => {
                    return (
                        <PaginationItem
                            sx={{background: '#fff'}}
                            slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                            {...item}
                        />
                    )
                }}
            /> */}
  <StyledPagination
    count={count}
    page={currentPage}
    onChange={handleChange}
    variant="outlined"
    shape="rounded"
    siblingCount={0}  // Hide middle numbers
    boundaryCount={isMobile ? 1 : 2} // Show first & last page numbers
    sx={{display:"flex",flexWrap:"nowrap !important"}}
    renderItem={(item) => (
        <PaginationItem
        
            sx={{ background: "#fff" }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
        />
    )}
/>

        </Box>
    );
};

export default BackendPagination;