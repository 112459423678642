
import React, {useState} from 'react';
import {Box, FormControl, FormHelperText, FormLabel, IconButton, Typography} from "@mui/material";
import {MdDelete} from "react-icons/md";
import {AiFillPlusCircle} from "react-icons/ai";
import {labelStyle} from "../form/formInput";



const Image = ({ setFieldValue, errors, label, values, touched, name, styles }) => {
    const [active, setActive] = useState(true);

    const handleDeleteClick = (event) => {
        event.stopPropagation(); // Prevents event from propagating to parent elements
        setFieldValue(name, null);
    };

    return (
        <FormControl fullWidth sx={{ mb: '16px' }}>
            <FormLabel sx={{ mb: '5px' }}>{label}</FormLabel>
            <Box
                onClick={() => {
                    if (active) {
                        document.getElementById('image-input').click();
                    }
                }}
                sx={{
                    '@media (max-width: 420px)': { marginTop: '50px' },
                    height: '200px',
                    width: '200px',
                    backgroundColor: '#edf2f7',
                    border: '1px solid #EDF2F7',
                    display: 'flex',
                    borderRadius: '7px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    position: 'relative',
                    img: {
                        borderRadius: '7px',
                    },
                    ...styles,
                }}
            >
                <IconButton
                    sx={{
                        fontSize: '1.5rem',
                        position: 'absolute',
                        top: '5px',
                        right: '6px',
                    }}
                    onMouseEnter={() => setActive(false)}
                    onMouseLeave={() => setActive(true)}
                    onClick={handleDeleteClick} // Updated click handler
                >
                    <MdDelete color={'red'} />
                </IconButton>
                <input
                    type="file"
                    id={'image-input'}
                    name={name}
                    accept="image/*"
                    hidden
                    onChange={(event) => {
                        setFieldValue(name, event.currentTarget.files[0]);
                        event.target.value = '';
                    }}
                />
                {values[name] !== null && (
                    <img src={typeof values[name] === 'string' ? values[name] : URL.createObjectURL(values[name])} width={'100%'} height={'100%'} alt={'logo'} />
                )}
                {values[name] === null && (
                    <Box sx={{ textAlign: 'center' }}>
                        <AiFillPlusCircle type="file" color={'blue'} fontSize={'2rem'} />
                        <Typography variant="body1">Upload Photo</Typography>
                    </Box>
                )}
            </Box>
            {errors[name] && touched[name] && (
                <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>{errors[name]}</FormHelperText>
            )}
        </FormControl>
    );
};

export default Image;

