import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    odd: {
        '&:nth-child(odd)': {
          backgroundColor: '#F5FCFF',
        },
      },
      buttons:{
        background:'#fff',
         color:'#333333',
         height:'45px',
         
      },
      buttonIcons:{
        width:'20px',
        marginRight:'5px'
      },
      archiveText:{
        display:'flex',alignItems:'center', justifyContent:'end', color:'#2F80ED'
      },
      eodText:{
        color:'black', fontFamily:'helvetica-lt-std-light !important'
      },

      // Table
      tableHead:{
        background: '#D8F3FF',
        borderRadius: '8.4769px'
      },
      tableLink:{
        color: "#2F80ED",
        textDecoration: "underline",
      }

}));
export default useStyles;