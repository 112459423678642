import React, { useEffect, useState, useRef } from "react";
import BookingHeader from "./bookingHeader";
import Box from "@mui/material/Box";
import defaultPersonImg from '../../assets/images/default-person-img.png';

import {
  AppBar,
  Autocomplete,
  Avatar,
  Button,
  CardMedia,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";  
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Slide from "react-reveal/Slide";

import BookingCalender from "./bookingCalender";
import {
  MySwal,
  convertTimeToMinutes,
  getEndTime,
  getTimeSlotsArray,
} from "../../services/utills";
import useStyles from "./styles";
import BookingContact from "./bookingContact";
import { getAllActiveClinicsLocations } from "../../store/user/default-location";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import Person3Icon from '@mui/icons-material/Person3';
import {
  getBooingHolidays,
  getBookingServices,
  getBookingSchedules,
} from "../../store/booking-widget";
import moment from "moment/moment";
import toast from "react-hot-toast";
import SimpleInput from "../../components/form/simpleInput";
import { escapeRegExp } from "../../components/table";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import planImage from "../../assets/images/plan image1.png";
import homeoutline from "../../assets/images/homeoutline.png";
import booking from "../../assets/images/Vector (Stroke).png";   
import menu from "../../assets/images/homeIcon.png";
import plan from "../../assets/images/wallet2.png";
import profile from "../../assets/images/user.png";
import Login from "../login";
import Waveloading from "../loading/Waveloading";
import CloseIcon from "@mui/icons-material/Close";
import { AllClinicServices, AllServices, SubCategory } from "../../store/home";
import Footer from "../home/Footer";

const style = {
  color: "#000",
  fontWeight: "600",
  cursor: "pointer",
  marginBottom: "16px",
};
const landing = {
  display: "block",

  "@media screen and (max-width: 400px)": {
    display: "none",  
  },
};

const servicesSlug = "Botox - Crows Feet (Wrinkles Around Eyes)";

const BookingWidget = () => {
  const currentURL = window.location.href;
  const pathSegments = currentURL.split("/");
  const servicesIndex = pathSegments.indexOf("services");
  const serviceType = pathSegments[servicesIndex + 2];

  const categoryName = pathSegments[servicesIndex + 1];
  const classes = useStyles();
  const { slug } = useParams();
  const subcategorySlug = slug || serviceType;
  const [active, setActive] = useState(0);
  const [selectedService, setSelectedService] = useState([]);
  const [bookingServicesList, setBookingServicesList] = useState([]);
  const [selectedServicesStaff, setSelectedServicesStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("any");
  const [selectedStaffIds, setSelectedStaffIds] = useState("any");
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateForApi, setSelectedDateForApi] = useState(null);
  const [showEditSection, setShowEditSection] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [moreInfo, setMoreInfo] = useState([]);

  const [onHover, setOnHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingdate, setLoadingDate] = useState(true);
  const [valuetab, setValuetab] = useState(0);
  const [value, setValue] = useState(0);
  // const [search, setSearch] = useState(''); // State variable to store search input

  // const [isOpen, setIsOpen] = useState(false)
  // const [selectedTimeZone, setSelectedTimeZone] = useState(null)
  const [provider, setProvider] = useState(null); 
  const [serviceDate, setserviceDate] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [providerBookingSettings, setProviderBookingSettings] = useState(null);
  const [locationOpen, setLocationOpen] = useState(false);
  const [loginOpen, setOpenLogin] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const urlParams = useParams();
  const ref = useRef();
  const loadingRef = useRef();  
  const navigate = useNavigate();
  const authStore = useSelector((state) => state?.authStore);
  const { user } = authStore;
  const hasRun = useRef(false);
  const location = useLocation();  
  const selectedLocation = location?.state?.selectedLocation 
  console.log("booking widget location",selectedLocation,location)
  const dispatch = useDispatch();
  const { treatment_slug, category_slug, location_id } = useParams();
  console.log("Location Id:" + location_id);
  let service_type = "booking";
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has("type")) {

    service_type = queryParams.get("type"); 
  }
  console.log("Service Type:" + service_type);

  const bookingServicesStore = useSelector(
    (state) => state?.bookingWidgetStore?.bookingServices
  );

  console.log("serviceselected", bookingServicesStore);
  const bookingSlots = useSelector(
    (state) => state?.bookingWidgetStore?.bookingSlots
  );
  const userDefaultLocationStore = useSelector(
    (state) => state?.userDefaultLocationStore?.activeClinics
  );

  const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
 
  const { scrollToDiv } = location.state || {};

  // Dependency on bookingServicesStore
  
  const handleSelect = (val) => {
    if (!onHover) {
      if (selectedService?.length) {
        if (isExist(selectedService, val)) {
          let finalRes = selectedService.filter((item) => item?.id !== val?.id);
          setSelectedService(finalRes);
        } else {
          setSelectedService([...selectedService, val]);
        }
      } else {
        let finalArray = [];
        finalArray.push(val);
        setSelectedService(finalArray);
      }
    }
  };
  
  // Helper function to check if the service exists in selectedService
  const isExist = (array, val) => {
    return array?.some((item) => item?.id === val?.id);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(3);
        setLoading(true);
  
        if (provider?.id || location_id) {
          let { payload } = await dispatch(getAllActiveClinicsLocations());
  
          if (payload?.success === "true") {   
            let selectedClinic = payload?.data?.find(
              (item) => item?.id === parseInt(provider?.id || location_id)
            );
            if (selectedClinic) {  
              setProviderBookingSettings(selectedClinic?.booking_setting || null);
              setProvider(selectedClinic);
  
              let finalData = {
                group: category_slug,
                treatment: treatment_slug,
                provider_id: selectedClinic?.id,
              };
            const response= await dispatch(getBookingServices(finalData));

  console.log("response api",response?.payload?.data)
              setDataLoaded(true);
let bookingdata=response?.payload?.data
console.log("response api",bookingdata) 
              if (bookingdata?.length && !hasRun.current) {
                setBookingServicesList(bookingServicesStore);
                const queryParams = new URLSearchParams(window.location.search);
                if (queryParams.has("service_id")) {
                  const serviceId = queryParams.get("service_id");
                  console.log("serviceId from query params:", serviceId);
            
                  // Ensure both serviceId and bookingServicesStore's ids are of the same type (integers)
                  const parsedServiceId = parseInt(serviceId, 10);
                  console.log("Parsed serviceId:", parsedServiceId);
            
                  // Filter to find the selected service
                  let selectedId = bookingdata.filter((item) => {
                    // Log the item to see its contents
                    console.log("Item being processed:", item?.id ,item);
                    
                    // Return the condition to check the service id
                    return item.id == parsedServiceId;  // Use == to check for equality
                  });
                  console.log("Selected service found:", selectedId);
            
                  if (selectedId.length > 0) {
                    // Call handleSelect only if the selected service is found
                    handleSelect(selectedId[0]);
                    setActive(1);
                  } else {
                    console.log("No matching service found for id:", parsedServiceId);
                  }
                }
                hasRun.current0 = true; // Mark as run
              } else if (!bookingdata?.length) {
                setBookingServicesList([]);
                hasRun.current = false; // Reset for next relevant change
              }
            }
          }
  
          const queryParams = new URLSearchParams(window.location.search);
          setActive(queryParams.has("service_id") ? 1 : 0);
        } else {
          let res = await dispatch(getAllActiveClinicsLocations());
          if (res?.payload?.success === "true" && !provider?.id) {
            setLocationOpen(true);
          }
          setDataLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  
    fetchData()
  }, [location_id])
  
  useEffect(() => {  
    console.log(1);     
    if (selectedService?.length) {
      console.log("api work");     
      const queryParams = new URLSearchParams(window.location.search);
      let staffArray = [];
      selectedService.forEach((item) => {
        if (item?.service_schedule_date) {
          const date = item.service_schedule_date;
          setserviceDate(date);
          setSelectedDate(moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY"));
          setSelectedDateForApi(date);
          handleSelectDate(date); // Call the function here
        }
        if (queryParams.has("date")) {
          // Get the value of the 'service_slug' parameter
          const date = queryParams.get("date");
          setserviceDate(date);
          setSelectedDate(moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY"));
          setSelectedDateForApi(date);
          handleSelectDate(date); // Call the function here
        } 
        staffArray.push(...item?.staff)
      })  
    
      const uniqueArray = Object.values(  
        staffArray.reduce((acc, obj) => { 
          acc[obj.id] = obj;
          return acc;        
        }, {})   
      );
      setSelectedServicesStaff(uniqueArray);
    }   
  }, [selectedService])
  console.log("selected issue", selectedService);
  useEffect(() => {
    console.log(4);
    if (dataLoaded && scrollToDiv) {
      setTimeout(() => {
        const element = document.getElementById(scrollToDiv);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [dataLoaded, scrollToDiv]);

  const homeStore = useSelector((state) => state?.homeStore);      
  const { Services, subcategory, ServiceData } = homeStore;
  const childSlugs = subcategory?.child?.map((child) => child.slug);
  const selectedChildSlug = childSlugs?.[value];   
  const handletabs = (event, newValue) => {
    setValue(newValue);
  };
  const [services_1, setServices] = useState([]);
  useEffect(() => {
    console.log(5)    
    dispatch(AllClinicServices( provider?.id||location_id));
  }, []);
  useEffect(() => {
    console.log(6)
    setServices(Services);
  }, [Services]);
  const [filteredServices, setFilteredServices] = useState(ServiceData);
  const handleCategoryClick = (Slug) => {
       setSelectedTab(Slug)
    if (servicescrollRef.current) {  
      servicescrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    let payload = {
      provider_id: provider?.id|| location_id,     
      Slug: Slug,    
    };  
    const response = dispatch(SubCategory(payload));
  };    
  useEffect(() => {      
    console.log("slug",subcategorySlug);
    if (subcategorySlug) {
      if (servicescrollRef.current) {
        servicescrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
      handleCategoryClick(subcategorySlug);
    }
  }, [subcategorySlug]);

  const fetchData = async () => {
    setLoading(true);

    let payload = {
      provider_id:provider?.id|| location_id, 
    };

    if (selectedChildSlug && selectedChildSlug.length > 0) {
      payload.treatment = selectedChildSlug;
      payload.group = subcategory?.slug;
    } else {     
      payload.treatment = subcategory?.slug;

      if (subcategory?.parent) {  
        payload.group = subcategory?.parent?.slug;
      } else {
        payload.group = subcategory?.slug;
      }
    }

    const response = await dispatch(AllServices(payload));
    setLoading(false);
  };
  useEffect(() => {
    console.log(8);
    fetchData();
  }, [subcategory, value]); 

  const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
  const handleBoxClick = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      navigate("/user/bookings");
    }
  };
  const handleplan = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      navigate("/user/membership");
    }
  };
  const handleHome = () => {
    navigate("/home");
  };
  const handleprofile = () => {
    if (shouldRedirectToLogin) {
      setOpenLogin(true);
    } else {
      // Navigate to the booking page
      navigate("/user/user-account");  
    }
  };
  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  const myRef = useRef(null);
  const handleNextClick = () => {
    if (active === 0 && selectedService?.length) {
      setActive(1);
    } else if (active === 1) {
      setActive(2);
    }  
  };
  useEffect(() => {
    console.log(9);
    if (active === 2 && myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [active]);
  const handleBackClick = () => {
    if (active === 1) {
      setActive(0);
    } else if (active === 2) {
      setActive(1);
    }
  };
  const handleEdit = (val) => {
    setShowEditSection(!showEditSection);
    const MOBILE_VIEW_MAX_WIDTH = 786;
    if (window.innerWidth <= MOBILE_VIEW_MAX_WIDTH && val === 0) {
      navigate(`/categories/${serviceType}/${location_id}`);
    } else {
      setSelectedTime(null);
      setSelectedDate(null);
      setActive(val);
    }
  };

 
  const handleMoreInfo = (val) => {
    if (moreInfo?.length) {
      if (moreInfo?.some((item) => item === val)) {
        let finalRes = moreInfo.filter((item) => item !== val);
        setMoreInfo(finalRes);
      } else {
        setMoreInfo([...moreInfo, val]);
      }
    } else {
      let finalArray = [];
      finalArray.push(val);
      setMoreInfo(finalArray);
    }
  };

  const continueButtonRef = useRef(null);
  const servicescrollRef = useRef(null);
   
  const handleTimeSelect = (slotsArray, startTime) => {
    let totalTime = 0;
    selectedService.forEach((item) => {
      let time = convertTimeToMinutes(item?.time_duration);
      totalTime = totalTime + time;
    });

    let endTime = getEndTime(startTime, totalTime);
    let getArraySlots = getTimeSlotsArray(startTime, endTime, slotsArray);
    let lastIndex = getArraySlots[getArraySlots?.length - 1];

    // if (bookingSlots?.slots.includes(lastIndex)) {
      let object = {
        start: startTime,
        end: endTime,
        slots: getArraySlots,
      };

      setSelectedTime(object);
      handleNextClick()
      // if (continueButtonRef.current) {
      //   continueButtonRef.current.scrollIntoView({ behavior: "smooth" });
      // }
    // } else {
    //
    //  MySwal.fire({
    //     icon: 'error',
    //     title: 'Booking ending time exceed slots time.',
    //     showConfirmButton: true,
    //     timer: 5000,
    // });
    //   // toast.error("Booking ending time exceed slots time", { duration: 5000 });
    //   setSelectedTime(null);
    // }
  };

  const getMonthHolidays = async (date, staff) => {
    setLoading(true);
    let finalData = {
      date: moment(date).format("YYYY-MM-DD"),
      provider_id: provider?.id,
    };

    if (typeof staff === "string") {
      // If `staff` is a comma-separated string, assign it directly
      finalData["staff_id"] = staff;
    } else if (typeof staff === "object" && staff?.id) {
      // If `staff` is an object, use its `id`
      finalData["staff_id"] = staff.id;
    }

    try {
      let res = await dispatch(getBooingHolidays(finalData));
    } catch (error) {
      console.error("Error fetching holidays:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleReset = (val) => {
    setSelectedService([]);
    setSelectedServicesStaff(null);
    setSelectedStaff("any");
    setSelectedDate(null);
    setSelectedDateForApi(null);
    setSelectedTime(null);
    if (val === "reset") {
      setActive(0);
    }
  };
  const handleStaffSelect = async (staff) => {

    if(provider?.booking_setting?.is_staff_availability_check_on_booking === 1 && selectedServicesStaff?.length > 1 && staff == 'any'){
      setLoading(true);
      const staffIds = selectedServicesStaff?.map(staff => staff.id).join(",") || "";
      setSelectedStaffIds(staffIds);
      await setSelectedStaff(staff);
      setLoading(false);
      await getMonthHolidays(selectedDateForApi || new Date(), staffIds);
    }else{
      setLoading(true);
      await setSelectedStaff(staff);
      setLoading(false);
      await getMonthHolidays(selectedDateForApi || new Date(), staff);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const staffIds = selectedServicesStaff?.map(staff => staff.id).join(",") || "";
      if(provider?.booking_setting?.is_staff_availability_check_on_booking === 1 && selectedServicesStaff?.length > 1){
        setSelectedStaffIds(staffIds);
        await getMonthHolidays(selectedDateForApi || new Date(), staffIds);
      }else{
        if (selectedServicesStaff?.length === 1) {
          handleStaffSelect(selectedServicesStaff[0]);
        } else {
          let finalHolidayData = {
            date: moment(new Date()).format("YYYY-MM-DD"),
            provider_id: provider?.id || location_id,
          };

          let res = await dispatch(getBooingHolidays(finalHolidayData));
        }
      }
    };
  
    fetchData();
  }, [ selectedServicesStaff, provider, location_id]);

  const backhandler= (selectedClinic) =>{
  if (active === 1) {
    navigate(`/categories/${serviceType}/${provider?.id}`,{ state: { selectedLocation: selectedLocation  } });
    console.log("id comes",selectedLocation,selectedLocation?.id)
  } else if (active === 2) {
    setActive(1);
  }else {
    console.log("location sending",selectedClinic)
    navigate(`/home`, { state: { selectedLocation: selectedLocation  } });
  }
  };
  const handleSaveLocation = async () => {
    if (provider?.id) {
      setLocationOpen(false);
      setActive(0);
      handleReset();
    }    
    if (providerBookingSettings?.booking_process === 1) {
      setLoading(true);
      try {
        let finalData = {
          group: category_slug,
          treatment: treatment_slug,
          provider_id: provider?.id,
        };
        let payload = {
          provider_id: provider?.id|| location_id,     
          Slug: subcategorySlug,    
        };  
        const response = dispatch(SubCategory(payload));
        // await dispatch(getBookingServices(finalData));
        await getMonthHolidays(new Date());
      } catch (error) {
        console.error("Error saving location:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  


  // Inside your component

  const handleSelectDate = async (date) => {
    if (date) {
      setSelectedDate(moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY"));
      setSelectedDateForApi(date);
    }
    let selectedServicesIds = [];
    selectedService.forEach((item) => {
      selectedServicesIds.push(item?.id);
    });
    setSelectedTime(null);
    let finaData = {
      date: date,
      provider_id: provider?.id,
      service_ids: selectedServicesIds,
    };
      if (selectedStaff?.id) {
        finaData["staff_id"] = selectedStaff?.id;
      }else{
        if(selectedStaffIds && selectedStaffIds !== "any"){
          finaData["staff_id"] = selectedStaffIds;
        }
    }

    setLoadingDate(true);
    try {
      setLoading(true);
      let res = await dispatch(getBookingSchedules(finaData));
      setLoading(false);
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);  
      if (res?.payload?.success === "true") {
      }
    } catch (error) {
    } finally {
      setLoadingDate(false);
    }
  };

  const handleDateReset = () => {
    setSelectedDate(null);
    setSelectedTime(null);
  };


  console.log("data list of service ",ServiceData?.data)
  const handleLocationClick = (newValue) => {
    if (newValue?.booking_setting?.id) {
      setProviderBookingSettings(newValue?.booking_setting);
    } else {
      setProviderBookingSettings(null);
    }
    setProvider(newValue);
  };
  const handleRemoveService = (indexToRemove) => {
    const MOBILE_VIEW_MAX_WIDTH = 786;
    if (window.innerWidth <= MOBILE_VIEW_MAX_WIDTH) {
      navigate(`/categories/${serviceType}/${provider?.id}`);
    }
    // Create a copy of the selectedService array without the service to be removed
    const updatedSelectedService = selectedService.filter(
      (_, index) => index !== indexToRemove
    );
    // Update the selectedService state with the new array
    setSelectedService(updatedSelectedService);
    if (updatedSelectedService.length === 0) {
      setSelectedTime(null);
      setSelectedDate(null);
      setActive(0);
    } else {
      setSelectedTime(null);
      setSelectedDate(null);
      setActive(1);
    }
  };

  return active === 3 ? (
    <Box>Not found</Box>
  ) : (
    <>
      {loginOpen ? (
        <Box
          sx={{
            position: "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: 0,
            zIndex: "1111111111111!important",
          }}
        >
          <div>
            <Slide top>
              <Login
                loginOpen={loginOpen}
                setOpenLogin={setOpenLogin}
                showFooter={true}
              />
            </Slide>
          </div>
        </Box>
      ) : null}

      <Box>
        {loading ? <Waveloading fullSize /> : ""}
        {/**************************  location Modal ***************************/}

        {locationOpen && (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <Slide top>
              <Grid container sx={{ p: "48px 20px" }}>
                <Grid item xs={12} md={10} lg={7} sx={{ mx: "auto" }}>
                  <Typography
                    variant={"h3"}
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "22px",
                      mb: "16px",
                    }}
                  >
                    Choose Your Default Spa Location
                  </Typography>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {userDefaultLocationStore?.length > 0 &&
                      userDefaultLocationStore?.map((item, index) => (
                        <ListItem  
                          key={index}
                          sx={{
                            mt: "10px",
                            background:
                              item?.id === provider?.id ? "#09ABF1" : "#fff",
                            p: 0,
                            borderRadius: "5px",
                            color: item?.id === provider?.id ? "#fff" : "#000",
                          }}
                          secondaryAction={
                            <IconButton
                              sx={{
                                ".Mui-checked": {
                                  color:
                                    item?.id === provider?.id
                                      ? "#fff!important"
                                      : "#000",
                                },
                              }}
                              onClick={() => handleLocationClick(item)}
                              edge="end"
                            >
                              <Checkbox
                                checked={item?.id === provider?.id}
                                disableRipple
                              />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            role={undefined}
                            onClick={() => handleLocationClick(item)}
                            dense
                          >
                            <ListItemAvatar>
                              <Avatar alt={item?.name} />   
                            </ListItemAvatar>
                            <ListItemText
                              primary={item?.name}
                              secondary={
                                item?.address?.address ? (
                                  <React.Fragment>
                                    <Box as={"span"} sx={{ display: "block" }}>
                                      <Typography
                                        as={"span"}
                                        sx={{
                                          display: "block",
                                          color:
                                            item?.id === provider?.id
                                              ? "#fff"
                                              : "#626262",
                                        }}
                                        variant="body2"
                                      >
                                        {item?.address?.address}
                                      </Typography>
                                      <Typography
                                        as={"span"}
                                        sx={{
                                          display: "block",
                                          color:
                                            item?.id === provider?.id
                                              ? "#fff"
                                              : "#626262",
                                        }}
                                        variant="body2"
                                      >
                                        {item?.address?.state},
                                        {item?.address?.postal_code}
                                      </Typography>
                                    </Box>
                                  </React.Fragment>
                                ) : null
                              }
                            />
                          </ListItemButton>
                        </ListItem> 
                      ))}
                  </List>
                  <Button
                    fullWidth
                    onClick={handleSaveLocation}
                    variant={"contained"}
                    sx={{
                      backgroundColor: "#aa2167",
                      mt: "32px",
                      height: "40px",
                      color:'#fff'
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Slide>
          </Box>
        )}
        {/************************** Ending of location Modal ***************************/}

        {providerBookingSettings?.booking_process === 1 && !locationOpen ? (
          <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, }}>
              <Box onClick={backhandler} sx={{ marginLeft:'15px' }}>
                <ArrowBackIcon />
              </Box>
              </Box>
            <BookingHeader
              setLocationOpen={setLocationOpen}  
              provider={provider}
            />
            <Container
              sx={{
                pt: { sm: "120px", xs: "30px" },
                pb: { sm: "50px", xs: "120px" },
                maxWidth: "md",
              }}
            >
              <Grid container justifyContent={"space-between"}>
                {/*{service_type !== "booking" && (*/}
                {/*  <Grid*/}
                {/*    item*/}
                {/*    xs={12}*/}
                {/*    sx={{*/}
                {/*      mb: "50px", */}
                {/*    }}*/}
                {/*  >*/}
                {/*    <Typography*/}
                {/*      sx={{*/}
                {/*        fontFamily: "helvetica-world-bold",*/}
                {/*        textAlign: "center",*/}
                {/*        fontSize: "20px",*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      {service_type === "booking"*/}
                {/*        ? ""*/}
                {/*        : service_type === "Consultation"*/}
                {/*        ? "In Person Consultation"*/}
                {/*        : "Virtual"}*/}
                {/*    </Typography>   */}
                {/*  </Grid>*/}
                {/*)}*/}
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  // sx={{ display: { xs: "none", md: "block" } }}
                >
                  <Box>
                    <Typography sx={active === 0 ? style : {display:'flex', fontWeight: "600",
                            mb: "24px",
                            fontSize: "20px",
                            color: "black",}}>
                      Select Service
                    </Typography>
                    {selectedService?.length > 0 || active === 0
                      ? selectedService.map((item, index) => (
                          <Box>
                            <Box
                              key={index}
                              sx={{
                                background: "#f4ede3",

                                padding: " 20px",
                                mt: "16px",
                                borderRadius: "4px",
                              }}
                            >
                              <Box style={{ textAlign: "end" }}>
                                <IconButton
                                  onClick={() => handleRemoveService(index)}
                                  sx={{
                                    positon: "relative",
                                    top: "-15px",
                                    right: "-10px",
                                    color:'red',
                                    fontSize:'13px',
                                    fontWeight:'700'
                                  }}
                                >
                                 Change Service <ChangeCircleOutlinedIcon sx={{fontSize:'18px' , marginLeft:'5px' }} />
                                </IconButton>
                              </Box>
                              <Box sx={{ position: "relative", top: "-15px" }}>
                                <Typography sx={{ fontSize:'18px' }}>
                                  {item?.name}
                                  {service_type === "booking"
                                      ? ""             
                                      : service_type === "Consultation"
                                          ? " (In Person Consultation)"
                                              : " (Virtual)"
                                  }
                                  {item?.is_free === 1
                                    ? " (No Deposit Required )"
                                    : ""}

                                  {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                </Typography>
                                <Typography sx={{ color: "#636363" }}>
                                <AccessTimeFilledIcon  sx={{fontSize:'18px', mr:'5px'}}/>  
                                 {convertTimeToMinutes(item?.time_duration)}{" "}
                                  minutes
                                </Typography>
                                {/* <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                  {item?.staff?.length > 0 &&
                                    item?.staff.map((staffData, i) => (
                                      <Typography
                                        key={i}
                                        sx={{
                                          fontWeight: "bold",
                                          color: "black",
                                        }}
                                      >
                                        {i !== 0 && ", "} {staffData?.name}  
                                      </Typography>
                                    ))}
                                </Box> */}
                              </Box>
                            </Box>
                          </Box>
                        ))
                      : ""}
                  </Box>

                </Grid>

                {active === 0 && (
                  <Grid item xs={12} md={7.5} lg={8} xl={8.5}>
                    {/*
                {showEditSection && (
*/}
                    <Grid container>
                      {services_1 && services_1.length > 0 ? (
                        services_1.map((service) => (
                          <>
                            <Box
                              key={service.slug}
                              sx={{
                                display: "flex",
                                marginBottom: "10px",
                                flexWrap: "wrap",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  border: "1px solid black",
                                  borderRadius: "20px",
                                  minHeight: "35px",
                                  color:
                                    selectedTab === service.slug
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    selectedTab === service.slug
                                      ? "black"
                                      : "white",
                                  padding: "10px 16px",
                                }} 
                                onClick={() =>
                                  handleCategoryClick(service?.slug)
                                }
                              >
                                {service?.title}
                              </Typography>
                            </Box>
                          </>
                        ))
                      ) : (
                        <Typography variant="body2">
                          No Services available
                        </Typography>
                      )}
                      {bookingServicesStore?.length > 0 && (
                        <Grid item xs={12} sx={{ mb: "24px" }}>
                          <TextField
                            id="search"
                            type="search"
                            label="Search"
                            value={searchTerm}
                            onChange={handleChange}
                            className={classes.search}
                            sx={{
                              width: "100%",
                              mt: 3,
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "cernter",
                              label: { mt: "-10px" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      )}

                      <Typography
                        variant={"h3"}
                        sx={{
                          fontWeight: "bold", 
                          mb: "24px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      >
                        Select one or more services
                      </Typography>

                      <Grid
                        ref={servicescrollRef}
                        container      
                        spacing={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                          mb: 20,
                        }}
                      >
                        {subcategory?.child &&
                        subcategory.child.length === 0 ? (
                          <>
                            {ServiceData?.promotion_services &&
                            ServiceData?.promotion_services.length > 0 ? (
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mt: 3,
                                  display: { xs: "block", sm: "none" },
                                }}
                              >            
                                <Typography       
                                  variant="h6"
                                  style={{ marginLeft: "18px" }}
                                >
                                  Promotion
                                </Typography>
                                {ServiceData?.promotion_services.map(
                                  (item, index) => (
                                    <>
                                      <Grid
                                        key={index}
                         
                                        item
                                        xs={12}
                                        sm={6}
                                        onClick={() => handleSelect(item)}
                                      >
                                        <Box
                                          sx={{
                                            borderRadius: "9px",
                                            p: "10px 15px",
                                            border: "1px solid #E0E0E0",
                                            background: "#fff",
                                            display: "flex",  
                                            flexDirection: "column",
                                          }}
                                        >
                                          <Typography
                                            variant="h4"
                                            sx={{
                                              color: "#000",
                                              fontSize: "18px",
                                              fontWeight: "700",
                                              my: 1,
                                            }}
                                          >
                                            {item?.name}
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              my: 1,
                                            }}
                                          >
                                            <Box sx={{ display: "flex" }}>
                                              <Box>
                                                <Typography
                                                  variant="h4"
                                                  sx={{
                                                    color: "#000",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    mr: 4,
                                                    textDecoration:
                                                      "line-through",
                                                    textDecorationColor:
                                                      "black",
                                                  }}
                                                >
                                                  ${item?.regular_price}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={{ fontSize: "10px" }}
                                                >
                                                  Regular Price
                                                </Typography>
                                              </Box>
                                              <Box>
                                                <Typography
                                                  variant="h4"
                                                  sx={{
                                                    color: "#000",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                  }}
                                                >
                                                  ${item?.member_price}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={{ fontSize: "10px" }}
                                                >
                                                  Member Price
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontFamily:
                                                    "helvetica-lt-std-roman",
                                                  color: "#000",
                                                  fontSize: "15px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {window.formatTimeDuration(item?.time_duration)}
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              my: 1,
                                              alignItems: "center",
                                            }}
                                          >
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontSize: "15px",
                                                  fontWeight: "300",
                                                  color: "rgba(28, 75, 137, 1)",
                                                  textDecoration: "underline",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  (window.location.href =
                                                    item?.link)
                                                }
                                              >
                                                More Info
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <Checkbox
                                                sx={{
                                                  color: "#c3af93",
                                                  "&.Mui-checked": {
                                                    color: "#c3af93",
                                                  },
                                                }}
                                                checked={isExist(
                                                  selectedService,
                                                  item
                                                )}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </>
                                  )
                                )}
                              </Grid>
                            ) : null}

                            {ServiceData?.promotion_services &&
                            ServiceData?.promotion_services.length > 0 ? (
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mt: 3,
                                  "@media (max-width: 400px)": {
                                    display: "none",
                                  },
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ marginLeft: "18px" }}
                                >
                                  Promotion
                                </Typography>
                                {ServiceData?.promotion_services
                                  .filter((item) =>
                                    item.name.toLowerCase().includes(searchTerm)
                                  )
                                  .map((item, index) => (
                                    <>
                                      <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sx={{
                                          border: `1px solid ${
                                            isExist(selectedService, item)
                                              ? "#d2bea2"
                                              : "#d2bea2"
                                          }`,
                                          // pr: "32px",
                                          paddingLeft: "0 !important",
                                          paddingTop: "0 !important",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                          display: "flex",
                                          // mb: "24px",
                                          marginBottom: "20px",
                                          justifyContent: "space-between",
                                          "&:hover": {
                                            border: "1px solid #d2bea2",
                                          },
                                        }}
                                        onClick={() => handleSelect(item)}
                                      >
                                        <Box
                                          sx={{
                                            maxWidth: "80%",
                                            display: "flex",
                                          }}
                                        >
                                          <Avatar
                                            src={item?.image}
                                            variant="square"
                                            sx={{
                                              height: "100%",
                                              width: "135px",
                                              borderBottomLeftRadius: "4px",
                                              borderTopLeftRadius: "4px",
                                            }}
                                          />
                                          <Box sx={{ py: "24px", pl: "32px" }}>
                                            <Typography
                                              sx={{
                                                fontSize: "16px",
                                                mb: "8px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {item?.name}
                                              {item?.is_free === 1
                                                ? " (No Deposit Required)"
                                                : ""}
                                              {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                            </Typography>
                                            <Box>
                                              <Typography>
                                                {item?.is_free === 1
                                                  ? " Free"
                                                  : "$" +
                                                    item?.regular_price}{" "}
                                                ·{" "}
                                                {convertTimeToMinutes(
                                                  item?.time_duration
                                                )}{" "}
                                                minutes ·{" "}
                                                <Typography
                                                  as={"span"}
                                                  sx={style}
                                                  onMouseEnter={() => {
                                                    setOnHover(true);
                                                  }}
                                                  onMouseLeave={() => {
                                                    setOnHover(false);
                                                  }}
                                                  onClick={() =>
                                                    (window.location.href =
                                                      item?.link)
                                                  }
                                                >
                                                  More info
                                                </Typography>
                                              </Typography>
                                              {moreInfo.includes(item?.id) && (
                                                <Typography
                                                  sx={{
                                                    color: "#666666",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {item?.description}
                                                </Typography>
                                              )}
                                              {/*{isExist(selectedService,item) &&*/}
                                              <Box sx={{ display: "flex" }}>
                                                {item?.staff?.length > 0 &&
                                                  item?.staff.map(
                                                    (staffData, i) => (
                                                      <Typography
                                                        key={i}
                                                        sx={{
                                                          fontWeight: "400",
                                                          fontSize: "16px",
                                                          color: "black",
                                                        }}
                                                      >
                                                        {i !== 0 && ", "}{" "}
                                                        {staffData?.name}
                                                      </Typography>
                                                    )
                                                  )}
                                              </Box>
                                              {/*}*/}
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box sx={{ pt: "24px" }}>
                                          <Checkbox
                                            sx={{
                                              color: "#c3af93",
                                              "&.Mui-checked": {
                                                color: "#c3af93",
                                              },
                                            }}
                                            checked={isExist(
                                              selectedService,
                                              item
                                            )}
                                          />
                                        </Box>
                                      </Grid>
                                    </>
                                  ))}
                              </Grid>
                            ) : null}

                            <Typography
                              variant="h6"
                              style={{ marginLeft: "18px", mt: 3 }}
                            >
                              List of services
                            </Typography>
                            {ServiceData?.data &&
                            ServiceData?.data.length > 0 ? (
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mt: 3,
                                  mb: 20,
                                  display: { xs: "block", sm: "none" },
                                }}
                              >
                                {ServiceData?.data.map((item, index) => (
                                  <>
                                    <Grid
                                      key={index}
                                      item
                                      xs={12}
                                      sm={6}
                                      onClick={() => handleSelect(item)}
                                    >
                                      <Box
                                        sx={{
                                          borderRadius: "9px",
                                          p: "10px 15px",
                                          border: "1px solid #E0E0E0",
                                          background: "#fff",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Typography
                                          variant="h4"
                                          sx={{
                                            color: "#000",
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            my: 1,
                                          }}
                                        >
                                          {item?.name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            my: 1,
                                          }}
                                        >
                                          <Box sx={{ display: "flex" }}>
                                            <Box>
                                              <Typography
                                                variant="h4"
                                                sx={{
                                                  color: "#000",
                                                  fontSize: "18px",
                                                  fontWeight: "700",
                                                  mr: 4,
                                                  textDecoration:
                                                    "line-through",
                                                  textDecorationColor: "black",
                                                }}
                                              >
                                                ${item?.regular_price}
                                              </Typography>
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontSize: "10px" }}
                                              >
                                                Regular Price
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <Typography
                                                variant="h4"
                                                sx={{
                                                  color: "#000",
                                                  fontSize: "18px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                ${item?.member_price}
                                              </Typography>
                                              <Typography
                                                variant="subtitle2"
                                                sx={{ fontSize: "10px" }}
                                              >
                                                Member Price
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontFamily:
                                                  "helvetica-lt-std-roman",
                                                color: "#000",
                                                fontSize: "15px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {window.formatTimeDuration(item?.time_duration)}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            my: 1,
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontSize: "15px",
                                                fontWeight: "300",
                                                color: "rgba(28, 75, 137, 1)",
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                (window.location.href =
                                                  item?.link)
                                              }
                                            >
                                              More Info
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Checkbox
                                              sx={{
                                                color: "#c3af93",
                                                "&.Mui-checked": {
                                                  color: "#c3af93",
                                                },
                                              }}
                                              checked={isExist(
                                                selectedService,
                                                item
                                              )}
                                            />
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            ) : null}

                            {ServiceData?.data &&
                            ServiceData?.data.length > 0 ? (
                              ServiceData?.data
                                .filter((item) =>
                                  item.name.toLowerCase().includes(searchTerm)
                                )
                                .map((item, index) =>
                                  // item?.is_promo === 0 &&
                                  // item?.is_consultation_category === 0 &&
                                  item?.is_show_website === 1 ? (
                                  // item?.is_free === 0 ? (
                                    <>
                                      <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        sx={{
                                          border: `1px solid ${
                                            isExist(selectedService, item)
                                              ? "#d2bea2"
                                              : "#d2bea2"
                                          }`,
                                          // pr: "32px",
                                          paddingLeft: "0 !important",
                                          paddingTop: "0 !important",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                          display: "flex",
                                          // mb: "24px",
                                          marginBottom: "20px",
                                          justifyContent: "space-between",
                                          "&:hover": {
                                            border: "1px solid #d2bea2",
                                          },
                                          "@media (max-width: 400px)": {
                                            display: "none",
                                          },
                                        }}
                                        onClick={() => handleSelect(item)}
                                      >
                                        <Box
                                          sx={{
                                            maxWidth: "80%",
                                            display: "flex",
                                          }}
                                        >
                                          <Avatar
                                            src={item?.image}
                                            variant="square"
                                            sx={{
                                              height: "100%",
                                              width: "135px",
                                              borderBottomLeftRadius: "4px",
                                              borderTopLeftRadius: "4px",
                                            }}
                                          />
                                          <Box sx={{ py: "24px", pl: "32px" }}>
                                            <Typography
                                              sx={{
                                                fontSize: "16px",
                                                mb: "8px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {item?.name}
                                              {item?.is_free === 1
                                                ? " (No Deposit Required)"
                                                : ""}
                                              {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                            </Typography>
                                            <Box>
                                              <Typography>
                                                {item?.is_free === 1
                                                  ? " Free"
                                                  : "$" +
                                                    item?.regular_price}{" "}
                                                ·{" "}
                                                {convertTimeToMinutes(
                                                  item?.time_duration
                                                )}{" "}
                                                minutes ·{" "}
                                                <Typography
                                                  as={"span"}
                                                  sx={style}
                                                  onMouseEnter={() => {
                                                    setOnHover(true);
                                                  }}
                                                  onMouseLeave={() => {
                                                    setOnHover(false);
                                                  }}
                                                  onClick={() =>
                                                    (window.location.href =
                                                      item?.link)
                                                  }
                                                >
                                                  More info
                                                </Typography>
                                              </Typography>
                                              {moreInfo.includes(item?.id) && (
                                                <Typography
                                                  sx={{
                                                    color: "#666666",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {item?.description}
                                                </Typography>
                                              )}
                                              {/*{isExist(selectedService,item) &&*/}
                                              <Box sx={{ display: "flex" }}>
                                                {item?.staff?.length > 0 &&
                                                  item?.staff.map(
                                                    (staffData, i) => (
                                                      <Typography
                                                        key={i}
                                                        sx={{
                                                          fontWeight: "400",
                                                          fontSize: "16px",
                                                          color: "black",
                                                        }}
                                                      >
                                                        {i !== 0 && ", "}{" "}
                                                        {staffData?.name}
                                                      </Typography>
                                                    )
                                                  )}
                                              </Box>
                                              {/*}*/}
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box sx={{ pt: "24px" }}>
                                          <Checkbox
                                            sx={{
                                              color: "#c3af93",
                                              "&.Mui-checked": {
                                                color: "#c3af93",
                                              },
                                            }}
                                            checked={isExist(
                                              selectedService,
                                              item
                                            )}
                                          />
                                        </Box>
                                      </Grid>
                                    </>
                                  ) : (
                                    ""
                                  )
                                )
                            ) : (
                              <Grid item xs={12} sx={{ mt: "30px" }}>
                                <Typography sx={{ textAlign: "center" }}>
                                  Services Not found.
                                </Typography>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                marginTop: "20px",
                                width: "100%",
                                bgcolor: "background.paper",
                              }}
                            >
                              {subcategory?.child &&
                              subcategory?.child.length > 0 ? (
                                <div
                                  style={{ overflowX: "auto", width: "100%" }}
                                >
                                  <Tabs
                                    value={value}
                                    onChange={handletabs}
                                    orientation="horizontal"
                                    centered
                                    sx={{
                                      "& .MuiTabs-scroller": {
                                        overflowX: "auto",
                                      },
                                    }}
                                    TabIndicatorProps={{
                                      style: { display: "none" },
                                    }}
                                  >
                                    {subcategory?.child.map(
                                      (service, index) => (
                                        <Tab
                                          key={index}
                                          label={service?.title}
                                          sx={{
                                            marginRight: "20px",
                                            // marginLeft:'20px',
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            border: "1px solid black",
                                            borderRadius: "20px",
                                            color: "black",
                                            minHeight: "35px",
                                            padding: "10px 16px",
                                            "&.Mui-selected": {
                                              backgroundColor: "black",
                                              color: "white",
                                              border: "1px solid black",
                                            },
                                          }}
                                        />
                                      )
                                    )}{" "}
                                  </Tabs>{" "}
                                </div>
                              ) : (
                                ""
                              )}
                              {value === 0 && (
                                <>
                                  {ServiceData?.promotion_services &&
                                  ServiceData?.promotion_services.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        display: { xs: "block", sm: "none" },
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        Promotion
                                      </Typography>
                                      {ServiceData?.promotion_services.map(
                                        (item, index) => (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sm={6}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  borderRadius: "9px",
                                                  p: "10px 15px",
                                                  border: "1px solid #E0E0E0",
                                                  background: "#fff",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <Typography
                                                  variant="h4"
                                                  sx={{
                                                    color: "#000",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    my: 1,
                                                  }}
                                                >
                                                  {item?.name}
                                                </Typography>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    my: 1,
                                                  }}
                                                >
                                                  <Box sx={{ display: "flex" }}>
                                                    <Box>
                                                      <Typography
                                                        variant="h4"
                                                        sx={{
                                                          color: "#000",
                                                          fontSize: "18px",
                                                          fontWeight: "700",
                                                          mr: 4,
                                                          textDecoration:
                                                            "line-through",
                                                          textDecorationColor:
                                                            "black",
                                                        }}
                                                      >
                                                        ${item?.regular_price}
                                                      </Typography>
                                                      <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Regular Price
                                                      </Typography>
                                                    </Box>
                                                    <Box>
                                                      <Typography
                                                        variant="h4"
                                                        sx={{
                                                          color: "#000",
                                                          fontSize: "18px",
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        ${item?.member_price}
                                                      </Typography>
                                                      <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Member Price
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                  <Box>
                                                 <Typography
                                                      sx={{
                                                        fontFamily:
                                                          "helvetica-lt-std-roman",
                                                        color: "#000",
                                                        fontSize: "15px",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                   {window.formatTimeDuration(item?.time_duration)}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    my: 1,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography
                                                      sx={{
                                                        fontSize: "15px",
                                                        fontWeight: "300",
                                                        color:
                                                          "rgba(28, 75, 137, 1)",
                                                        textDecoration:
                                                          "underline",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href =
                                                          item?.link)
                                                      }
                                                    >
                                                      More Info
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Checkbox
                                                      sx={{
                                                        color: "#c3af93",
                                                        "&.Mui-checked": {
                                                          color: "#c3af93",
                                                        },
                                                      }}
                                                      checked={isExist(
                                                        selectedService,
                                                        item
                                                      )}
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Grid>
                                          </>
                                        )
                                      )}
                                    </Grid>
                                  ) : null}

                                  {ServiceData?.promotion_services &&
                                  ServiceData?.promotion_services.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        "@media (max-width: 400px)": {
                                          display: "none",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        Promotion
                                      </Typography>
                                      {ServiceData?.promotion_services
                                        .filter((item) =>
                                          item.name
                                            .toLowerCase()
                                            .includes(searchTerm)
                                        )
                                        .map((item, index) => (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sx={{
                                                border: `1px solid ${
                                                  isExist(selectedService, item)
                                                    ? "#d2bea2"
                                                    : "#d2bea2"
                                                }`,
                                                // pr: "32px",
                                                paddingLeft: "0 !important",
                                                paddingTop: "0 !important",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                display: "flex",
                                                // mb: "24px",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                  border: "1px solid #d2bea2",
                                                },
                                              }}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  maxWidth: "80%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Avatar
                                                  src={item?.image}
                                                  variant="square"
                                                  sx={{
                                                    height: "100%",
                                                    width: "135px",
                                                    borderBottomLeftRadius:
                                                      "4px",
                                                    borderTopLeftRadius: "4px",
                                                  }}
                                                />
                                                <Box
                                                  sx={{
                                                    py: "24px",
                                                    pl: "32px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "16px",
                                                      mb: "8px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.name}
                                                    {item?.is_free === 1
                                                      ? " (No Deposit Required)"
                                                      : ""}
                                                    {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                  </Typography>
                                                  <Box>
                                                    <Typography>
                                                      {item?.is_free === 1
                                                        ? " Free"
                                                        : "$" +
                                                          item?.regular_price}{" "}
                                                      ·{" "}
                                                      {convertTimeToMinutes(
                                                        item?.time_duration
                                                      )}{" "}
                                                      minutes ·{" "}
                                                      <Typography
                                                        as={"span"}
                                                        sx={style}
                                                        onMouseEnter={() => {
                                                          setOnHover(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                          setOnHover(false);
                                                        }}
                                                        onClick={() =>
                                                          (window.location.href =
                                                            item?.link)
                                                        }
                                                      >
                                                        More info
                                                      </Typography>
                                                    </Typography>
                                                    {moreInfo.includes(
                                                      item?.id
                                                    ) && (
                                                      <Typography
                                                        sx={{
                                                          color: "#666666",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item?.description}
                                                      </Typography>
                                                    )}
                                                    {/*{isExist(selectedService,item) &&*/}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {item?.staff?.length >
                                                        0 &&
                                                        item?.staff.map(
                                                          (staffData, i) => (
                                                            <Typography
                                                              key={i}
                                                              sx={{
                                                                fontWeight:
                                                                  "400",
                                                                fontSize:
                                                                  "16px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {i !== 0 && ", "}{" "}
                                                              {staffData?.name}
                                                            </Typography>
                                                          )
                                                        )}
                                                    </Box>
                                                    {/*}*/}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box sx={{ pt: "24px" }}>
                                                <Checkbox
                                                  sx={{
                                                    color: "#c3af93",
                                                    "&.Mui-checked": {
                                                      color: "#c3af93",
                                                    },
                                                  }}
                                                  checked={isExist(
                                                    selectedService,
                                                    item
                                                  )}
                                                />
                                              </Box>
                                            </Grid>
                                          </>
                                        ))}
                                    </Grid>
                                  ) : null}
                                  <Typography
                                    variant="h6"
                                    style={{ marginLeft: "18px", mt: 3 }}
                                  >
                                    List of services
                                  </Typography>
                                  {ServiceData?.data &&
                                  ServiceData?.data.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        mb: 20,
                                        display: { xs: "block", sm: "none" },
                                      }}
                                    >
                                      {ServiceData?.data.map((item, index) => (
                                        <>
                                          <Grid
                                            key={index}
                                            item
                                            xs={12}
                                            sm={6}
                                            onClick={() => handleSelect(item)}
                                          >
                                            <Box
                                              sx={{
                                                borderRadius: "9px",
                                                p: "10px 15px",
                                                border: "1px solid #E0E0E0",
                                                background: "#fff",
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                variant="h4"
                                                sx={{
                                                  color: "#000",
                                                  fontSize: "18px",
                                                  fontWeight: "700",
                                                  my: 1,
                                                }}
                                              >
                                                {item?.name}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                            
                                                    my: 1,
                                                }}
                                              >
                                                <Box sx={{ display: "flex" }}>
                                                  <Box>
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mr: 4,
                                                        textDecoration:
                                                          "line-through",
                                                        textDecorationColor:
                                                          "black",
                                                      }}
                                                    >
                                                      ${item?.regular_price}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{ fontSize: "10px" }}
                                                    >
                                                      Regular Price
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      ${item?.member_price}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{ fontSize: "10px" }}
                                                    >
                                                      Member Price
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      fontFamily:
                                                        "helvetica-lt-std-roman",
                                                      color: "#000",
                                                      fontSize: "15px",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {window.formatTimeDuration(item?.time_duration)}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  my: 1,
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      fontSize: "15px",
                                                      fontWeight: "300",
                                                      color:
                                                        "rgba(28, 75, 137, 1)",
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      (window.location.href =
                                                        item?.link)
                                                    }
                                                  >
                                                    More Info
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  <Checkbox
                                                    sx={{
                                                      color: "#c3af93",
                                                      "&.Mui-checked": {
                                                        color: "#c3af93",
                                                      },
                                                    }}
                                                    checked={isExist(
                                                      selectedService,
                                                      item
                                                    )}
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Grid>
                                        </>
                                      ))}
                                    </Grid>
                                  ) : null}

                                  {ServiceData?.data &&
                                  ServiceData?.data.length > 0 ? (
                                    ServiceData?.data
                                      .filter((item) =>
                                        item.name
                                          .toLowerCase()
                                          .includes(searchTerm)
                                      )
                                      .map((item, index) =>
                                        item?.is_promo === 0 &&
                                        item?.is_consultation_category === 0 &&
                                        item?.is_show_website === 1 &&
                                        item?.is_free === 0 ? (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sx={{
                                                border: `1px solid ${
                                                  isExist(selectedService, item)
                                                    ? "#d2bea2"
                                                    : "#d2bea2"
                                                }`,
                                                // pr: "32px",
                                                paddingLeft: "0 !important",
                                                paddingTop: "0 !important",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                display: "flex",
                                                // mb: "24px",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                  border: "1px solid #d2bea2",
                                                },
                                                "@media (max-width: 400px)": {
                                                  display: "none",
                                                },
                                              }}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  maxWidth: "80%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Avatar
                                                  src={item?.image}
                                                  variant="square"
                                                  sx={{
                                                    height: "100%",
                                                    width: "135px",
                                                    borderBottomLeftRadius:
                                                      "4px",
                                                    borderTopLeftRadius: "4px",
                                                  }}
                                                />
                                                <Box
                                                  sx={{
                                                    py: "24px",
                                                    pl: "32px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "16px",
                                                      mb: "8px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.name}
                                                    {item?.is_free === 1
                                                      ? " (No Deposit Required)"
                                                      : ""}
                                                    {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                  </Typography>
                                                  <Box>
                                                    <Typography>
                                                      {item?.is_free === 1
                                                        ? " Free"
                                                        : "$" +
                                                          item?.regular_price}{" "}
                                                      ·{" "}
                                                      {convertTimeToMinutes(
                                                        item?.time_duration
                                                      )}{" "}
                                                      minutes ·{" "}
                                                      <Typography
                                                        as={"span"}
                                                        sx={style}
                                                        onMouseEnter={() => {
                                                          setOnHover(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                          setOnHover(false);
                                                        }}
                                                        onClick={() =>
                                                          (window.location.href =
                                                            item?.link)
                                                        }
                                                      >
                                                        More info
                                                      </Typography>
                                                    </Typography>
                                                    {moreInfo.includes(
                                                      item?.id
                                                    ) && (
                                                      <Typography
                                                        sx={{
                                                          color: "#666666",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item?.description}
                                                      </Typography>
                                                    )}
                                                    {/*{isExist(selectedService,item) &&*/}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {item?.staff?.length >
                                                        0 &&
                                                        item?.staff.map(
                                                          (staffData, i) => (
                                                            <Typography
                                                              key={i}
                                                              sx={{
                                                                fontWeight:
                                                                  "400",
                                                                fontSize:
                                                                  "16px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {i !== 0 && ", "}{" "}
                                                              {staffData?.name}
                                                            </Typography>
                                                          )
                                                        )}
                                                    </Box>
                                                    {/*}*/}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box sx={{ pt: "24px" }}>
                                                <Checkbox
                                                  sx={{
                                                    color: "#c3af93",
                                                    "&.Mui-checked": {
                                                      color: "#c3af93",
                                                    },
                                                  }}
                                                  checked={isExist(
                                                    selectedService,
                                                    item
                                                  )}
                                                />
                                              </Box>
                                            </Grid>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      )
                                  ) : (
                                    <Grid item xs={12} sx={{ mt: "30px" }}>
                                      <Typography sx={{ textAlign: "center" }}>
                                        Services tab Not found.
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              )}
                              {value === 1 && (
                                <>
                                  {ServiceData?.promotion_services &&
                                  ServiceData?.promotion_services.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        display: { xs: "block", sm: "none" },
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        Promotion
                                      </Typography>
                                      {ServiceData?.promotion_services.map(
                                        (item, index) => (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sm={6}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  borderRadius: "9px",
                                                  p: "10px 15px",
                                                  border: "1px solid #E0E0E0",
                                                  background: "#fff",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <Typography
                                                  variant="h4"
                                                  sx={{
                                                    color: "#000",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    my: 1,
                                                  }}
                                                >
                                                  {item?.name}
                                                </Typography>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    my: 1,
                                                  }}
                                                >
                                                  <Box sx={{ display: "flex" }}>
                                                    <Box>
                                                      <Typography
                                                        variant="h4"
                                                        sx={{
                                                          color: "#000",
                                                          fontSize: "18px",
                                                          fontWeight: "700",
                                                          mr: 4,
                                                          textDecoration:
                                                            "line-through",
                                                          textDecorationColor:
                                                            "black",
                                                        }}
                                                      >
                                                        ${item?.regular_price}
                                                      </Typography>
                                                      <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Regular Price
                                                      </Typography>
                                                    </Box>
                                                    <Box>
                                                      <Typography
                                                        variant="h4"
                                                        sx={{
                                                          color: "#000",
                                                          fontSize: "18px",
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        ${item?.member_price}
                                                      </Typography>
                                                      <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Member Price
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      sx={{
                                                        fontFamily:
                                                          "helvetica-lt-std-roman",
                                                        color: "#000",
                                                        fontSize: "15px",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      {window.formatTimeDuration(item?.time_duration)}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    my: 1,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography
                                                      sx={{
                                                        fontSize: "15px",
                                                        fontWeight: "300",
                                                        color:
                                                          "rgba(28, 75, 137, 1)",
                                                        textDecoration:
                                                          "underline",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href =
                                                          item?.link)
                                                      }
                                                    >
                                                      More Info
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Checkbox
                                                      sx={{
                                                        color: "#c3af93",
                                                        "&.Mui-checked": {
                                                          color: "#c3af93",
                                                        },
                                                      }}
                                                      checked={isExist(
                                                        selectedService,
                                                        item
                                                      )}
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Grid>
                                          </>
                                        )
                                      )}
                                    </Grid>
                                  ) : null}

                                  {ServiceData?.promotion_services &&
                                  ServiceData?.promotion_services.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        "@media (max-width: 400px)": {
                                          display: "none",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        Promotion
                                      </Typography>
                                      {ServiceData?.promotion_services
                                        .filter((item) =>
                                          item.name
                                            .toLowerCase()
                                            .includes(searchTerm)
                                        )
                                        .map((item, index) => (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sx={{
                                                border: `1px solid ${
                                                  isExist(selectedService, item)
                                                    ? "#d2bea2"
                                                    : "#d2bea2"
                                                }`,
                                                // pr: "32px",
                                                paddingLeft: "0 !important",
                                                paddingTop: "0 !important",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                display: "flex",
                                                // mb: "24px",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                  border: "1px solid #d2bea2",
                                                },
                                              }}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  maxWidth: "80%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Avatar
                                                  src={item?.image}
                                                  variant="square"
                                                  sx={{
                                                    height: "100%",
                                                    width: "135px",
                                                    borderBottomLeftRadius:
                                                      "4px",
                                                    borderTopLeftRadius: "4px",
                                                  }}
                                                />
                                                <Box
                                                  sx={{
                                                    py: "24px",
                                                    pl: "32px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "16px",
                                                      mb: "8px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.name}
                                                    {item?.is_free === 1
                                                      ? " (No Deposit Required)"
                                                      : ""}
                                                    {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                  </Typography>
                                                  <Box>
                                                    <Typography>
                                                      {item?.is_free === 1
                                                        ? " Free"
                                                        : "$" +
                                                          item?.regular_price}{" "}
                                                      ·{" "}
                                                      {convertTimeToMinutes(
                                                        item?.time_duration
                                                      )}{" "}
                                                      minutes ·{" "}
                                                      <Typography
                                                        as={"span"}
                                                        sx={style}
                                                        onMouseEnter={() => {
                                                          setOnHover(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                          setOnHover(false);
                                                        }}
                                                        onClick={() =>
                                                          (window.location.href =
                                                            item?.link)
                                                        }
                                                      >
                                                        More info
                                                      </Typography>
                                                    </Typography>
                                                    {moreInfo.includes(
                                                      item?.id
                                                    ) && (
                                                      <Typography
                                                        sx={{
                                                          color: "#666666",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item?.description}
                                                      </Typography>
                                                    )}
                                                    {/*{isExist(selectedService,item) &&*/}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {item?.staff?.length >
                                                        0 &&
                                                        item?.staff.map(
                                                          (staffData, i) => (
                                                            <Typography
                                                              key={i}
                                                              sx={{
                                                                fontWeight:
                                                                  "400",
                                                                fontSize:
                                                                  "16px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {i !== 0 && ", "}{" "}
                                                              {staffData?.name}
                                                            </Typography>
                                                          )
                                                        )}
                                                    </Box>
                                                    {/*}*/}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box sx={{ pt: "24px" }}>
                                                <Checkbox
                                                  sx={{
                                                    color: "#c3af93",
                                                    "&.Mui-checked": {
                                                      color: "#c3af93",
                                                    },
                                                  }}
                                                  checked={isExist(
                                                    selectedService,
                                                    item
                                                  )}
                                                />
                                              </Box>
                                            </Grid>
                                          </>
                                        ))}
                                    </Grid>
                                  ) : null}
                                  <Typography
                                    variant="h6"
                                    style={{ marginLeft: "18px", mt: 3 }}
                                  >
                                    List of services
                                  </Typography>
                                  {ServiceData?.data &&
                                  ServiceData?.data.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        mb: 20,
                                        display: { xs: "block", sm: "none" },
                                      }}
                                    >
                                      {ServiceData?.data.map((item, index) => (
                                        <>
                                          <Grid
                                            key={index}
                                            item
                                            xs={12}
                                            sm={6}
                                            onClick={() => handleSelect(item)}
                                          >
                                            <Box
                                              sx={{
                                                borderRadius: "9px",
                                                p: "10px 15px",
                                                border: "1px solid #E0E0E0",
                                                background: "#fff",
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                variant="h4"
                                                sx={{
                                                  color: "#000",
                                                  fontSize: "18px",
                                                  fontWeight: "700",
                                                  my: 1,
                                                }}
                                              >
                                                {item?.name}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  my: 1,
                                                }}
                                              >
                                                <Box sx={{ display: "flex" }}>
                                                  <Box>
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mr: 4,
                                                        textDecoration:
                                                          "line-through",
                                                        textDecorationColor:
                                                          "black",
                                                      }}
                                                    >
                                                      ${item?.regular_price}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      Regular Price
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      ${item?.member_price}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      Member Price
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      fontFamily:
                                                        "helvetica-lt-std-roman",
                                                      color: "#000",
                                                      fontSize: "15px",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {window.formatTimeDuration(item?.time_duration)}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  my: 1,
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      fontSize: "15px",
                                                      fontWeight: "300",
                                                      color:
                                                        "rgba(28, 75, 137, 1)",
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      (window.location.href =
                                                        item?.link)
                                                    }
                                                  >
                                                    More Info
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  <Checkbox
                                                    sx={{
                                                      color: "#c3af93",
                                                      "&.Mui-checked": {
                                                        color: "#c3af93",
                                                      },
                                                    }}
                                                    checked={isExist(
                                                      selectedService,
                                                      item
                                                    )}
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Grid>
                                        </>
                                      ))}
                                    </Grid>
                                  ) : null}

                                  {ServiceData?.data &&
                                  ServiceData?.data.length > 0 ? (
                                    ServiceData?.data
                                      .filter((item) =>
                                        item.name
                                          .toLowerCase()
                                          .includes(searchTerm)
                                      )
                                      .map((item, index) =>
                                        item?.is_promo === 0 &&
                                        item?.is_consultation_category === 0 &&
                                        item?.is_show_website === 1 &&
                                        item?.is_free === 0 ? (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sx={{
                                                border: `1px solid ${
                                                  isExist(selectedService, item)
                                                    ? "#d2bea2"
                                                    : "#d2bea2"
                                                }`,
                                                // pr: "32px",
                                                paddingLeft: "0 !important",
                                                paddingTop: "0 !important",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                display: "flex",
                                                // mb: "24px",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                  border: "1px solid #d2bea2",
                                                },
                                                "@media (max-width: 400px)": {
                                                  display: "none",
                                                },
                                              }}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  maxWidth: "80%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Avatar
                                                  src={item?.image}
                                                  variant="square"
                                                  sx={{
                                                    height: "100%",
                                                    width: "135px",
                                                    borderBottomLeftRadius:
                                                      "4px",
                                                    borderTopLeftRadius: "4px",
                                                  }}
                                                />
                                                <Box
                                                  sx={{
                                                    py: "24px",
                                                    pl: "32px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "16px",
                                                      mb: "8px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.name}
                                                    {item?.is_free === 1
                                                      ? " (No Deposit Required)"
                                                      : ""}
                                                    {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                  </Typography>
                                                  <Box>
                                                    <Typography>
                                                      {item?.is_free === 1
                                                        ? " Free"
                                                        : "$" +
                                                          item?.regular_price}{" "}
                                                      ·{" "}
                                                      {convertTimeToMinutes(
                                                        item?.time_duration
                                                      )}{" "}
                                                      minutes ·{" "}
                                                      <Typography
                                                        as={"span"}
                                                        sx={style}
                                                        onMouseEnter={() => {
                                                          setOnHover(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                          setOnHover(false);
                                                        }}
                                                        onClick={() =>
                                                          (window.location.href =
                                                            item?.link)
                                                        }
                                                      >
                                                        More info
                                                      </Typography>
                                                    </Typography>
                                                    {moreInfo.includes(
                                                      item?.id
                                                    ) && (
                                                      <Typography
                                                        sx={{
                                                          color: "#666666",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item?.description}
                                                      </Typography>
                                                    )}
                                                    {/*{isExist(selectedService,item) &&*/}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {item?.staff?.length >
                                                        0 &&
                                                        item?.staff.map(
                                                          (staffData, i) => (
                                                            <Typography
                                                              key={i}
                                                              sx={{
                                                                fontWeight:
                                                                  "400",
                                                                fontSize:
                                                                  "16px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {i !== 0 && ", "}{" "}
                                                              {staffData?.name}
                                                            </Typography>
                                                          )
                                                        )}
                                                    </Box>
                                                    {/*}*/}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box sx={{ pt: "24px" }}>
                                                <Checkbox
                                                  sx={{
                                                    color: "#c3af93",
                                                    "&.Mui-checked": {
                                                      color: "#c3af93",
                                                    },
                                                  }}
                                                  checked={isExist(
                                                    selectedService,
                                                    item
                                                  )}
                                                />
                                              </Box>
                                            </Grid>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      )
                                  ) : (
                                    <Grid item xs={12} sx={{ mt: "30px" }}>
                                      <Typography sx={{ textAlign: "center" }}>
                                        Services tab Not found.
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              )}
                              {value === 2 && (
                                <>
                                  {ServiceData?.promotion_services &&
                                  ServiceData?.promotion_services.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        display: { xs: "block", sm: "none" },
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        Promotion
                                      </Typography>
                                      {ServiceData?.promotion_services.map(
                                        (item, index) => (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sm={6}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  borderRadius: "9px",
                                                  p: "10px 15px",
                                                  border: "1px solid #E0E0E0",
                                                  background: "#fff",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <Typography
                                                  variant="h4"
                                                  sx={{
                                                    color: "#000",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    my: 1,
                                                  }}
                                                >
                                                  {item?.name}
                                                </Typography>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    my: 1,
                                                  }}
                                                >
                                                  <Box sx={{ display: "flex" }}>
                                                    <Box>
                                                      <Typography
                                                        variant="h4"
                                                        sx={{
                                                          color: "#000",
                                                          fontSize: "18px",
                                                          fontWeight: "700",
                                                          mr: 4,
                                                          textDecoration:
                                                            "line-through",
                                                          textDecorationColor:
                                                            "black",
                                                        }}
                                                      >
                                                        ${item?.regular_price}
                                                      </Typography>
                                                      <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Regular Price
                                                      </Typography>
                                                    </Box>
                                                    <Box>
                                                      <Typography
                                                        variant="h4"
                                                        sx={{
                                                          color: "#000",
                                                          fontSize: "18px",
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        ${item?.member_price}
                                                      </Typography>
                                                      <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Member Price
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      sx={{
                                                        fontFamily:
                                                          "helvetica-lt-std-roman",
                                                        color: "#000",
                                                        fontSize: "15px",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      {window.formatTimeDuration(item?.time_duration)}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    my: 1,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography
                                                      sx={{
                                                        fontSize: "15px",
                                                        fontWeight: "300",
                                                        color:
                                                          "rgba(28, 75, 137, 1)",
                                                        textDecoration:
                                                          "underline",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        (window.location.href =
                                                          item?.link)
                                                      }
                                                    >
                                                      More Info
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Checkbox
                                                      sx={{
                                                        color: "#c3af93",
                                                        "&.Mui-checked": {
                                                          color: "#c3af93",
                                                        },
                                                      }}
                                                      checked={isExist(
                                                        selectedService,
                                                        item
                                                      )}
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Grid>
                                          </>
                                        )
                                      )}
                                    </Grid>
                                  ) : null}

                                  {ServiceData?.promotion_services &&
                                  ServiceData?.promotion_services.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        "@media (max-width: 400px)": {
                                          display: "none",
                                        },
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{ marginLeft: "18px" }}
                                      >
                                        Promotion
                                      </Typography>
                                      {ServiceData?.promotion_services
                                        .filter((item) =>
                                          item.name
                                            .toLowerCase()
                                            .includes(searchTerm)
                                        )
                                        .map((item, index) => (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sx={{
                                                border: `1px solid ${
                                                  isExist(selectedService, item)
                                                    ? "#d2bea2"
                                                    : "#d2bea2"
                                                }`,
                                                // pr: "32px",
                                                paddingLeft: "0 !important",
                                                paddingTop: "0 !important",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                display: "flex",
                                                // mb: "24px",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                  border: "1px solid #d2bea2",
                                                },
                                              }}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  maxWidth: "80%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Avatar
                                                  src={item?.image}
                                                  variant="square"
                                                  sx={{
                                                    height: "100%",
                                                    width: "135px",
                                                    borderBottomLeftRadius:
                                                      "4px",
                                                    borderTopLeftRadius: "4px",
                                                  }}
                                                />
                                                <Box
                                                  sx={{
                                                    py: "24px",
                                                    pl: "32px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "16px",
                                                      mb: "8px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.name}
                                                    {item?.is_free === 1
                                                      ? " (No Deposit Required)"
                                                      : ""}
                                                    {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                  </Typography>
                                                  <Box>
                                                    <Typography>
                                                      {item?.is_free === 1
                                                        ? " Free"
                                                        : "$" +
                                                          item?.regular_price}{" "}
                                                      ·{" "}
                                                      {convertTimeToMinutes(
                                                        item?.time_duration
                                                      )}{" "}
                                                      minutes ·{" "}
                                                      <Typography
                                                        as={"span"}
                                                        sx={style}
                                                        onMouseEnter={() => {
                                                          setOnHover(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                          setOnHover(false);
                                                        }}
                                                        onClick={() =>
                                                          (window.location.href =
                                                            item?.link)
                                                        }
                                                      >
                                                        More info
                                                      </Typography>
                                                    </Typography>
                                                    {moreInfo.includes(
                                                      item?.id
                                                    ) && (
                                                      <Typography
                                                        sx={{
                                                          color: "#666666",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item?.description}
                                                      </Typography>
                                                    )}
                                                    {/*{isExist(selectedService,item) &&*/}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {item?.staff?.length >
                                                        0 &&
                                                        item?.staff.map(
                                                          (staffData, i) => (
                                                            <Typography
                                                              key={i}
                                                              sx={{
                                                                fontWeight:
                                                                  "400",
                                                                fontSize:
                                                                  "16px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {i !== 0 && ", "}{" "}
                                                              {staffData?.name}
                                                            </Typography>
                                                          )
                                                        )}
                                                    </Box>
                                                    {/*}*/}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box sx={{ pt: "24px" }}>
                                                <Checkbox
                                                  sx={{
                                                    color: "#c3af93",
                                                    "&.Mui-checked": {
                                                      color: "#c3af93",
                                                    },
                                                  }}
                                                  checked={isExist(
                                                    selectedService,
                                                    item
                                                  )}
                                                />
                                              </Box>
                                            </Grid>
                                          </>
                                        ))}
                                    </Grid>
                                  ) : null}
                                  <Typography
                                    variant="h6"
                                    style={{ marginLeft: "18px", mt: 3 }}
                                  >
                                    List of services
                                  </Typography>
                                  {ServiceData?.data &&
                                  ServiceData?.data.length > 0 ? (
                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: 3,
                                        mb: 20,
                                        display: { xs: "block", sm: "none" },
                                      }}
                                    >
                                      {ServiceData?.data.map((item, index) => (
                                        <>
                                          <Grid
                                            key={index}
                                            item
                                            xs={12}
                                            sm={6}
                                            onClick={() => handleSelect(item)}
                                          >
                                            <Box
                                              sx={{
                                                borderRadius: "9px",
                                                p: "10px 15px",
                                                border: "1px solid #E0E0E0",
                                                background: "#fff",
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                variant="h4"
                                                sx={{
                                                  color: "#000",
                                                  fontSize: "18px",
                                                  fontWeight: "700",
                                                  my: 1,
                                                }}
                                              >
                                                {item?.name}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  my: 1,
                                                }}
                                              >
                                                <Box sx={{ display: "flex" }}>
                                                  <Box>
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mr: 4,
                                                        textDecoration:
                                                          "line-through",
                                                        textDecorationColor:
                                                          "black",
                                                      }}
                                                    >
                                                      ${item?.regular_price}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{ fontSize: "10px" }}
                                                    >
                                                      Regular Price
                                                    </Typography>
                                                  </Box>
                                                  <Box>
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        color: "#000",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      ${item?.member_price}
                                                    </Typography>
                                                    <Typography
                                                      variant="subtitle2"
                                                      sx={{ fontSize: "10px" }}
                                                    >
                                                      Member Price
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      fontFamily:
                                                        "helvetica-lt-std-roman",
                                                      color: "#000",
                                                      fontSize: "15px",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {window.formatTimeDuration(item?.time_duration)}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  my: 1,
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      fontSize: "15px",
                                                      fontWeight: "300",
                                                      color:
                                                        "rgba(28, 75, 137, 1)",
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      (window.location.href =
                                                        item?.link)
                                                    }
                                                  >
                                                    More Info
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  <Checkbox
                                                    sx={{
                                                      color: "#c3af93",
                                                      "&.Mui-checked": {
                                                        color: "#c3af93",
                                                      },
                                                    }}
                                                    checked={isExist(
                                                      selectedService,
                                                      item
                                                    )}
                                                  />
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Grid>
                                        </>
                                      ))}
                                    </Grid>
                                  ) : null}

                                  {ServiceData?.data &&
                                  ServiceData?.data.length > 0 ? (
                                    ServiceData?.data
                                      .filter((item) =>
                                        item.name
                                          .toLowerCase()
                                          .includes(searchTerm)
                                      )
                                      .map((item, index) =>
                                        item?.is_promo === 0 &&
                                        item?.is_consultation_category === 0 &&
                                        item?.is_show_website === 1 &&
                                        item?.is_free === 0 ? (
                                          <>
                                            <Grid
                                              key={index}
                                              item
                                              xs={12}
                                              sx={{
                                                border: `1px solid ${
                                                  isExist(selectedService, item)
                                                    ? "#d2bea2"
                                                    : "#d2bea2"
                                                }`,
                                                // pr: "32px",
                                                paddingLeft: "0 !important",
                                                paddingTop: "0 !important",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                display: "flex",
                                                // mb: "24px",
                                                marginBottom: "20px",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                  border: "1px solid #d2bea2",
                                                },
                                                "@media (max-width: 400px)": {
                                                  display: "none",
                                                },
                                              }}
                                              onClick={() => handleSelect(item)}
                                            >
                                              <Box
                                                sx={{
                                                  maxWidth: "80%",
                                                  display: "flex",
                                                }}
                                              >
                                                <Avatar
                                                  src={item?.image}
                                                  variant="square"
                                                  sx={{
                                                    height: "100%",
                                                    width: "135px",
                                                    borderBottomLeftRadius:
                                                      "4px",
                                                    borderTopLeftRadius: "4px",
                                                  }}
                                                />
                                                <Box
                                                  sx={{
                                                    py: "24px",
                                                    pl: "32px",
                                                  }}
                                                >
                                                  <Typography
                                                    sx={{
                                                      fontSize: "16px",
                                                      mb: "8px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item?.name}
                                                    {item?.is_free === 1
                                                      ? " (No Deposit Required)"
                                                      : ""}
                                                    {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                  </Typography>
                                                  <Box>
                                                    <Typography>
                                                      {item?.is_free === 1
                                                        ? " Free"
                                                        : "$" +
                                                          item?.regular_price}{" "}
                                                      ·{" "}
                                                      {convertTimeToMinutes(
                                                        item?.time_duration
                                                      )}{" "}
                                                      minutes ·{" "}
                                                      <Typography
                                                        as={"span"}
                                                        sx={style}
                                                        onMouseEnter={() => {
                                                          setOnHover(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                          setOnHover(false);
                                                        }}
                                                        onClick={() =>
                                                          (window.location.href =
                                                            item?.link)
                                                        }
                                                      >
                                                        More info
                                                      </Typography>
                                                    </Typography>
                                                    {moreInfo.includes(
                                                      item?.id
                                                    ) && (
                                                      <Typography
                                                        sx={{
                                                          color: "#666666",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item?.description}
                                                      </Typography>
                                                    )}
                                                    {/*{isExist(selectedService,item) &&*/}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {item?.staff?.length >
                                                        0 &&
                                                        item?.staff.map(
                                                          (staffData, i) => (
                                                            <Typography
                                                              key={i}
                                                              sx={{
                                                                fontWeight:
                                                                  "400",
                                                                fontSize:
                                                                  "16px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {i !== 0 && ", "}{" "}
                                                              {staffData?.name}
                                                            </Typography>
                                                          )
                                                        )}
                                                    </Box>
                                                    {/*}*/}
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Box sx={{ pt: "24px" }}>
                                                <Checkbox
                                                  sx={{
                                                    color: "#c3af93",
                                                    "&.Mui-checked": {
                                                      color: "#c3af93",
                                                    },
                                                  }}
                                                  checked={isExist(
                                                    selectedService,
                                                    item
                                                  )}
                                                />
                                              </Box>
                                            </Grid>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      )
                                  ) : (
                                    <Grid item xs={12} sx={{ mt: "30px" }}>
                                      <Typography sx={{ textAlign: "center" }}>
                                        Services tab Not found.
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              )}
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {/*
                )}
*/}
                  </Grid>
                )}

                {active === 1 && (
                  <Grid
                    item
                    xs={12}
                    md={7.5}
                    lg={8}
                    xl={8.5}
                    sx={{ pb: { sm: 0, xs: "60px" } }}
                  >
                    {providerBookingSettings?.staff_available === 1 && (
                      <Box sx={{ mb: "16px" }}>
                        <Typography
                          variant={"h3"}
                          sx={{
                            fontWeight: "bold",
                            mb: "24px",
                            fontSize: "20px",
                            color: "black",
                            mt:2
                          }}
                        >
                          Select Staff
                        </Typography>
                        <Box sx={{ display: "flex" , flexWrap:'wrap',}}>
                          {selectedServicesStaff?.length > 0 &&
                            selectedServicesStaff.map((data, index) => (
                              <Box
                                key={index}
                                sx={{
                                  mr: "10px",
                                  mb: "10px",
                                  width: "100px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleStaffSelect(data);
                                }}
                              >
                                {data?.id === selectedStaff?.id && (
                                  <Box
                                    sx={{ position: "absolute", pt: "3.8px", }}
                                  >
                                    <Typography
                                      as={"span"}
                                      sx={{
                                        p: "5px 8px",
                                        color: "#fff",
                                        background: "#AA2167",
                                      }}
                                    >
                                      ✔
                                    </Typography>
                                  </Box>
                                )}
                                <Box sx={{ border: data?.id === selectedStaff?.id ? "4px solid #AA2167" : "none", }}>
                                  <img
                                    src={data?.profile_image || defaultPersonImg}
                                    alt={"staff"}
                                    height={"100px"}
                                    style={{ verticalAlign: "middle", objectFit:'cover' }}
                                    width={"100%"}

                                  />
                                </Box>
                                <Typography
                                  as={"p"}
                                  variant={"contained"}
                                  sx={{
                                    fontWeight: "600",
                                    textAlign: "center",
                                    fontSize: "14px",
                                    mt: "5px",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    WebkitLineClamp: 2, // Limits text to 2 lines
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {data?.name}
                                </Typography>
                              </Box>
                            ))}
                        </Box>
                        <Button
                          variant={"contained"}
                          onClick={() => {
                            handleStaffSelect("any");
                          }}
                          sx={{
                            color: "any" === selectedStaff ? "#000" : "#000",
                            backgroundColor:
                              "any" === selectedStaff ? "#fff" : "#E6DAD1",
                            border: "2px solid #E6DAD1",
                            fontWeight: "bold",
                            minWidth: "140px",
                            mr: "10px",
                            mb: "10px",
                            mt: "14px",

                            "&:hover": {
                              color: "any" === selectedStaff ? "#000" : "#fff",
                              backgroundColor:
                                "any" === selectedStaff ? "#fff" : "#006AFF",
                            },
                          }}
                        >
                          {"any" === selectedStaff && "✔"} No Preference On
                          Staff
                        </Button>
                      </Box>
                    )}
                    {!serviceDate && (
                    <div id="targetDivId">
                      <Typography
                        variant={"h3"}
                        sx={{
                          fontWeight: "bold",
                          mb: "24px",
                          fontSize: "22px",
                          color: "black",
                        }}
                      >
                      </Typography>
                      <Box sx={{ mt: "24px" }}>
                    <Typography sx={active === 1 ? style : { my: "16px",}}>
                    <span style={{ fontWeight: "bold",
                            mb: "24px",
                            fontSize: "20px",
                            color: "black", }}>
                      Select Date
                      </span>  
                      {active > 1 && (
                        <Typography
                          sx={{
                            ...style,
                            fontSize: "14px",
                            textDecoration: "unset",
                            ml: "5px",
                          }}
                          as={"span"}
                          onClick={() => handleEdit(1)}
                        >
                          Edit
                        </Typography>
                      )}
                    </Typography>
                    {selectedTime?.start || active === 1 ? (
                      <Box
                        sx={{
                          background: "#f4ede3",
                          padding: " 20px",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography> <CalendarMonthIcon  sx={{fontSize:'18px'}}/> {selectedDate}</Typography>
                        {selectedTime?.start && (
                          <Typography sx={{ color: "#636363" }}>
                              {selectedTime?.start} – {selectedTime?.end}
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                      <BookingCalender
                        handleDateReset={handleDateReset}
                        selectedStaff={selectedStaff}
                        selectedMultiStaffIds={selectedStaffIds}
                        handleSelectDate={handleSelectDate}
                        getMonthHolidays={getMonthHolidays}
                      />
                    </div>
                    )}
                    {/* {loading ? ( 
        
 <div >
<Waveloading  />
 </div>
              
           
            ) : ( 
              <> */}
                    <Box ref={ref}>
                      {selectedDate && (
                        <Box>
                        <span style={{ fontWeight: "bold",
                         fontFamily:
                         "helvetica-lt-std-roman",
                            marginTop: "24px",
                            display:'block',
                            fontSize: "20px",
                            color: "black", }}>
                      Select Time
                      </span>  
                      <Typography
                          variant={"h3"}
                          sx={{
                            fontWeight: "bold",
                            mt: "24px",
                            mb: { sm: "8px", xs: "20px" },
                            fontSize: "18px",
                            color: "black",
                          }}
                        >
                          Available on {selectedDate}
                        </Typography>
                        </Box>

                      )}

                      <Box>
                        {bookingSlots?.success && !loading && selectedDate && (
                          <Box>
                            <Typography
                              sx={{
                                mt: "16px",
                                mb: "8px",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Morning
                            </Typography>
                            <Box
                              sx={{
                                "@media (max-width:600px)": {
                                  display: "flex ",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                },
                              }}
                            >
                              {bookingSlots?.morningTimeSlots?.length > 0 ? (
                                bookingSlots?.morningTimeSlots.map(
                                  (item, index) => (
                                    <Button
                                      key={index}
                                      variant={"outlined"}
                                      sx={{
                                        color: selectedTime?.slots.includes(
                                          item
                                        )
                                          ? "#000"
                                          : "#000",
                                        backgroundColor:
                                          selectedTime?.slots[0] === item
                                            ? "#fff"
                                            : "#E6DAD1",
                                        border: "1px solid #E6DAD1",
                                        fontWeight: "bold",
                                        minWidth: "140px",
                                        "@media (max-width:340px)": {
                                          minWidth: "110px !important",
                                        },
                                        "@media (max-width:300px)": {
                                          minWidth: "110px !important",
                                        },
                                        mr: "10px",
                                        mb: "10px",
                                        "&:hover": {
                                          border: "1px solid #E6DAD1",
                                          color: selectedTime?.slots.includes(
                                            item
                                          )
                                            ? "#000"
                                            : "#000",
                                          backgroundColor:
                                            selectedTime?.slots[0] === item
                                              ? "#fff"
                                              : "#E6DAD1",
                                        },
                                      }}
                                      onClick={() =>
                                        handleTimeSelect(
                                          bookingSlots?.slots,
                                          item
                                        )
                                      }
                                    >
                                      {selectedTime?.slots[0] === item
                                        ? "✔ "
                                        : ""}
                                      {item}
                                    </Button>
                                  )
                                )
                              ) : (
                                <Button
                                  disabled
                                  variant={"contained"}
                                  sx={{
                                    backgroundColor: "#fff",
                                    color: "#E6DAD1",
                                    fontWeight: "bold",
                                  }}
                                >
                                  All Booked
                                </Button>
                              )}
                            </Box>
                            <Typography
                              sx={{
                                mt: "16px",
                                mb: "8px",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Afternoon
                            </Typography>
                            <Box
                              sx={{
                                "@media (max-width:600px)": {
                                  display: "flex ",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                },
                              }}
                            >
                              {bookingSlots?.afternoonTimeSlots?.length > 0 ? (
                                bookingSlots?.afternoonTimeSlots.map(
                                  (item, index) => (
                                    <Button
                                      key={index}
                                      variant={"outlined"}
                                      sx={{
                                        color: selectedTime?.slots.includes(
                                          item
                                        )
                                          ? "#000"
                                          : "#000",
                                        backgroundColor:
                                          selectedTime?.slots[0] === item
                                            ? "#fff"
                                            : "#E6DAD1",
                                        border: "1px solid #E6DAD1",
                                        fontWeight: "bold",
                                        minWidth: "140px",
                                        "@media (max-width:340px)": {
                                          minWidth: "110px !important",
                                        },
                                        "@media (max-width:300px)": {
                                          minWidth: "110px !important",
                                        },
                                        mr: "10px",
                                        mb: "10px",
                                        "&:hover": {
                                          border: "1px solid #E6DAD1",
                                          color: selectedTime?.slots.includes(
                                            item
                                          )
                                            ? "#000"
                                            : "#000",
                                          backgroundColor:
                                            selectedTime?.slots[0] === item
                                              ? "#fff"
                                              : "#E6DAD1",
                                        },
                                      }}
                                      onClick={() =>
                                        handleTimeSelect(
                                          bookingSlots?.slots,
                                          item
                                        )
                                      }
                                    >
                                      {selectedTime?.slots[0] === item
                                        ? "✔ "
                                        : ""}
                                      {item}
                                    </Button>
                                  )
                                )
                              ) : (
                                <Button
                                  disabled
                                  variant={"contained"}
                                  sx={{
                                    backgroundColor: "#fff",
                                    color: "#006AFF",
                                    fontWeight: "bold",
                                  }}
                                >
                                  All Booked
                                </Button>
                              )}
                            </Box>
                            <Typography
                              sx={{
                                mt: "16px",
                                mb: "8px",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              Evening
                            </Typography>
                            <Box
                              sx={{
                                paddingBottom: "100px",
                                "@media (max-width:600px)": {
                                  display: "flex ",
                                  justifyContent: "center",
                                  flexWrap: "wrap",
                                },
                              }}
                            >
                              {bookingSlots?.eveningTimeSlots?.length > 0 ? (
                                bookingSlots?.eveningTimeSlots.map(
                                  (item, index) => (
                                    <Button
                                      key={index}
                                      variant={"outlined"}
                                      sx={{
                                        color: selectedTime?.slots.includes(
                                          item
                                        )
                                          ? "#000"
                                          : "#000",
                                        backgroundColor:
                                          selectedTime?.slots[0] === item
                                            ? "#fff"
                                            : "#E6DAD1",
                                        border: "1px solid #E6DAD1",
                                        fontWeight: "bold",
                                        minWidth: "140px",
                                        "@media (max-width:340px)": {
                                          minWidth: "110px !important",
                                        },
                                        "@media (max-width:300px)": {
                                          minWidth: "110px !important",
                                        },
                                        mr: "10px",
                                        mb: "10px",
                                        "&:hover": {
                                          border: "1px solid #E6DAD1",
                                          color: selectedTime?.slots.includes(
                                            item
                                          )
                                            ? "#000"
                                            : "#000",
                                          backgroundColor:
                                            selectedTime?.slots[0] === item
                                              ? "#fff"
                                              : "#E6DAD1",
                                        },
                                      }}
                                      onClick={() =>
                                        handleTimeSelect(
                                          bookingSlots?.slots,
                                          item
                                        )
                                      }
                                    >
                                      {selectedTime?.slots[0] === item
                                        ? "✔ "
                                        : ""}
                                      {item}
                                    </Button>
                                  )
                                )
                              ) : (
                                <Button
                                  disabled
                                  variant={"contained"}
                                  sx={{
                                    backgroundColor: "#fff",
                                    color: "#E6DAD1",
                                    fontWeight: "bold",
                                  }}
                                >
                                  All Booked
                                </Button>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {/* </>
                    )} */}
                  </Grid>
                )}
                {active === 2 && (
                  <Grid item xs={12} md={7.5} lg={8} xl={8.5}>
                    <Box ref={myRef}>
                      <BookingContact
                      SelectedLocation={selectedLocation}
                        handleReset={handleReset}
                        providerBookingSettings={providerBookingSettings}
                        selectedFullDate={selectedDate}
                        selectedDate={selectedDateForApi}
                        selectedStaff={selectedStaff}
                        provider={provider}
                        selectedServices={selectedService}
                        selectedTime={selectedTime}
                        location={provider}
                      />
                    </Box>

                    {active === 2 && (
                      <Typography
                        sx={{
                          color: "#666666",
                          mt: "100px",
                          fontSize: "14px",
                          mb: 3,
                        }}
                      >
                        © 3D Lifestyle Aesthetics & Wellness.
                        {/* <Typography
                          as={"span"}
                          sx={{
                            color: "#006AFF",
                            fontWeight: "bold", 
                            mx: "3px",
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          Security
                        </Typography> */}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </Container>
            {active !== 2 && (
              <AppBar
                position="fixed"
                sx={{
                  top: "auto",
                  minHeight: "69px",
                  ...(active === 0 || (active === 1 && selectedTime?.slots)
                    ? { bottom: { sm: "0", xs: "90px" } }
                    : {}),
                  backgroundColor: "#fff",
                  boxShadow: "none",
                  borderTop: "1px solid #E5E5E5",
                }}
              >
                <Toolbar
                  sx={{
                    justifyContent: active === 0 ? "flex-end" : "space-between",
                    minHeight: "69px!important",
                    px: "48px!important",
                    "@media (max-width:400px)": {
                      paddingRight: "10px !important",
                    },
                  }}
                >
                  {active === 1 && (
                    <Button
                      // onClick={() => handleBackClick()}

                      sx={
                        {
                          // backgroundColor: "#fff",
                          // color: "#006AFF",
                        }
                      }
                    ></Button>
                  )}
                  
                  {(active === 0 || (active === 1 && selectedTime?.slots)) && (
                    <Button
                      ref={continueButtonRef}
                      disabled={!selectedService?.length}
                      onClick={() => handleNextClick()}
                      variant={"contained"}
                      sx={{
                        backgroundColor: "#aa2167",
                        color: "#fff",
                        fontWeight: "bold",
                        borderColor: "#aa2167",
                        "&:hover": { backgroundColor: "#aa2167" },
                      }}
                    >
                      Continue
                    </Button>
                  )}
                </Toolbar>
                <Box sx={{ display: { sm: "none", xs: "block" } }}>
                  {/* <Box
                    sx={{
                      position: "fixed",
                      bottom: "0",
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "#fff",
                      zIndex: "999",
                      border: "1px solid lightgray",
                      borderRadius: "11px",
                      width: "100%",
                      p: {
                        xs: "10px 15px 20px 15px",
                        sm: "10px 30px 30px 30px",
                      },
                      '@media (max-width:340px)':{
                        p:"10px 5px 10px 5px"
                      }
                    }}
                    className="footer-wrappper"
                  >
                    <Box
                      sx={{
                        backgroundColor: "rgba(239, 230, 224, 1)",
                        borderRadius: "7px",
                        p: "8px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        mr: { xs: "8px", sm: "0px" },
                        '@media (max-width:340px)':{
                          marginLeft:"6px",
                          p:"5px"
                        }
                      }}
                    >
                      <CardMedia
                        sx={{ width: { xs: "20px", sm: "30px" } }}
                        component="img"
                        alt="Descriptive alt text"
                        image={homeoutline}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "300",
                          fontSize: { xs: "12px", sm: "18px" },
                          mt: "10px",
                          color: "rgba(30, 30, 30, 1)",
                        }}
                      >
                        Home
                      </Typography>
                    </Box>

                    <Box
                      onClick={handleBoxClick}
                      sx={{
                        borderRadius: "7px",
                        p: "8px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: "5px",
                      }}
                    >
                      <CardMedia
                        sx={{
                          width: { xs: "18px", sm: "26px" },
                        }}
                        component="img"
                        alt="Descriptive alt text"
                        image={booking} 
                      />
                      <Typography
                        variant="body1"      
                        sx={{
                          fontWeight: "300",
                          fontSize: { xs: "12px", sm: "18px" },     
                          mt: "10px",
                          color: "rgba(30, 30, 30, 1)",
                        }}
                      >
                        Bookings
                      </Typography>
                    </Box>

                    <Box
                      onClick={handleHome}
                      sx={{
                        borderRadius: "7px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CardMedia
                        sx={{
                          width: { xs: "80%", sm: "100%" },
                          mt: { xs: "-40px", sm: "-40px" },
                          '@media (max-width:319px)':{
                            width:'80%',
                            position:'relative',
                            right:'5px'

                          },
                        }}
                        component="img"
                        alt="Descriptive alt text"
                        image={menu}
                      />
                    </Box>

                    <Box
                      onClick={handleplan}
                      sx={{
                        borderRadius: "7px",
                        p: "8px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CardMedia
                        sx={{ width: { xs: "20px", sm: "30px" } }}
                        component="img"
                        alt="Descriptive alt text"
                        image={plan}
                      />
                      <Typography
                        variant="body1"  
                        sx={{    
                          fontWeight: "300",
                          fontSize: { xs: "12px", sm: "18px" },
                          mt: "10px",
                          color: "rgba(30, 30, 30, 1)",
                        }}
                      >
                       Member
                      </Typography>
                    </Box>

                    <Box
                      onClick={handleprofile}
                      sx={{
                        borderRadius: "7px",
                        p: "8px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        ml: { xs: "5px", sm: "0px" },
                       
                      }}
                >
                      <CardMedia
                        sx={{ width: { xs: "20px", sm: "30px"},}}
                        component="img"
                        alt="Descriptive alt text"
                        image={profile}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "300",
                          fontSize: { xs: "12px", sm: "18px" },
                          mt: "10px",
                          color: "rgba(30, 30, 30, 1)",
            
                        }}
                      >
                     Account
                      </Typography>
                    </Box>

                  </Box> */}
                  <Footer SelectedLocation={selectedLocation} />
                </Box>
              </AppBar>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            {!locationOpen && provider?.name && (
              <Box>
                <Typography variant={"h4"}>
                  Currently {provider?.name} is not booking any appointment
                </Typography>
                <Button
                  fullWidth
                  onClick={() => setLocationOpen(true)}
                  variant={"contained"}
                  sx={{
                    backgroundColor: "#006AFF",
                    mt: "24px",
                    maxWidth: "300px",
                    mx: "auto",
                    display: "block",
                  }}
                >
                  Select Default / Spa Location
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default BookingWidget;
