import {
    Box,
    Typography,
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, IconButton,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useDispatch, useSelector} from "react-redux";
import {approveRequestedAppointment, getAllRequestedAppointment} from "../../store/crm/appointment";
import {MySwal, successConfirmation} from "../../services/utills";
import {confirmationButton} from "../../pages/crm/call-logs/Appointment";
import Loader from "../loader/loader";
import AppointmentReschedule from "../card-appointment/AppointmentReschedule";
import {scrollCSS} from "../../App";
import moment from "moment";
import Waveloading from "../../pages/loading/Waveloading";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const RequestedAppointment = () => {
    const [loading, setLoading] = useState(false);
    const [isMouseEnter, setIsMouseEnter] = useState(false);
    const [expanded, setExpanded] = useState({}); // Use an object to track expanded state of each appointment

    const crmAppointmentStore = useSelector(state => state?.crmAppointmentStore)
    const {requestedAppointment = []} = crmAppointmentStore

    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(getAllRequestedAppointment())
            setLoading(false)
        })()
    }, [])

    // Update handleAccordionChange to toggle specific section
    const handleAccordionChange = (id) => {
        // Toggle the expanded state of the specific appointment by its ID
        setExpanded(prevExpanded => ({
            ...prevExpanded,
            [id]: !prevExpanded[id], // If true, set to false, if false, set to true
        }));
    };

    const handleAccept = async (id) => {
        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? You want to accept this booking?
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={confirmationButton}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes. Accept it.
                    </Button>
                    <Button
                        sx={confirmationButton}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let {payload} = await dispatch(approveRequestedAppointment(
                {
                    booking_id: id
                }
            ))
            setLoading(false)
            if (payload?.success === 'true') {
                await successConfirmation('Appointment Approved Successfully')
            }
        }
    };

    return (
        <Box sx={{padding: "40px 20px 20px 20px"}}>
            <Typography variant="h5" sx={{fontFamily: "helvetica-lt-std-bold"}}>
                Appointment Needs to Approved
            </Typography>

            {
                loading ?
                    <Box sx={{mt: 4}}>
                        <Waveloading />
                    </Box> :
                    <Grid container sx={{
                        mt: 5,
                        height: '313px',
                        overflowY: 'auto',
                        ...scrollCSS,
                    }}>
                        {
    requestedAppointment?.data?.length > 0 ?
        requestedAppointment?.data?.map((appointment, index) => (
            <Grid
                key={index}
                item
                xs={12}
                sx={{
                    mb: 2,
                    height: 'max-content',
                    fontWeight: "bold",
                    background: "#fff",
                    borderBottom: "1px solid rgb(226, 232, 240)",
                }}
            >
                <Accordion
                   
                    onChange={() => handleAccordionChange(appointment.id)} // Update the expanded state on change
                    sx={{
                        boxShadow: "0px 0px 11px -4px rgba(81, 65, 65, 0.25)",
                        borderRadius: "6px",
                    }}
                >
                      
                    <AccordionSummary
                     expandIcon={expanded ? <ExpandMoreIcon /> :  <ExpandLessIcon />} 
                        aria-controls={`panel-${appointment.id}-content`}
                        id={`panel-${appointment.id}-header`}
                        sx={{ ".MuiAccordionSummary-content": { justifyContent: 'space-between' }, px:{sm:'16px', xs:'3px'}}}
                    >
                        <Box
                            sx={{
                                p: {sm:'16px', xs:'3px'},
                                width:"100%",
                                display: 'flex',
                                cursor: 'pointer',
                                justifyContent: "space-between",
                                flexWrap:{sm:'unset', xs:'wrap'},
                               
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: "18px",
                                        fontFamily: "helvetica-lt-std-roman",
                                    }}
                                >
                                    {appointment?.name}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexWrap: "wrap", mt:{sm:'unset', xs:2} }}>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    sx={{ mr: "10px" }}
                                    onMouseEnter={() => { setIsMouseEnter(true) }}
                                    onMouseLeave={() => { setIsMouseEnter(false) }}
                                    onClick={() => isMouseEnter && handleAccept(appointment?.id)}
                                >
                                    Accept
                                </Button>
                                <AppointmentReschedule
                                    appointment={appointment}
                                    isMouseEnter={isMouseEnter}
                                    setIsMouseEnter={setIsMouseEnter}
                                    isRequested={true}
                                />
                                {/* <IconButton
                                    sx={{
                                        ml: '10px',
                                    }}
                                    onClick={() => !isMouseEnter && handleAccordionChange(appointment.id)}
                                >
                                    {expanded[appointment.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton> */}
                            </Box>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{ mt: '16px', px: '16px', pb: '16px' }}>
                            <Box sx={{ display: "flex", flexWrap: 'wrap' }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: "helvetica-lt-std-bold",
                                        marginRight: "10px",
                                    }}
                                >
                                    Phone No:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ fontFamily: "helvetica-lt-std-roman" }}
                                >
                                    {appointment?.phone}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginRight: "10px",
                                        fontFamily: "helvetica-lt-std-bold",
                                        ml: { lg: 4, md: 2, sm: 1, xs: 1 },
                                    }}
                                >
                                    Email ID:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ fontFamily: "helvetica-lt-std-roman" }}
                                >
                                    {appointment?.email}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexWrap: 'wrap', mt: '6px' }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: "helvetica-lt-std-bold",
                                        marginRight: "10px",
                                    }}
                                >
                                    Date:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ fontFamily: "helvetica-lt-std-roman" }}
                                >
                                    {moment(appointment?.date).format('dddd MMMM D, YYYY')}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginRight: "10px",
                                        fontFamily: "helvetica-lt-std-bold",
                                        ml: { lg: 4, md: 2, sm: 1, xs: 1 },
                                    }}
                                >
                                    Time:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ fontFamily: "helvetica-lt-std-roman" }}
                                >
                                    {appointment?.start_time} - {appointment?.end_time}
                                </Typography>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{ mt: 2, fontFamily: "helvetica-lt-std-bold" }}
                            >
                                Service:
                            </Typography>
                            {
                                appointment?.services_name?.map((service, index) => (
                                    <Typography
                                        key={index}
                                        variant="body2"
                                        sx={{ fontFamily: "helvetica-lt-std-roman" }}
                                    >
                                        {service}
                                    </Typography>
                                ))
                            }
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        ))
        :
        <Grid
            item
            xs={12}
            sx={{
                mt: 5,
                fontWeight: "bold",
                background: "#fff",
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center'
                }}
            >
                No Requested Appointment found.
            </Typography>
        </Grid>
}

                    </Grid>
            }
        </Box>
    );
};


export default RequestedAppointment;
