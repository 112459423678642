import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    Select,
    Typography, Container
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import CustomButtoncolor from '../../../../components/custom-button/customColorButton';
import {useDispatch, useSelector} from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SquareCard from "./squareCard";
import BraintreeCard from "./braintreeCard";
import * as Yup from "yup";
import moment from "moment/moment";
import CloseIcon from '@mui/icons-material/Close';
import {useFormik} from "formik";
import {crmCreateCard, defaultUserCard, deleteUserCard, getAllExistingUserCards} from "../../../../store/crm/appointment/book-appointment";
import Loader from "../../../../components/loader/loader";
import visa from "../../../../assets/icon/visa.png";
import successIcon from "../../../../assets/icon/checkmark-circle.png";
import {Close, DoneAll} from "@mui/icons-material";
import {MySwal, successConfirmation} from "../../../../services/utills";
import {
    createCrmCustomerSubscription,
    getUserActiveSubscription,
    updateCrmCustomerSubscription
} from "../../../../store/crm/crm";
import ListItemText from "@mui/material/ListItemText";
import Waveloading from '../../../loading/Waveloading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const wrapper={
    alignItems:"center",
  }
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '80%', md: '600px' }, // Responsive width
    maxHeight: '90vh', // Prevents overflow on small screens
    overflowY: 'auto', // Scroll if content exceeds viewport
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: { xs: 0, lg:4 }, 
    outline: 'none',
    borderRadius: "8px",
  };
  const heading={
    fontSize:"18px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'bold',
    textAlign:"left",
    width:'100%'

  }
export const selectInput= {
   
    "& .MuiInputBase-root.MuiFilledInput-root.MuiSelect-root:after": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root.MuiFilledInput-root.MuiSelect-root:before": {
      borderBottom: "none"
    }
  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}

export const cardValidationSchema = Yup.object({
    number: Yup.string().required('Card number is required'),
    cvv: Yup.string().required('CVC is required'),
    expirationDate: Yup.string()
        .required('Expiry date is required')
        .test(
            'is-expiration-date-valid',
            'The expiration date should be later than the current date',
            (value) => {
                if (!value) {
                    return false;
                }

                const [month, year] = value.split('/').map(str => parseInt(str, 10));

                // Check the date validity and whether the expiry date is later than the current date
                if (isNaN(month) || isNaN(year) || !moment([year, month - 1]).isValid()) {
                    return false;
                }

                const currentDate = moment().startOf('month');
                const expirationDate = moment([year, month - 1]);

                return expirationDate.isSameOrAfter(currentDate)
            }),
});


const MangeCardModal= ({ customer_id,reFetchData, styles  }) => {
    const [squareCard, setSquareCard] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('select');
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [userCards, setUserCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [useExistingCard, setUseExistingCard] = useState(false);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();   
  
    const authStore = useSelector(state => state?.authStore)
    const crmStore = useSelector(state => state?.crmStore)  
    const {user} = authStore
    const {crmSubscriptions = []} = crmStore
    const [isModalOpen, setIsModalOpen] = useState(false);  

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
        
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const{selectedUserCard}  = useSelector(state => state?.bookAppointmentStore)

console.log(" card",selectedUserCard)     

    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        touched,
        errors,
    } = useFormik({  
        initialValues: {
            number: '',   
            expirationDate: '',
            cvv: '',
        },
        validationSchema: cardValidationSchema,
        onSubmit: async (data) => {
           await  handleMakeMembership('BrainTree',selectedPlan,data)
        },
    });   
    useEffect(() => {
        (async () => {
            if (open) {
                setLoading(true)
                let {payload} = await dispatch(getAllExistingUserCards(customer_id))
                setUserCards(payload?.data?.cards?.cards)      
               await getActivePlan()
              
                // if (payload?.data?.id) {
                //     if (payload?.data?.payment_method_id === 4) {
                //         setUserCards(payload?.data?.cards?.cards || [])
                //         console.log("usercard 3",userCards)
                //     } else {
                //         setUserCards(payload?.data?.cards?.cards || []) 
                //         console.log("usercard 2",userCards)
                //     }
                // } else {
                //     setUserCards([])
                //     console.log("usercard 1",userCards)
                // }
                console.log("usercard",userCards)
            }
        })() 
    }, [open])


    const handleChange = (event) => {  
        setSelectedPlan(event.target.value);
    };


    const getActivePlan = async () => {
        let res = await dispatch(getUserActiveSubscription(customer_id))

        setLoading(false)
        if (res?.payload?.data?.plan_id){   
            setSubscriptionPlan(res?.payload?.data)
            setSelectedPlan(res?.payload?.data?.plan_id)     
        }
    }  

    const handleOpen = () => setOpen(true);  
    const handleClose = () => {
        setOpen(false);
        setUseExistingCard(false)
        setSelectedCard(null)
    }      
    const tokenize = async (method) => { 
        const tokenResult = await method.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }

            throw new Error(errorMessage);  
        }
    };

    const handlePaymentMethodSubmission = async (event) => {
        event.preventDefault();
        await handleMakeMembership('Square',selectedPlan)
  
    }  
    
    const deleteusercard = async (cardId,event) => {
        const result = await MySwal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this card?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it'
        });
    
        if (result.isConfirmed) {  
            setLoading(true);  
    
            console.log("card data", selectedUserCard?.data?.application_token);
            const payload = {
                card_id:cardId,  
                customer_id: customer_id,
                provider_id:user?.id
            };
            console.log("data", payload);  
    
            try {
                await dispatch(deleteUserCard(payload)); 
    
            
            } catch (error) {  
                console.error("Error in deleteUserCard:", error);
               
            } finally {
                let {payload} = await dispatch(getAllExistingUserCards(customer_id))
                setUserCards(payload?.data?.cards?.cards)
                setLoading(false);
               
            }
        } else {
           
        }  
    };
    
    const defaultusercard = async (cardId, event) =>  {
        const result = await MySwal.fire({
            title: 'Are you sure?',
            text: "Do you want to make this card default?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, make it default'  
        });
    
        if (result.isConfirmed) {
            setLoading(true);
    
            console.log("card data", selectedUserCard);
            const payload = {
                card_id: cardId,
                provider_id: user?.id,
                user_id: selectedUserCard?.data?.user?.id,
            };
            console.log("data", payload);
    
            try {
                await dispatch(defaultUserCard(payload));
    
                
                setSelectedCard(event); 
            } catch (error) {
                console.error("Error in defaultUserCard:", error);
               
            } finally {
              let {payload} = await dispatch(getAllExistingUserCards(customer_id))
                setLoading(false); 
             
            }
        } else {
          
        }
    };
    
    
    
  
    const handleMakeMembership =async (type,selectedPlan,data) => {

        let selectedPlanDetails
        crmSubscriptions.forEach((item)=> {
            if (item?.plan_id === selectedPlan){
                selectedPlanDetails = item
            }
        })
        console.log("Data received in handleMakeMembership:", data); // Debugging line to inspect the data object
    setLoading(true);

        let confirm = await MySwal.fire({
            icon: 'warning',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to add this card.
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '20px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
     
    if (!confirm?.isConfirmed) {
        setLoading(false);
        return;
    }

    console.log("Card Active");

    let cardAddedResponse;
    if (type === 'now') {
        // Assuming 'now' means using the card directly
        cardAddedResponse = { success: true }; // Replace with actual API response
    } else if (type === 'BrainTree') {
        let cardData = {
            user_id: customer_id,
            number: data?.number,
            expirationDate: data?.expirationDate,
            cvv: data?.cvv,
        };
        let { payload } = await dispatch(crmCreateCard(cardData));

        if (payload?.data?.token) {
            cardAddedResponse = { success: true }; // Replace with actual API response
        } else {
            setLoading(false);
            return;
        }
    } else if (type === 'Square') {
        try {
            let squareToken = await tokenize(squareCard);
            let cardData = {
                user_id: customer_id,
                nonce: squareToken,
            };
            let { payload } = await dispatch(crmCreateCard(cardData));

            if (payload?.data?.id) {
                cardAddedResponse = { success: true }; // Replace with actual API response
            } else {
                setLoading(false);
                return;
            }
        } catch (e) {
            setLoading(false);
            console.error(e.message, "message");
            return;
        }
    }

    setLoading(false);

    // Check if card is added successfully
    if (cardAddedResponse?.success) {
        await successConfirmation("Success", "Card added successfully.");
        handleClose();
    } else {
        // Handle error case
    }

    }


    const memberShipSelection = () => (
        <Grid item lg={12} md={12} sm={12} sx={{mb: '24px',mt: useExistingCard ? "24px" :''}}>
            <FormControl style={{width: '100%'}} sx={selectInput}>
                <Typography>Subscription Plan</Typography>
                <Select
                    value={selectedPlan}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        if (selected === 'select') {
                            return 'Choose membership plan';
                        }

                        const selectedOption = crmSubscriptions.find((plan) => plan.plan_id === selected);
                        return selectedOption?.plan_name || '';
                    }}
                >
                    <MenuItem selected={"select" === selectedPlan} value={"select"}>Choose membership plan</MenuItem>

                    {crmSubscriptions?.length > 0 &&
                        crmSubscriptions.map((plan, index) => {
                            return(
                            <MenuItem
                                value={plan?.plan_id}
                                key={index}
                            >
                                <Checkbox checked={selectedPlan === plan?.plan_id} />
                                <ListItemText primary={plan?.plan_name} />
                            </MenuItem>
                        )})
                    }
                </Select>
            </FormControl>
        </Grid>
    )


    return (
        <Box>
            {loading ? <Waveloading fullSize/> : ""}
            <CustomButtoncolor
                handleClick={handleOpen}
                mainColor={"#EB5757"}
                size="md"
                val={'Manage Card'}
                style={{
                    padding: '7px 15px',
                    fontSize: "18px",
                    minWidth: 'max-content',
                    borderRadius: "6px!important",
                    width: "100%",
                    "@media (minWidth: 420px)": {
                        width: 'auto'
                    },
                    ...styles
                }}
            />
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                   <Container maxWidth="md">
                   <Grid container spacing={1} sx={wrapper}>
                        <Grid item lg={12} style={{display: "flex", justifyContent: "space-around", width: "100%"}}>

                            <Typography
                                sx={heading}
                            >
                              Manage Card
                            </Typography>
                            <IconButton
                                onClick={() => handleClose()}
                            >
                                <Close/>
                            </IconButton>
                        </Grid>
                        <Box style={{display:"block",width:'100%'}}>
                     
                            <Box sx={{display:"flex",flexDirection:"column",height:"auto",width:{lg:"60%",sm:"60%",xs:"100%"}}}>

                            {selectedUserCard?.data?.cards?.map((item, index) => (
  <React.Fragment key={index}>
    <Box
      sx={{
          position: "relative", 
          mt: 2, 
      }}
    >
      <Box
        sx={{
            position: "absolute", 
            top: -11, 
            right: -3, 
        }}
      > 
        <CloseIcon 
          style={{color:"#EB5757"}}
          onClick={() => deleteusercard(item?.id)}
        />
      </Box>

      <Card
        sx={{
            mt: 2,
            border: `0.8px solid ${selectedCard === (item?.token || item?.id) || item?.status === "Active" ? "#128D64" : "#C4C4C4"}`,
            borderRadius: "4px",
            boxShadow: "none",
            position: "relative",
            overflow: "unset",
            cursor: 'pointer'
        }}
        onClick={() => defaultusercard(item?.id)}
      >
        <Box
          sx={{display: "flex", alignItems: "center", width: '100%', px: '70px'}}
        >
          <img
            src={visa}
            width="50px"
            style={{marginLeft: 2}}
            alt=""
          />
          <Typography
            variant="h5"
            color="initial"
            sx={{
                ml: 3,
                fontSize: "16px",
                "@media (max-width:500px)": {
                    marginLeft: "5px",
                    textAlign: "center",
                    fontSize: "15px",
                },
            }}

>

            {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
          </Typography>
          {(selectedCard === (item?.token || item?.id) || item?.status === "Active") && <Box sx={{ml: {lg:"50px",sm:"50px",xs:"24px"}}}>
            <img
              src={successIcon}
              alt="successIcon"
            />
          </Box>}
        </Box>
      </Card>
    </Box>
  </React.Fragment>
))}


                            </Box>
                     <Box  style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                     <Button variant='contained'  style={{height:"42px"}}onClick={handleOpenModal}>Add Payment Method</Button>
                   
                     </Box>
                    
                        </Box>
                     

<Modal
  open={isModalOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={modalStyle}>
    <Box sx={{ mt: 3, width: "100%", px: 2 }}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (useExistingCard) {
            if (selectedCard) {
              await handleMakeMembership("now", selectedCard);
            } else {
              await successConfirmation('Error', "Choose A Card", 'error');
            }
          } else {
            if (user?.active_payment_method?.payment_gateway === "BrainTree") {
              handleSubmit(e);
            } else {  
              await handlePaymentMethodSubmission(e);
            }
          }
        }}
      >
        {user?.active_payment_method?.payment_method_id === 4 && !useExistingCard &&
          <SquareCard     
            setSquareCard={setSquareCard}
            paymentMethod={user?.active_payment_method}
          />
        }
        {user?.active_payment_method?.payment_gateway === 3 && !useExistingCard &&
          <BraintreeCard
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            values={values} 
          />
        }

        <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
         
          <Grid item xs={12} lg={6}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                height: "50px",
                background: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
                borderRadius: '8px',
                width: "100%", // Fix alignment
              }}
            >
              Add Card
            </Button>
          </Grid>
          <Grid item xs={12} lg={6}>
            <CustomButtoncolor
              mainColor={"#4F4F4F"}
              size="md"
              val='Cancel'
              type={'button'}
              style={{
                padding: '7px 5px',
                fontSize: "16px",
                borderRadius: "6px",
                width: "100%", // Responsive
              }}
              handleClick={handleCloseModal}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
          {!useExistingCard && userCards?.length > 0 &&
            <Typography
              onClick={() => setUseExistingCard(true)}
              sx={{
                color: '#006AFF',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
                "&:hover": {
                  textDecoration: 'underline'
                }
              }}
            >
              Use Existing Card
            </Typography>
          }
        </Grid>
      </form>
    </Box>
  </Box>
</Modal>

                     
{/* 
<Modal
  open={isModalOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  style={{ width: 200, height: 300 }}
>
  <Box sx={modalStyle}>
    <Box style={{ marginTop: '26px', width: "100%", padding: "0px 3px 0px 10px" }}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (useExistingCard) {
            if (selectedCard) {
              await handleMakeMembership("now", selectedCard);
            } else {
              await successConfirmation('Error', "Choose A Card", 'error');
            }
          } else {
            if (user?.active_payment_method?.payment_gateway === "BrainTree") {
              handleSubmit(e);
            } else {  
              await handlePaymentMethodSubmission(e);
            }
          }
        }}
      >
        {user?.active_payment_method?.payment_method_id === 4 && !useExistingCard &&
          <SquareCard     
            setSquareCard={setSquareCard}
            paymentMethod={user?.active_payment_method}
          />
        }
        {
          user?.active_payment_method?.payment_gateway === 3 && !useExistingCard &&
          <BraintreeCard
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            values={values} 
          />
        }

        <Grid item lg={12} md={12} sm={12} sx={btnwrappe} style={{ marginBottom: '10px' }}>
          <CustomButtoncolor
            mainColor={"#4F4F4F"}
            size="md"
            val='Cancel'
            type={'button'}
            style={{
              padding: '7px 5px',
              fontSize: "18px",
              minWidth: 'max-content',
              borderRadius: "6px!important",
              width: "15%",
              "@media (minWidth: 420px)": {
                width: 'auto'
              },
            }}
            handleClick={handleCloseModal}
          />
          <Button
            type={'submit'}
            variant="contained"
            size="large"
            style={{
              height: "50px",
              backgroundColor: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
              borderRadius: '8px',
            }}
          >
            Add Card
          </Button>
        </Grid>
        <Grid item xs={12}>
          {!useExistingCard && userCards?.length > 0 &&
            <Typography
              onClick={() => setUseExistingCard(true)}
              sx={{
                color: '#006AFF',
                mt: '16px',
                textAlign: 'center',
                cursor: 'pointer',
                "&:hover": {
                  textDecoration: 'underline'
                }
              }}
            >
              Use Existing Card
            </Typography>
          }
        </Grid>
      </form>
    </Box>
  </Box>
</Modal> */}






                    </Grid>
                   </Container>
                </Box>
            </Modal>
        </Box>
    )
};

export default MangeCardModal;

