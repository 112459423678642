import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";
import moment from "moment";

export const getAllCalenderBookings = createAsyncThunk("getAllCalenderBookings", async ( data,{getState }) => {

    try {
        const { selectedStaff, calenderType, isCancel} = getState()?.crmCalenderStore
        let payload = {
            type: calenderType,
            date:moment(data?.date).format('YYYY-MM-DD'),
        }
        if (isCancel){
            payload['is_cancel']=isCancel
        }
        if (selectedStaff!=='all'){
            payload['staff_id'] = selectedStaff
        }
        const response = await axios.post(apis.crm_calender_booking, {...payload}, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        } else {
            return [];
        }
    } catch (err) {
        return []
    }
});

//  delete 
// export const deleteBlockHour = createAsyncThunk('deleteBlockHour', async (id,{dispatch}) => {
//     try {
//         const response = await axios.get(apis.delete_block_hour +id,{
//             headers: apiHeaders()
//         })
      
  
//         if (response?.data?.success ==='true'){
//         // //   await dispatch(getPromoCodes())
//             toast.success("Block hours  Deleted Successfully",{duration:3000})
  
//         }else {
//             console.log(response?.data)
//         }
  
//     }catch (err){
//         console.log(err)
//         if (err?.response?.status!==200){
//             toast.error(err?.message,{duration:4000})
//         }
//     }
//   })

  export const deleteBlockHour = createAsyncThunk('deleteBlockHour', async (id,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_block_hour +id,{
            headers: apiHeaders()
        })
 console.log("daaa",response)
        if (response?.data?.success ==='true'){
        //   await dispatch(getAllRoles())
            toast.success(response?.data?.message,{duration:3000})
 
        }else {
            console.log(response?.data)
        }
 
    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
 })


//  Edit_Blockhour
export const getBlockhourId = createAsyncThunk('getBlockhourId', async (id) => {
    try {
        const response = await axios.get(apis.Edit_Blockhour+id,{
            headers: apiHeaders()
        })
        return  response?.data
  
    }catch (err){  
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
  }) 
export const updateCrmCalenderFilter = createAsyncThunk('updateCrmCalenderFilter', async (data) => {
    return data
})





export const crmCalenderSlice = createSlice({
    name: "crmCalenderSlice",
    initialState: {
        calenderBookings: [],
        selectedStaff:'all',
        calenderType:'Day',
        isCancel:false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCalenderBookings.fulfilled, (state, action) => {
            state.calenderBookings = action.payload;
        });

        builder.addCase(updateCrmCalenderFilter.fulfilled, (state, action) => {
            const {calenderType,selectedStaff,isCancel} = action?.payload

            if (calenderType){
                state.calenderType = calenderType ;
            }
            if (selectedStaff){
                state.selectedStaff = selectedStaff ;
            }

            if (isCancel===true || isCancel ===false){
                state.isCancel = isCancel ;
            }
        });

    },
});

export default crmCalenderSlice.reducer;
