export const maincontainer = {
    margin: "15px 0px",
    padding: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 19px -2px rgba(189, 189, 189, 0.25)",
    borderRadius: "10px",
};


export const outercontainer = {
    display: "flex",
    justifyContent: "space-between",
    marginTop:'-18px',
    '@media (max-width: 900px)': {
        display:"flex",
        flexDirection:"column"
    }
};
export const wrapper = {
    marginTop:'2%',
    width:'66%',
    display: "flex",
gap:"28px",
'@media (max-width: 900px)': {
    // alignItems:"center",
    width:"100%",
    margin:"20px 0",
},
    '@media (max-width: 768px)': {
        // alignItems:"center",
        display:"flex",
        flexDirection:"column",
        width:"100%"
    }
};
export const subheading = {
    fontsize:'16px',
    fontWeight:'400',
    fontFamily:'helvetica-lt-std-bold',
    // color:"#4F4F4F"
    color:'#000',

};
export const heading = {
    fontsize:'25px',
    fontWeight:'700',
    fontFamily:'helvetica-lt-std-bold',


};
export const content = {
    fontsize:'18px',
    fontWeight:'400',
    fontFamily:'helvetica-lt-std-roman',
    color:"#000000"

};
export const btnwrapper ={
    width:"25%",
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    '@media (max-width: 900px)': {
        // alignItems:"center",
        width:"100%"
    }
}
export const contentwrape={
    width:"20%",
    '@media (max-width: 900px)': {
        // alignItems:"center",
        width:"100%"
    }
}

export const headerwraper={
    padding:'0px 20px',
    display:'flex',
    justifyContent:'space-between',
    marginTop:'2px',
    alignItems:'flex-start',
    '@media (max-width: 900px)': {
        flexDirection:'column',
        alignItems:'center',
    }

}
export const contentwrap={
    display:'flex',
    alignItems:'center',
    flexDirection:'row'
    ,gap:'6px',
    '@media (max-width: 768px)': {
        flexDirection:'column',
        // flexWrap:'wrap'
        // alignItems:'center'
    }
}
export const vectorWrapper= {
    backgroundColor: "#FFFFFF",
    color: "#333333",
    p:'15px 12px',
    cursor:'pointer',
    borderRadius: "8px",
    border: "1px solid #BDBDBD",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:'auto',

}
export const btnwrap={
    mb: "16px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    marginLeft:'10px',
    '@media (max-width: 425px)': {
        // flexDirection:'column',
        marginLeft:'0px',
        
        
        // alignItems:'center'
    }

}

