import { Box, Typography, Grid, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import TotalMemberNamount from "./totalMemberNamount";
import DateRange from "../date-range/date-range";
import MonthYearFilter from "./MonthViewFilter";


const MemberReportMonthly = (props,) => {
  const currentMonthName = new Date().toLocaleString('default', { month: 'long' });
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  
  const {
    membershipTitle,
    memberSignup,
    memberSignupTotal,
    memberShipAmountTitle,
    memberShipAmountTotal,
    memberShipRenewel,
    memberShipRenewelTotal,
    totalRenewals,
    renewalsTotal,
    totalDicline,
    totalDiclineTotal,
    totalCancellations,
    totalCancellationsTotal,
    total69Member,
    total69MemberAmount,
    total99Member,
    total99MemberAmount,
    total149Member,
  total149MemberAmount,
  onClick,
  fetchData,

  } = props;
  const handleMonthYearChange = (year, month) => {
    const monthNumber = getMonthNumber(month); // Assuming getMonthNumber is defined somewhere in this component
    fetchData(year, monthNumber); // Assuming fetchData is correctly implemented to call your API
  };

  // useEffect(() => {
  //   // Call fetchData when either selectedMonth or selectedYear changes
  //   handleButtonClick()
  // }, [selectedMonth, selectedYear]);

  function getMonthNumber(monthName) {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
  
    // Return the numeric value for the given monthName
    return months[monthName] || null;
  }
  
  // Usage example:
  // const monthName = "January";
  // const monthNumber = getMonthNumber(monthName);
      const handleButtonClick = async () => {
        const selectedMonthNumber = getMonthNumber(selectedMonth);
        const response = await props.fetchData(selectedYear, selectedMonthNumber );
       
      };
    
  const color =
    
       membershipTitle === "Monthly Membership"
      ? "black" // Black
      : "#000"; // Default color

  const iconStyle =
  
    membershipTitle === "Monthly Membership"
      ? "#F44336"
      : "#F44336"; // Default color

  const background =

     membershipTitle === "Monthly Membership"
      ? "#FFF7F8"
      : "#FFF7F8"; // Default color


      

  return (
    <Box sx={{cursor:'pointer'}}>
      <Box
        sx={{
          padding: {lg:'40px', xs:'20px 10px'},
          position: "relative",
          borderRadius: "8px",
          overflow: "hidden",
          background: background,

          '@media (min-width:1600px)': {padding:'20px 10px', height:'600px'}

        }}
      >
        <Typography
          variant="h5"
          sx={{ fontFamily: "helvetica-lt-std-bold", color: color, mb:3 }}
        >  
          {membershipTitle}  
        </Typography>

        <Box sx={{ display:"flex" , flexDirection:'column', justifyContent:"space-between", alignItems:{lg:'center', xs:'start'}, '@media (min-width:1600px)': {flexDirection:'row'} , height:"50px"  }}>
        <MonthYearFilter
        initialYear={selectedYear}
        initialMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        onMonthYearChange={handleMonthYearChange}
      />
   {/* <Button style={{marginTop:"9px"}} variant="contained"  onClick={handleButtonClick}>
  Fetch Report
</Button>     */}
</Box>
     
 
<Box >     

        <Grid container sx={{ mt: 3 }}>
          <Grid item lg={6} sm={6} xs={6}>      
            <Box>   
              <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                {memberSignup}
              </Typography>      
              <Typography  variant="body1" sx={{ color , fontSize:"20px" }}>
                {memberSignupTotal}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                {memberShipAmountTitle}
              </Typography> 
              <Typography variant="body1" sx={{ color , fontSize:"20px"}}>
                ${memberShipAmountTotal}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                {memberShipRenewel}
              </Typography>
              <Typography variant="body1" sx={{ color , fontSize:"20px" ,}}>
                {memberShipRenewelTotal}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                {totalRenewals}
              </Typography>
              <Typography variant="body1" sx={{ color , fontSize:"20px" }}>
              $  {renewalsTotal}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>
                {totalDicline}
              </Typography>   
              <Typography variant="body1" sx={{ color , fontSize:"20px"  }}> 
                {totalDiclineTotal}        
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box> 
              <Typography sx={{ color ,fontSize:"22px",fontWeight:900}}>   
                {totalCancellations}
              </Typography>  
              <Typography variant="body1" sx={{ color , fontSize:"20px" }}>   
                {totalCancellationsTotal}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box>
          <TotalMemberNamount
            color={color}
            total69MemberTitle={"Total $69 Plan Member"}
            total69Member={total69Member}
            total69MemberAmount={total69MemberAmount}
            total99MemberTitle={"Total $199 Plan Member"}
            total99Member={total99Member}
            total99MemberAmount={total99MemberAmount}
            total149MemberTitle={"Total $149 Plan Member"}
            total149Member={total149Member}
            total149MemberAmount={total149MemberAmount}
          />
        </Box>
        </Box>
        <Box sx={{ position: "absolute", bottom: "-26px", right: "-24px" }}>
          <svg
            id="check-circle"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style={{ width: "96px", color: iconStyle }}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default MemberReportMonthly;
