import React, { useEffect, useState,useRef} from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Link, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, makeStyles, useMediaQuery } from '@mui/material';
import logo from '../../../assets/images/logo.jpg'
import CustomButtoncolor from '../../../components/custom-button/customColorButton';
import Loader from '../../../components/loader/loader';
import {styled, useTheme} from "@mui/material/styles";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {
    appointmentCallLogData,
    updateCallLogFilters, appointmentConfirmedCallLogsData, changeAppointmentConfirmStatus
} from '../../../store/crm/call-log';
import { useDispatch, useSelector } from 'react-redux';
import BackendPagination from '../../../components/pagination/backendPagination';
import FilterInput from '../../../components/form/filterInput';
import DateRange from '../../../components/date-range/date-range';
import moment from "moment";
import {MySwal, successConfirmation} from "../../../services/utills";
import AppointmentReschedule from "../../../components/card-appointment/AppointmentReschedule";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Waveloading from '../../loading/Waveloading';


export const confirmationButton ={
    backgroundColor: "#fff",
    minWidth: "max-content",
    textTransform:'capitalize',
    border: "1px solid #666666",
    borderRadius: '10px',
    fontSize: '16px',
    px: '20px',
    mx: '10px',
    color: "#666666",
    "&:hover": {
        backgroundColor: "#fff",
        border: "1px solid #666666",
    },
}
export const logowrap = {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    '@media (max-width: 500px)': {
        flexDirection:'column',
        height:'fit-content'
        
          }
  };
    

  export const heading = {
    fontFamily: 'helvetica-lt-std-light',
    fontWeight: '700!important',  
    fontSize: '30px!important',
    '@media (max-width: 468px)': {
        fontSize:'20px!important'
        
          }
  };
  
  export const labeldesign = {
    fontFamily: 'helvetica-lt-std-bold',
    fontSize: '18px!important',
    marginBottom:'10px'

  };

  export const btnstyle ={
      color: "#4BADE8",
      borderColor: "#4BADE8",
      textTransform: "capitalize",
      fontSize: "14px",
      "&:hover": {
        backgroundColor:  "#4BADE8",
        borderColor:  "#4BADE8",
        color: "#fff",  
      },
      '@media (max-width: 468px)': {
        width:'100%!important',
        fontSize:'18px',
        
          },
  
    variant:"outlined"
   
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: "#27272E", 
        fontSize: "16px",
        fontWeight: 700,
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingTop:'7px',
        paddingBottom:'7px',
    },
  }));
const StyledTableRow = styled(TableRow)(({ theme }) => ({

    // hide last border
    // "&:last-child td, &:last-child th": {
    //     border: 0,
    // },
  }));  

  

const AppointmentCallLog = () => {
    const [innerTab, setInnerTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        no: true,
        yes: false,
        noans: false,
    });
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const callLogStore = useSelector(state => state?.callLogStore)
    const tableRef = useRef(null);
    const handleDownload = () => {
        const table = tableRef.current;
    
        if (!table) {
          console.error('Table not found.');
          return;
        }
    
      
  const pdf = new jsPDF('p', 'pt', 'a4');

  // Get the actual width and height of the table
  const tableWidth = table.offsetWidth;
  const tableHeight = table.offsetHeight;

  // Calculate the scale factor to fit the table in the PDF
  const scaleFactor = 550 / tableWidth; // Assuming the PDF width is 550pt

  html2canvas(table, { scale: scaleFactor }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');

    // Add the image to the PDF
    pdf.addImage(imgData, 'JPEG', 25, 25, tableWidth * scaleFactor, tableHeight * scaleFactor);

    // Save the PDF
    pdf.save('table.pdf');
  });
};
    const handlePrint = () => {
        const tableToPrint = tableRef.current;
        if (tableToPrint) {
          const printWindow = window.open('', '', 'width=800,height=600');
          printWindow.document.write(`<html><head><title>Print</title></head><body>${tableToPrint.outerHTML}</body></html>`);
          printWindow.document.close();
          printWindow.print();
        }
      };
      
    const {
        appointmentCallLogs=[],
        appointmentConfirmedCallLogs=[],
        appointmentStartTime=null,
        appointmentEndTime=null,
        appointmentPageLimit,
        appointmentCurrentPage,
        appointmentFilterBy,
        appointmentSearch,
    } = callLogStore

    const handleCheckboxChange = async ( id,value) => {

        let confirm = await MySwal.fire({
            icon: 'question', 
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? You want to do this?
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={confirmationButton}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes. Do it.
                    </Button>
                    <Button
                        sx={confirmationButton}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            const finalPayload = {
                id: id,   
                status: value
            };

            setLoading(true);
            let {payload}  = await dispatch(changeAppointmentConfirmStatus(finalPayload));
            if (payload?.success ==='true'){
                await fetchAppointmentCallLogs()
                setLoading(false);
            }else {
                setLoading(false);
                await successConfirmation("Error",'Try Again. Something went wrong','error')
            }
        }

    };
    useEffect(() => {
        (async () => {

            await fetchAppointmentCallLogs()
        })();   
    }, []);

    const fetchAppointmentCallLogs = async () => {
        setLoading(true);
        await dispatch(appointmentCallLogData());
        setLoading(false);
    }
    const fetchAppointmentConfirmCallLogs = async () => {
        setLoading(true);
        await dispatch(appointmentConfirmedCallLogsData());
        setLoading(false);
    }
    const handleInnerTabChange = async (event, newValue) => {   
        setInnerTab(newValue);        
        if (newValue === 1){   
            await fetchAppointmentConfirmCallLogs()
        }   
    }  

    const fetchPageData = async (page) => {
        await dispatch(updateCallLogFilters({currentPage: page,type:'appointment'}))
        await fetchAppointmentCallLogs()
    }
    const handleLimitChange = async (val) => {
        await dispatch(updateCallLogFilters({pageLimit: val, currentPage: 1,type:'appointment'}))
        await fetchAppointmentCallLogs()
    }
    const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (   
        <Grid
            sx={{
                background: 'white',
                padding: {xs:'15px 8px',sm:'15px 28px'},
                borderRadius: "8px"   
            }}   
            container
        >
            {loading ? <Waveloading fullSize/> : ""}
            <Grid item lg={12} sx={logowrap}>
                <Typography sx={heading}>Appointment Call Log Activity</Typography>

                <img src={logo} alt="loading" width={'20%'}  style={{
    display: isMobile ? 'none' : 'block',
    height: 'fit-content',
  }} />
            </Grid>    

            <Grid item lg={12}>   
                <div>
                    <Grid container spacing={4} style={{marginTop: "6px"}}>   
                        <Grid item  lg={7} xs={12} sm={12}  sx={logowrap }> 

                        {/* <Grid container sx={{ display:'flex',justifyContent:'space-between',alignItems:'center',flexDirection:{xs:'column',sm:'row'} }}> */}
                            <Grid item lg={3} xs={12} sm={12} >
                                <Typography sx={labeldesign}>Appointment List For:</Typography>
                            </Grid>    
                            <Grid item lg={8}  xs={12} sm={12}>
                                <FormControl style={{width: "100%"}}>   
                                    <DateRange   
                                        color={'#EDF2F7'}
                                          
                                        startDate={appointmentStartTime}
                                        endDate={appointmentEndTime}
                                        setStartDate={async (val)=>{
                                            await dispatch(updateCallLogFilters({startTime: val,type:'appointment'}))
                                        }}
                                        setEndDate={async (val)=>{
                                            await dispatch(updateCallLogFilters({
                                                endTime: val,
                                                currentPage: 1,
                                                type: 'appointment'
                                            }))  

                                            if(val) {
                                                await fetchAppointmentCallLogs()
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        {/* </Grid> */}

                        </Grid>

                        <Grid item lg={4}> </Grid>

                        <Grid item lg={8} xs={12} sx={{display: 'flex', gap: '5px',flexDirection:{xs:'column',sm:'row'}}}>
                                <Button
                                    variant="outlined"
                                    size="large"   
                                    sx={btnstyle}  
                                    startIcon={<LocalPrintshopOutlinedIcon   
                                        style={{fontSize: '20px',color: 'inherit',}}/>}
                                        onClick={handlePrint}
                                >
                                    Print
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                      
                                        color: '#4CAF50', // Green color for the button text
                                        borderColor: '#4CAF50', // Green color for the button border
                                        '&:hover': {
                                          backgroundColor: '#4CAF50', // Green background color on hover
                                          color: '#FFF', // White text color on hover
                                        },
                                      }}
                                    size="large"
                                    startIcon={
                                        <CloudDownloadOutlinedIcon sx={{ color: 'inherit', mr:"7px" }}/>
                                    }
                                    onClick={handleDownload}
                                >
                                    Download report
                                </Button>
                                {/* <Button        
                                    variant="outlined"
                                    sx={btnstyle}
                                    size="large"
                                    startIcon={<EmailOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}>
                                    Email Reports  
                                </Button>  */}
                        </Grid>   
                          
                        {/* <Grid item lg={4}>
                        </Grid> */}
                       
                    </Grid>
                </div>
            </Grid>

            <Grid item lg={12} xs={12}>
                            <div>
                                <Tabs
                                    value={innerTab}
                                    onChange={handleInnerTabChange}
                                    sx={{
                                        overflowX:'auto',
                                        maxWidth: '100%',
                                        display:'flex',
                                        flexDirection:'column',
                                        '@media screen and (max-width: 768px)': {
                                            '.MuiTabs-scroller': {
                                              overflow: 'scroll !important',
                                            },
                                          },
                                        ".MuiTabs-indicator": {
                                            background: '#000000'
                                        },
                                        ".Mui-selected": {
                                            color: '#000!important',
                                            fontWeight: '600'
                                        },
   
                                    }}
                                >
                                    <Tab
                                        label="Appointment Confirmation List"
                                        sx={{
                                            fontSize:'18px',
                                            color: '#000',
                                            textTransform: "capitalize",
                                            fontFamily:innerTab ===0? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                                        }}
                                    />
                                    <Tab
                                        label="Confirmed Appointment"
                                        sx={{
                                            fontSize:'18px',
                                            color: '#000',
                                            textTransform: "capitalize",
                                            fontFamily:innerTab === 1 ? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                                        }}
                                    />
                                </Tabs> 

                             {innerTab === 0 && (
                                <>
                            
                    <TableContainer sx={{mt: '24px',  maxWidth: '100%',
                    overflowX: 'auto',
                }}>
                                        {loading === true && <Waveloading fullSize/>}
                     <Table stickyHeader aria-label="customized table" ref={tableRef} sx={{ width:{sm:'100%', xs:'1350px'} }}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>
                                                        ID
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Customer Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Treatment Name
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                    sx={{
                                                    minWidth:'200px'
                                                    }}
                                                    >
                                                        Booking Time
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Tell #
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Email
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Appointment Confirmed
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Action
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { appointmentCallLogs?.data?.length ? (
                                                    appointmentCallLogs?.data.map((item,index) => (
                                                        <StyledTableRow key={index}>
                                                                <StyledTableCell>
                                                                    {item?.id}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {item?.customer?.name}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {item?.service_name}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <Typography>
                                                                        {moment(item?.date)?.format('YYYY-MM-DD')}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {item?.start_time} - {item?.end_time}
                                                                    </Typography>
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {item?.customer?.phone}
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    {item?.customer?.email}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                  sx={{
                                                                    paddingBottom:"33px !important",
                                                                    display: "flex",
                                                                    justifyContent: "center", // Adjust this to align the checkboxes within the cell
                                                                    alignItems: "center", // Vertically center the items
                                                                    minWidth: "160px",
marginTop:"50px"
                                                                  }}
                                                                >
                                                                    <div style={{marginBottom:'20px',display:"flex"}}>
                                                                    <FormControlLabel 
                                                                     className="print-table-checkboxes"
                                                                        control={
                                                                            <Checkbox
                                                                                checked={item?.is_confirmed === 'No'}
                                                                                onClick={()=>handleCheckboxChange(item?.id,'No')}
                                                                                name="No"
                                                                                size="small"
                                                                            />
                                                                        }
                                                                        label="No"
                                                                    />
                                                                    <FormControlLabel
                                                                     className="print-table-checkboxes"
                                                                        control={
                                                                            <Checkbox
                                                                                checked={item?.is_confirmed === 'Yes'}
                                                                                onClick={()=>handleCheckboxChange(item?.id,'Yes')}
                                                                                name="Yes"
                                                                                size="small"/>
                                                                        }
                                                                        label="Yes"
                                                                    />
                                                                    <FormControlLabel
                                                                     className="print-table-checkboxes"
                                                                              control={
                                                                            <Checkbox
                                                                                checked={item?.is_confirmed === 'No Ans'}
                                                                                onClick={()=>handleCheckboxChange(item?.id,'No Ans')}
                                                                                name="noans"
                                                                                size="small"
                                                                            />
                                                                        }
                                                                        label="No Ans"
                                                                    />
                                                                    </div>
                                                                   
                                                                </StyledTableCell>
                                                                <StyledTableCell sx={{ minWidth: "100px"}}>
                                                                    <AppointmentReschedule appointment={item}/>
                                                                </StyledTableCell>
                                                              </StyledTableRow>      
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                height:'300px'
                                                            }}
                                                            colSpan={9}
                                                            align="center"
                                                        >
                                                            Appointment data not found
                                                        </TableCell>
                                                     </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                     
                                    </TableContainer>
                                       {appointmentCallLogs?.data?.length > 0 && (
                                     <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mt: '30px',
                                        flexWrap: 'wrap',
                                        '@media (min-width: 600px)': { 
                                          justifyContent: 'flex-end', 
                                          mt: '20px', 
                                        }
                                      }}>
                                        <div style={{
                                          display: 'flex',
                                          alignItems: 'baseline',
                                          '@media (max-width: 599px)': { 
                                            marginBottom: '16px', 
                                          }
                                        }}>
                                          <FilterInput
                                            isBorder
                                            limit={appointmentPageLimit}
                                            setLimit={handleLimitChange}
                                          />
                                          <Typography
                                          sx={{ '@media (max-width: 1200px)' :{
                                            marginLeft:"10px"
                                          } }}
                                          >Showing {appointmentCallLogs?.meta?.from} to {appointmentCallLogs?.meta?.to} of {appointmentCallLogs?.meta?.total} entries</Typography>
                                        </div>
                                        <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: { xs: 'center', sm: 'flex-end' }, // xs targets small screens like mobile
    width: { xs: '100%', sm: 'auto' }, // xs sets width to 100% on mobile, sm sets it to auto on screens wider than 600px
    mt: { xs: '16px', sm: '0' } // xs adds margin top on mobile, sm has no margin top
  }}>
    <BackendPagination
      count={appointmentCallLogs?.meta?.last_page}
      currentPage={appointmentCurrentPage}
      handleOnChange={fetchPageData}
    />
  </Box>
                                      </Box>
                                      
                                      
                                    )}
                                        </>
                                )}
                                {innerTab === 1 && (  
                                    <TableContainer sx={{mt: '24px' , overflowX:'auto'}}>
                                        {loading === true && <Waveloading fullSize/>}
                                        <Table stickyHeader aria-label="customized table" ref={tableRef}>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell> 
                                                        Booking Id
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Customer Name
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Treatment Name
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                      sx={{
                                                        minWidth:'200px'
                                                        }}
                                                    >
                                                        Booking Time
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Tell #
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Email
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        Status
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {appointmentConfirmedCallLogs?.data && appointmentCallLogs?.data?.length ? (
                                                    appointmentConfirmedCallLogs?.data.map((item,index) => (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell>
                                                                {item?.id}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.service_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.date}
                                                                <br/>
                                                                {item?.start_time}-{item?.end_time}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.phone}
                                                            </StyledTableCell>

                                                            <StyledTableCell>
                                                                {item?.customer?.email}
                                                            </StyledTableCell>
                                                            <StyledTableCell>
                                                                <Typography
                                                                    sx={{
                                                                        background:item?.status_color || '#000',
                                                                        color:"#fff",
                                                                        borderRadius:"22px",
                                                                        padding:"2px 22px"
                                                                    }}
                                                                >
                                                                {item?.status}
                                                                </Typography>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                height:'300px'
                                                            }}
                                                            colSpan={9}
                                                            align="center"
                                                        >
                                                          Confirmed  appointment data not found
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        {appointmentConfirmedCallLogs?.data && appointmentConfirmedCallLogs?.data.length > 0 && (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                mt: '24px',
                                                flexWrap:'wrap',
                                                gap:{xs:'20px',sm:'0'}
                                            }}>
                                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                                    <FilterInput
                                                        isBorder
                                                        limit={appointmentPageLimit}
                                                        setLimit={handleLimitChange}
                                                    />
                                                    <Typography
                                                    sx={{ '@media (max-width: 1200px)' :{
                                                        marginLeft:"10px"
                                                      } }}
                                                    >Showing {appointmentConfirmedCallLogs?.meta?.from} to {appointmentConfirmedCallLogs?.meta?.to} of {appointmentConfirmedCallLogs?.meta?.total} entries</Typography>
                                                </div>
                                                <BackendPagination
                                                    count={appointmentConfirmedCallLogs?.meta?.last_page}
                                                    currentPage={appointmentCurrentPage}
                                                    handleOnChange={fetchPageData}
                                                />
                                            </Box>
                                        )}
                                    </TableContainer>
                                )}
                            </div>
                        </Grid>

        </Grid> 
    )
};

export default AppointmentCallLog
  