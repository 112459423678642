import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import useStyles from "./style";
import {useDispatch, useSelector} from "react-redux";
import {getAllProducts} from "../../../store/crm/inventory";
import Loader from "../../../components/loader/loader";
import {useNavigate} from "react-router-dom";
import Waveloading from '../../loading/Waveloading';

const InventoryProduct = () => {
    const [loading,setLoading] = useState(false)

    const inventoryStore = useSelector(state => state?.inventoryStore)
    const {
        products = [],
    } = inventoryStore


    const classes = useStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(getAllProducts())
            setLoading(false)
        })()
    }, [])

    return (
        <Box sx={{
            minHeight: 'calc(100vh - 109px)',
            background: "#fff",
            padding: {sm:"24px", xs:'8px'},
            borderRadius: '7px',
        }}>
            <Typography variant="h5">Product List</Typography>
            {loading ? <Waveloading/> :
                <TableContainer
                    component={Paper}
                    sx={{mt: 5, maxHeight: "650px"}}
                >
                    <Table sx={{}} aria-label="simple table" >
                        <TableHead className={classes.tableHead} style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                        width:'70px',
                                    }}
                                >
                                    <Checkbox/>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                        width:'70px',
                                    }}
                                >
                                    ID
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                    }}
                                >
                                   Product Name
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                    }}
                                >
                                    Category Name
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                    Brand
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                    Price
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                        width:'120px'
                                    }}
                                >
                                    Product Stock
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                    Status
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {products?.length > 0 ?
                                products.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    className={classes.odd}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableLink}
                                        sx={{cursor: 'pointer'}}
                                    >
                                        <Checkbox/>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableLink}
                                        sx={{cursor: 'pointer'}}
                                    >
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box
                                            sx={{
                                                display:'flex',
                                                alignItems:'center',
                                            }}
                                        >
                                            <Avatar
                                                src={row?.product_image}
                                                alt={row?.name}
                                                sx={{
                                                    height:'50px',
                                                    width:'50px',
                                                }}
                                                variant={'rounded'}
                                            />
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    ml:'16px',
                                                    textTransform:'capitalize',
                                                }}
                                            >
                                                {row?.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            
                                            sx={{
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {row?.category?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography
                                            variant="body1"
                                            
                                            sx={{
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {row?.brand?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        ${row?.price}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row?.stock_item}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row?.status}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button variant="contained" color="primary" onClick={()=>navigate(`/crm/inventory/product/basic-info/${row?.id}`)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )):
                            <TableRow>
                                <TableCell
                                    colSpan={9}
                                    align="center"
                                    sx={{
                                        height:'300px',
                                    }}
                                >
                                    No Product Available in Inventory.
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    );
};

export default InventoryProduct;