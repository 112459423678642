import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Map from "../../assets/images/treatment.webp";
import "./style.css";
import Typography from "@mui/material/Typography";
import ThreeDLogo from "../../assets/logo/logo-3d-lifestyleR-White-01.png";
import Grid from "@mui/material/Grid";
import { Autocomplete, Avatar, Box, Button, ButtonGroup, Checkbox, MenuItem, Select, TextField } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getAllClinic, getgiftcardsbuy, storebuygift } from "../../store/home";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../home/style";
import Waveloading from "../loading/Waveloading";
import PhoneIcon from '@mui/icons-material/Phone';
import SquareCard from "../crm/crmpage/crmMembership/squareCard";
import { crmCreateCard } from "../../store/crm/appointment/book-appointment";
import { findGuestUser } from "../../store/booking-widget";
import { registerUser } from "../../store/auth";
import { storePublicUserCreateCard } from "../../store/user/user-cards";
import defaultPersonImg from "../../assets/images/category.png"
import { Password } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import moment from "moment";
 
import Slider from "react-slick";

const GiftOrder = () => { 
  const classes = useStyles();
  const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState(0);
    const [squareCard, setSquareCard] = useState(null);
    const [clinicUser, setClinicUser] = useState(null);
    const [selectedClinic, setSelectedClinic] = useState();
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState(null);

    const homeStore = useSelector(state => state?.homeStore)
    const [activeGiftIndex, setActiveGiftIndex] = useState(null); 
    const [activePriceIndex, setActivePriceIndex] = useState(null);
    const [selectedGiftCardId, setSelectedGiftCardId] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null); 
    const [selectedProviderId, setSelectedProviderId] = useState(null);
    const [selectedGift, setSelectedGift] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    console.log("prices comes",activePriceIndex)
    const [phoneError, setPhoneError] = useState(false); 
    const [methodLoading, setMethodLoading] = useState(false);
    const [cardLoaded, setCardLoaded] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loadingPrice, setLoadingPrice] = useState(false);
 

    const [showArrows, setShowArrows] = useState(true);
    const sliderRef = useRef(null); 

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      swipeToSlide: true,
      slidesToScroll: 1,
      prevArrow: (
        <div
          className="custom-arrow custom-prev"
          onClick={() => {  
            console.log('Prev Button Clicked'); 
            if (sliderRef.current) {
              sliderRef.current.slickPrev(); 
            }
          }}
        >
          <i className="fa fa-chevron-left"></i>
        </div>
      ),
      nextArrow: (
        <div
          className="custom-arrow custom-next"
          onClick={() => {
            console.log('Next Button Clicked'); // Debugging click event 
            if (sliderRef.current) {
              sliderRef.current.slickNext(); // Navigate to next slide
            }
          }}
        >
          <i className="fa fa-chevron-right"></i>
        </div>
      ),
    };
  
  // Function to check screen size on load and resize
  // const checkScreenSize = () => {
  //   setShowArrows(window.innerWidth > 786); // Adjust 786px to your breakpoint
  // };

  // Add event listener on component mount and clean up on unmount
  // useEffect(() => {
  //   checkScreenSize();
  //   window.addEventListener('resize', checkScreenSize);
  //   return () => window.removeEventListener('resize', checkScreenSize);
  // }, []);

  // Slider settings with inline media query for arrow visibility
    const handleSelect = (item,index) => {
    setSelectedGift(item);
    const selectedIndex =index
      setActiveGiftIndex(selectedIndex);
      setSelectedGiftCardId(gifts[selectedIndex].id)
      setActivePriceIndex(null);
      setSelectedPrice(null);
  };



    const handlePriceClick = async (priceIndex, item) => {
        console.log("Price Index:", priceIndex);
        console.log("Selected Item:", item);

        try {
            // Set loading state to true
            setLoadingPrice(true);

            // Update state based on the selected price index
            setActivePriceIndex(priceIndex);
            setSelectedPrice(item.prices[priceIndex]?.price);
            setSelectedProviderId(item.provider_id);
            setSelectedGiftCardId(item?.id);

            // Check for the card container and initialize Square Payment if needed
            const cardContainer = document.getElementById('card-container');
            setMethodLoading(true);
            console.log("cardContainer:", cardContainer);
            console.log("cardContainerChild:", cardContainer.hasChildNodes());

            if (cardContainer && !cardContainer.hasChildNodes()) {
                console.log("Initializing payment method:", clinicUser?.active_payment_method);
                await initializeSquarePayment(clinicUser?.active_payment_method);
            }
            setCardLoaded(true);
            setMethodLoading(false);
        } catch (error) {
            console.error("Error in handlePriceClick:", error);
        } finally {
            // Set loading state back to false
            setLoadingPrice(false);
        }
    };

  
    const dispatch = useDispatch();
    const {clinic,gifts} = homeStore

    const handleButtonClick = (buttonIndex) => {
      setActiveButton(buttonIndex);
    };
    const authStore = useSelector(state => state?.authStore)
    const {user} = authStore
    useEffect(() => {
      (async () => { 
        setLoading(true)
           await dispatch(getAllClinic())
           setLoading(false)
      })()
  },[])
  useEffect(() => {
    setActivePriceIndex("")
},[ selectedClinic])
  //

const clinicOptions = clinic.data && Array.isArray(clinic.data)
? clinic.data.map((clinic) => ({
    name: clinic?.name,
    id: clinic?.id, 
    email:clinic?.email,
  }))
: [];
const sortedClinicOptions = clinicOptions.sort((a, b) =>
  a.name.localeCompare(b.name)
);
    const handleClinicSelect = async (event, newValue) => {
        setLoading(true);

        try {
            console.log(newValue)
            // Update the selected clinic
            setSelectedClinic(newValue);

            // Fetch gift cards for the selected clinic
            if (newValue && newValue.id) {
                await dispatch(getgiftcardsbuy(newValue.id));
            }

            // Find guest user if email exists
            if (newValue && newValue.email) {
                const res = await dispatch(findGuestUser({ email: newValue.email }));
                if (res?.payload?.success === "true") {
                    let guestUser =  res?.payload?.data;
                    setClinicUser(guestUser);
                }
            }
        } catch (error) {
            console.error("Error handling clinic selection:", error);
        } finally {
            setLoading(false);
            setActivePriceIndex(null);

        }
    };



  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
    phone:"",
  });
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };
  const handlePhoneChange = (event) => {
    const phoneRegex = /^\+1\d{10}$/; // Regex to match US phone number format starting with +1
    const isValidPhone = phoneRegex.test(event.target.value);
    setPhoneError(!isValidPhone);
    setFormData({ ...formData, phone: event.target.value });
  };

  const handleBlur = (event) => {
    if (event.target.id === 'fullName') {
      const nameRegex = /^[A-Za-z\s]+$/; // Regex to match alphabets and spaces only
      const isValidName = nameRegex.test(formData.fullName);
      setNameError(!isValidName);
    } else if (event.target.id === 'email') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // Basic email format validation
      const isValidEmail = emailRegex.test(formData.email);
      setEmailError(!isValidEmail);
    }
  }

  const tokenize = async (method) => {
    const tokenResult = await method.tokenize();
    if (tokenResult.status === 'OK') {
        return tokenResult.token;
    } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;   
        }

        throw new Error(errorMessage);
    }
};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Dispatch the action to get gift cards
      await dispatch(getgiftcardsbuy(selectedClinic.id));

      // Dispatch the action to find guest user
      const findGuestAction = await dispatch(findGuestUser({ email: selectedClinic.email }));

      setLoading(false);
    };

    if (selectedClinic) {
      fetchData();
    }
  }, [dispatch, selectedClinic]);


        const initializeSquarePayment = async (method) => {
            if (!window.Square) {
                 setPaymentError("Square is not loaded. Reload it or contact the provider.");
                return;
            }

            let payments;
            try {
                payments = window.Square.payments(method?.application_key, method?.merchant_id);
            } catch {
                 setPaymentError('Square credentials are invalid.');
                return;
            }

            let card;
            try {
                card = await initializeCard(payments);
                setCard(card);
            } catch (e) {
                // setPaymentError("Square credentials are invalid.");
                console.error('Initializing Card failed', e);
            }
        };

// Get location parameter from URL
    const queryParams = new URLSearchParams(location.search);
    const locationId = queryParams.get("loc");
    useEffect(() => {
        if (locationId) {
            const clinicToSelect = clinic?.data?.find((clinic) => clinic.loc.toString() === locationId);
            if (clinicToSelect) {
                console.log(clinicToSelect)

                setSelectedClinic(clinicToSelect);
                dispatch(getgiftcardsbuy(clinicToSelect.id));
                dispatch(findGuestUser({ email: clinicToSelect.email }))
                    .then((res) => {
                        if (res?.payload?.success === "true") {
                            setClinicUser(res?.payload?.data);
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching guest user:", error);
                    });
            }
        }
    }, [dispatch, locationId, clinic?.data]);

    const initializeCard = async (payments) => {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    };

    const handelbuy = async () => {
      setLoading(true);
      setPaymentError('');

      try {
        // Check if card details are valid before making API calls
        const tokenID = await tokenize(card);
        if (!tokenID) {
          setPaymentError('Invalid card details. Please enter a valid card.');
          setLoading(false);
          return;
        }

        // Find guest user
        const findGuest = await dispatch(findGuestUser({ email: formData.email }));
        if (findGuest?.payload?.success === 'true') {
          const token = findGuest?.payload?.data?.token;
          const user_id = findGuest?.payload?.data?.id;
          let invoices = [];

          if (token) {
            invoices.push({
              payment_method_id: "4",
              amount: selectedPrice,
              token: tokenID,
            });
          }

          const planFinalPayload = {
            token: token,
            user_id: user_id,
            type: 'giftcard',
            gift_card_id: selectedGiftCardId,
            provider_id: selectedProviderId,
            invoices: invoices,
            fullName: formData.fullName,
            email: formData.email,
            message: formData.message,
            amount: selectedPrice,
          };

          const res = await dispatch(storebuygift(planFinalPayload));

          if (res?.payload?.success === "true") {
            setLoading(false);
            toast.success(res?.payload?.message);
            setFormData({
              fullName: '',
              email: '',
              message: '',
              phone: '',
            });
            const data = res?.payload?.data
            console.log("data sending",data)
            navigate(`/gift-sucess`, { state: { selectedLocation: data } });

          } else {
            setLoading(false);
            toast.error(res?.payload?.message);
          }
        } else {
          // Register new guest user if not found
          const registerData = {
            name: formData.fullName,
            email: formData.email,
            social_login: "0",
            postal_code: "+1",
            phone: formData.phone,
            is_guest: "1",
            password: "3Dlifestyle!",
            default_provider_id: selectedProviderId,
          };

          const response = await dispatch(registerUser(registerData));
          const findGuestNew = await dispatch(findGuestUser({ email: formData.email }));
          const token = findGuestNew?.payload?.data?.token;
          const user_id = findGuestNew?.payload?.data?.id;
          let invoices = [];

          if (token) {
            invoices.push({
              payment_method_id: "4",
              amount: selectedPrice,
              token: tokenID,
            });
          }

          const planFinalPayload = {
            user_id: user_id,
            token: token,
            type: 'giftcard',
            gift_card_id: selectedGiftCardId,
            provider_id: selectedProviderId,
            invoices: invoices,
            fullName: formData.fullName,
            email: formData.email,
            message: formData.message,
            amount: selectedPrice,
          };

          const res = await dispatch(storebuygift(planFinalPayload));

          if (res?.payload?.success === "true") {
            setLoading(false);
            toast.success(res?.payload?.message);
            setFormData({
              fullName: '',
              email: '',
              message: '',
              phone: '',
            });
            const data = res?.payload?.data
            console.log("data sending",data)
            navigate(`/gift-sucess`, { state: { selectedLocation: data } });
          } else {
            setLoading(false);
            toast.error(res?.payload?.message);
          }
        }
      } catch (error) {
        console.error('Error during gift purchase:', error);
        setLoading(false);
        toast.error('An error occurred. Please try again.');
      }
    };
   const isBuyButtonDisabled = !(formData.fullName && formData.email && formData.phone);
  return (
    <Box sx={{ background:'#EDF2F7' }}>

      <Container maxWidth="sm" sx={{ background:'#fff' }}>
          <Autocomplete
              sx={{ mt: '10px', pt: 3 }}
              disablePortal
              clearIcon={null}
              id="combo-box-demo"
              options={clinicOptions}
              value={selectedClinic || null} // Auto-select based on selectedClinic state
              onChange={handleClinicSelect}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      sx={{ label: { mt: '-10px', zIndex: 0 } }}
                      label="Select Your Clinic Location"
                  />
              )}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === (value?.id || value === null)}
          />
           {loading ? <Waveloading fullSize /> :""}
        <>
     <Box maxWidth="sm" sx={{ mt:5 }}>
      <Grid
      container
      spacing={1}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mt: 3,
        mb: 5,
        // display: { xs: 'block', sm: 'none' },
      }}
    >
   {gifts.length > 0 ?
   <>
   <Typography  variant="h5" color="initial" >Gift Cards</Typography>
   </>
   :"" }

 
          <div style={{ width: "100%", marginTop: "10px", position: "relative" }}>
          <Slider {...settings}  
           beforeChange={(current, next) => {
       console.log("scroll",current,next)
    // When the slider changes to the next card, reset the price selection
    setActivePriceIndex(null);
  }}>
  {gifts.length > 0 ? (
    gifts.map((item, index) => (
      <Box key={index}>
        {/* Gift Card Display */} 
        <Box
          sx={{ 
            display: "flex !important",
            justifyContent: "end",
            alignItems: "center",
            cursor: "pointer",
            
          }}  
          
        >  
          <Box
            id="gift-card"
            className={`card ${activeGiftIndex === index ? "active-card" : ""}`}
            sx={{
              width: "100%",
              borderRadius: "15px",
              border: activeGiftIndex === index ? "2px solid #aa2167" : "1px solid #ddd",
              transition: "transform 0.3s ease, border 0.3s ease", 
              boxShadow:
                activeGiftIndex === index
                  ? "0px 4px 10px rgba(170, 33, 103, 0.5)"
                  : "0px 2px 5px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <div className="card-inner">
              <div className="front" style={{ position: "relative", borderRadius: "15px", overflow: "hidden" }}>
                <img
                  src={Map}
                  className="map-img"
                  alt="Map"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <Grid container sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                  <Grid item xs={6}>
                    <img
                      src={ThreeDLogo}
                      alt="3D Lifestyle Logo"
                      className="logo-3d"
                      // style={{ width: "80px" }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold" }}>
                      Gift Card
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="card-no" sx={{ mt: 2 }}>
                  <Grid item xs={12} id="gift-code">
                    <Typography sx={{ color: "#fff", fontSize: "7px", fontWeight: "bold" }}>
                      {item.title}
                    </Typography>
                    {/* <Typography sx={{ color: "#fff", fontSize: "12px", mt: 1 }}>
                      Gift Card Number
                    </Typography> */}
                  </Grid>
                </Grid>  
                <Grid container className="name" sx={{ mt: 2 }}>  
                  <Grid item xs={12}>
                    <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                      {item?.expiry_date ? moment(item.expiry_date).format("dddd, MMMM D, YYYY") : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                  <Grid item xs={6}>
                    <a href="https://3dlifestyle.ca" style={{ textDecoration: "none", color: "#fff", fontSize: "12px" }}>
                      3dlifestyle.ca
                    </a>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <a href="tel:4168500231" style={{ textDecoration: "none", color: "#fff", fontSize: "12px" }}>
                      416-850-0231
                    </a>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>
        </Box>

          <Typography variant="h5" color="initial" sx={{ fontWeight:'600' , marginTop:'30px'}}>
              Select Amount
          </Typography>
          <Box sx={{ width: "100%", mt: 2 ,display:"flex", justifyContent:"center"}}>
              <ButtonGroup
              variant="contained"
              aria-label="Basic button group"
              sx={{ display: "flex", flexWrap: "wrap", boxShadow: "none" ,justifyContent:"center"}}
            >
              {item?.prices &&
                item.prices.map((priceObj, priceIndex) => (
                  <Button
                    key={priceIndex}
                    onClick={() => handlePriceClick(priceIndex, item)}
                    disabled={loadingPrice || activePriceIndex === priceIndex} // Disable if loading or already selected
                    style={{
                      backgroundColor: activePriceIndex === priceIndex ? "#aa2167" : "#ffffff",
                      color: activePriceIndex === priceIndex ? "#fff" : "#000000",
                      fontSize: "18px", 
                      boxShadow: "0px -4px 8px rgba(99, 99, 99, 0.2), 0px 4px 8px rgba(99, 99, 99, 0.2)",
                      minWidth: "100px",
                      margin: "5px",
                    }}
                  > 
                    {`CA$${priceObj.price.toFixed(2)}`}
                  </Button>
                ))}
            </ButtonGroup>
          </Box>
        {/* )} */}
      </Box>
    ))
  ) : (
    <Box>
      <Typography
        variant="body1"
        sx={{
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        No Gift Cards Available
      </Typography>
    </Box>
  )}
</Slider>

          </div>

    </Grid>

      {/* {activeGiftIndex !== null && ( 
        <ButtonGroup
          variant="contained"
          aria-label="Basic button group"
          sx={{ display: 'flex', flexWrap: 'wrap', boxShadow: 'none', mt: 2 }}
        >
          {gifts[activeGiftIndex]?.prices && gifts[activeGiftIndex].prices.map((priceObj, priceIndex) => (
  <Button
    key={priceIndex}
    onClick={() => handlePriceClick(priceIndex)}
    style={{
      backgroundColor: activePriceIndex === priceIndex ? '#aa2167' : '#ffffff',
      color: activePriceIndex === priceIndex ? '#fff' : '#000000',
      fontSize: '18px',
      boxShadow: '0px -4px 8px rgba(99, 99, 99, 0.2), 0px 4px 8px rgba(99, 99, 99, 0.2)',
      minWidth: '100px', 
      margin: '5px' 
    }}
  >
    {`CA$${priceObj.price.toFixed(2)}`} 
  </Button>
))}

        </ButtonGroup>
      )} */}


    <Typography variant="h5" color="initial" sx={{ fontWeight:'600' , marginTop:'30px'}}>
        Enter Your Details
    </Typography>
    <Box sx={{ paddingBottom: '100px' }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
        <PersonIcon sx={{ mr: 2, my: 0.5, color: '#000' }} />
        <TextField
          id="fullName" 
          label="Full Name"
          fullWidth
          variant="standard"
          value={formData.fullName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={nameError}
          helperText={nameError ? 'Enter a valid name (alphabets and spaces only)' : ''}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
        <EmailIcon sx={{ mr: 2, my: 0.5, color: '#000' }} />
        <TextField
          id="email"
          label="Email Address"
          fullWidth
          variant="standard"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={emailError}
          helperText={emailError ? 'Enter a valid email address' : ''}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
        <PhoneIcon sx={{ mr: 2, my: 0.5, color: '#000' }} />
        <TextField
          id="phone"
          label="Phone Number"
          fullWidth
          variant="standard"
          error={phoneError}
          helperText={phoneError ? 'Enter a valid phone number starting with +1' : ''}
          // InputProps={{ startAdornment: '+1' }}
          value={formData.phone}
          onChange={handlePhoneChange}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
        <MessageIcon sx={{ mr: 2, my: 0.5, color: '#000' }} />
        <TextField
          id="message"
          label="Personal Message Here"
          multiline
          fullWidth
          variant="standard"
          value={formData.message}
          onChange={handleChange}
        />
      </Box>
        <Box style={{ marginTop: "20px" }}>
            <Grid  item xs={12} >
                {methodLoading ? <Box>Loading...</Box> : ""}
                {cardLoaded ? <Box variant="h5" color="initial" sx={{ fontWeight:'300' , marginTop:'30px'}}>Enter Your Card Detail:</Box> : ""}

                <Box id="card-container"></Box>
            </Grid>
        </Box>
      <Box>
        <Button
          variant="contained"
          sx={{ width: '100%', background: '#aa2167', mt: 3 }}
          onClick={handelbuy}
          disabled={isBuyButtonDisabled}
        >
          <ShoppingCartIcon sx={{ mr: 1, width: '23px' }} /> Buy Now
        </Button>
      </Box>
    </Box>
      </Box>
      </>
      </Container>

    </Box>
  );
};

export default GiftOrder;
