import React, {useState} from 'react';
import {Box, Button, FormControl, FormHelperText, FormLabel, Grid, IconButton, Modal, TextareaAutosize, TextField, Tooltip, Typography} from "@mui/material";
import FormDateInput from "../../../../components/form/FormDateInput";
import {MdDeleteOutline} from "react-icons/md";
import {confirmation, handleDelete, parseTime, timeSlots} from "../../../../services/utills";
import * as yup from "yup";
import * as Yup from "yup";
import {useFormik} from "formik";
import {storeBlockHour, storeStaffBreakTime} from "../../../../store/clinics/clinicSettings/business-hours";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {inputStyle} from "../../../../components/form/formInput";
import Loader from "../../../../components/loader/loader";
import CustomButtoncolor from '../../../../components/custom-button/customColorButton';
import Waveloading from '../../../loading/Waveloading';
import ClearIcon from '@mui/icons-material/Clear';
import toast from "react-hot-toast";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: '41px 23px',
    borderRadius:'6px',
    '@media (max-width: 480px)': {
      width: "95%",
      p: '31px 13px',
    },
    gap:"5px"
  };


  const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": {
        color:'#000!important',
    }
}
const StaffBreakTimeModal = ({onRescheduleSuccess,staff_id}) => {
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch()
    const params = useParams()


    const validationSchema = yup.object({
        notes: yup.string().min(3, "Notes  is Too Short").required('Notes is required'),
        date: Yup.date().typeError("Staff holiday date is required").required("Staff holiday date is required"),
        start_time: yup
            .date()
            .transform((value, originalValue) =>
                originalValue ?  parseTime(originalValue) : null
            )
            .required("Start time is required").typeError("Start time is required"),
        end_time: yup
            .date() 
            .transform((value, originalValue) =>
                originalValue ? parseTime(originalValue) : null
            )
          
            .required("End time is required").typeError("End time is required")
            .min(yup.ref("start_time"), "End time must be after start time"),
    });


    const {values, handleSubmit, setFieldValue,resetForm, handleChange, touched, errors} = useFormik({
        initialValues: {
            date: '',
            start_time: '',
            end_time: '',
            notes:'',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res = await dispatch(storeBlockHour({...data}))
            console.log(res?.payload)
            if (res?.payload?.errors) {

                const {errors} = res?.payload
                if (errors) {
                    toast.error(errors, { duration: 5000 });
                }
                if (errors?.date) { 
                    setFieldTouched("date", true)
                    setFieldError("date", errors?.date)
                }
                if (errors?.start_time) {
                    setFieldTouched("start_time", true)
                    setFieldError("start_time", errors?.start_time)
                }
                if (errors?.end_time) {
                    setFieldTouched("end_time", true)
                    setFieldError("end_time", errors?.end_time)
                }
                if (errors?.notes) {
                    setFieldTouched("notes", true)
                    setFieldError("notes", errors?.notes)
                }
                if (onRescheduleSuccess) {
                    onRescheduleSuccess();
                }
                setLoading(false)
            } else if (res?.payload?.success === "true") {
                resetForm()
                if (onRescheduleSuccess) {
                    onRescheduleSuccess();
                }
                setLoading(false)
                handleClose()
            }
        },
    });


    const handleDeleteStaffBreak = async (row) => {
        if (await confirmation()) {
            setLoading(true);
            let finalData = { 
                id: row?.id,
                provider_id: params?.clinic_id,
                staff_id: staff_id,   
            }
            await handleDelete(dispatch, "staffBreak", finalData);
            setLoading(false);
        }
    }


    return (
        <Grid container>
           
             <Button
                                sx={{
                                    background: 'red',
                                    px: '24px',
                                    border: '1px solid red',
                                    color: '#fff',
                                    marginRight:{sm:2, xs:2,lg:0,md:0},
                                   width:"100%",
                                    marginBottom:{sm:0, xs:2},  
                                    "&:hover": {
                                      background: '#fff',
                                      color: 'red',
                                    }
                                  }}
                               onClick={handleOpen}
                                variant={'contained'}>
                                Block Hours
                            </Button>
            {loading?<Waveloading fullSize/>:''}
            <Modal
      style={{}}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
        <Grid item xs={12} sx={{mt: 2 ,  display:"flex" , justifyContent:"space-between",alignItems:"center"}} >
                <Typography variant={'h4'} sx={{mb: 1}}>
                 Add Block Hours
                </Typography>
                
                <ClearIcon style={{cursor:"pointer"}} onClick={handleClose} />
               
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
                <form onSubmit={handleSubmit}> 

                    <Grid container sx={{justifyContent: 'space-between', display: 'flex'}}>
                    <Grid item xs={12} lg={12} mb={'16px'} >   
                            <FormDateInput 
                                name={"date"}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}  
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} mb={'16px'} >
                            <Autocomplete
                                name={'start_time'}
                                options={timeSlots}
                                getOptionLabel={(option) => option}
                                value={values?.start_time}
                                renderInput={(params) => {
                                    const { inputProps, helperText, error,...rest } = params;
                                    return(<TextField   {...rest}    inputProps={{ ...inputProps }} sx={inputStyle} placeholder={"Start Time"}/>
                                    )}}

                                onChange={(event, newValue) => {
                                    setFieldValue("start_time", newValue);
                                }}

                                // getOptionDisabled={getStartTimeOptionDisabled(selectedEndTime)}
                            />
                            {errors?.start_time && touched?.start_time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.start_time}</FormHelperText>}

                        </Grid>
                        <Grid item xs={12} lg={12} mb={'16px'}  >
                            <Autocomplete
                                name={'end_time'}
                                options={timeSlots}
                                getOptionLabel={(option) => option}
                                value={values?.end_time}
                                renderInput={(params) => {
                                    const { inputProps, helperText, error,...rest } = params;
                                    return(<TextField   {...rest}    inputProps={{ ...inputProps }} sx={inputStyle} placeholder={"End Time"}/>
                                    )}}

                                onChange={(event, newValue) => {
                                    setFieldValue("end_time", newValue);
                                }}
                                // getOptionDisabled={getEndTimeOptionDisabled(selectedStartTime)}
                            />
                            {errors?.end_time && touched?.end_time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.end_time}</FormHelperText>}

                        </Grid>
                      
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{...labelStyles}}>Notes:</FormLabel>
                            <textarea
            className="MuiInputBase-input MuiOutlinedInput-input"
            rows={3}
           name='notes'
            value={values?.notes}
            onChange={handleChange}  
            style={{
                width: '100%',
                resize: 'vertical', 
                padding: '8px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                fontFamily: 'inherit', 
                fontSize: '1rem', 
            }}
        />
                           
                            {errors?.notes && touched?.notes && <FormHelperText
                                sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors?.notes}</FormHelperText>}

                        </FormControl>
                    </Grid>
                       
                        <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                            <Button
                                sx={{
                                    backgroundColor: '#09ABF1',
                                    borderColor: '#09ABF1',
                                    color: '#fff',
                                    mt: '16px', 
                                    height: '44px',
                                    "&:hover": {
                                        color: '#09ABF1',
                                        borderColor: '#09ABF1',
                                    }
                                }}
                                type={'submit'}
                                variant={'outlined'}>
                                Save Block Hours
                            </Button>
                        </Grid>
                    
                    </Grid>
                </form> 
            </Grid>
        </Box>
            {/* {loading?<Loader fullSize/> :<></>} */}
         
            </Modal>
        </Grid>
    );
};

export default StaffBreakTimeModal;