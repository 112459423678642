import { Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Modal, Radio, RadioGroup, Select, Typography, Container, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButtoncolor from '../custom-button/customColorButton';
import { storeAppointmentNotes } from '../../store/crm/appointment/notes';
import { getAppointmentDetails, getAppointmentcalender } from '../../store/crm/appointment';
import { successConfirmation } from '../../services/utills';
import { getStaff } from '../../store/crm/reports';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../custom-button/customButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Waveloading from '../../pages/loading/Waveloading';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useParams } from 'react-router';
import { getCrmNotes, storeCrmNotes } from '../../store/crm/crm/crm-details';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = { 
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": {
        color:'#000!important',
    }
}
 
const AddNotesModal = ({appointmentDetail,wid}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  console.log("customer id",appointmentDetail)    
    const [selectedStaff,setSelectedStaff] = useState({})
    const [loading,setLoading] = useState(false)
    const [comments,setComments] = useState('')
    const [type,setType] = useState('Staff')
    const crmStaffStore = useSelector(state => state?.crmStaffsStore)
    const {crmStaffs = []} = crmStaffStore 
   console.log(crmStaffs)
    const dispatch = useDispatch()
 

    useEffect(()=>{
        (async ()=>{
            await dispatch(getStaff())
        })()
    },[])
    useEffect(() => {
        // Assuming you fetch your staff data here and set it to crmStaffs
        // After fetching, set the selectedStaff to the first item in the array
        if (crmStaffs && crmStaffs.length > 0) {
            setSelectedStaff(crmStaffs[0]);
        }
    }, [crmStaffs]);
    const handleSubmit = async () => {
        if (comments?.length > 3 && selectedStaff?.id){
            let finalData ={
                booking_id:appointmentDetail?.id,
                staff_id:selectedStaff?.id,
                type:type,
                description:comments   
            }
            setLoading(true)  
 console.log("data",finalData)

            let res =await dispatch(storeAppointmentNotes(finalData))
          
            if (res?.payload?.success === 'true'){
    
                 dispatch(getAppointmentcalender(appointmentDetail?.id))
                 await dispatch(getCrmNotes(appointmentDetail?.customer?.id))
                 setLoading(false)
                 await successConfirmation(`${type} Note Added Successfully`)
                 handleClose()
                setType('Staff')          
                setSelectedStaff({})
                setComments('')   

               
            }

        }
    }
  return (

<>

{/* <CustomButtoncolor
    handleClick={handleOpen}
     mainColor={"#09ABF1"}
     size="md"
     val='Add Notes'
     style={{
      padding:'7px 5px',
      fontSize: "18px",
      minWidth:'max-content',
      borderRadius: "6px!important",
      width: "100%",
      "@media (minWidth: 420px)": {
          width: 'auto'
      },
  }}                                  
  /> */}
<Box sx={{ width:{sm:'auto', xs:wid}, textAlign:'center' }}>

<Button variant="contained"
sx={{ backgroundColor:'#09ABF1', color:'#fff',px:4,width:{sm:'auto', xs:wid} }}
onClick={handleOpen}
>
 <TextSnippetIcon sx={{mr:1}}/>   Add a Note
  
</Button>
</Box>

 
    <Modal
 
     style={{}}
       open={open}
       onClose={handleClose}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
        <Container maxWidth="xl">
        {loading ? <Waveloading fullSize/>:''}
        <Box sx={style}>     
       
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
                    <IconButton   
                            size="small"
                            onClick={() => setOpen(false)}    
                            style={{ color: "red" }} 
                        >
                           
                            <CloseRoundedIcon  style={{fontSize: '2rem' }} /> 
                        </IconButton>
                    </Box>
        <Box
         sx={{
                background:"#fff",  
                minHeight:'100px',  
                borderRadius:'8px',
                p:{sm:'24px', xs:'8px'},
            }}     
        >
            <Typography       
                sx={{   
                    fontFamily:'helvetica-world-bold',   
                    fontSize:'20px',
                    mb:"24px",
                }}
            >
                Add New Notes
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                    <FormControl fullWidth>
                        <FormLabel
                            sx={{...labelStyles}}  
                        >
                            Staff Name  
                        </FormLabel>
                        <Select
                            size={'small'}
                            value={selectedStaff?.id || ''}                             
                            displayEmpty
                            MenuProps={MenuProps}
                        >
                            {crmStaffs?.data?.length > 0 && crmStaffs?.data.map((staff,key) => (
                                <MenuItem
                                    key={key} 
                                    value={staff?.id}
                                    onClick={()=>setSelectedStaff(staff)}
                                >
                                    {staff?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Notes</FormLabel>
                        <RadioGroup
                            row
                            value={type}               
                            onChange={(e)=>setType(e.target.value)}
                            sx={{
                                  '@media  (max-width: 1540px) ': {justifyContent:'start' },
                                justifyContent:'space-between',
                                flexWrap:'nowrap',
                            }}
                        >
                            <FormControlLabel
                                value="Medical"
                                label="Medical"
                                control={<Radio  sx={{mr:"10px"}}/>}
                            />
                            <FormControlLabel
                                value="Staff"
                                label="Staff"
                                control={<Radio sx={{mr:"10px"}} />}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
        <FormControl fullWidth>
<FormLabel sx={{...labelStyles}}>Notes</FormLabel>
        <textarea
            className="MuiInputBase-input MuiOutlinedInput-input"
            rows={3}
            placeholder=""  
            value={comments}
            onChange={(e)=>setComments(e.target.value)}
            style={{
                width: '100%',
                resize: 'vertical', // Allow vertical resizing
                padding: '8px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                fontFamily: 'inherit', // Use the same font as Material-UI inputs
                fontSize: '1rem', // Use the same font size as Material-UI inputs
            }}
        />
                        {/* <TextField
                            size={'small'}
                            variant="outlined"
                            placeholder={""}
                            value={comments}
                            onChange={(e)=>setComments(e.target.value)}
                        /> */}
                    </FormControl>
                </Grid>
                <Grid xs={12} item>
                    <CustomButton val={'Save'} handleClick={handleSubmit}/>
                </Grid>
            </Grid>
        </Box>
        </Box>
        </Container>
       

     </Modal>
     </>
  )
}

export default AddNotesModal 