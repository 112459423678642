import { Box, Typography, Grid } from '@mui/material'
import React from 'react'

const TotalMemberNamount = (props) => {
  const {color,total69MemberTitle,total69Member, total69MemberAmount,total99MemberTitle, total99Member, total99MemberAmount,total149MemberTitle, total149Member, total149MemberAmount }= props
  return (
    
    <Box sx={{ mt:2 }}>
      <Grid container >
        <Grid item lg={6}>
          
        </Grid>
      </Grid>
      <Typography sx={{ mt: 2, color: color, fontSize: "18px" }}>
      <span style={{ fontWeight: 900 }}>{total69MemberTitle}</span> {total69Member} <span style={{ fontWeight: 900 }}> & Amount </span> $ {total69MemberAmount}
    </Typography>
  {/*  <Typography variant="body1" sx={{ mt:2, color:color }}>
    {total149MemberTitle} {total149Member} & Amount {total149MemberAmount}
    </Typography>*/}
    <Typography  sx={{ mt:2, color:color ,fontSize:"18px", pb:{sm:0, xs:3}}}>
    {total99MemberTitle} {total99Member} & Amount  ${total99MemberAmount}
    </Typography>
  </Box>
  )
}

export default TotalMemberNamount