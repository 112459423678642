import React from 'react'
import CrmLayout from '../../../layout/CRMLayout';
import { Box, Grid, Typography } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useLocation, useNavigate, useParams } from "react-router";

import CircleIcon from '@mui/icons-material/Circle';
import InventoryIcon from '@mui/icons-material/Inventory';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CategoryIcon from '@mui/icons-material/Category';


const Report = () => {
     const navigate = useNavigate();
    const handleNavigate = (url) => {
       
        navigate(url);
      
    };

    // const sideData = [
    //     { name: "General Reports", logo: <SummarizeIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/crm/general-report",
    //     active:['general-report',] },
    //     {
    //         name: "Sale Reports",
    //         logo: <ReceiptIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
    //         url: "/crm/sale-report" ,
    //     active:['sale-report',]
    //     },
    //     { name: "Gift Card Reports", logo: <CardGiftcardIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/crm/gift-card-report" ,
    //     active:['gift-card-report',] },
    //     { name: "Members Reports", logo: <RememberMeIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />, url: "/crm/member-report",
    //     active:['member-report',] },
    //     {
    //         name: "Staff Reports",
    //         count: "Staff Reports",
    //         logo: <GroupsIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
    //         url: "/crm/staff-sale-report" ,
    //     active:['staff-sale-report',]
    //     },
    //     {
    //         name: "Category Reports",
    //         logo: <CategoryIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
    //         url: "/crm/category-sale-report",
    //         active:['category-sale-report',]
    //     },
    //     {
    //         name: "Wallet Reports",
    //         logo: <WalletIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,
    //         url: "/crm/wallet-report",
    //     active:['wallet-report',]
    //     },
        

    //     { name: "Attandance", logo: <AssignmentIndIcon style={{ color: "black", fontSize:'33px', position:'relative', top:'-10px' }} />,         url: "/crm/attendance-report",
    //     active:['attendance',] },


    // ];

    const inventoryRoutes = [
        {
            name: "PRODUCT",
            logo: <InventoryIcon sx={{fontSize:"26px"}}/>,
            url: "/crm/inventory/product",
            active:['product',]
        },
        {
            name: "ADD PRODUCT",
            logo: <AddIcon sx={{fontSize:"26px"}}/>,  
            url: "/crm/inventory/product/basic-info",
            active:['basic-info','pricing','inventory','brand','category',"vendor","product-image"]
        },
        {  
            name: "PURCHASED",   
            logo: <CheckIcon sx={{fontSize:"26px"}}/>,
            url: "/crm/inventory/purchase",
            active:['purchase',]
        },
        {
            name: "INV CONSUME",
            logo: <CategoryIcon sx={{fontSize:"26px"}}/>,
            url: "/crm/inventory/inv-consume",
            active:['inv-consume',]
        },
    ]



  return (
    <CrmLayout>
        
    <Grid container spacing={2} sx={{ padding: "20px 20px 200px 20px" }}>
    {inventoryRoutes.map((item, index) => (
        <Grid item xs={6} sm={4} md={2} key={index}>
            <Box
                    onClick={() => handleNavigate(item?.url)}
                sx={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: "8px",
                    padding: "16px",
                    textAlign: "center",
                    cursor: "pointer",
                    height: "130px", // Fixed height for uniformity
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center", // Ensures icon and text are close
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                        color: "#9d7658",
                    },
                }}
            >
                {/* Icon */}
                <Box
                    sx={{
                        fontSize: "2rem",
                        position: item.name === "My Bookings" ? "relative" : "initial",
                        top: item.name === "My Bookings" ? "-10px" : "0px",
                    }}
                >
                    {item.logo}
                </Box>

                {/* Text */}
                <Typography
                    variant="body2"
                    sx={{
                        mt: 1,
                        textAlign: "center",
                        whiteSpace: "normal",
                        lineHeight: "1.2",
                        maxWidth: "100%",
                        position: item.name === "My Bookings" ? "relative" : "initial",
                        top: item.name === "My Bookings" ? "-10px" : "0px",
                    }}
                >
                    {item.name}
                </Typography>
            </Box>
        </Grid>
    ))}
</Grid>
    </CrmLayout>
  )
}

export default Report