import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Grid, IconButton, TextField, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getAllClinicsStaff, getStaffScheduleData} from "../../../../store/clinics/clinicSettings/clinic-staff";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Autocomplete from "@mui/material/Autocomplete";
import {inputStyle} from "../../../../components/form/formInput";
import {MonthView} from "../../../../components/calender-table/monthView";
import WeekView from "../../../../components/calender-table/weekView";
import {DayView} from "../../../../components/calender-table/dayView";
import Loader from "../../../../components/loader/loader";
import {useNavigate} from "react-router";
import Waveloading from "../../../loading/Waveloading";


 const arrowStyle = {borderRadius:'unset',background:'#EDF2F7',height:'44px',width:"46px"}

const typeArray =[
    'Day',
    'Week',
    'Month',
]
const StaffCalender = () => {
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('Month')
    const [selectedStaff, setSelectedStaff] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()

    const clinicsStaffSliceStore = useSelector(state => state?.clinicsStaffSliceStore)
    const {staffs, scheduleData} = clinicsStaffSliceStore
    const user = useSelector(state => state?.authStore?.user)

    useEffect(() => {
        (async () => {
            if (!clinicsStaffSliceStore?.staffs?.length) {
                setLoading(true)
            }
            console.log("all clinic")  
            await dispatch(getAllClinicsStaff(params?.clinic_id))
            await handleStaffCalender()
    
            setTimeout(() => {
                setLoading(false);
            }, 10000);
        })()
    }, [params?.clinic_id, startDate, endDate,clinicsStaffSliceStore?.staffs?.length])
    // useEffect(() => {
       
    //     (async () => {
    //         console.log("staff calendar")
    //         setLoading(true)
      
    //         setLoading(false)
    //         console.log("staff calendar get")
    //     })()
       
    // }, [params?.clinic_id,startDate, endDate,clinicsStaffSliceStore?.staffs?.length])

  

    useEffect(() => {
        setSelectedStaff(staffs)
    }, [staffs])
  
    const handleStaffCalender =async () =>{
        console.log("get staff")
        if (staffs?.length) {
            console.log("get staff",staffs?.length)
            if (type ==='Month'){
                const firstDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 2);
                const lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
               
                await dispatch(getStaffScheduleData({
                    provider_id: params?.clinic_id,
                    // staff_ids:[17,34],
                    from: firstDayOfMonth,
                    to: lastDayOfMonth
                })
               
                )
            }else {
                // setLoading(true)
                await dispatch(getStaffScheduleData({
                    provider_id: params?.clinic_id,
                    // staff_ids:[17,34],
                    from: startDate,
                    to: endDate
                }))
            }
        }
    }

    const handleDateChange = (direction) => {
        let newStartDate = new Date(startDate);
        let newEndDate = new Date(endDate);
        const increment = direction === "left" ? -1 : 1;

        switch (type) {
            case "Day":
                newStartDate.setDate(newStartDate.getDate() + increment);
                newEndDate.setDate(newEndDate.getDate() + increment);
                break;
            case "Week":
                newStartDate.setDate(newStartDate.getDate() + increment * 7); 
                newEndDate.setDate(newEndDate.getDate() + increment * 7);
                break;
            case "Month":
                newStartDate.setMonth(newStartDate.getMonth() + increment);
                newEndDate.setMonth(newEndDate.getMonth() + increment);
                break;
            default:
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    const formatDate = () => {
        if (type === 'Day') {
            return weekday[startDate?.getDay()] + " - " + startDate.toLocaleDateString("en-US", {month: "short", day: "numeric"});
        } else if (type === 'Week') {
            return startDate.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric"
            }) + "-" + endDate.toLocaleDateString("en-US", {month: "short", day: "numeric"});  
        } else if (type === 'Month') {
            return startDate.toLocaleDateString("en-US", {month: "short", year: "numeric"});
        }
    }

   const handleEdit = (data)=>{
       navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/hours/${data?.id}`)
   }
    return (
        <Box
            sx={{
                height: '100%',
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "7px",
            }}
        >
            {/*<StaffWorkingHours*/}
            {/*    staff={staffData}*/}
            {/*    handleStaffCalender={handleStaffCalender}*/}
            {/*    open={staffData !== null}*/}
            {/*    setOpen={() => setStaffData(null)}*/}
            {/*/>*/}
            {loading ? <Waveloading fullSize/> : ""}
            <Box sx={{p: 3,}}>
                <Typography variant={'h4'}>Staff Schedule</Typography>
            </Box>
            <Grid container p={"16px"}>
                <Grid item xs={12} mb={'16px'} sx={{display: 'flex'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', mr: '16px'}}>
                        <IconButton
                            sx={{...arrowStyle, borderBottomLeftRadius: '7px', borderTopLeftRadius: '7px'}}
                            onClick={() => handleDateChange("left")}
                        >
                            <KeyboardArrowLeftIcon sx={{color: '#3671EF'}}/>
                        </IconButton>
                        <Typography sx={{
                            p: '10px 30px',
                            width: "max-content",
                            border: "1px solid #EDF2F7"
                        }}> {formatDate()}</Typography>
                        <IconButton
                            sx={{...arrowStyle, borderBottomRightRadius: '7px', borderTopRightRadius: '7px'}}
                            onClick={() => handleDateChange("right")}
                        >
                            <KeyboardArrowRightIcon sx={{color: '#3671EF'}}/>
                        </IconButton>
                    </Box>
                    <Autocomplete
                        sx={{width: '130px'}}
                        options={typeArray}
                        getOptionLabel={(option) => option}
                        value={type}
                        disableClearable
                        onChange={(event, value) => {
                            setType(value)
                            if (value === 'Week') {
                                const end = new Date(startDate);
                                end.setDate(end.getDate() + 6);
                                setEndDate(end);
                            } else if (value === 'Month') {
                                const end = new Date(startDate);
                                end.setMonth(end.getMonth() + 1);
                                setEndDate(end);
                            } else {
                                setEndDate(startDate)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField {...params} sx={inputStyle}/>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>  
                    {
                        type === 'Month'  ?
                            <MonthView
                                startDate={startDate}
                                data={scheduleData?.month_view}
                                setStaffData={handleEdit}
                            />
                            : type === 'Week' ?
                            <WeekView
                                year={startDate.getFullYear()}
                                month={startDate.getMonth()}
                                day={startDate}
                                data={scheduleData?.data}
                                setStaffData={handleEdit}
                            /> :
                            type === "Day" ?
                                <DayView
                                    data={scheduleData}
                                    date={startDate}
                                    setStaffData={handleEdit}
                                />
                                : <></>
                    }
                </Grid>
            </Grid>
        </Box>
    );
};


export default StaffCalender;