import React, {useEffect, useRef, useState} from 'react';
import {Box, useMediaQuery,Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import { useTheme } from '@mui/material/styles'
// import {fetchChatsContacts, fetchUserProfile, removeSelectedChat,} from "../../../store/crm/chat";
import SidebarLeft from "./SidebarLeft";
import ChatContent from "./chatContent";
import { getAllClinics, getAllMessages, getUserDetails, ReadMessageuser, SendMsgss } from '../../../store/user/Chat-modue';
import Loader from '../../../components/loader/loader';
import Footer from "../../home/Footer";


const isToday = date => {
    const today = new Date()
    return (
        new Date(date).getDate() === today.getDate() &&
        new Date(date).getMonth() === today.getMonth() &&
        new Date(date).getFullYear() === today.getFullYear()
    )
} 
export const getInitials = string => {
    if (!string) return ''; // Check if string is undefined or null
    return string.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
};

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }
    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }   

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

const Chat = () => {  
    const [userStatus, setUserStatus] = useState('online')
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false)  
    const [userProfileLeftOpen, setUserProfileLeftOpen] = useState(false)
    const [userProfileRightOpen, setUserProfileRightOpen] = useState(false)
     const theme = useTheme()
    const dispatch = useDispatch()
    const hidden = useMediaQuery(theme.breakpoints.down('lg'))
    const store = useSelector(state => state.chat)

    const smAbove = useMediaQuery(theme.breakpoints.up('sm'))
       const sidebarWidth = smAbove ? 370 : 300
       const mdAbove = useMediaQuery(theme.breakpoints.up('md'))   
 const authStore = useSelector(state => state?.authStore);
    const { user } = authStore;

    const statusObj = {  
        busy: 'error',
        away: 'warning',     
        online: 'success',
        offline: 'secondary'   
    }       
                              
    const ws = useRef(null); 
 
  const UserChatStore = useSelector((state) => state?.UserChatStore);
  console.log("data received from chatclinic",UserChatStore?.clinicscaht)
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);
        try {
            // Check if userProfile is not already fetched
            // if (!UserChatStore?.userProfile) {    
                await dispatch(getUserDetails());
            // }
            await dispatch(getAllClinics())
            const provider_id = UserChatStore?.userProfile?.provider_id;
            console.log("provder_id",provider_id,UserChatStore?.userProfile)
            const user_id = UserChatStore?.userProfile?.id;

            // if (user) {
            //     await dispatch(getAllMessages(user?.provider_id));
            // } 

            // if (user) {

                await dispatch(ReadMessageuser(user?.id));
                await dispatch(getAllMessages(user?.default_provider_id))

            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally { 
            setLoading(false);
        }
    };

    fetchData(); 
}, [dispatch,]); 


//     useEffect(() => {
//         dispatch(getUserDetails());
//         const provider_id=UserChatStore?.userProfile?.provider_id
//         const user_id=UserChatStore?.userProfile?.id
//         dispatch(getAllMessages(provider_id));  
//         dispatch(ReadMessageuser(user_id))
//         dispatch(getAllMessages(provider_id));  
//     }, [dispatch]);

 
    console.log("data all",UserChatStore)
    const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen)
    const handleUserProfileLeftSidebarToggle = () => setUserProfileLeftOpen(!userProfileLeftOpen)
    const handleUserProfileRightSidebarToggle = () => setUserProfileRightOpen(!userProfileRightOpen)
    if (!user?.defaultProvider?.is_inbox_enable) {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: `calc(100vh - 109px)`,
                    backgroundColor: 'background.paper',
                }}
            >
                <Typography variant="h6" color="textSecondary">
                    Chat functionality is not enabled for your clinic.
                </Typography>
            </Box>
        );
    }
    return (
      <Box
      sx={{
          width: '100%',  
          display: 'flex', 
          borderRadius: 1,    
          overflow: 'hidden',
          position: 'relative', 
          backgroundColor: 'background.paper',   
          height: `calc(100vh - 109px)`,    
      }} 
      >    
        <SidebarLeft      
              store={UserChatStore}      
              hidden={hidden}
              mdAbove={mdAbove}
              dispatch={dispatch}
              statusObj={statusObj}
              userStatus={userStatus}  
            //   selectChat={selectChat} 
              getInitials={getInitials}
              sidebarWidth={sidebarWidth}  
              setUserStatus={setUserStatus} 
              leftSidebarOpen={leftSidebarOpen}
            //   removeSelectedChat={removeSelectedChat}
              userProfileLeftOpen={userProfileLeftOpen}
              formatDateToMonthShort={formatDateToMonthShort}
              handleLeftSidebarToggle={handleLeftSidebarToggle}
              handleUserProfileLeftSidebarToggle={handleUserProfileLeftSidebarToggle}
          />
         {/* {loading ? <Loader fullSize /> : ""}    */}

          <ChatContent
              store={UserChatStore}
              hidden={hidden}
              SendMsgs={SendMsgss}
              mdAbove={mdAbove} 
              dispatch={dispatch}
              statusObj={statusObj} 
              getInitials={getInitials}      
              sidebarWidth={sidebarWidth}
              userProfileRightOpen={userProfileRightOpen}
              handleLeftSidebarToggle={handleLeftSidebarToggle}
              handleUserProfileRightSidebarToggle={handleUserProfileRightSidebarToggle} 
          />
        <Footer />
      </Box> 
    );
};
 
export default Chat;